angular.module('pl-shared')

  .directive('longTextQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/long-text-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id
      },
      link: function($scope, $element, $attrs) {
        // Auto-adjust the number of rows displayed on the textarea to match the view height
        var txt = $element.find('textarea')[0]

        txt.rows = 2
        var clientHeight2 = txt.clientHeight
        txt.rows = 1
        var lineHeight = clientHeight2 - txt.clientHeight
        var extraHeight = txt.clientHeight - lineHeight

        function adjustRows() {
          if (txt.scrollHeight === txt.clientHeight) txt.rows = 1 // shrink because scrollHeight does not decrease
          txt.style.overflow = 'hidden'
          var rows = (txt.scrollHeight - extraHeight) / lineHeight
          if (isNaN(rows)) rows = 1
          txt.rows = Math.ceil(rows) // ceil to make sure we always have enough height (thanks FF)
          txt.style.overflow = ''
        }

        $scope.$watch('ctrl.model', adjustRows)
        angular.element(window).on('resize', adjustRows)
        $scope.$on('$destroy', function() { angular.element(window).off('resize', adjustRows) })
      }
    }

  })
