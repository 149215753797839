angular.module('pl-shared')

  .directive('tryBackgroundImage', function() {

    return {
      scope: {},
      controllerAs: 'ctrl',
      bindToController: {
        images: '=tryBackgroundImage'
      },
      controller: function(_, $element, $scope) {
        var ctrl = this
        var src
        var images
        var loader = angular.element('<img>')
        var css = {
          opacity: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          width: 0,
          height: 0,
          zIndex: -99
        }

        loader
          .on('error', loadNext)
          .on('load', setBackground)
          .css(css)
          .appendTo('body')

        $scope.$watch('ctrl.images', loadBackground)

        function loadBackground() {
          images = _.compact(ctrl.images)
          loadNext()
        }

        function loadNext() {
          src = images.shift()
          if (src) loader.prop('src', src)
        }

        function setBackground() {
          $element.css('backgroundImage', 'url(' + src + ')')
        }
      }
    }


  })
