// Fuzzy logic to guess when navigation has started and ended.
// We start navigation when a new successful routing event fires.
// We end navigation when we have had no requests or digest cycles
// for a set amount of time (100ms).

angular.module('pl-shared')
  .run(function($http, $rootScope, $debounce) {
    var routeChanged = false
    var checkSoon = $debounce(check, 100)

    $rootScope.$on('$routeChangeSuccess', startNavigation)
    $rootScope.$watch(checkSoonIfRouteChanged) // reset timeout while digest runs
    startNavigation()

    function startNavigation() {
      checkSoon()
      if (routeChanged) return
      $rootScope.$emit('navigation:start')
      routeChanged = true
    }

    function checkSoonIfRouteChanged() {
      if (!routeChanged) return // ignore any digests after the `navigation:end` event
      checkSoon()
    }

    function check() {
      if ($http.pendingRequests.length) checkSoonIfRouteChanged()
      else endNavigation()
    }

    function endNavigation() {
      if (!routeChanged) return
      routeChanged = false
      $rootScope.$emit('navigation:end')
    }
  })
