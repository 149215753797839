require('team-admin')
angular.module('team-admin')
  .component('teamBags', {
    bindings: {
      team: '=',
      game: '=',
      event: '=',
      parentForm: '<'
    },
    template: require('./team-bags.html'),
    controller: function(_, $timeout, moment) {
      var $ctrl = this
      $ctrl.$onInit = function() {

        if ($ctrl.event) {
          initializeBags($ctrl.event)
        }
        else if ($ctrl.game) {
          initializeBags($ctrl.game)
        }


        var minutesEarly = [
          { id: 15, text: '15 minutes early' },
          { id: 30, text: '30 minutes early' },
          { id: 45, text: '45 minutes early' },
          { id: 60, text: '60 minutes early' },
          { id: 90, text: '90 minutes early' }
        ]

        //select2 options object for adding custom arrival times
        $ctrl.select2Opts = {
          data: minutesEarly,
          placeholder: '# of minutes early',
          tags: true,
          createTag: function(params) {
            if (/^\d+$/.test(params.term)) {
              return {
                id: params.term,
                text: params.term + ' minutes early',
                newTag: true,
              }
            }
          }
        }

        //populates select2 data with current arrival_time if it is not a custom value
        if ($ctrl.bag.arrival_time) {
          var selectOption =  {
            id: $ctrl.bag.arrival_time,
            text: ($ctrl.bag.arrival_time).toString() + ' minutes early'
          }
          if (!_.findWhere(minutesEarly, selectOption)) {
            minutesEarly.push(selectOption)
          }

          $timeout(function() {
            var select = angular.element('#arrival_time')
            select.triggerHandler('change') // removes Angular's unknownOption injected by <select>
            if ($ctrl.parentForm) {
              $ctrl.parentForm.$setPristine() // reverts the dirty state set by triggering the change event
            }
          }, 100)
        }
      }

      //adds bag object with teamInstanceId and/or sets bag via teamInstanceId
      function setBag(event) {
        var idObj = { id: $ctrl.team.id }
        var principal = _.find(event.principals ||= [], idObj)
        if (!principal) event.principals.push(principal = idObj)
        $ctrl.bag = principal.extended_attributes ||= {}
      }

      function initializeBags(event) {
        setBag(event)
      }

    }
  })
