angular.module('pl-shared')
  .directive('spinner', function() {

    return {
      restrict: 'E',
      template: '<span></span>',
      controller: function($element, $attrs) {
        $element.append(spinnerMarkup('inline' in $attrs))
      }
    }

    function spinnerMarkup(inline) {
      var className = inline ? 'pl-spinner--inline' : 'pl-spinner'
      return '<svg class="' + className + '"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-spinner"></use></svg>'
    }

  })
