angular.module('pl-shared')

  .directive('phoneQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/regform/questions/phone-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this

        ctrl.PHONE_LENGTH = { min: 10, max: 12 }
        ctrl.pattern = /^([0-9]{1} )?\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id

      }
    }

  })

