angular.module('pl-shared')
  .service('showError', function(renderContext, $timeout, $q) {
    return function(err, errContext) {
      var status = parseInt(err.status || err.statusCode || err, 10)
      var contextParts = ['error', err && status || 500]
      if (errContext) contextParts.push(errContext)
      // clear the stack to let routing finish before updating the context

      $timeout(function showError() {
        renderContext.set(contextParts.join('.'))
      })

      console.error(err)

      return $q.reject(err)
    }
  })
