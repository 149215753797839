require('team-admin')
angular.module('team-admin')
  .factory('AlertPreference', function(DS, $q) {
    var AlertPreference = DS.defineResource({
      name: 'alert_preferences',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
    })

    // Keep a singleton hash so that multiple subsets can be requested concurrently
    var messagesEnabled = {}

    /**
     * Gets a hash of Boolean values that determine whether messages can be sent to certain personas.
     * The hash can be extended with further calls to this method, and the returned hash will be updated in-place at that time.
     *
     * @param {Team} The team object
     * @param {Array} An array of players to retrieve messages-enabled for
     * @returns {Object} The hash of messages enabled by persona id
     */
    AlertPreference.getMessagesEnabled = function(team, players) {
      var personaIds = _.map(_.reject(players, { invitation: { status: 'uninvited' } }), 'persona.persona_id')
      var orgId = team.org_details.id
      personaIds = _.compact(_.difference(personaIds, _.keys(messagesEnabled).map(Number))) // Don't refetch, but get any new ones

      if (!_.any(personaIds)) return $q.when(messagesEnabled)

      return AlertPreference.findAll({
        context_type: 'Organization',
        context_id: orgId,
        find_or_create: 1,
        'persona_id[]': personaIds
      })
        .then(function(allPrefs) {
          var prefsByPersona = _.groupBy(allPrefs, 'contact_preference.persona_id')
          _.extend(messagesEnabled, _.mapValues(prefsByPersona, function(prefs) { return _.any(prefs, 'enabled') }))
          return messagesEnabled
        })
    }

    return AlertPreference
  })
