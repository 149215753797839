require('team-admin')
angular.module('team-admin')
  .directive('rsvpReminders', function() {
    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        team: '=',
        event: '=',
        invitedPlayers: '=',
        rosterRole: '='
      },
      template: require('./rsvp-reminders.html'),
      controller: function(pageViewHandler, _, $scope, $log, moment, EventRsvp, snGlobals, Alerts) {
        pageViewHandler.fireEvent('SendRemindersModal.Open')

        if ($scope.event.contextName === 'games') {
          $scope.gaCategory = 'RSVP Game'
          $scope.opponent = _.find([$scope.event.game_details.team_1, $scope.event.game_details.team_2], team => team.id !== $scope.team.id)
        }
        else if ($scope.event.contextName === 'events') {
          $scope.gaCategory = 'RSVP Event'
        }
        $scope.responses = _.groupBy($scope.invitedPlayers, 'invitation.response')
        $scope.checkedRsvpStatuses = { ..._.mapValues($scope.responses, _.ary(_.any, 1)), no: false, yes: false }

        $scope.bag = _.find($scope.event.principals, { id: $scope.team.id })?.extended_attributes || {}
        $scope.arrival_time = moment($scope.event.start_date_time).subtract($scope.bag.arrival_time, 'minutes')

        $scope.requiredField = function(validate) {
          $scope.isRequired = !_.any($scope.checkedRsvpStatuses)
          if (validate) $scope.checkboxForm?.validateFields('rsvp')
        }
        $scope.requiredField()

        $scope.REMIND_MODAL = 'SCHEDULE.EVENT_RSVPS.REMIND_MODAL.'
        $scope.tokenizedRsvpLabel = () => `${ $scope.REMIND_MODAL }send_to_${ $scope.rosterRole == 'player' ? 'players' : 'staff' }`
        $scope.tokenizedErrorMessage = () => `${ $scope.REMIND_MODAL }select_${ $scope.rosterRole == 'player' ? 'players' : 'staff' }`
        $scope.tokenizedDetailsLabel = () => `${ $scope.REMIND_MODAL }${ $scope.event.contextName == 'events' ? 'event' : 'game' }_details`

        $scope.send = function() {
          pageViewHandler.fireEvent('SendRemindersModal.Send')
          var data = {
            event_id: $scope.event.id,
            team_id: $scope.team.id,
            rsvp_response: _.keys(_.pick($scope.checkedRsvpStatuses, Boolean)),
            remind_ngin: 1,
            roster_role: $scope.rosterRole
          }
          return EventRsvp.remind({ data })
            .then(function() {
              Alerts.success($scope.REMIND_MODAL + 'reminders_sent')
              $scope.confirm()
            })
        }

      }
    }
  })
