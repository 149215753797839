require('team-admin')
angular.module('team-admin')
  .directive('selectChangeRange', function() {
    return {
      restrict: 'A',
      scope: {
        confirm: '=',
        cancel: '=',
        buttonLabel: '<',
        event: '='
      },
      template: require('./select-change-range.html'),
      controller: function($scope, getEnum, i18ng) {

        var dateFormat = i18ng.t('DATEPICKER.display_format', { defaultValue: 'MMM D YYYY' })
        var date = moment($scope.event.date).format(dateFormat)
        var enumOpts = { date: date, returnObjectTrees: true }

        $scope.scope = $scope
        $scope.changeRangeOptions = getEnum('SCHEDULE.EDIT_EVENT.CHANGE_RANGE.options', enumOpts)
        $scope.changeRange = $scope.changeRangeOptions[0].value
        $scope.buttonLabel ||= 'SCHEDULE.EDIT_EVENT.button_label'

        $scope.setChangeRange = function() {
          $scope.confirm($scope.changeRange)
        }

        $scope.getGaLabel = function(option) {
          return option.value === 'one' ? 'Edit Event Only' : 'Edit Series'
        }

      }
    }
  })
