require('team-admin')
angular.module('team-admin')
  .directive('inviteAction', function($timeout, dialog, TeamInvite, Alerts) {
    return {
      restrict: 'A',
      require: '?^gaCategory',
      scope: {
        player: '=',
        team: '=',
        editMode: '='
      },
      template: require('./invite-action.html'),
      link: function($scope, $element, $attrs, ctrl) {
        if (ctrl) $scope.gaCategoryCtrl = ctrl
      },
      controller: function(_, $scope, $document, pageViewHandler) {

        $scope.resend = function() {
          pageViewHandler.fireEvent('ResendInvite', 8)
          $scope.loading = true
          TeamInvite.create({}, {
            method: 'PUT',
            endpoint: `v3/roster_personas/${ $scope.player.id }/invitations/remind`
          })
            .then(resendSuccess, resendError)
            .then($scope.setAs('loading', false))
        }

        function resendSuccess() {
          $scope.justResent = true
          $scope.player.invitation.status = 'sent'
          Alerts.success('ROSTER.INVITE.MESSAGE.resend_success', { player: $scope.player.fullName() })
        }

        function resendError() {
          Alerts.error('ROSTER.INVITE.MESSAGE.resend_error', { player: $scope.player.fullName() })
        }

        $scope.cancel = function() {
          $scope.loading = true
          TeamInvite.create({}, {
            method: 'DELETE', // weird, but doesn't require an ID
            endpoint: `v3/roster_personas/${ $scope.player.id }/invitations`
          })
            .then(cancelSuccess, cancelError)
            .then($scope.setAs('loading', false))
        }

        function cancelSuccess() {
          $scope.player.invitation = { status: 'uninvited' }
          Alerts.success('ROSTER.INVITE.MESSAGE.cancel_success', { player: $scope.player.fullName() })
        }

        function cancelError() {
          Alerts.error('ROSTER.INVITE.MESSAGE.cancel_error', { player: $scope.player.fullName() })
        }

        $scope.inviteModal = function() {
          pageViewHandler.fireEvent('Invite', 8)
          dialog.confirm({
            directive: 'invite-modal',
            scope: $scope,
            attrs: {
              player: $scope.player,
              team: $scope.team
            }
          })
        }

      }
    }
  })
