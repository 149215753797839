'use strict'

angular.module('pl-shared')
  .directive('deferred', function deferredDirective($document) {
    function link(scope, elem, attrs) {
      var loc = $document[0].getElementsByTagName('script')[0]
      var script = $document[0].createElement('script')
      script.src = scope.url
      loc.parentNode.insertBefore(script, loc)
    }
    return {
      restrict: 'AE',
      link: link,
      scope: {
        url: '='
      }
    }
  })
