angular.module('pl-shared')
  .filter('characterCount', function(i18ng) {
    return function(text, options) {
      var textCharacterCount = text ? text.length : 0

      return i18ng.t('CHARACTER_COUNT.count_out_of_max', {
        characterCount: textCharacterCount,
        maxCount: options.max
      })
    }
  })
