angular.module('pl-shared')
  .directive('surveyResultDetail', function() {

    return {
      restrict: 'E',
      scope: {},
      template: require('/static/shared/components/registration/survey-results/survey-result-detail.html'),
      bindToController: {
        surveyResult: '=',
        persona: '<',
        showHidden: '<'
      },
      controllerAs: 'ctrl',
      controller: function(_, $q, $scope, $routeParams, Form, FormResult, SurveyResult, setAs, setWhile, showError, surveyResultService, printService, dialog) {

        var ctrl = this
        ctrl.print = print
        ctrl.unclaim = unclaim

        $scope.$watch('ctrl.surveyResult.id', setWhile(ctrl, 'loading', update))
        $scope.$on('$destroy', $scope.$root.$on('$routeChangeSuccess', setSelectedForm))

        function update() {
          return loadFormResults()
            .then(setAs(ctrl, 'formResults'), showError)
            .then(loadForms)
            .then(setAs(ctrl, 'forms'), showError)
            .then(filterUnansweredForms) // temp until we handle dependent forms
            .then(setSelectedForm)
            .then(setUserPersonaFullName)
            .then(printService.preloadFonts)
            .then(setAs(ctrl, 'printReady', true))
        }

        function loadFormResults() {
          return FormResult
            .findAll({ survey_result_id: ctrl.surveyResult.id, show_hidden: ctrl.showHidden })
        }

        function setUserPersonaFullName() {
          var persona = ctrl.surveyResult.user.self_persona
          ctrl.surveyResult.user.self_persona.full_name = _.compact([persona.first_name, persona.last_name]).join(' ')
        }

        function loadForms() {
          // TODO: There is not forms#index in the api yet, so we have a temporary service that
          // maps survey result ids to form ids from the fomr results request. Replace this with
          // a Form.findAll({survey_id: ctrl.surveyResult.survey_id}) when it becomes available.
          var allFormIds = surveyResultService.formIds(ctrl.surveyResult.id)
          return $q.all(_.map(allFormIds, findForm))
        }

        function findForm(formId) {
          return Form.find(formId, { params: { show_hidden: ctrl.showHidden } })
        }

        function setSelectedForm() {
          var id = parseInt($routeParams.formId, 10)
          ctrl.selectedForm = _.findWhere(ctrl.forms, { id: id })
        }

        function filterUnansweredForms() {
          // allow all forms to display for site member surveys, but only those that
          // have been answered for other registration types (until we handle dependencies).
          ctrl.formResultsByFormId = _.indexBy(ctrl.formResults, 'form_id')
          if (ctrl.surveyResult.survey_type === 'site_member') return
          ctrl.forms = _.filter(ctrl.forms, function(form) {
            return form.id in ctrl.formResultsByFormId
          })
        }

        function print() {
          return printService.printContent({
            component: 'print-survey-result',
            margin: 1,
            attrs: {
              forms: ctrl.forms,
              formResults: ctrl.formResults,
              surveyResult: ctrl.surveyResult,
              persona: ctrl.persona
            }
          })
        }

        function unclaim() {
          dialog.confirm({
            component: 'unclaim-entry',
            scope: $scope,
            attrs: {
              surveyResult: ctrl.surveyResult,
              parentScope: $scope
            }
          })
        }
      }
    }

  })
