require('team-admin')
angular.module('team-admin')
  .directive('nginUrl', function() {

    var $ = angular.element
    var relativeRx = /^\w*\/|^\w+$/
    var DEFAULT_PROTOCOLS = ['http://', 'https://', 'mailto:', 'mailto://']

    return {
      restrict: 'E',
      replace: true,
      scope: true,
      // template content is generated in the controller because we need generated ng-model bindings
      controller: function($scope, $element, $attrs, $compile, $parse) {

        var protocolProp = $attrs.protocolProp
        var urlProp = $attrs.urlProp

        if (!protocolProp || !urlProp) throw new Error('The `ngin-url` directive requires `urlProp` and `protocolProp` values.')

        var namePrefix = $attrs.name ? $attrs.name + '_' : ''
        var setProtocol = $parse(protocolProp).assign.bind(null, $scope)
        var setUrl = $parse(urlProp).assign.bind(null, $scope)
        var protocols = $parse($attrs.protocol)() || DEFAULT_PROTOCOLS
        var protocolRx = new RegExp('^' + protocols.join('|'))
        var inputActive = false

        var $input = newInput('text', 'full_url', 'tempUrl')
        var $protocol = newInput('hidden', 'protocol', protocolProp)
        var $url = newInput('hidden', 'url', urlProp)

        if ($attrs.placeholder) $input.attr('placeholder', $attrs.placeholder)

        $input
          .addClass('pl-field--full')
          .focus(function() { $scope.inputActive = true; $scope.$digest() })
          .blur(function() { $scope.inputActive = false; $scope.$digest() })

        $scope.$watchGroup(['inputActive', protocolProp, urlProp], formatUrl)
        $scope.$watch('tempUrl', parseUrl)

        function formatUrl(data) {
          if (data[0]) return // input active
          $scope.tempUrl = (data[1] || '') + (data[2] || '')
        }

        function parseUrl(newVal, oldVal) {
          if (oldVal === undefined) return

          var relative = relativeRx.test(newVal || '')
          var protocol = !newVal || relative ? '' : ((newVal || '').match(protocolRx) || [])[0] || protocols[0]
          var url = protocol ? newVal.replace(protocol, '') : newVal || ''

          setProtocol(protocol)
          setUrl(url)
        }

        function newInput(type, name, modelProp) {
          var input = $('<input/>').attr({
            type: type,
            name: namePrefix + name,
            'ng-model': modelProp
          }).appendTo($element)
          $compile(input)($scope)
          return input
        }
      }
    }

  })
