require('team-admin')
angular.module('team-admin')
  .directive('eventDetail', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./edit-event.html'),
      controller: function($scope, Event, $routeParams, renderContext, setAs, setWhile) {
        $scope.scope = $scope

        var loadEvent = setWhile($scope, 'loading', function(id) {
          return Event.find(id, {
            endpoint: `/v3/calendar/team/${ $scope.team.id }/event`
          })
            .then(setAs($scope, 'event'))
            .then(event => $scope.invitations = event.event_attendees)
        })

        $scope.$watch(() => $routeParams.eventId, loadEvent)

        $scope.cancel = function(discardChanges) {
          renderContext.backto(renderContext.schedule.next)
        }

        $scope.listenToRoot('event:save_recurring', ($event, event) => {
          renderContext.goto(renderContext.context, { eventId: event.id }, true) // update to new ID
        })
        // $scope.listenToRoot('event_saved:reset_rsvps', loadInvites)
      }
    }
  })
