angular.module('pl-shared')
  .directive('formDetail', function() {

    return {
      restrict: 'E',
      scope: {},
      template: require('/static/shared/components/registration/forms/form-detail.html'),
      bindToController: {
        form: '=formData', // `form` attribute causes issues
        formResult: '=',
        editMode: '='
      },
      controllerAs: 'ctrl',
      controller: function($routeParams, $scope, currentOrg, setAs, renderContext, Alerts, i18ng, FormResult) {

        var ctrl = this
        var inUserApp = renderContext.get('app.user')
        var baseContext = inUserApp ? 'registrations.detail' : 'surveyResult'

        syncModelData()

        // This should only be editable in the user app, or if it's a reg for the current org.
        ctrl.readOnly = !(inUserApp || $routeParams.organizationId == currentOrg.id)

        ctrl.editForm = function(question) {
          if (ctrl.readOnly || !question.isEditable || ctrl.isTeamRoster(question.directiveName)) return
          var params = {
            surveyResultId: ctrl.formResult.survey_result_id,
            formId: ctrl.form.id
          }
          renderContext.goto(baseContext + '.form', params)
        }

        ctrl.cancelForm = function() {
          ctrl.formController.$setPristine()
          renderContext.goto(baseContext, { surveyResultId: ctrl.formResult.survey_result_id })
        }

        ctrl.saveForm = function() {
          return ctrl.formController.validateAndSubmit(submitForm)
        }

        ctrl.isHiddenForm = isHiddenForm

        function submitForm() {
          var data = {}
          _.each(ctrl.form.question_elements, function(q) {
            if (!q.isEditable) return
            data[q.id] = ctrl.models[q.id]
            // TODO: remove once the back end handles nulls/numbers correctly
            switch (q.question.question_type) {
              case 'short_text':
              case 'long_text':
                if (data[q.id] == null) data[q.id] = '' // eslint-disable-line no-eq-null
                break
              case 'decimal':
              case 'integer':
                if (data[q.id] != null) data[q.id] = String(data[q.id]) // eslint-disable-line no-eq-null
                break
              case 'document_upload':
              case 'profile_photo':
                if (data[q.id] && data[q.id].url) delete data[q.id]
                break
              // TEMP: delete the data for these question types until they're supported
              case 'bulk_text':
              case 'summary':
                delete data[q.id]
                break
              default:
                break
            }
          })

          // TODO: allow saving new form result if form was never completed in the first place
          var savedFormResult
          if (ctrl.formResult.id < 0) {
            data.id = ctrl.formResult.id
            data.persona_id = ctrl.formResult.persona_id
            data.survey_result_id = ctrl.formResult.survey_result_id
            data.form_id = ctrl.formResult.form_id
            savedFormResult = FormResult.save(data)
          }
          else {
            savedFormResult = ctrl.formResult.DSUpdate(data)
          }
          return savedFormResult
            .then(function(data) {
              ctrl.formResult = data
              renderContext.backto(baseContext)
              syncModelData()
              Alerts.success('FORM_RESULT.success')
            })
            .catch(function(err) {
              console.error('Error updating form result', data, err)
              var message = [].concat(_.get(err, 'data.error.messages')).join(', ')
              Alerts.error('FORM_RESULT.error' + (message ? '_message' : ''), { message: _.unescape(message) })
            })
        }

        function syncModelData() {
          ctrl.models = {}
          ctrl.answersByQuestionId = {}
          if (!ctrl.formResult) return
          ctrl.answersByQuestionId = _.indexBy(ctrl.formResult.answers, 'question_element_id')
          _.each(ctrl.formResult.answers, function(answer) {
            ctrl.models[answer.question_element_id] = answer.value
          })
        }

        /**
         * Determines if the current form is a hidden form.
         *
         * @returns {boolean} True if the form is a hidden form. False otherwise.
         */
        function isHiddenForm() {
          return !!ctrl.form && ctrl.form.form_type === 'hidden'
        }

        ctrl.isTeamRoster = function(directiveName) {
          return directiveName === 'teamRosterQuestion'
        }

      }
    }

  })
