angular.module('pl-shared')
  .component('chatInput', {
    bindings: {
      userCanAccessChat: '=',
      allMessages: '=',
      imageString: '=',
      teamInstance: '<',
      displayNewMessage: '&',
      goToBottom: '&'
    },
    template: require('/static/shared/components/chat/chat-input.html'),
    controller: function($scope, currentUser, fileHelper, $element, $timeout, $firebaseStorage, firebase) {
      var $ctrl = this
      var previewCanvas

      $ctrl.handleFileSelect = function(event) {
        var previewImagefile = event.currentTarget.files[0]
        fileHelper.getEXIFOrientation(previewImagefile, function(orientationVal) {
          setPreviewImage(previewImagefile, orientationVal)
        })
      }

      function setPreviewImage(file, orientationVal) {
        var reader = new FileReader()
        reader.onload = function(event) {
          var img = new Image()
          img.onload = function() {
            previewCanvas = angular.element('<canvas></canvas>')[0]
            $element.find('#preview-image').append(previewCanvas)

            rotateCanvas(img, orientationVal)
            scaleDownCanvas(previewCanvas.width, previewCanvas.height)
            drawImageToCanvas(img, orientationVal)

            $ctrl.imageString = previewCanvas.toDataURL('image/jpeg')
          }
          $scope.$apply(function() {
            img.src = event.target.result
          })
        }
        reader.readAsDataURL(file)
      }

      function rotateCanvas(img, orientationVal) {
        previewCanvas.width = img.width
        previewCanvas.height = img.height
        if (orientationVal >= 5 && orientationVal <= 8) {
          previewCanvas.width = img.height
          previewCanvas.height = img.width
        }
      }

      function scaleDownCanvas(originalWidth, originalHeight) {
        var maxSize = 1632
        if (originalWidth > maxSize || originalHeight > maxSize) {
          if (originalWidth > originalHeight) {
            previewCanvas.width = maxSize
            previewCanvas.height = originalHeight / (originalWidth / maxSize)
          }
          else if (originalWidth < originalHeight) {
            previewCanvas.width = originalWidth / (originalHeight / maxSize)
            previewCanvas.height = maxSize
          }
          else {
            previewCanvas.width = maxSize
            previewCanvas.height = maxSize
          }
        }
      }

      function drawImageToCanvas(img, orientationVal) {
        var ctx = previewCanvas.getContext('2d')
        switch (orientationVal) {
          case -1:    // image is not a jpeg
          case 0:     // image is a jpeg without EXIF orientation
          case 1:     // image is properly positioned
            ctx.drawImage(img, 0, 0, previewCanvas.width, previewCanvas.height)
            break
          case 3:     // jpeg orientation is upside down (180 degrees)
            ctx.transform(-1, 0, 0, -1, previewCanvas.width, previewCanvas.height)
            ctx.drawImage(img, 0, 0, previewCanvas.width, previewCanvas.height)
            break
          case 6:     // jpeg orientation is on its side (counterclockwise 90 degrees)
            ctx.transform(0, 1, -1, 0, previewCanvas.width, 0)
            ctx.drawImage(img, 0, 0, previewCanvas.height, previewCanvas.width)
            break
          default:
            ctx.transform(0, -1, 1, 0, 0, previewCanvas.height)
            ctx.drawImage(img, 0, 0, previewCanvas.height, previewCanvas.width)
        }
      }

      $ctrl.removePreviewImage = function() {
        $ctrl.imageString = ''
        $element.find('#upload-image')[0].value = ''
        previewCanvas.remove()
        previewCanvas = undefined
      }

      $ctrl.addMessage = function() {
        $ctrl.allMessages.$add({
          text: $element.find('#chat-message-input')[0].textContent,
          contentType: setContentType(),
          messageType: 'standard',
          textMessage: true,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          userId: currentUser.uuid
        }).then(function(ref) {
          if ($ctrl.imageString) storeImage(ref.key)
          $ctrl.displayNewMessage({ messageKey: ref.key })
          if (previewCanvas) $ctrl.removePreviewImage()
          $element.find('#chat-message-input')[0].textContent = ''
        })
      }

      function setContentType() {
        return $ctrl.imageString ? 'photo' : 'text'
      }

      function storeImage(messageKey) {
        var storageRef = firebase.storage().ref().child('channel-messages-v2').child($ctrl.teamInstance).child(messageKey).child('photo')
        var storageObj = $firebaseStorage(storageRef)
        storageObj.$putString($ctrl.imageString, 'data_url')
      }

      $ctrl.submitOnEnter = function(event) {
        if (event.which === 13) {
          event.preventDefault()
          $ctrl.addMessage()
        }
      }


    }
  })
