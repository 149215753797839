angular.module('pl-shared')
  .run(function(firebase, firebaseConfig, FirebaseChatFactory) {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
      FirebaseChatFactory.getCustomAuthToken()
    }
  })
  .directive('chatComponent', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        team: '='
      },
      template: require('/static/shared/components/chat/chat.html'),
      controllerAs: 'ctrl',
      controller: function($scope, $element, $timeout, $anchorScroll, $firebaseArray, firebase, _, currentUser, FirebaseChatFactory, Player, snGlobals) {
        var ctrl = this
        ctrl.currentUser = currentUser
        ctrl.imageString = ''
        if (!ctrl.displayedMessages) ctrl.displayedMessages = []
        ctrl.teamInstance = ''
        var groupOfMessages = 40
        var refBase
        var referenceToOldestKey
        var fromCurrentUser
        var lastReadMessageId
        var firstUnreadMessage

        function init() {
          ctrl.teamInstance = /* 'team_instance_' + */ ctrl.activeSeason.season_team_id
          refBase = firebase.database().ref().child('channel-messages-v2').child(ctrl.teamInstance).orderByKey()
          if (ctrl.allMessages) {
            ctrl.allMessages.$destroy()
            ctrl.initialMessages.$destroy()
          }

          ctrl.userUnreadObject = FirebaseChatFactory.returnUserUnreadObject()
          ctrl.allMessages = FirebaseChatFactory.getAllMessages()
          ctrl.initialMessages = FirebaseChatFactory.getInitialMessages()
          ctrl.users = FirebaseChatFactory.getUsers()

          ctrl.users.$loaded()
            .then(function(response) {
              ctrl.users.$watch(function(event) {
                updateChatAccess()
                ctrl.addingToRoster = false
              })
              updateChatAccess()
            })

          function updateChatAccess() {
            var userMatch = _.find(ctrl.users, { '$id': ctrl.currentUser.uuid })
            ctrl.userCanAccessChat = userMatch && _.contains(userMatch.rosterPersonaIds, true)
          }

          ctrl.initialMessages.$loaded()
            .then(function(response) {
              if (response === ctrl.initialMessages) {
                setDisplayedMessages(ctrl.initialMessages)
                lastReadMessageId = ctrl.userUnreadObject.lastReadMessageId
                firstUnreadMessage = _.find(ctrl.displayedMessages, function(message) {
                  return message.timestamp > ctrl.userUnreadObject.lastReadMessageTimestamp
                })
                referenceToOldestKey = response.$keyAt(response[0])
                $timeout(function() {
                  ctrl.finishedLoading = true
                }, 500).then(anchorToLastRead)
              }
            })

          ctrl.allMessages.$loaded()
            .then(function(response) {
              ctrl.allMessages.$watch(function(event) {
                handleFirebaseWatcherEvents(event)
              })
            })
        }

        function anchorToLastRead() {
          $timeout($anchorScroll, 0, false, lastReadMessageId)
        }

        $scope.listenToRoot('load_more_messages', function($event) {
          getMoreMessages()
        })

        ctrl.addUserToRoster = function() {
          ctrl.addingToRoster = true
          return currentUser.getSelfPersona()
            .then(function({ id: persona_id, full_name }) {
              return Player.create({
                org_id: ctrl.team.org_details?.id,
                persona_id,
                name: full_name,
                roster_id: _.get(ctrl.team, ['rosters', 0, 'id']),
                roster_role: 'staff'
              }, Player.v3Options)
            })
            .then(function() {
              ctrl.team.clearRosterCache()
            })
        }

        function getMoreMessages() {
          if (ctrl.allMessages.length > ctrl.displayedMessages.length) {
            var moreMessagesRef = refBase.endAt(referenceToOldestKey).limitToLast(groupOfMessages + 1)
            var moreMessages = $firebaseArray(moreMessagesRef)
            moreMessages.$loaded()
              .then(function(response) {
                moreMessages.splice(-1, 1)
                referenceToOldestKey = moreMessages.$keyAt(moreMessages[0])
                setDisplayedMessages(moreMessages)
              })
          }
        }

        function setDisplayedMessages(messageArray) {
          var lastVisibleMessage = _.find(ctrl.displayedMessages, function(message) {
            return !ctrl.messageIsHidden(message)
          })
          ctrl.displayedMessages = messageArray.concat(ctrl.displayedMessages)
          if (lastVisibleMessage) {
            $anchorScroll(lastVisibleMessage.$id)
          }
        }

        function handleFirebaseWatcherEvents(event) {
          ctrl.eventMessage = ctrl.allMessages.$getRecord(event.key)
          fromCurrentUser = ctrl.eventMessage && ctrl.eventMessage.userId === ctrl.currentUser.uuid
          if (fromCurrentUser) ctrl.hideNewMessageLineOnReply = true
          if (event.event === 'child_removed') {
            _.remove(ctrl.displayedMessages, function(message) {
              return message.$id === event.key
            })
          }

          if (event.event === 'child_added' && !fromCurrentUser) {
            $timeout(function() { ctrl.displayNewMessage(event.key) }, 1000)
          }
        }

        ctrl.displayNewMessage = function(key) {
          if (key) ctrl.eventMessage = ctrl.allMessages.$getRecord(key)
          if (fromCurrentUser) ctrl.eventMessage.thumbnailUrl = ctrl.imageString
          ctrl.displayedMessages.push(ctrl.eventMessage)
        }

        ctrl.messageIsHidden = function(message) {
          return message.hide && message.hide[ctrl.currentUser.uuid]
        }

        ctrl.messageLoaded = function(message) {
          if (message.contentType === 'photo') {
            return !!message.thumbnailUrl
          }
          else if (message.contentType === 'gif') {
            return !!message.contentUrl
          }
          else {
            return true
          }
        }

        function updateSeason(data) {
          ctrl.displayedMessages = []
          ctrl.finishedLoading = false
          ctrl.hideNewMessageLineOnReply = false
          ctrl.activeSeason = ctrl.team.activeSeason()
          ctrl.activeSeasonId = ctrl.activeSeason.season_team_id
          init()
        }

        ctrl.setLikeCount = function(message) {
          if (message.reactions) {
            var reactionValues = Object.values(message.reactions)
            return _.filter(reactionValues, function(boolean) { return boolean }).length
          }
          else return 0
        }

        ctrl.isLikedByUser = function(message) {
          return message.reactions && message.reactions[ctrl.currentUser.uuid]
        }

        $scope.$watchGroup(['team', snGlobals.watcher('activeSeasonId')], updateSeason)

        ctrl.showReactionBadge = function(message) {
          var messageHasLikes = ctrl.setLikeCount(message) > 0
          var showLikesByDefault = message.contentType === ('photo' || 'gif')
          return (messageHasLikes || showLikesByDefault)
        }

        ctrl.showLastReadLine = function(message) {
          return firstUnreadMessage && firstUnreadMessage.$id === message.$id && !ctrl.hideNewMessageLineOnReply
        }

        ctrl.addEmoji = function(emoji) {
          ctrl.messageModel = ctrl.messageModel + emoji
        }

        ctrl.signOut = function() {
          firebase.auth().signOut().then(function() {
            // Sign-out successful.
          }).catch(function(error) {
            // An error happened.
          })
        }

      }
    }
  })
