angular.module('pl-shared')

  .directive('singleCheckboxQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/regform/questions/single-checkbox-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this
        var props = ctrl.question.properties || {}
        var trueValue = ('true_value' in props) ? props.true_value : true
        var falseValue = ('false_value' in props) ? props.false_value : false

        ctrl.properties = props
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id

        ctrl.cbModel = ctrl.model === trueValue

        ctrl.onChange = function() {
          ctrl.model = ctrl.cbModel ? trueValue : falseValue
        }

      }
    }

  })
