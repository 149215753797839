require('team-admin')
angular.module('team-admin')

  .directive('teamDetail', function() {
    return {
      restrict: 'A',
      replace: true,
      scope: {},
      template: require('./team-detail.html'),
      controller: function(
        $scope,
        $routeParams,
        $location,
        $q,
        $timeout,
        renderContext,
        Team,
        DS,
        _,
        Alerts,
        snGlobals,
        $firebaseAuth,
        FirebaseChatFactory,
        ENV,
        $window,
        currentUser,
        featureToggles,
        Program,
        SeasonTeam,
        setAs,
        setWhile,
        showError,
        SNAP,
        Sports,
        payload,
        Affiliation) {

        $scope.scope = $scope
        $scope.params = $routeParams
        $scope.$watch(() => $routeParams.teamId, setWhile($scope, 'loading', function(teamId) {
          return Team.find(teamId)
            .then(loadTeamSuccess, loadTeamError)
            .then(loadOfficialTeam)
        }))
        $scope.$watch('team', setTeamData)

        notifyAutoClaim()

        function notifyAutoClaim() {
          var autoClaimedPersonaName = $routeParams.persona_name
          $location.search('persona_name', null)
          if (!autoClaimedPersonaName) return
          Alerts.success('PERSONAS.autoclaim_success', {
            name: autoClaimedPersonaName
          })
        }

        function getAdapterOptions(adapterName, otherParams) {
          return _.extend({
            basePath: DS.adapters[adapterName].defaults.basePath, // workaround for js-data-angular fallbacks
            httpConfig: DS.adapters[adapterName].defaults.httpConfig, // workaround for js-data-angular fallbacks
            defaultAdapter: adapterName
          }, otherParams)
        }
        var seApiTeamOptions = getAdapterOptions('se_api', { endpoint: '/v3/teams' })
        var statNginTeamOptions = getAdapterOptions('stat_ngin_api', { params: { include: 'program_configuration,credential_configuration' } })

        function loadOfficialTeam() {
          return $q.when((function() {
            var referenceIds = _.map(_.get($scope.team, 'team_internal_references'), 'reference_team_id')
            if (!_.any(referenceIds)) return

            return $q.all(_.map(referenceIds, function(id) {
              return Team.find(id, seApiTeamOptions)
                .then(function(v3team) {
                  // if (v3team.status !== 'active') return

                  return SeasonTeam.find(v3team.originator_id, statNginTeamOptions)
                    .then(function(team) {
                      return Affiliation.findAll({
                        parent_organization_id: _.get(team, 'program.program_configuration.parent_organization_id'),
                        child_organization_id: _.get(team, 'program.program_configuration.organization_id'),
                        credential_status: 'active'
                      })
                        .then(function(affiliation) {
                          // Do not allow printing of affiliate rosters or cards for inactive orgs
                          if (_.isEmpty(affiliation)) return

                          return _.extend(team, {
                            print_cards: /(^|,\s*)print_cards(\s*,|$)/.test(
                              _.get(team, 'program.credential_configuration.features')
                            ),
                            root_organization_id: _.get(team, 'program.program_configuration.root_organization_id'),
                            root_organization_name: _.get(team, 'program.program_configuration.root_organization_name'),
                            program_id: _.get(team, 'program.id'),
                            program_name: _.get(team, 'program.name')
                          })
                        })
                    })
                })
                .catch(_.noop) // silently catch 404's
            }))
          })())
            .then(_.compact)
            .then(setAs($scope.$root, 'statNginTeams'))
        }

        function loadTeamSuccess(team) {
          $scope.team = team
          $scope.sport = team.sport
          $scope.teamLogo = SNAP.assetPath + '/images/sport-ball-icons/other.svg'
          $window.document.title = 'SportsEngine | ' + $scope.team.name

          var orgId = team.org_details.id

          var intercomPlat = { intercom: { renderIntercom: !!team.userIsAdmin } }

          if (currentUser.username !== 'testuser') { // Do not fire requests to mParticle if in test mode
            var currentPlat = {
              currentEnv: payload.env,
              platProp: 'sn_frontend',
              platformSubSec: 'TeamCenter',
              orgId: orgId
            }
            window.postMessage({ type: 'SnFAnalyticsON', currentPlat: currentPlat  })
            window.dataLayer.push(intercomPlat) // Not really a way around this...
          }

          $scope.teamLogo = Sports.getSportLogo($scope.sport)
        }

        function loadTeamError() {
          renderContext.set('error.404')
        }

        var authObj = $firebaseAuth()

        authObj.$onAuthStateChanged(function(firebaseUser) {
          $scope.userUnreadObject = FirebaseChatFactory.userUnread()
        })

        function setTeamData(data) {
          if (!$scope.team) return

          FirebaseChatFactory.initialize($scope.team.activeSeason())
          $scope.userUnreadObject = FirebaseChatFactory.userUnread()
          setTeamInstanceRoster()
          $scope.popoverMenuShowing = false

          if ($scope.team.isNginOriginator) {
            SeasonTeam.find($scope.team.originator_id)
              .then(st => {
                $scope.team.nginSeasonTeam = st
                $scope.teamPageUrl = `${ ENV.urls.nginRedirect }/ngin_redirect/team/${ st.team_id }?season_id=${ st.season_id }`
              }, showError)
          }
        }

        function setTeamInstanceRoster() {
          $scope.team.getRoster()
            .then(function(roster) {
              $scope.teamInstanceRoster = roster
            })
        }
      }
    }
  })
