angular.module('pl-shared')
  .directive('chatScroll', function(_, $timeout, FirebaseChatFactory) {
    return {
      scope: {
        chatScroll: '=',
        incomingMessage: '=',
        userUnreadObject: '=',
      },
      transclude: true,
      template: require('/static/shared/components/chat/chat-most-recent-button.html'),
      controllerAs: '$ctrl',
      bindToController: true,
      controller: function() {},
      link: function($scope, $element, $attrs, $ctrl, $transclude) {
        $transclude(function(transcludedContent) {
          $element.append(transcludedContent)
        })

        $ctrl.atTheBottom = true
        $ctrl.goToBottom = goToBottom

        var windowPadding = parseInt($element.css('padding'), 10) * 2
        var atTheTop = false
        var afterScrollTimeout = null
        $element.on('scroll', function() {
          $ctrl.atTheBottom = (this.scrollTop === (this.scrollHeight - this.offsetHeight))
          atTheTop = this.scrollTop === 0
          var maxViewport = this.scrollTop + this.offsetHeight - windowPadding
          if (afterScrollTimeout) $timeout.cancel(afterScrollTimeout)
          afterScrollTimeout = $timeout(calculateLastVisibleMessage, 150, false, maxViewport)

          if (atTheTop) {
            $scope.$emit('load_more_messages')
          }
        })

        function calculateLastVisibleMessage(maxViewport) {
          var allMessageWrappers = $element.children()
          var distanceFromTop = windowPadding
          var lastVisibleMessageWrapper
          _.each(allMessageWrappers, function(div) {
            var messageWrapper = angular.element(div)
            var messageWrapperHeight = messageWrapper.outerHeight(true)
            if (messageWrapper[0].id !== 'most-recent-button') distanceFromTop += messageWrapperHeight
            if (distanceFromTop > maxViewport) {
              lastVisibleMessageWrapper = messageWrapper
              return false
            }
            else lastVisibleMessageWrapper = messageWrapper
          })
          var lastVisibleMessageId = lastVisibleMessageWrapper.children()[0].id
          FirebaseChatFactory.updateLastRead(lastVisibleMessageId)
        }

        function goToBottom(transitionDuration) {
          $element.animate({ scrollTop: $element[0].scrollHeight - $element[0].offsetHeight }, transitionDuration)
        }

        $scope.$watchCollection('$ctrl.chatScroll', function(displayedMessages) {
          if (displayedMessages && $ctrl.atTheBottom) $timeout(goToBottom, 0, false, 0)
          $timeout(updateLastReadWithoutScroll, 500, false, displayedMessages)
        })

        function updateLastReadWithoutScroll(displayedMessages) {
          var noMessagesOverflow = $element[0].scrollHeight === $element[0].offsetHeight
          if (noMessagesOverflow) {
            var lastMessageInArray = _.last(displayedMessages)
            FirebaseChatFactory.updateLastRead(lastMessageInArray.$id)
          }
        }

        $scope.$watch('$ctrl.incomingMessage.contentUrl', function(attachedImage) {
          if (attachedImage && $ctrl.atTheBottom) {
            $timeout(goToBottom, 0, false, 0)
            FirebaseChatFactory.updateLastRead($scope.incomingMessage.$id)
          }
        })
      }
    }
  })
