// Filter to convert US formated dates into a standard YYYY-MM-DD String
angular.module('pl-shared')
  .filter('normalizeDate', function() {
    return function(str) {
      if (!str) return str
      var parts = str.split('/')
      if (parts.length !== 3) return str
      return parts[2] + '-' + parts[0] + '-' + parts[1]
    }
  })
