require('team-admin')
angular.module('team-admin')
  .component('squadLockerStore', {
    bindings: {
      store: '=',
      onDelete: '&',
      showNewStoreModal: '<'
    },
    template: require('./squad-locker-store.html'),
    controller: function(pageViewHandler, Alerts, featureToggles, setAs, showError, SNAP, ENV, $routeParams, $window, i18ng) {
      if (!featureToggles.squadlocker_integration) return showError('404')

      var $ctrl = this
      $ctrl.copyLinkSuccess = copyLinkSuccess
      $ctrl.openStore = openStore
      $ctrl.editStore = editStore
      $ctrl.deleteStore = deleteStore
      $ctrl.addTracking = addTracking
      $ctrl.escapedStoreUrl = encodeURIComponent($ctrl.store.squad_locker_store_url)
      $ctrl.hideNewStoreModal = hideNewStoreModal
      $ctrl.deleteStoreSuccess = deleteStoreSuccess
      $ctrl.pageElementPreviewPath = SNAP.assetPath + '/images/squadlocker-page-element-preview.png'
      $ctrl.squadlockerLogoPath = SNAP.assetPath + '/images/squadlocker-logo.svg'
      $ctrl.teamId = $routeParams.teamId
      $ctrl.season_id = $routeParams.season_id
      $ctrl.teamPageUrl = ENV.urls.nginRedirect + '/ngin_redirect/team/' + $ctrl.teamId + '?season_id=' + $ctrl.season_id
      $ctrl.twitterStoreUrl = encodeURIComponent(i18ng.t('SQUAD_LOCKER.STORE.twitter_text', { team_name: $ctrl.store.store_name, link: $ctrl.store.squad_locker_store_url }))

      function hideNewStoreModal() {
        $ctrl.showNewStoreModal = false
      }

      function deleteStore() {
        return $ctrl.store.DSDestroy()
          .then(deleteStoreSuccess)
          .catch(setAs($ctrl, 'destroyError'))
      }

      function deleteStoreSuccess() {
        $ctrl.onDelete()
        Alerts.success('SQUAD_LOCKER.STORE_DELETE_MODAL.success_message')
      }

      function copyLinkSuccess() {
        Alerts.success('SQUAD_LOCKER.STORE.copy_link_success')
      }

      function editStore() {
        pageViewHandler.fireEvent('Apparel.EditStore', 8)
        $window.open($ctrl.store.edit_squad_locker_store_url)
      }

      function openStore() {
        pageViewHandler.fireEvent('Apparel.GoToStore', 8)
        $window.open($ctrl.store.squad_locker_store_url)
      }

      function addTracking() {
        pageViewHandler.fireEvent('Apparel.AddPageElement', 8)
      }

    }
  })
