(function() {

  var stickyStyle = !!window.CSS &&
                    _.isFunction(window.CSS.supports) &&
                    ((window.CSS.supports('position', 'sticky') && 'sticky') ||
                     (window.CSS.supports('position', '-webkit-sticky') && '-webkit-sticky'))
  if (!stickyStyle) return console.warn('Browser does not support position: sticky')

  angular.element(`<style type="text/css" id="sticky-header-styles">
    thead[sticky-header] > tr > * {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 10;
    }
    tfoot[sticky-footer] > tr > * {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 10;
    }
    table[sticky-column-id] > tbody > tr:not(.selected):nth-child(even) {
      background-color: #f2f2f3;
    }
    table[sticky-column-id] > tbody > tr:not(.selected):nth-child(odd) {
      background-color: #ffffff;
    }
    table[sticky-column-id] > tbody > tr > * {
      background-color: inherit;
    }
  </style>`).appendTo('head')

  var STICKY_COLUMN_ID_NAME = 'sticky-column-id'
  var STICKY_ZINDEX = 10
  var getStickyStylesheet = _.memoize(function() {
    return angular.element('<style type="text/css" id="sticky-column-styles">').appendTo('head')[0].sheet
  })
  function getScrollParent(element, includeHidden) {
    var style = getComputedStyle(element)
    var excludeStaticParent = style.position === 'absolute'
    var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/
    if (style.position === 'fixed') return document.body
    for (var parent = element; (parent = parent.parentElement);) {
      style = getComputedStyle(parent)
      if (excludeStaticParent && style.position === 'static') continue
      if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent
    }
    return document.body
  }
  function setupScrollTable($element) {
    var $table = $element.closest('table')
    var $parent = $table.parent()
    if (!angular.element(getScrollParent($table[0])).is($parent)) $parent.addClass('pl-data-table__container')
    if (!$parent.data('hasWheelHandler')) $parent.on('wheel.sa_stickyHeader', cancelScroll).data('hasWheelHandler', true)
    return $table
  }
  function cancelScroll(event) {
    var x = event.originalEvent.deltaX
    var y = event.originalEvent.deltaY
    if (!(Math.abs(x) / Math.abs(y) > 1)) return // this means it's a "horizontalish" scroll
    if (x < 0 && this.scrollLeft <= 0 || x > 0 && this.scrollLeft >= this.scrollWidth - this.clientWidth) event.preventDefault()
  }
  function getOffset(el, type) {
    // this is not quite correct, in the case where there are non-sticky columns to the left or right of the sticky column
    if (type === 'left') return el.offsetLeft
    if (type === 'right') return _.get(el, 'offsetParent.offsetWidth', 0) - (el.offsetLeft + el.offsetWidth)
  }

  angular.module('team-admin')
    .directive('stickyHeader', function(
      _
    ) {
      return {
        restrict: 'A',
        controller: function($scope, $element, $attrs) {
          var ctrl = this
          if (!$element.is('thead')) return console.error('sticky-header is only supported on thead elements')

          ctrl.$postLink = function() {
            setupScrollTable($element)
          }

        }
      }
    })
    .directive('stickyColumn', function(
      _,
      $debounce,
      $window
    ) {
      return {
        restrict: 'A',
        controller: function($scope, $element, $attrs) {
          var ctrl = this
          if (!$element.is('th')) return console.error('sticky-column is only supported on th elements')

          var offset, positionRule
          var rules = []
          var styleSheet = getStickyStylesheet()
          var type = $attrs.stickyColumn || 'left'
          var checkOffset = $debounce(_checkOffset, 50, false)

          function _checkOffset() {
            positionRule.style.position = 'static'
            var newOffset = getOffset($element[0], type)
            if (newOffset !== offset) {
              offset = newOffset
              positionRule.style[type] = offset + 'px'
            }
            positionRule.style.position = stickyStyle
          }

          ctrl.$onDestroy = function() {
            _.each(rules, function(rule) { styleSheet.deleteRule(_.indexOf(styleSheet.cssRules, rule)) })
            angular.element($window).off('resize', checkOffset)
          }

          ctrl.$postLink = $debounce(function() {
            var $table = setupScrollTable($element)
            if (!$table.attr(STICKY_COLUMN_ID_NAME)) $table.attr(STICKY_COLUMN_ID_NAME, _.uniqueId(STICKY_COLUMN_ID_NAME))
            var stickyColumnId = $table.attr(STICKY_COLUMN_ID_NAME)
            var tableSelector = '[' + STICKY_COLUMN_ID_NAME + '="' + stickyColumnId + '"].pl-data-table.pl-data-table' // bump specificity

            angular.element($window).on('resize', checkOffset)

            function addRule(tgroupSelector, rulesObj) {
              var selectors = _.map([].concat(tgroupSelector), function(tgs) { return tableSelector + ' > ' + tgs + indexSelector }).join(',\n')
              var rule = styleSheet.cssRules[styleSheet.insertRule(selectors + '{}')]
              _.extend(rule.style, rulesObj)
              rules.push(rule)
              return rule
            }

            var sameSelector = '[sticky-column="' + type + '"]'
            var index = $element[type === 'right' ? 'nextAll' : 'prevAll'](sameSelector).length + 1
            var isLast = !_.any($element[type === 'right' ? 'prevAll' : 'nextAll'](sameSelector))
            var indexSelector = ' > tr > *' + (type === 'right' ? ':nth-last-child' : ':nth-child') + '(' + index + ')'

            addRule('tbody', { zIndex: STICKY_ZINDEX })
            addRule(['thead[sticky-header]', 'tfoot[sticky-footer]'], { zIndex: STICKY_ZINDEX + 1 })
            positionRule = addRule('*', { position: stickyStyle })
            if (isLast) {
              var px = (type === 'right' ? '' : '-') + '1px'
              addRule(['thead[sticky-header]', 'tfoot[sticky-footer]'], { boxShadow: px + ' 0px 0px 0px #b8c0c5 inset' })
              addRule('tbody', { boxShadow: px + ' 0px 0px 0px #d4d9dc inset' })
            }

            (ctrl.$doCheck = checkOffset)()
          })
        }
      }
    })

})()
