require('team-admin')
angular.module('team-admin')
  .directive('gameDetail', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./edit-game.html'),
      controller: function($scope, Game, $routeParams, renderContext, setAs, setWhile) {
        Game.setupTranslation($scope)
        $scope.scope = $scope
        var loadGame = setWhile($scope, 'loading', function(gameId) {
          return Game.find(gameId, {
            endpoint: `/v3/calendar/team/${ $scope.team.id }/event`
          })
            .then(setAs($scope, 'game'))
            .then(game => $scope.invitations = game.event_attendees)
            .catch(err => renderContext.requireConfirmation(false))
        })

        $scope.$watch(() => $routeParams.gameId, loadGame)

        $scope.cancel = function(discardChanges) {
          renderContext.backto(renderContext.schedule.next)
        }

        // $scope.listenToRoot('game_saved:reset_rsvps', loadInvites)

      }
    }
  })
