require('team-admin')
angular.module('team-admin')
  .factory('TeamHighResImages', function(DS, apiV2, setAs) {
    return DS.defineResource({
      name: 'team_high_res_images',
      cacheResponse: false,
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
    })
  })
