require('team-admin')
angular.module('team-admin')

  .factory('Roster', function(DS, $http, $q, Player, RosteringPersonas) {
    var Roster = DS.defineResource({
      name: 'rosters',
      endpoint: '/v3/rosters',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          teams: {
            localKey: 'team_id',
            localField: 'team'
          }
        },
        hasMany: {
          players: {
            localField: 'roster_personas',
            foreignKey: 'roster_id'
          }
        }
      }
    })

    // Add here because the shared Player resource doesn't know about Roster objects.
    DS.addRelations(Player, {
      belongsTo: {
        rosters: {
          localField: 'roster',
          localKey: 'roster_id'
        }
      }
    })

    // Put this here so it stays out of shared
    Roster.loadRosteringPersonas = function(players) {
      var params = { include_contact_info: 1, include_owner: 1, include_guardians: 1 }
      return $q.all(_.map(players, player => {
        player.persona_id ||= player.persona.persona_id
        return $q.when(player.rostering_persona || player.DSLoadRelations('rosteringPersonas', { params }))
      }))
        .then(() => players) // return for chaining purposes
    }

    return Roster
  })
