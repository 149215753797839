require('team-admin')
angular.module('team-admin')
  .directive('editPlayerPhoto', function() {

    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        player: '=',
        dialogOptions: '='
      },
      template: require('./edit-player-photo.html'),
      controller: function($document, $scope, AttachmentService, fileHelper, Player, _, SNAP, wrapDigest) {
        $scope.imageFile = ''
        $scope.croppedImage = ''
        $scope.originalImage = ($scope.player.headshot?.image_urls?.large || '').replace(/_large(\.*\w*)$/, '$1')
        $scope.hadImage = !!$scope.originalImage
        $scope.emptyTitle = $scope.hadImage ? 'ROSTER.EDIT_PHOTO.EMPTY.header_removed' : 'ROSTER.EDIT_PHOTO.EMPTY.header'
        $scope.SNAP = SNAP

        $scope.rosterPlayerImage = {
          org_id: $scope.player.org_id,
          id: $scope.player.id,
          filename: `${ $scope.player.name.toLowerCase().replace(/\s+/g, '_') }_${ moment().format('YYYYMMDDHHmmss') }.jpg`,
          filetype: 'image/jpeg'
        }
        var fileTypes = _.map(fileHelper.imageTypes, type => fileHelper.mimeTypes[type])
        $scope.acceptTypes = fileTypes.join(', ')

        $scope.handleFileSelect = event => readFile(event.currentTarget)
        $document.on('paste', $scope.handlePaste = event => readFile(event.originalEvent.clipboardData))
        $scope.$on('$destroy', () => $document.off('paste', $scope.handlePaste))

        function readFile({ files: [file] }) {
          if (!_.contains(fileTypes, file?.type)) return
          _.extend(new FileReader(), {
            onload: wrapDigest(event => $scope.originalImage = void ($scope.imageFile = event.target.result))
          }).readAsDataURL(file)
        }

        $scope.clearImage = function() {
          $scope.imageFile = $scope.croppedImage = $scope.rosterPlayerImage.photo = $scope.originalImage = null
        }

        $scope.imageCropped = function(data) {
          $scope.rosterPlayerImage.photo = data
        }

        $scope.updatePhoto = function() {
          if ($scope.rosterPlayerImage.photo) return setPhoto().catch(showError)
          else if ($scope.hadImage) return removePhoto().catch(showError)
        }

        $scope.confirmText = function() {
          if ($scope.errorMessage) return 'ROSTER.EDIT_PHOTO.error_retry_button_label'
          var imgRemoved = $scope.hadImage && !($scope.originalImage || $scope.imageFile)
          return imgRemoved ? 'ROSTER.EDIT_PHOTO.remove' : 'ROSTER.EDIT_PHOTO.confirm'
        }

        function showError() {
          var deleting = $scope.hadImage && !$scope.imageFile
          $scope.errorMessage = deleting ? 'ROSTER.EDIT_PHOTO.ERRORS.delete_failed' : 'ROSTER.EDIT_PHOTO.ERRORS.update_failed'
        }

        var hik = { headshot_image_key: null }
        function setPhoto() {
          var file = fileHelper.makeFileObj($scope.rosterPlayerImage.photo, $scope.rosterPlayerImage.filename)
          return AttachmentService.uploadCallback(file)
            .then(attachment => $scope.player.DSUpdate({ ...hik, remote_headshot_url: attachment.url }, Player.v3Options))
            .then($scope.confirm)
        }

        function removePhoto() {
          return $scope.player.DSUpdate({ ...hik, remote_headshot_url: null }, Player.v3Options).then($scope.confirm)
        }
      }
    }
  })
