'use strict'

require('team-admin')
angular.module('team-admin')
  .provider('EnvironmentProvider', function() {

    this.$get = function(ENV, $rootScope, payload) {

      function init(scope) {
        scope = scope || $rootScope
        var user = payload.user || {}
        var org = payload.org || {}

        scope.environment = ENV
        scope.currentYear = new Date().getFullYear()
        scope.currentUser = user
        scope.organization = org

        // ThirdNorth helper
        // Starting out as a function, gives us more flexibility
        scope.thirdNorthAccess = function() {
          var user = scope.currentUser || {}
          return !!user.thirdNorth
        }
      }

      return {
        init: init
      }

    }

  })
