angular.module('pl-shared')

  .factory('UserServicePersonas', function(DS) {
    var UserServicePersonas = DS.defineResource({
      name: 'user_service_personas',
      endpoint: 'personas',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

    return UserServicePersonas
  })
