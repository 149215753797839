angular.module('pl-shared')

  .service('linkableQuestionView', function() {

    return {
      scope: {},
      template: [
        '<div class="pl-question__answer">',
        '<a ng-if="ctrl.href" href="{{ ctrl.href }}" class="pl-link">{{ ctrl.displayValue }}</a>',
        '<span ng-if="!ctrl.href">{{ ctrl.displayValue }}</span>',
        '</div>'
      ].join(''), // collapse whitespace to avoid line breaks
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: function() {}
    }

  })

  .directive('emailQuestionView', function(_, linkableQuestionView) {

    return _.extend({}, linkableQuestionView, {
      controller: function() {
        var ctrl = this
        var answer = ctrl.answer || {}
        if (!ctrl.question.isEditable && answer.value) ctrl.href = 'mailto:' + answer.value
        ctrl.displayValue = answer.display_value || '--'
      }
    })

  })

  .directive('linkQuestionView', function(_, linkableQuestionView) {

    var URL_REGEXP = /^[a-z][a-z\d.+-]*:\/*(?:[^:@]+(?::[^@]+)?@)?(?:[^\s:/?#]+|\[[a-f\d:]+\])(?::\d+)?(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/i

    return _.extend({}, linkableQuestionView, {
      controller: function() {
        var ctrl = this
        var answer = ctrl.answer || {}
        if (!ctrl.question.isEditable && answer.value) ctrl.href = answer.value
        if (ctrl.href && !URL_REGEXP.test(ctrl.href)) ctrl.href = 'http://' + ctrl.href
        ctrl.displayValue = answer.display_value || '--'
      }
    })

  })
