require('team-admin')
angular.module('team-admin')
  .service('TeamCurrentUserService', function(CurrentUserService) {
    return {
      find: CurrentUserService.find,
    }
  })
  .run(function(CurrentUserService) {
    return CurrentUserService.find()
  })
