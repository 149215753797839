/* eslint-disable no-console */
angular.module('pl-shared')
  .factory('pageViewHandler', function(_, $route, $rootScope, defaultPageDepths, SEAnalyticsService) {
    var pageDepths = {}
    var prevPageDepths = {}
    if ($route.current) {
      setPageDepths()
    }
    $rootScope.$on('$routeChangeSuccess', setPageDepths)
    $rootScope.$on('navigation:end', autoPageView)

    function setPageDepths() {
      pageDepths = $route.current.pageDepths ? $route.current.pageDepths : defaultPageDepths
    }

    function autoPageView() { // App controlled pageViews
      if ($route.current.skipPageView) { // Respect the flags if auto control
        return
      }
      firePageView()
    }

    function firePageView() { // Dev controlled pageViews
      SEAnalyticsService.analytics.push({
        type: 'pageView',
        data: { ...pageDepths }
      })
    }

    function fireEvent(action, event_type, value) {
      SEAnalyticsService.analytics.push({
        type: 'seEvent',
        data: { ...pageDepths, action, event_type, value }
      })
    }

    function addPageDepths(passedPageDepths) {
      prevPageDepths = pageDepths
      _.extend(pageDepths, passedPageDepths)
    }

    function cleanPageDepths() {
      pageDepths = $route.current.pageDepths ? $route.current.pageDepths : defaultPageDepths
    }

    function mergeToDL(key, value) {
      window.fullSizeAnalytics.seDataLayer.mergeToDL(key, value)
    }

    function pushEventToDL(event) {
      window.fullSizeAnalytics.seDataLayer.pushEventToDL(event)
    }

    return {
      firePageView: firePageView,
      addPageDepths: addPageDepths,
      fireEvent: fireEvent,
      mergeToDL: mergeToDL,
      pushEventToDL: pushEventToDL,
      cleanPageDepths: cleanPageDepths
    }

  })
