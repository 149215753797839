angular.module('pl-shared')
  .factory('Player', function($injector, $q, DS, paginate, _, RoleAssignments) {
    function computeExtendedAttributes(method) {
      return function(config, player, cb) {
        if (player.$extended) player.extended_attributes = _.map(player.$extended, (value, key) => ({ key, value }))
        DS.defaults[method](config, player, cb)
      }
    }
    var Player = DS.defineResource({
      name: 'players',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      beforeCreate: computeExtendedAttributes('beforeCreate'),
      beforeUpdate: computeExtendedAttributes('beforeUpdate'),

      afterDestroy: async function(config, player, cb) {
        if (!_.contains(['coach', 'staff'], player.type || player.roster_role)) return DS.defaults.afterDestroy(config, player, cb)
        var persona_id = player.persona?.persona_id || player.persona_id
        var seasonTeam = player.roster?.team || (await $injector.get('SeasonTeams').findAll({
          ..._.pick(player, 'org_id', 'season_id'),
          'team_id[]': [player.team_id]
        }))[0]
        var roleProps = {
          persona_id: persona_id,
          principal_type: 'Persona',
          principal_id: persona_id,
          resource_type: 'TeamInstance',
          resource_id: String(seasonTeam.id), // this field is stored as a string in US
          org_id: player.org_id || seasonTeam.org_details.id,
          collapsed: true,
          role_type: 'CompositeRole'
        }
        RoleAssignments.findAll(roleProps, { bypassCache: true })
          .then(ras => $q.all(_.map(ras, ra => ra.DSDestroy({ params: { persona_id } }))))
          .finally(() => DS.defaults.afterDestroy(config, player, cb))
      },

      relations: {
        belongsTo: {
          rosteringPersonas: {
            localKey: 'persona_id',
            localField: 'rostering_persona'
          }
        }
      },
      computed: {
        $extended: ['extended_attributes', function(xas) {
          return _.object(_.map(xas, xa => [xa.key, xa.value]))
        }],
        full_name: [
          'name', 'first_name', 'last_name',
          function(name, first_name, last_name) {
            return name || (first_name + ' ' + last_name)
          }
        ],
        positionsAbbrev: [
          'positions',
          function(positions) {
            return _.map(positions, function(position) { return position.abbreviation || position })
          }
        ]
      },
      methods: {
        fullName: function() {
          return this.name || this.first_name + ' ' + this.last_name
        },
        canMessage: function(messagesEnabled) {
          return this.invite_status !== 'uninvited' && messagesEnabled !== false
        },
        persona: function() {
          return {
            first_name: this.first_name,
            last_name: this.last_name,
            id: this.persona_id
          }
        }
      }
    })

    Player.defaultSortOrder = 'first_name,last_name'
    Player.defaultSortDir = 'asc'
    Player.types = ['player', 'coach']
    Player.typesV3 = ['player', 'staff']
    Player.contexts = ['players', 'staff']
    Player.contextType = _.object(Player.contexts, Player.types)
    Player.contextTypeV3 = _.object(Player.contexts, Player.typesV3)
    Player.typeContexts = _.object(Player.types, Player.contexts)
    Player.typeContextsV3 = _.object(Player.typesV3, Player.contexts)

    Player.v3Options = {
      endpoint: '/v3/roster_personas',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api'
    }

    return paginate(Player)
  })
