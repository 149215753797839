(function() {

  var $ = angular.element

  angular.module('pl-shared')
    .run(extendFormController)
    .directive('form', formDirective)
    .directive('ngForm', formDirective)
    .directive('touchOn', touchOn)

  function extendFormController(_, $q, $timeout, formDirective, ngModelDirective) {

    var FormController = formDirective[0].controller
    var NgModelController = _.last(ngModelDirective[0].controller)

    function withAllFields(formCtrl, fieldFn, formFn) {
      var args = Array.prototype.slice.call(arguments, 3)
      for (var key in formCtrl) {
        if (_.startsWith(key, '$')) continue

        var fieldCtrl = formCtrl[key] || {}
        var args2 = [fieldCtrl].concat(args)
        if (fieldCtrl instanceof FormController) formFn.apply(null, args2)
        else if (fieldCtrl instanceof NgModelController) fieldFn.apply(null, args2)
      }
    }

    // Marks all existing fields as dirty and touched to force error display
    // while allowing new fields to behave as normal. Usefull in cases where
    // you can add new subforms (like add many).
    function dirtyTouchAllInvalidFields(formCtrl) {
      withAllFields(formCtrl, dirtyTouchField, dirtyTouchAllInvalidFields)
    }

    function dirtyTouchField(fieldCtrl, forceDirtyTouch) {
      if (!(fieldCtrl instanceof NgModelController)) return
      fieldCtrl.$validate()
      if (forceDirtyTouch || fieldCtrl.$invalid) {
        fieldCtrl.$setDirty()
        fieldCtrl.$setTouched()
      }
    }


    function formErrors(formCtrl, prevOpts) {
      var opts = {
        prefix: 'prefix' in prevOpts ? formCtrl.name + ':' : '',
        errors: prevOpts.errors
      }

      withAllFields(formCtrl, fieldErrors, formErrors, opts)
      return opts.errors
    }

    function fieldErrors(fieldCtrl, opts) {
      var errors = _fieldErrors(fieldCtrl)
      if (!errors) return
      for (var e in errors) {
        opts.errors[opts.prefix + fieldCtrl.$name + ':' + e] = true
      }
    }

    function _fieldErrors(fieldCtrl, opts) {
      return fieldCtrl && fieldCtrl.$touched && fieldCtrl.$dirty && _.omit(fieldCtrl.$error, opts && opts.ignore || [])
    }

    function formHasErrors(formCtrl, opts) {
      return withAllFields(formCtrl, fieldHasErrors, formHasErrors, opts)
    }

    function fieldHasErrors(fieldCtrl, opts) {
      var hasErrors = !_.isEmpty(_fieldErrors(fieldCtrl, opts))
      if (opts && hasErrors) opts.hasErrors = true
      return hasErrors
    }

    _.extend(FormController.prototype, {

      // Sets one or more inputs to dirty/touched and validates them.
      // This is useful if you want a control to trigger a model change and validation on another form input.
      validateFields: function() {
        var form = this
        _.each(arguments, function(property) {
          var fieldCtrl = _.get(form, property) // allow nested paths
          dirtyTouchField(fieldCtrl, true) // true = force dirty touch
        })
      },

      // If field name is not provided, we will search for all form errors
      hasErrors: function(fieldName, opts) {
        if (typeof fieldName !== 'string') {
          opts = fieldName
          fieldName = null
        }

        opts = opts || {}
        opts.hasErrors = false

        if (fieldName) {
          return fieldHasErrors(this[fieldName], opts)
        }
        else {
          formHasErrors(this, opts)
          return opts.hasErrors
        }
      },

      getErrors: function() {
        // clear the errors object reference for this form controller before we start
        var errors = this._getErrors = this._getErrors || {}
        for (var e in errors) { delete errors[e] }
        return formErrors(this, { errors: errors })
      },

      // Wraps the form submit action so that it returns a rejected promise (e.g. for spinner-click) if the form is invalid.
      validateAndSubmit: function(callback) {
        var form = this
        form.$setSubmitted()
        dirtyTouchAllInvalidFields(form)
        if (!form.$valid) {
          var formElement = form.$$formElement
          var field = formElement.find('.ng-invalid:not(form):not(ng-form):not([ng-form])').first()
          field.focus()
          if (field.data('select2')) field.data('select2').focus()

          return $q.reject()
        }

        // don't submit unless we are ready
        if (!callback || form.$submitting) return $q.resolve()

        form.$submitting = true
        return $q
          .resolve(callback())
          .finally(function() {
            form.$submitting = false
          })
      },

      // You may need to use this if you trigger from a controller when a digest is already in progress
      validateAndSubmitAsync: function(callback) {
        return $timeout(function() { return this.validateAndSubmit(callback) }.bind(this))
      }

    })
  }

  function formDirective(_) {
    return {
      require: 'form',
      link: function(scope, element, attrs, controller) {
        controller.$$formElement = element
        var hasAction = 'action' in attrs
        var validateOnSubmit = 'validateAndSubmit' in attrs
        var preValidated

        // Use `form-controller-as="mySubFormName"` in a nested repeated ng-form directive to create a local scope reference to the subform.
        if (attrs.formControllerAs) scope[attrs.formControllerAs] = controller

        // validate on submit if custom attribute/handler is present
        if (validateOnSubmit) element.on('submit', validateAndSubmit)

        function validateAndSubmit(event) {
          if (hasAction) {
            if (preValidated) return
            else event.preventDefault()
          }
          return scope.$apply(function() {
            controller
              .validateAndSubmit(submit)
              .then(hasAction && resubmit)
          })
        }

        function submit() {
          return scope.$eval(attrs.validateAndSubmit)
        }

        function resubmit() {
          preValidated = true
          element.submit()
          preValidated = false
        }
      }
    }
  }

  function touchOn($rootScope) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        if (!attrs.touchOn) return
        // coppied from ngModel postLink
        element.on(attrs.touchOn, function() {
          if (modelCtrl.$touched) return
          else if ($rootScope.$$phase) scope.$evalAsync(modelCtrl.$setTouched)
          else scope.$apply(modelCtrl.$setTouched)
        })
      }
    }
  }

})()
