angular.module('pl-shared')
  .run(function(printedCardService) {
    printedCardService.listen()
  })
  .factory('printedCardService', function(_, $q, $http, $window, $rootScope, ENV, DS, Organization, dialog, i18ng) {

    var doc = $window.document

    return {
      print: print,
      listen: listen,
      printWithoutWarning: printWithoutWarning
    }

    // EXAMPLE PARAMS
    // {
    //   type: 'Cards:Print',
    //   data: {
    //     user_uuid: '1234',
    //     issuing_organization_id: '1234',
    //     affiliation_organization_id: '1234',
    //     scope: 'roster_persona',
    //     roster_personas: [{ persona_id: 1234, season_id: 1234, roster_id: 1234 }]
    //   }
    // }

    function print(params) {
      return $q.all(params)
        .then(showIneligibleWarning)
        .then(printCards)
    }

    function printWithoutWarning(params) {
      return $q.all(params)
        .then(printCards)
    }

    function listen() {
      $window.addEventListener('message', function(event) {
        if ((event.data || {}).type !== 'Cards:Print') return

        var message
        try {
          message = JSON.parse(event.data.data)
        }
        catch (error) {
          message = {}
        }

        print(message)
      })
    }

    function showIneligibleWarning(params) {
      return buildConfirmationDialog(params)
        .then(dialog.confirm)
        .then(function() { return params })
    }

    function buildConfirmationDialog(params) {
      return Organization
        .findPublic(params.issuing_organization_id)
        .then(function(org) {
          var messageKey = confirmationMessageKey(params)
          var rosterPersonas = params.roster_personas || []
          var messageOpts = { org: org.name, selectedCount: rosterPersonas.length }
          return {
            component: 'confirm-dialog',
            scope: $rootScope,
            attrs: {
              heading: i18ng.t('PRINT_CARDS.confirmation_title'),
              confirmLabel: i18ng.t('PRINT_CARDS.confirmation_submit'),
              modalSize: 'small',
              message: i18ng.t(messageKey, messageOpts)
            }
          }
        })
    }

    function confirmationMessageKey(params) {
      var prefix = 'PRINT_CARDS.confirmation_message_'
      if ('roster_type' in params) return prefix + params.roster_type
      if ('roster_id' in params) return prefix + 'roster'
      return prefix + 'persona'
    }

    function printCards(params) {
      var form = doc.createElement('form')
      form.method = 'post'
      form.target = '_blank'
      form.action = ENV.urls.membershipService + '/cards/print'

      for (var name in params) {
        var val = params[name]
        addHiddenField(form, name, val)
      }

      doc.body.appendChild(form)
      form.submit()
      doc.body.removeChild(form)
    }

    function addHiddenField(form, name, value) {
      if (_.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
          addHiddenField(form, name + '[]', value[i])
        }
      }
      else if (_.isObject(value)) {
        for (var key in value) {
          addHiddenField(form, name + '[' + key + ']', value[key])
        }
      }
      else {
        var input = doc.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      }

    }

  })
