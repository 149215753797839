require('team-admin')
angular.module('team-admin')
  .factory('Calendar', function(DS, paginate, Event, Game, EventRsvp) {

    var ONE_DAY = 1000 * 60 * 60 * 24

    var Calendar = DS.defineResource({
      name: 'calendar',
      endpoint: '/v3/calendar', // overwrite with team id
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      cacheResponse: false // There is no show endpoint, and we want to reload every findAll
    })

    paginate(Calendar)

    function distributeEvents(data) {
      var arr = Array.isArray(data) ? data : [data]
      var calendarItems = []
      var opts = {}

      arr.forEach(function(attrs) {
        calendarItems.push((attrs.event_type === 'game' ? Game : Event).parseCalendar(attrs, opts))
      })

      return calendarItems
    }

    Calendar.instanceType = function(calendarItem) {
      if (calendarItem.event_type === 'event') return 'Event'
      if (calendarItem.event_type === 'game') return 'Game'
    }

    Calendar.instanceId = function(calendarItem) {
      if (calendarItem.event_type === 'event') return 'Event' + calendarItem[Event.idAttribute]
      if (calendarItem.event_type === 'game') return 'Game' + calendarItem[Game.idAttribute]
    }

    Calendar.orderByTime = function(calendarItem) {
      var tbd = Calendar.instanceType(calendarItem) === 'Game' && calendarItem.all_day_event
      var startTime = calendarItem.start_date_time
      if (tbd) startTime.replace('T', 'Z') // force tbd game to end or that day
      return startTime
    }

    var findAll = Calendar.findAll
    Calendar.findAll = function(params, opts) {
      return findAll(params, opts).then(distributeEvents)
    }

    return Calendar
  })
