require('team-admin')
angular.module('team-admin')
  .directive('newTeamMessage', function() {
    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        team: '=',
        roster: '=',
        recipients: '=',
        messagesEnabled: '=?'
      },
      template: require('./new-message.html'),
      controller: function(pageViewHandler, _, $scope, $element, $log, fileHelper, Player, TeamMessage, setWhile, AttachmentService, AlertPreference, Alerts, i18ng, listFilter, wrapDigest) {
        pageViewHandler.fireEvent('MessageModal.Open')

        // var prependedText = $scope.currentUser.firstname + ' [' + $scope.team.name + ']: '
        // var TEXT_LIMIT = prependedText.length > 20 ? (160 - prependedText.length) : 140
        // var SUBJECT_LIMIT = $scope.SUBJECT_LIMIT = 255
        // var MESSAGE_LIMIT = 950

        $scope.acceptTypes = _.uniq([...fileHelper.imageTypes, ...fileHelper.docTypes, ...fileHelper.textTypes]).map(type => `.${type}`).join(',')

        var playerNotInvited = i18ng.t('PLAYER.has_not_been_invited')
        var playerOptedOut = i18ng.t('PLAYER.opted_out_of_messages')
        $scope.gaCategory = 'Message Modal'

        var sendOpts = {
          params: _.pick($scope.team.nginSeasonTeam, 'team_id', 'season_id')
        }

        // ROSTER OPTIONS

        var optGroups = {}
        var recipientOptionsData = _.map(Player.contexts, function(context) {
          return optGroups[context] = {
            text: i18ng.t(context.toUpperCase() + '.title'),
            children: []
          }
        })

        $scope.recipientOptions = {
          data: recipientOptionsData,
          templateResult: function(data) {
            // optGroup || player has claimed the profile
            if (data.children || !data.disabled) return data.text

            var cannotMessageText = data.uninvited ? playerNotInvited : playerOptedOut
            // player has not claimed the profile
            return angular.element('<div pl-grid>' +
              '<div pl-grid="1/2@phone" pl-grid-el>' + data.text + '</div>' +
              '<div pl-grid="1/2@phone" pl-grid-el class="pl-text--right pl-field__help--inline">' + cannotMessageText + '</div>' +
              '</div>')
          }
        }

        $scope.message = TeamMessage.createInstance({
          recipients: personaIdsToOptions($scope.recipients),
          message: {
            mode: 'richtext',
            subject: '',
            body_richtext: '',
            attachments: []
          }
        })

        function updateChildren(members, oldMembers) {
          return AlertPreference.getMessagesEnabled($scope.team, $scope.roster)
            .then(function(messagesEnabled) {
              $scope.messagesEnabled = messagesEnabled

              _.each(Player.contexts, function(context) {
                var optGroup = optGroups[context]
                optGroup.children.length = 0
                optGroup.children.push({
                  disabled: true,
                  id: 'all_' + context,
                  text: i18ng.t(context.toUpperCase() + '.all')
                })
              })

              _.each($scope.roster, function(member) {
                var context = Player.typeContextsV3[member.roster_role]
                var optGroup = optGroups[context]
                var options = optGroup.children
                var allOption = options[0]
                var canMessage = member.canMessage($scope.messagesEnabled[member.persona.persona_id])

                options.push({
                  id: member.persona.persona_id,
                  text: member.fullName(),
                  disabled: !canMessage,
                  uninvited: member.invitation.status === 'uninvited'
                })

                // enable all option if at least on member in this optGroup
                if (canMessage) allOption.disabled = false
              })

              addRecipientPersonaIds($scope.recipients) // re-add here because we have options in the optgroups now
            })
            .catch($log.error)
        }

        $scope.$watchCollection('roster', setWhile($scope, 'playersLoading', updateChildren))

        $scope.$watch('message.recipients', checkSelectAll)

        $scope.processFiles = wrapDigest(event => {
          _.each(event.target.files, file => {
            $scope.message.message.attachments.push(AttachmentService.upload(file))
          })
          event.target.value = null // reset for future uploads
          $scope.$$postDigest(() => $element.find('attachments-process-card').last()[0].scrollIntoView())
        })

        function checkSelectAll(recipients) {
          for (var i = 0; i < recipients.length; i++) {
            var id = recipients[i]
            if (!/^all_/.test(id)) continue
            recipients.splice(i, 1)
            addAllOfType(id.replace(/^all_/, ''))
            break
          }
        }

        function addAllOfType(type) {
          var personaIds = _.pluck(optGroups[type].children, 'id').slice(1) // remove "all" option
          addRecipientPersonaIds(personaIds)
          $element.find('#messageRecipients').val($scope.message.recipients)
        }

        function addRecipientPersonaIds(personaIds) {
          var recipients = $scope.message.recipients
          var options = personaIdsToOptions(personaIds)
          _.each(options, function(recipient) {
            if (!_.contains(recipients, recipient)) recipients.push(recipient)
          })
        }

        $scope.togglePreview = function() {
          $scope.preview = !$scope.preview
          $scope.gaCategory = $scope.preview ? 'Message Preview' : 'Message Modal'
        }

        $scope.sendMessage = function() {
          pageViewHandler.fireEvent('MessagePreview.SendMessage', 8)
          $scope.submitting = true
          var message = angular.copy($scope.message)
          message.message.attachments = _.filter(message.message.attachments, { status: 'uploaded' })
          if ($scope.team.originator_system !== 'Ngin') {
            message.originator_type = 'TeamService::Team'
            message.team_id = $scope.team.id
            message.recipients = message.recipients.map(Number) // org endpoint requires numbers
            sendOpts = { endpoint: 'team_messages' }
          }
          return TeamMessage
            .create(message, sendOpts)
            .then(sendMessageSuccess, sendMessageError)
        }

        function sendMessageSuccess() {
          Alerts.success('MESSAGES.NEW.send_success', {
            list: listFilter(personaIdsToNames($scope.message.recipients))
          })
          $scope.confirm()
        }

        function sendMessageError() {
          $scope.submitting = false
          $scope.failed = true
        }

        function personaIdsToNames(personaIds) {
          personaIds = _.map(personaIds, String)
          var messageableNames = []
          _.each(recipientOptionsData, function(optgroup) {
            _.each(optgroup.children, function(option) {
              if (_.contains(personaIds, String(option.id))) messageableNames.push(option.text)
            })
          })
          return messageableNames
        }

        function personaIdsToOptions(personaIds) {
          personaIds = _.map(personaIds, String)
          var options = []
          var unmessageableNames = []
          _.each(recipientOptionsData, function(optgroup) {
            _.each(optgroup.children, function(option) {
              var id = String(option.id)
              var index = personaIds.indexOf(id)
              if (index >= 0) {
                if (option.disabled) return unmessageableNames.push(option.text)
                options.push(id)
              }
            })
          })
          if (unmessageableNames.length) showUnmessageableRecipients(unmessageableNames)
          return options
        }

        function showUnmessageableRecipients(unmessageableNames) {
          $scope.failed = false // only show one warning at a time
          $scope.unmessageableRecipientOptions = {
            count: unmessageableNames.length,
            list: listFilter(unmessageableNames)
          }
        }
      }
    }
  })
