angular.module('pl-shared')
  .service('$debounce', function(_, $timeout) {
    /**
     * $debounce
     *
     * Returns a version of _.debounce that uses Angular's $timeout.
     * This allows the timeouts created to be programmatically flushed for testing purposes.
     */
    return function $debounce(fnc, delay) {
      var prev
      return function _debounced() {
        $timeout.cancel(prev)
        prev = $timeout(_.spread(fnc).bind(this, arguments), delay, false)
      }
    }

  })
