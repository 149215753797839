angular.module('pl-shared')
  .factory('FirebaseChatFactory', function(apiV2, $http, firebase, $firebaseAuth, $firebaseArray, $firebaseObject, currentUser, ENV) {
    var allMessages = []
    var initialMessages = []
    var groupOfMessages = 40
    var lastReadMessage
    var teamInstance = ''
    var userAuthenticated = false
    var userUnreadObject
    var users

    function getCustomAuthToken() {
      var url = ENV.urls.apiProxy + '/firebase/auth'
      var opts = { headers: apiV2.headers, withCredentials: true }
      var promise = $http.post(url, {}, opts)
      return promise.then(signInWithToken)
    }

    function signInWithToken(response) {
      var token = apiV2.deserialize(response)
      var authObj = $firebaseAuth()
      authObj.$signInWithCustomToken(token.jwt)
        .then(function(response) {
          userAuthenticated = true
          setLastReadMessage()
        })
    }

    function userUnread() {
      var ref = firebase.database().ref().child('user-unread').child(currentUser.uuid).child('channels').child(teamInstance)
      userUnreadObject = $firebaseObject(ref)
      return userUnreadObject
    }

    function setLastReadMessage() {
      var lastReadRef = firebase.database().ref().child('channel-last-read').child(teamInstance).child(currentUser.uuid)
      lastReadMessage = $firebaseObject(lastReadRef)
    }

    function initialize(activeSeason) {
      teamInstance = /* 'team_instance_' + */ activeSeason.season_team_id

      var channelMetaRef = firebase.database().ref().child('channel-metadata').child(teamInstance).child('users')
      users = $firebaseArray(channelMetaRef)

      var refBase = firebase.database().ref().child('channel-messages-v2').child(teamInstance).orderByKey()
      allMessages = $firebaseArray(refBase)

      var initialMessagesRef = refBase.limitToLast(groupOfMessages)
      initialMessages = $firebaseArray(initialMessagesRef)
      if (userAuthenticated) setLastReadMessage()
    }

    function updateLastRead(messageId) {
      var message = allMessages.$getRecord(messageId)
      var updatedLastReadRef = firebase.database().ref().child('channel-last-read').child(teamInstance).child(currentUser.uuid).child(message.$id)
      var updatedLastRead = $firebaseObject(updatedLastReadRef)
      userUnreadObject.$loaded().then(function() {
        if (message.timestamp > userUnreadObject.lastReadMessageTimestamp || !userUnreadObject.lastReadMessageTimestamp) {
          lastReadMessage.$remove()
          updatedLastRead.$loaded()
            .then(function(response) {
              updatedLastRead.$value = message.timestamp
              updatedLastRead.$save()
            })
        }
      })
    }

    function getAllMessages() {
      return allMessages
    }

    function getInitialMessages() {
      return initialMessages
    }

    function getLastRead() {
      return lastReadMessage
    }

    function getUsers() {
      return users
    }

    function returnUserUnreadObject() {
      return userUnreadObject
    }

    return {
      initialize: initialize,
      getCustomAuthToken: getCustomAuthToken,
      getAllMessages: getAllMessages,
      getInitialMessages: getInitialMessages,
      updateLastRead: updateLastRead,
      getLastRead: getLastRead,
      getUsers: getUsers,
      userUnread: userUnread,
      returnUserUnreadObject: returnUserUnreadObject
    }

  })
