require('team-admin')
angular.module('team-admin')
  .directive('removeEvents', function() {

    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        events: '=',
        team: '=',
        gaCategory: '=',
        dialogOptions: '='
      },
      template: require('./remove-events.html'),
      controller: function(pageViewHandler, _, i18ng, $rootScope, snAsync, getEnum, moment, $scope, $timeout, Event, setWhile) {
        pageViewHandler.fireEvent('DeleteEventsModal.Open')

        $scope.scope = $scope
        $scope.title = 'SCHEDULE.REMOVE.title'
        $scope.message = 'SCHEDULE.REMOVE.message'

        var sortedEvents = _.sortBy($scope.events, 'start_date_time')
        var [eventsToDelete, eventsNotDeletable] = _.partition(sortedEvents, 'canDelete')

        var buttonLabel = $scope.button_label = 'SCHEDULE.REMOVE.button_label'
        var buttonLabelPlural = $scope.button_label_plural = 'SCHEDULE.REMOVE.button_label_plural'
        var dateFormat = i18ng.t('DATEPICKER.display_format', { defaultValue: 'MMM D YYYY' })
        var deleted = [], recurringEvents, nonRecurringEvents

        function setEventCollections() {
          [recurringEvents, nonRecurringEvents] = _.partition(eventsToDelete, e => e.recurInterval && e.recurInterval !== 'none')
          _.extend($scope, { eventsToDelete, eventsNotDeletable, recurringEvents, nonRecurringEvents })
        }
        setEventCollections()

        _.each(recurringEvents, ({ id, date }) => {
          // ask user for change range if events are from the same series
          var key = 'SCHEDULE.EDIT_EVENT.CHANGE_RANGE.options'
          var enumOpts = { date: moment(date).format(dateFormat), returnObjectTrees: true }

          _.set($scope, ['changeRangeOptions', id], getEnum(key, enumOpts))
          _.set($scope, ['changeRange', id], $scope.changeRangeOptions[id][0].value)
          _.set($scope, ['changeRangeDate', id], enumOpts.date)
        })

        function setTranslationOptions() {
          var count = eventsToDelete.length
          var deleteFutureEvents = _.any($scope.changeRange, r => r === 'future')
          $scope.button_label = deleteFutureEvents ? buttonLabelPlural : buttonLabel
          $scope.translationOpts = {
            team: $scope.team,
            count: count,
            count_message: countMessage(count),
            event: eventsToDelete[0]
          }
        }

        function countMessage(count) {
          return count == $scope.events.length ? count : count + ' of ' + $scope.events.length
        }

        $scope.$watch('eventsToDelete', setTranslationOptions)
        $scope.$watchGroup('changeRange', setTranslationOptions)

        $scope.removeEvents = setWhile($scope, 'deleting', function() {
          var notDeleted = []

          function removeEvent(event) {
            var deleteRecurring = event.recurInterval && event.recurInterval !== 'none' && $scope.changeRange[event.id] === 'future'
            var params = deleteRecurring ? {
              event_id: event.id // The start and end dates are taken from the event in CS
            } : {}

            return Event.destroy(deleteRecurring ? event.event_series_id : event.id, {
              params,
              endpoint: `/v3/calendar/team/${ $scope.team.id }/event${ deleteRecurring ? '_series' : '' }`
            })
              .then(() => deleted.push(event), () => notDeleted.push(event))
          }

          function finish() {
            if (!notDeleted.length) $timeout($scope.cancel) // done if nothing left to delete
            else {
              eventsToDelete = notDeleted
              eventsNotDeletable = {}
              setEventCollections()
              $scope.error = true
              $scope.title = 'SCHEDULE.REMOVE.ERROR.title'
              $scope.message = 'SCHEDULE.REMOVE.ERROR.message'
              $scope.button_label = 'SCHEDULE.REMOVE.ERROR.button_label'
            }
          }

          return snAsync.eachLimit([
            ...nonRecurringEvents,
            ..._.sortBy(recurringEvents, 'date').reverse() // avoid errors when multiples of the same are passed in
          ], 1, removeEvent)
            .then(finish)
        })

        $scope.dialogOptions.preCloseCallback = function() {
          if ($scope.deleting) return false
          if (deleted.length) {
            $scope.confirm(deleted)
            if (_.any(recurringEvents)) $rootScope.$emit('event:save_recurring', {}) // listeners reload on this event
            return false
          }
        }

        $scope.getGaLabel = function(option) {
          if (option) {
            return option.value === 'one' ? 'Delete Event Only' : 'Delete Series'
          }
          else {
            return eventsToDelete.length === 1 && !_.any($scope.changeRange, r => r === 'future') ? 'Delete Event' : 'Delete Events'
          }
        }

      }
    }
  })
