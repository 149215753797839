require('team-admin')
angular.module('team-admin')
  .component('timezoneSelect', {
    bindings: {
      tzDate: '=',
      event: '='
    },
    template: require('./timezone-select.html'),
    controller: function(_, moment, ApiTimezone, setAs) {
      var $ctrl = this
      $ctrl.$onInit = function() {

        $ctrl.zones = []

        $ctrl.sortOffsets = function(zone) {
          var minus = zone.offset.charAt(0) === '-'
          var hr = parseInt(zone.offset.substring(1, 3))
          var min = parseInt(zone.offset.substring(4, 6)) / 60
          var offsetAsNum = hr + min
          return minus ? 0 - offsetAsNum : offsetAsNum
        }

        ApiTimezone.getOptions().then(setAs($ctrl, 'zones'))
      }
    }
  })
