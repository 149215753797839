angular.module('pl-shared')

  .factory('SquadLockerStores', function(DS) {

    function forceDateOnly(store) {
      var utcRgx = /^(\d{4})-(\d{2})-(\d{2})T00:00:00Z$/
      function stripUtcTime($0, $1, $2, $3) {
        return new Date($2 + '/' + $3 + '/' + $1).toISOString() // force to ISO of local browser time if it's 00:00:00Z, we just want the date
      }
      if (utcRgx.test(store.open_date)) store.open_date = store.open_date.replace(utcRgx, stripUtcTime)
      if (utcRgx.test(store.close_date)) store.close_date = store.close_date.replace(utcRgx, stripUtcTime)
    }

    return DS.defineResource({
      name: 'squad_locker_stores',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      beforeInject: function(Resource, data) {
        (Array.isArray(data) ? data : [data]).forEach(forceDateOnly)
      },
      beforeCreateInstance: function(Resource, data) {
        forceDateOnly(data)
      }
    })
  })
