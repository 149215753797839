angular.module('pl-shared')
  .run(['_', '$rootScope', 'setAs', function(_, $rootScope, setAs) {
    _.extend($rootScope.constructor.prototype, {

      /* setAs - see docs in standalone setAs component */

      setAs: function() {
        return setAs(this, ...arguments)
      },

      /* listenToRoot ***

      Simple way to add an event handler to the root scope,
      that will be removed when the listening scope is destroyed.
      Binds to the listening scope or an optional context.

      Example: $scope.listenToRoot('event:save_instance', loadEvents)

      */
      listenToRoot: function(eventName, eventHandler, context) {
        return this.$on('$destroy', $rootScope.$on(eventName, eventHandler.bind(context || this)))
      },

      /* $one ***
        Adds an event listener that is removed after being executed once.
        Similar to jQuery's .one() function.
      */
      $one: function(name, listener) {
        $rootScope.$on(name, $rootScope.$on(name, listener))
      },

      /* safeApply ***

      Runs the function in an $apply block if there is not already a digest in progress.

      Example: $rootScope.safeApply(function() { (code that needs a $digest) })

      */
      safeApply: function(digestable) {
        if (this.$$phase) digestable()
        else this.$apply(digestable)
      },

      /* ngModelArray ***

      */

      ngModelArray: function(prop, val) {
        var scope = this
        return function(bool) {
          var arr = scope.$eval(prop)
          var index = arr.indexOf(val)
          var isSelected = index >= 0
          if (bool === undefined) return isSelected
          if (bool &&  !isSelected) arr.push(val)
          else if (!bool && isSelected) arr.splice(index, 1)
        }
      },

      now: function() {
        return new Date()
      }

    })

  }])
