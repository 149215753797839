require('team-admin')
angular.module('team-admin')
  .directive('gameSettings', function() {

    return {
      restrict: 'A',
      scope: {
        team: '=',
        game: '=',
        gameForm: '=',
      },
      template: require('./game-settings.html'),
      controller: function($scope, Game, Opponent, Alerts, moment, timezone, setWhile, i18ng) {
        Game.setupTranslation($scope)

        var game = $scope.game
        $scope.scope = $scope

        if (!$scope.game.start_time) {
          var defaultStartTime = moment().add(1, 'hour').startOf('hour')
          $scope.game.start_time = defaultStartTime.format('HH:mm')
          $scope.game.date = defaultStartTime.format('YYYY-MM-DD')
        }

        _.extend($scope, {
          scope: $scope,
          failed: false,
          timezone: timezone,
          homeTeamOptions: [$scope.team],
          existingTeamsLabel: i18ng.t('GAME.existing_teams'),
          locationProtocols: Game.locationProtocols
        })

        $scope.allowCreateCustomOpponent = $scope.featureToggles.league_custom_opponents

        if ($scope.teamsLocked) {
          $scope.homeTeamOptions = [game.game_details.team_1, game.game_details.team_2]
          return
        }

        // THIS ONLY RUNS IF TEAMS ARE NOT LOCKED

        function otherTeamId() {
          return game.otherTeam($scope.team).id
        }

        function updateHomeTeamOptions(opponentId) {
          $scope.opponent(opponentId)
          $scope.homeTeamOptions = [$scope.team, _.findWhere($scope.opponents, { id: opponentId })]
        }

        $scope.$watch(otherTeamId, updateHomeTeamOptions)

        $scope.opponent = function(opponentId) {
          if (!game) return
          // setter
          if (arguments.length) updateOpponent(opponentId)
          // getter
          else return game.otherTeam($scope.team).id
        }

        function updateOpponent(opponentId) {
          var otherTeam = game.otherTeam($scope.team)
          var opponent = _.findWhere($scope.opponents, { id: opponentId })
          if (!opponent) return
          otherTeam.id = opponent.id
          if (opponentId == 'new') otherTeam.name = ''
          else otherTeam.name = opponent.name
        }

        setWhile($scope, 'opponentsLoading', function() {
          Opponent.findAll({ per_page: 100 }, {
            load: 'all',
            endpoint: `v3/teams/${ $scope.team.id }/opponents`
          })
            .then(function(data) {
              $scope.opponents = _.sortBy(_.reject(data, { id: $scope.team.id }), 'name')

              // Add TBD option
              $scope.opponents.unshift({
                id: '',
                name: i18ng.t('GAME.tbd_team')
              })

              // Add custom opponent option
              if ($scope.allowCreateCustomOpponent) {
                $scope.opponents.unshift({
                  id: 'new',
                  name: i18ng.t('GAME.new_team')
                })
              }

              updateHomeTeamOptions(otherTeamId())
            })
        })()

      }
    }
  })
