angular.module('pl-shared')
  .filter('tel', function() {

    return function(tel) {
      if (!tel) return ''

      var value = tel.toString().trim().replace(/^\+/, '')

      if (value.match(/\D/)) return tel

      var country, areaCode, phonePrefix, phoneBody

      switch (value.length) {
        case 10: // AAA####### -> AAA-###-####
          country = ''
          areaCode = value.slice(0, 3)
          phonePrefix = value.slice(3, 6)
          phoneBody = value.slice(6)
          break

        case 11: // +CAAA####### -> C AAA-###-####
          country = value.slice(0, 1)
          areaCode = value.slice(1, 4)
          phonePrefix = value.slice(4, 7)
          phoneBody = value.slice(7)
          break

        case 12: // +CCCAA####### -> CCC AA-###-####
          country = value.slice(0, 3)
          areaCode = value.slice(3, 5)
          phonePrefix = value.slice(5, 8)
          phoneBody = value.slice(8)
          break

        default:
          if (value.length > 12) {
          // +CCCAA####### -> CCC AA-###-####
            country = value.slice(0, 3)
            areaCode = value.slice(3, 5)
            phonePrefix = value.slice(5, 8)
            phoneBody = value.slice(8)
            break
          }
          else {
            return tel
          }
      }

      return (country + ' (' + areaCode + ') ' + phonePrefix + '-' + phoneBody).trim()
    }
  })
