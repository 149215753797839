(function() {
  'use strict'

  require('team-admin')
  angular.module('team-admin')
    .directive('snDateField', function(_, $timeout, $document) {

      var $ = angular.element
      var document = $document.get(0)

      var now = new Date()
      var defaults = {
        month: 0, //now.getMonth() + 1,
        date: 0, //now.getDate(),
        year: now.getFullYear()
      }

      function allParts(d) {
        return { year: d.year, month: d.month, date: d.date } // in case any of the date properties aren't set on the model
      }

      function invalidDate(d) {
        return isNaN(Number(d))
      }

      function validDatePart(part, val, d) {
        var date = new Date((+d.month) + '/' + (+d.date) + '/' + (+d.year))

        if (_.all(allParts(d)) && invalidDate(date)) return false
        switch (part) {
          case 'month':
            return val >= 1 && val <= 12
          case 'date':
            if (val < 1 || val > 31) return false
            if (!invalidDate(date) && date.getMonth() + 1 != +d.month) return false // 2/30 => 3/2 through Date()
            return true
          case 'year':
            return true // more checks later?
        }
      }

      function tweakDate(d) {
        d = _.clone(d)

        // do nothing for blank strings. '0' is truthy.
        if (d.year) d.year = (new Date('1/1/' + d.year)).getFullYear() // expand yy to yyyy
        if (d.month) d.month = Math.max(Math.min(d.month, 12), 1) // don't check validity of the Date object yet
        if (d.date) { // force date into a valid value for the chosen month
          while (!validDatePart('date', d.date, d)) d.date = +d.date + (d.date > 0 ? -1 : 1)
        }

        return formatDateHash(d)
      }

      function lpad(val) {
        if (!val || isNaN(val)) return ''
        val = String(+val)
        return (new Array(Math.max(2 - val.length + 1, 0))).join('0') + val
      }

      function formatDateHash(dateHash) {
        return _.mapValues(dateHash, lpad)
      }

      return {
        restrict: 'E',
        replace: true,
        scope: true,
        require: '?ngModel',
        template: require('./sn-date-field.html'),
        link: function($scope, $element, $attrs, ngModel) {

          var inputs = {
            month: $element.find('[sn-date-part="month"]'),
            date: $element.find('[sn-date-part="date"]'),
            year: $element.find('[sn-date-part="year"]')
          }

          if ('disabled' in $attrs) $scope.disabled = true
          else if ('ngDisabled' in $attrs) $scope.$watch($attrs.ngDisabled, $scope.setAs('disabled'))

          ngModel.$validators.date = function(modelValue, viewValue) {
            var d = allParts(viewValue)
            var dateObj = new Date(d.month + '/' + d.date + '/' + d.year)
            return !_.any(d) || // allow blank - make `required` handle it
                 _.all(d) && !invalidDate(dateObj)
          }

          ngModel.$parsers.push(function(viewValue) {
            return !_.any(viewValue) ? null : viewValue.year + '-' + viewValue.month + '-' + viewValue.date
          })

          ngModel.$formatters.push(function(modelValue) {
            if (!modelValue) return { year: '', month: '', date: '' }

            var date = new Date(modelValue)
            return formatDateHash({
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              date: date.getDate()
            })
          })

          ngModel.$render = function() {
            var d = ngModel.$viewValue
            if (inputs.month.val() !== d.month) inputs.month.val(d.month)
            if (inputs.date.val() !== d.date) inputs.date.val(d.date)
            if (inputs.year.val() !== d.year) inputs.year.val(d.year)
          }

          function setViewValue(dateHash) {
            ngModel.$setViewValue(tweakDate(formatDateHash(dateHash)))
            ngModel.$render() // doesn't update for programmatic sets unless we explicitly call this here
          }

          // INPUT SELECTION EVENTS

          $element.on('keydown', 'input', function(e) {

            function selectPos(obj, pos) {
              if (obj && obj.setSelectionRange) obj.setSelectionRange(pos, pos)
            }

            function alterDate(delta) {
              var d = ngModel.$viewValue
              var newD = _.clone(d)
              var newVal = newD[datePart] = delta + (+val || defaults[datePart])
              if (validDatePart(datePart, newVal, newD)) {
                if (d[datePart] == newVal) $input.val(lpad(newVal)) // $watchCollection will not fire if this ends up as the same value on $scope.d
                else setViewValue(newD)
              }
              $input.select()
            }

            var $input = $(e.target)
            var input = $input[0]
            var $prevInput = $input.prevAll('input:first')
            var prevInput = $prevInput[0]
            var $nextInput = $input.nextAll('input:first')
            var nextInput = $nextInput[0]

            var datePart = $input.attr('sn-date-part')
            var val = $input.val()

            switch (e.which) {

              case 13: // enter
                e.preventDefault()
                return $input.blur().select()

              case 27: // escape
                e.preventDefault()
                return ngModel.$rollbackViewValue()

              case 37: // left
                if (input.selectionStart === 0) {
                  e.preventDefault()
                  $prevInput.focus()
                }
                return

              case 39: // right
                if (input.selectionEnd === val.length) {
                  e.preventDefault()
                  $nextInput.focus()
                }
                return

              case 38: // up
                e.preventDefault()
                return alterDate(1)

              case 40: // down
                e.preventDefault()
                return alterDate(-1)

              case 9: // tab
              case 46: // delete
                return

              case 8: // backspace
                if (!val && prevInput) {
                  $prevInput.focus()
                  selectPos(prevInput, $prevInput.val().length)
                }
                return

              case 109: // numpad -
              case 111: // numpad /
              case 189: // normal -
              case 191: // normal /
                e.preventDefault()
                $nextInput.focus()
                return

                // don't restrict numbers
              case 48: // normal 0
              case 49: // normal 1
              case 50: // normal 2
              case 51: // normal 3
              case 52: // normal 4
              case 53: // normal 5
              case 54: // normal 6
              case 55: // normal 7
              case 56: // normal 8
              case 57: // normal 9
                if (e.shiftKey || e.altKey) return e.preventDefault()
              /* falls through */
              case 96: // numpad 0
              case 97: // numpad 1
              case 98: // numpad 2
              case 99: // numpad 3
              case 100: // numpad 4
              case 101: // numpad 5
              case 102: // numpad 6
              case 103: // numpad 7
              case 104: // numpad 8
              case 105: // numpad 9
                $timeout(function() {
                  var val = $input.val()
                  if (val.length === +$input.attr('maxlength') ||
                    (datePart === 'month' && /^[2-9]$/.test(val)) ||
                    (datePart === 'date' && /^[4-9]$/.test(val))) $nextInput.focus()
                })
                return

              default:
                e.preventDefault()

            }
          })

          $element.on('focus', 'input', function(e) {
            $(e.target).select()
          })

          $element.on('blur', 'input', function(e) {
            var input = $(e.target)
            var d = _.clone(ngModel.$viewValue)
            d[input.attr('sn-date-part')] = input.val()
            setViewValue(d)

            $timeout(function() {
              if (!$.contains($element.get(0), document.activeElement)) $element.blur()
            })
          })

        }
      }
    })

})()
