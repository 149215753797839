require('team-admin')
angular.module('team-admin')
  .directive('printLanding', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./print-landing.html'),
      controller: function($q, $scope, ENV, $window, setWhile, printService, $routeParams) {
        var ctrl = this
        ctrl.$onChanges = $onChanges

        function $onChanges() {
          if ($routeParams.printRoster) {
            $scope.print('roster')
          }
          else if ($routeParams.printContactList) {
            $scope.print('contactList')
          }
          else if ($routeParams.printAffiliateRoster && $routeParams.affiliateTeamId) {
            $scope.print('ngbRoster', { id: $routeParams.affiliateTeamId })
          }
        }

        $scope.print = setWhile($scope, 'printing', function(content, data) {
          switch (content) {
            case 'roster':
              return printService.printContent({
                component: 'print-roster',
                margin: .25,
                attrs: {
                  team: $scope.team
                }
              })
            case 'contactList':
              return printService.printContent({
                component: 'print-contact-list',
                margin: .25,
                landscape: true,
                attrs: {
                  team: $scope.team
                }
              })
            case 'ngbRoster':
              return $q(function(resolve) {
                $window.addEventListener('message', function handler(event) {
                  if (_.get(event, 'data.type') !== 'print:complete') return
                  $window.removeEventListener('message', handler)
                  resolve()
                })
                $window.open(ENV.urls.hq + '/program-print-roster?teamID=' + data.id + '&set_current_org_id=' + $scope.team.org_details.id + '#externalRoute&externalNav', 'hidden')
              })
          }
        })
      }
    }
  })
