angular.module('pl-shared')
  .factory('Affiliation', function(DS, paginate, _) {
    var Affiliation = DS.defineResource({
      name: 'organization_affiliations',
      endpoint: '/affiliations',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api'
    })

    Affiliation.credentialDefinitionIDs = function(affiliations) {
      return Affiliation.credentialDefinitionIDsList(affiliations).join(',')
    }

    Affiliation.credentialDefinitionIDsList = function(affiliations) {
      return _.flatten(_.map(affiliations, function(affiliation) {
        return _.map(affiliation.credentials, function(credential) {
          return _.get(credential, 'credential_definition.id')
        })
      }))
    }

    return paginate(Affiliation)
  })
