require('team-admin')
angular.module('team-admin')
  .directive('input', function() {

    // stolen from AngularJS v1.3.15 and modified to require TLD (change *$ to +$)
    var EMAIL_RX = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/i

    return {
      restrict: 'E',
      require: '?ngModel',
      link: function(scope, el, attrs, ctrl) {
        if (!ctrl || attrs.type !== 'email') return
        ctrl.$validators.email = function(value) {
          return ctrl.$isEmpty(value) || EMAIL_RX.test(value)
        }
      }
    }
  })
