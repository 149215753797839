require('team-admin')
angular.module('team-admin')
  .directive('removePlayers', function() {

    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        team: '=',
        players: '=',
        dialogOptions: '=',
        context: '=',
        gaCategory: '=',
        gaLabelType: '='
      },
      template: require('./remove-players.html'),
      controller: function(pageViewHandler, _, snAsync, $scope, Player) {
        pageViewHandler.fireEvent('RosterRemoveModal.Open')

        var TYPE = $scope.TYPE = $scope.context.toUpperCase()
        _.extend($scope, {
          playersToDelete: _.clone($scope.players),
          title: TYPE + '.REMOVE.title',
          warning: TYPE + '.REMOVE.warning',
          warning_help: TYPE + '.REMOVE.warning_help',
          button_label: TYPE + '.REMOVE.button_label'
        })

        var deleted = []
        var deleting

        $scope.otherTerm = function() {
          return $scope.context
        }

        $scope.removePlayers = function() {
          var notDeleted = []

          function removePlayer(player) {
            return Player.destroy(player, Player.v3Options).then(
              function() { deleted.push(player) },
              function() { notDeleted.push(player) }
            )
          }

          function finish() {
            deleting = false
            if (notDeleted.length) {
              _.extend($scope, {
                playersToDelete: notDeleted,
                error: true,
                title: TYPE + '.REMOVE.error_retry_title',
                warning: TYPE + '.REMOVE.error_retry',
                button_label: TYPE + '.REMOVE.error_retry_button_label'
              })
            }
            else $scope.cancel()
          }

          deleting = true

          return snAsync.eachLimit($scope.playersToDelete, 5, removePlayer).then(finish)
        }

        $scope.dialogOptions.preCloseCallback = function() {
          if (deleting) return false
          if (deleted.length) {
            $scope.confirm(deleted)
            return false
          }
        }

      }
    }
  })
