require('team-admin')
angular.module('team-admin')
  .directive('addGame', function() {
    return {
      restrict: 'A',
      scope: {
        team: '=',
        confirm: '=',
        cancel: '=',
        dialogOptions: '='
      },
      template: require('./add-game.html'),
      controller: function(pageViewHandler, $scope, $timeout, _, Alerts, Game, Venue) {
        pageViewHandler.fireEvent('NewGameModal.Open')
        Game.setupTranslation($scope)

        $scope.game = Game.createInstance({
          event_type: 'game',
          local_timezone: $scope.team.timezone_identifier,
          venue_id: null,
          subvenue_id: null,
          game_details: {
            team_1: {
              id: $scope.team.id,
              is_home_team: true,
              name: $scope.team.name
            },
            team_2: {
              id: ''
            },
          },
          with_notification: false, // !!$scope.featureToggles.availability,
          principals: [
            {
              id: $scope.team.id, // CS will fill in the rest, this is just for extended_attributes
              extended_attributes: {}
            }
          ]
        })

        $scope.scope = $scope
        $scope.buttonLabel = 'SCHEDULE.ADD_GAME.button_label'

        $scope.createGame = function() {
          pageViewHandler.fireEvent('NewGameModal.AddGame', 8)
          $scope.failed = false

          return Venue.check(angular.copy($scope.game), $scope.team)
            .then(gameData => Game.create(gameData, { endpoint: `/v3/calendar/team/${ $scope.team.id }/event` }))
            .then($scope.setAs('createdGame'))
            .then(createGameSuccess, createGameError)
        }

        function createGameSuccess(game) {
          $timeout($scope.cancel) // confirms in preCloseCallback
        }

        function createGameError() {
          $scope.failed = true
        }

        $scope.dialogOptions.preCloseCallback = function() {
          if ($scope.gameForm.$submitting) return false
          if ($scope.createdGame) {
            Alerts.success('SCHEDULE.ADD_GAME.success')
            $scope.confirm($scope.createdGame)
            return false
          }
        }
      }
    }
  })
