angular.module('pl-shared')

  .config(['$provide', function($provide) {
    $provide
      .decorator('$exceptionHandler', ['$delegate', '$window', function($delegate, $window) {
        return function(exception, cause) {
          if ($window.newrelic) {
            $window.newrelic.noticeError(exception)
          }
          $delegate(exception, cause)
        }
      }])
  }])
