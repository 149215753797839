angular.module('pl-shared')
  .service('CurrentUserService', function(_, OAuthMe, OAuthState, payload, $q, $location, currentUser) {
    return {
      find: find,
    }

    function parseUser(user) {
      if (!user) return null

      var userObj = {
        id: user.id,
        username: user.username || user.user_name,
        firstname: user.firstname || user.first_name,
        lastname: user.lastname || user.last_name,
        email: user.email || _.find(user.email_addresses, { is_primary: true }),
        roles: user.roles || {},
        uuid: user.uuid,
        profile_images: user.profile_images
      }

      if (user.role_assignments) {
        _.each(_.groupBy(user.role_assignments, 'role'), function(roles, key) {
          userObj.roles[key] = {}
          _.each(_.groupBy(roles, 'resource_type'), function(roles, type) {
            if (type === 'Platform') return userObj.roles[key][type] = true
            userObj.roles[key][type] = {}
            if (type !== 'Organization') userObj.roles[key][type].byOrg = {}
            _.each(roles, function(role) {
              userObj.roles[key][type][role.resource_id] = true
              if (type !== 'Organization' && role.org_id) {
                userObj.roles[key][type].byOrg[role.org_id.toString()] = userObj.roles[key][type].byOrg[role.org_id.toString()] || {}
                userObj.roles[key][type].byOrg[role.org_id.toString()][role.resource_id] = true
              }
            })
          })
        })
      }

      userObj.thirdnorth = user.thirdnorth || !!userObj.roles.third_north

      return userObj
    }

    function initUser(data) {
      if (!data || !data.user) return null

      var user = parseUser(data.user)
      OAuthMe.currentUser = payload.currentUser = user
      _.forEach(Object.keys(user), function(k) {
        currentUser[k] = user[k]
      })
      if (user.firstname && user.lastname) currentUser.name = user.firstname + ' ' + user.lastname
      else currentUser.name = user.username || 'Anonymous'
      currentUser.platformAdmin = currentUser.hasRole('platform_admin')
      currentUser.superRole = currentUser.platformAdmin || currentUser.hasRole('third_north')
      return user
    }

    function find() {
      if (OAuthMe.currentUser) return $q.resolve(OAuthMe.currentUser)
      return OAuthMe.find().then(initUser).catch(function() {
        var appName = $location.host().split('.')[0]
        OAuthState.initiateLogin(appName)
      })
    }
  })
