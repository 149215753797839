require('team-admin')
angular.module('team-admin')
  .directive('rsvpsAction', function() {
    return {
      restrict: 'A',
      require: '?^gaCategory',
      scope: {
        event: '=',
        eventInvitation: '=',
        eventForm: '=',
        member: '=',
        memberType: '=',
        popoverOpen: '=?',
        sourceView: '<',
        team: '='
      },
      template: require('./rsvps-action.html'),
      link: function($scope, $element, $attrs, ctrl) {
        if (ctrl) $scope.gaCategoryCtrl = ctrl
      },
      controller: function($scope, currentUser, pageViewHandler) {
        currentUser.getPersonas()

        $scope.setResponse = function(response) {
          pageViewHandler.fireEvent('RSVP.'+response, 8)
          if (!$scope.eventInvitation) $scope.eventInvitation = {}
          var firstResponse = !$scope.eventInvitation || (response !== $scope.eventInvitation.response)
          if ($scope.eventForm && firstResponse) $scope.eventForm.$setDirty()

          $scope.eventInvitation.response = response
          $scope.$emit('event_rsvp:update_response', $scope.member)
        }

        $scope.canEdit = () => $scope.team?.canManage()
          || ($scope.team?.isGameAdmin() && $scope.event.event_type === 'game')
          || ($scope.team?.isEventAdmin() && $scope.event.event_type === 'event')
          || currentUser.checkIfPlayerIsUser($scope.eventInvitation?.persona_id)

        $scope.$watch('!eventInvitation', $scope.setAs('isPending'))
        $scope.$watchGroup(['eventInvitation', 'event'], function() {
          $scope.id = $scope.member.id + '_' + $scope.event.unique_id + '_' + $scope.sourceView
        })
      }
    }
  })
