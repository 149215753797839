require('team-admin')
angular.module('team-admin')
  .directive('editContactPreferences', function() {

    var EMAIL_ADDRESSES_COUNT = 6

    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        contacts: '=',
        player: '='
      },
      template: require('./edit-contact-preferences.html'),
      controller: function(_, $scope, $element, $timeout, Contact, $q) {


        $scope.failed = false
        var emailAddresses = $scope.emailAddresses = angular.copy($scope.contacts)
        var deletedEmailAddresses = []
        var playerParams = { params: { playerId: $scope.player.originator_id } }

        while (emailAddresses.length < EMAIL_ADDRESSES_COUNT) {
          emailAddresses.push({})
        }

        for (var i = 0; i < emailAddresses.length; i++) {
          if (emailAddresses[i].id) continue
          $scope.firstBlankIndex = i
          break
        }

        $scope.addAnother = function() {
          emailAddresses.push({})
          $timeout(function() {
            var content = $element.find('.pl-modal__content')[0]
            var focused = $element.find('[ng-model="contactPreference.label"]').last()
            content.scrollTop = content.scrollHeight - content.clientHeight
            focused.focus()
          })
        }

        $scope.removeEmailAddress = function(emailAddress) {
          var index = emailAddresses.indexOf(emailAddress)
          if (index >= 0) {
            var deletedEmailAddress = emailAddresses.splice(index, 1)[0]
            if (deletedEmailAddress.id) {
              deletedEmailAddress._destroy = true
              deletedEmailAddresses.push(deletedEmailAddress)
            }
          }
          if (!emailAddresses.length) $scope.addAnother()
        }

        $scope.saveContacts = function() {
          $scope.failed = false

          function saveContactsSuccess(requestResults) {
            $scope.confirm(_.filter(requestResults, 'id'))
          }

          function saveContactsError(contacts) {
            $scope.failed = true
          }

          var contactRequests = []
          _.each(deletedEmailAddresses, function(emailAddress) {
            contactRequests.push(Contact.destroy(emailAddress.id, playerParams))
          })

          _.each(emailAddresses, function(emailAddress) {
            if (emailAddress.id) {
              return contactRequests.push($q.when(emailAddress))
            }

            if (emailAddress.address) {
              contactRequests.push(Contact.create(emailAddress, playerParams).then(c => c.DSRefresh(playerParams)))
            }
          })

          $q.all(contactRequests)
            .then(saveContactsSuccess, saveContactsError)
        }
      }
    }
  })
