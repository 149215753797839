angular.module('pl-shared')

  .value('KEY', {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    ENTER: 13,
    TAB: 9,
    ESC: 27,
    SHIFT: 16,
    CONTROL: 17,
    OPTION: 18,
    COMMAND_LEFT: 91,
    COMMAND_RIGHT: window.navigator.userAgent.indexOf('Mac OS') >= 0 ? 93 : 92
  })

  .service('keyboardHelper', function($document, $window, KEY, _) {

    var modifierKeys = {}
    var win = angular.element($window)

    var modifierKeyMap = _.object([
      [KEY.SHIFT, 'SHIFT'],
      [KEY.CONTROL, 'CONTROL'],
      [KEY.OPTION, 'OPTION'],
      [KEY.COMMAND_LEFT, 'COMMAND'],
      [KEY.COMMAND_RIGHT, 'COMMAND']
    ])

    function setKey(key, down) {
      if (key in modifierKeyMap) modifierKeys[modifierKeyMap[key]] = down
    }

    function clearModifierKeys() {
      for (var k in modifierKeys) {
        delete modifierKeys[k]
      }
    }

    $document.on('keydown', function(e) { setKey(e.which, true) })
    $document.on('keyup', function(e) { setKey(e.which, false) })
    win.on('blur', clearModifierKeys)

    return modifierKeys

  })
