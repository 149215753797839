(function() {
  'use strict'

  angular.module('pl-shared')
    .service('currentUser', function($q, $rootScope, payload, Persona) {
      var user = $rootScope.constructor.prototype.currentUser = payload.currentUser || {}

      if (user.firstname && user.lastname) user.name = user.firstname + ' ' + user.lastname
      else user.name = user.username || 'Anonymous'

      user.hasRole = function(roleKey) {
        var canonicalKey = _.snakeCase(roleKey)
        return !!_.get(user.roles, [canonicalKey, 'Platform']) ||
          !!_.get(user.roles, [canonicalKey, 'Organization', payload.currentOrgId]) ||
          _.has(user.roles, [canonicalKey, 'League', 'byOrg', payload.currentOrgId]) ||
          _.has(user.roles, [canonicalKey, 'TeamInstance', 'byOrg', payload.currentOrgId])
      }

      user.hasPreference = function(permissionKey) {
        var canonicalKey = _.snakeCase(permissionKey)
        if (!_.get(user, 'preferences')) return false
        return _.some(user.preferences, function(pref) {
          return (pref.key === canonicalKey && pref.value)
        })
      }

      user.hasRoleForResource = function(role, resource_type, resource_id) {
        return !!_.get(user.roles, [role, resource_type, resource_id])
      }

      user.platformAdmin = user.hasRole('platform_admin')
      user.superRole = user.platformAdmin || user.hasRole('third_north')

      user.getPersonas = function() {
        if (user.getPersonas.promise) return user.getPersonas.promise
        user.persona = {} // placeholder until promise resolves, to avoid JS errors
        return user.getPersonas.promise = Persona.findAll({ include_guarded_personas: 1 }, { load: 'all' })
          .then(function(currentUserPersonas) {
            return user.personas = currentUserPersonas
          })
      }

      user.checkIfPlayerIsUser = function(playerPersonaId) {
        return _.some(user.personas, { 'id': playerPersonaId })
      }

      user.getSelfPersona = function() {
        if (user.selfPersona) return $q.resolve(user.selfPersona)
        if (user.getSelfPersona.promise) return user.getSelfPersona.promise
        return user.getSelfPersona.promise = Persona
          .findAll({ persona_type: 'self' })
          .then(function(personas) { return user.selfPersona = personas[0] })
      }

      user.canSendClubAssignment = function() {
        return (
          user.hasRole('governing_season_admin') ||
          user.hasRole('platform_admin') ||
          user.hasRole('third_north')
        )
      }

      return user
    })
    .run(function(currentUser) {})

})(window)
