// ROUTE TITLE DIRECTIVE

// Displays an interpolated tile from a route's `title`
// property (defined in app-routes). Defaults to the current route. A route can
// also be passed in as an option, or bound as a data attribute on the element
// (usefull for other directives).

// EXAMPLE

// <a route-title="{ templateKey: 'MODAL.back_to', defaultKey: 'MODAL.back', append: true }"></a>


// OPTIONS

// `templateKey`      EX: 'ROUTE_TITLES.template' => 'SportsEngine | __title__'
//                    i18next translation key to use for token replacement.
//                    The title will be translated first, then injected into the
//                    template in a second pass.
//

// `defaultKey`       EX: 'MODAL.back' => 'Back'
//                    i18next translation key to use if some of the interpolated
//                    data fails to load, or cannot be found.

// `append`           Boolean (defaults to false) -- If set to true, the interpolated
//                    title will be injected as a text node at the end of the element
//                    instead of replacing the entire content of the element (useful for icons)


angular.module('pl-shared')

  .service('routeTitle', function(_, $route, i18ng) {

    var UNREPLACED_TOKENS = /__[A-Z_]+__/gi
    var cachedParams = {} // cached in memory for now
    var bindings = []

    return {
      setParams: setParams,
      register: register,
      render: render,
      replace: replace,
    }

    // This should always be run inside a digest
    function replace(title) {
      var binding = bindings[0]
      if (binding) binding.title = title
    }

    function render(bindings) {
      _.each(bindings, function(binding) {
        var opts = binding.options
        var el = binding.element
        var title = binding.title

        if (!title) {
          title = i18ng.t(binding.routeSrc.current.title || '', cachedParams)

          // Allow fallbacks to take over (if any) rather than showing the user a broken translation
          if (title.match(UNREPLACED_TOKENS)) title = ''

          if (title && opts.templateKey) title = i18ng.t(opts.templateKey, { title: title })
          else if (!title && opts.defaultKey) title = i18ng.t(opts.defaultKey)
        }

        if (!opts.append) return el.text(title)
        if (!binding.textNode) return el[0].appendChild(binding.textNode = document.createTextNode(title))
        else binding.textNode.textContent = title
      })
    }

    // Accepts (key, value) or (paramsHash) interface
    function setParams(params) {
      // TODO: figure out if these should always be converted to strings...
      _.extend(cachedParams, params)
      render(bindings)
    }

    function register($scope, $element) {
      var routeSrc = $route
      var options = $scope.options || {}
      var route = options.route || $element.data('route')

      // Mimic $route.current path for renderer when we have a custom route
      if (route) routeSrc = { current: route }

      var binding = {
        scope: $scope,
        options: options,
        element: $element,
        routeSrc: routeSrc,
        title: null // overridden when replacing (from an iframe)
      }

      bindings.push(binding)
      $scope.$watch(routeTitle, renderBinding)
      $scope.$on('$destroy', function() { _.pull(bindings, binding) })

      function routeTitle() {
        return binding.title || routeSrc.current.title
      }

      function renderBinding() {
        render([binding])
      }
    }

  })

  .directive('routeTitle', function(routeTitle, $route) {
    return {
      restrict: 'A',
      scope: {
        options: '=routeTitle' // TODO: convert to one way binding after angular 1.5.8 merge
      },
      link: routeTitle.register
    }
  })
