(function(win) {
  'use strict'

  angular.module('pl-shared')
    .factory('ApiTimezone', function(_, DS, moment) {
      var Timezone = DS.defineResource({
        name: 'time_zones',
        idAttribute: 'identifier',
        basePath: DS.adapters.ngin_v2.defaults.basePath,
        httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
        defaultAdapter: 'ngin_v2',
      })

      var i18ng = win.i18n

      Timezone.set = function(tz) {
        this.current = tz
      }

      Timezone.displayMessage = function() {
        return i18ng.t('TIMEZONE.display_message', { tz: this.current.identifier })
      }

      Timezone.browserTimezone = function() {
        if (win.Intl) {
          var format = win.Intl.DateTimeFormat() || {}
          if (format.resolved) return format.resolved.timeZone
        }
        if (win.jstz) {
          var tz = win.jstz.determine()
          if (tz) return tz.name()
        }
        return 'UTC'
      }

      Timezone.browserDefault = Timezone.browserTimezone()
      Timezone.getOptions = getOptions
      Timezone.buildOptions = buildOptions

      function getOptions() {
        var promise = Timezone.optionsPromise = Timezone.optionsPromise || Timezone.findAll()
        return promise.then(buildOptions)
      }

      function buildOptions(data) {
        var now = moment()
        return _.map(data, function(zone) {
          zone.value = zone.identifier
          zone.group = isUsCanada(zone) ? 'United States and Canada' : 'International'
          zone.offset = now.tz(zone.identifier).format('Z') // update offset if current date is DST for zone
          zone.label = '(UTC' + zone.offset + ') ' + zone.name
          return zone
        })
      }

      function isUsCanada(z) {
        return _.contains(['United States', 'Canada'], z.country_name)
      }

      return Timezone
    })
})(window)
