(function(win) {
  'use strict'

  // README: https://github.com/jessehouchins/angular-render-context

  var $ = angular.element
  var RC = angular.module('renderContext', [])
  var renderContext
  var getRouteInfo
  var MAX_HISTORY = 20

  // we can't inject i18ng here so we pull this dynamically when first needed
  function t() {
    return win.i18n.t.apply(win.i18n, arguments)
  }

  RC.run(function(_, $rootScope, $route, $routeParams, $location, $q) {

    function RenderContext() {
      // only one instance per app
      renderContext = renderContext || this
      this._pastStates = []

      if (!$rootScope.constructor.prototype.renderContext) {
        $rootScope.constructor.prototype.renderContext = renderContext
        $rootScope.$on('$locationChangeStart', renderContext._confirmChange)
        $rootScope.$on('$routeChangeSuccess', renderContext._change)
        $rootScope.$on('$routeChangeError', renderContext._change)
        $rootScope.$on('$routeUpdate', renderContext._replace)
      }

      return renderContext
    }

    RenderContext.prototype._confirmChange = function(e) {
      if (!renderContext._dirty) return
      if ((renderContext._dirty = !confirm(continueWithoutSavingText()))) {
        if (e) e.preventDefault()
        return true
      }
    }

    RenderContext.prototype._change = function(e, current) {
      var context = current && current.context
      if (!context) return // redirects do not have a context... wait for redirect to complete before updating.
      renderContext.set(context)
      renderContext._setState()
    }

    RenderContext.prototype._replace = function(e, current) {
      renderContext._pastStates.pop()
      renderContext._change(e, current)
    }

    // removes custom context attributes
    RenderContext.prototype._reset = function(contextName) {
      if (!renderContext.contexts) return
      while ((contextName = renderContext.contexts.shift())) {
        delete renderContext[contextName]
      }
    }

    RenderContext.prototype.get = function(path) {
      var context = renderContext
      var contextNames = path.split('.')
      var contextName

      while ((contextName = contextNames.shift())) {
        context = context[contextName]
        if (!context) return
      }

      return context
    }

    RenderContext.prototype.set = function(currentContext) {
      var root = renderContext
      var contextNames = currentContext ? currentContext.split('.') : []
      var contextName
      var prevContext = {} // temp object for simple logic below
      var prevContextName
      var descendents = []
      var contexts = root.contexts = root.contexts || []

      renderContext._reset()

      root.context = currentContext
      root.layout = contextNames[0]

      while ((contextName = contextNames.shift())) {
        contexts.push(contextName)
        var nextContextName = contextNames[0]

        // Build the context object and link it in the chain
        var context = root[contextName] = prevContext[contextName] = {
          prev: prevContextName,
          next: nextContextName,
          before: _.object(contextNames, contextNames),
          after: _.object(descendents, descendents)
        }

        // setup for the next loop
        if (nextContextName) {
          prevContext = context
          prevContextName = contextName
          descendents.push(contextName)
        }
      }
    }

    // finds a state in the history (excluding the current state) matching the contextPart
    RenderContext.prototype._prevState = function(contextPart) {
      return findState(contextPart, 1)
    }

    // finds a state in the history (including the current state) matching the contextPart
    RenderContext.prototype._findState = function(contextPart) {
      return findState(contextPart, 0)
    }

    RenderContext.prototype._setState = function() {
      var states = renderContext._pastStates
      var state = renderContext.state = {
        context: renderContext.context,
        url: $location.url(),
        params: angular.copy($routeParams)
      }
      if (RenderContext.replacing) {
        states.shift()
        RenderContext.replacing = false
      }
      states.unshift(state)
      if (states.length > MAX_HISTORY) states.length = MAX_HISTORY
    }

    RenderContext.prototype._parentContext = function(context, contextPart) {
      if (arguments.length === 1) {
        contextPart = context
        context = renderContext.context
      }
      if (!context) return null // usually occurs as a result of a route redirect
      var parentContext = context.substring(0, context.lastIndexOf('.'))
      var index = parentContext.lastIndexOf(contextPart)
      return index >= 0 ? parentContext.substring(0, index) + contextPart : null
    }

    RenderContext.prototype._leafContext = function(context, contextPart) {
      if (!context) return null // usually occurs as a result of a route redirect
      var index = context.lastIndexOf(contextPart)
      var found = index >= 0
      var leaf = index + contextPart.length === context.length
      return found && leaf ? context.substring(0, index) + contextPart : null
    }

    RenderContext.prototype.goto = function(context, contextParams, replace) {
      var route = getRouteInfo(context, contextParams)
      renderContext.setUrl(route.url, replace)
    }

    RenderContext.prototype.backto = function(contextPart) {
      var context = renderContext._parentContext(contextPart)
      if (!context) return false
      renderContext.goto(context, {})
    }

    RenderContext.prototype.back = function(context, contextParams) {
      var pastStates = renderContext._pastStates
      var pastIndex = _.findLastIndex(pastStates, { context: context, params: contextParams })
      if (pastIndex === -1) return false
      pastStates.splice(0, pastIndex + 1) // remove this and any more recent states
      renderContext.goto(context, contextParams)
    }

    RenderContext.prototype.setUrl = function(url, replace, e) {
      // stop normal link click handler if it exists
      if (e && e.preventDefault) e.preventDefault()

      if (renderContext._confirmChange()) return
      function applyUrl() {
        if (replace) {
          $location.replace()
          RenderContext.replacing = true
        }
        $location.url(url)
      }
      if ($rootScope.$$phase) applyUrl()
      else $rootScope.$apply(applyUrl)
    }

    // PRIVATE METHODS

    function findState(contextPart, startIndex) {
      var pastStates = renderContext._pastStates
      for (var i = startIndex; i < pastStates.length; i++) {
        if (!contextPart || renderContext._leafContext(pastStates[i].context, contextPart)) {
          return pastStates[i]
        }
      }
    }

    function routeUrlForContext(context) {
      var route
      var contextMatcher = new RegExp(context)
      for (var url in $route.routes) {
        route = $route.routes[url]
        if (route.context && route.context.match(contextMatcher)) return url
      }
    }

    getRouteInfo = function(context, contextParams, $el) {
      var url = routeUrlForContext(context)
      if (!url) throw new Error('No route found for the `' + context + '` context.')

      var route = $route.routes[url]
      var routeParams = []

      for (var i in route.keys) {
        var param = route.keys[i]
        var src = contextParams && (param.name in contextParams) ? contextParams : $routeParams
        var paramValue = src[param.name]
        // Optional
        if (param.optional) {
          routeParams.push(param.name)
          if (paramValue) url = url.replace(':' + param.name + '?', paramValue)
          else url = url.replace('/:' + param.name + '?', '')
        }
        // Required and not found
        else if (paramValue === undefined) {
          if ($el) console.error('Render Context Error: ', $el)
          throw new Error('Route for `' + context + '` requires the `' + param.name + '` param.')
        }
        // Required and found
        else {
          routeParams.push(param.name)
          url = url.replace(new RegExp(':' + param.name + '\\*?'), paramValue)
        }
      }

      var queryParams = _.omit(contextParams, routeParams)
      if (!_.isEmpty(queryParams)) url += '?' + $.param(queryParams)

      return {
        url: url,
        title: route.title || ''
      }
    }

    // Prevent naigation when changes are present

    RenderContext.prototype.requireConfirmation = function($scope) {
      if (typeof $scope === 'boolean') return requireConfirmation($scope)
      $scope.$on('$destroy', requireConfirmation.bind(null, false))
      return requireConfirmation
    }

    RenderContext.prototype.getRouteInfo = getRouteInfo

    function requireConfirmation(bool) {
      renderContext._dirty = bool !== false
    }

    function continueWithoutSavingText() {
      return continueWithoutSavingText._data = continueWithoutSavingText._data || t('UNSAVED_CHANGES.continue_without_saving')
    }

    renderContext = new RenderContext()

  })

  RC.factory('renderContext', function() {
    return renderContext
  })

  RC.directive('gotoContext', function() {
    return {
      priority: 100, // before ngHref
      scope: false, // cannot create isolate scope since it competes with other diretives
      link: function($scope, $element, $attrs) {
        contextLink({
          scope: $scope,
          attrs: $attrs,
          element: $element,
          method: 'goto',
          context: function() { return $attrs.gotoContext },
          params: function() { return $scope.$eval($attrs.contextParams) }
        })
        $scope.$watch()
      }
    }
  })

  RC.directive('backtoContext', function() {
    return {
      priority: 100, // before ngHref
      scope: false, // cannot create isolate scope since it competes with other diretives
      link: function($scope, $element, $attrs) {
        contextLink({
          scope: $scope,
          attrs: $attrs,
          element: $element,
          method: 'backto',
          context: function() { return $attrs.backtoContext }
        })
      }
    }
  })

  RC.directive('backOrGotoContext', function() {
    return {
      priority: 100, // before ngHref
      scope: false, // cannot create isolate scope since it competes with other diretives
      controller: function($scope, $element, $attrs, $parse) {
        var prevState = renderContext._prevState()
        var method = prevState ? 'back' : 'goto'
        var options = {
          scope: $scope,
          attrs: $attrs,
          element: $element
        }

        // Check to see if the context attr is an angular {{foo}} expression.
        // If it is, we need to parse it and evaluate in using scope data.
        var expr = _.trim($attrs.backOrGotoContext)
        var interpolate = expr.match(/^\{\{.+\}\}$/)
        var getter = interpolate ? $parse(expr.substring(2, expr.length - 2)) : _.constant(expr)

        if (prevState) {
          options.method = 'back'
          options.context = prevState.context
          options.params = prevState.params
        }
        else {
          options.method = 'goto'
          options.context = getter.bind(null, $scope)
        }

        contextLink(options)
      }
    }
  })

  function contextLink(options) {
    // NOTE: options.scope IS NOT AN ISOLATE SCOPE, so don't change it in any way.

    // Required options
    var scope = options.scope
    var attrs = options.attrs
    var element = options.element
    var method = options.method

    // Optional for goto
    var replace = 'replaceContext' in attrs

    // expose route info for the route-title directive
    var routeInfo = {}
    element.data('route', routeInfo)

    // Update the link/click action
    element.on('click', updateLocation)
    if (element.is('a')) {
      updateHref()
      var skipFirst = true
      scope.$on('$destroy', scope.$watch(function() {
        return {
          context: context(),
          params: params()
        }
      }, updateHref, true))
    }

    function params() {
      return _.result(options, 'params') || {}
    }

    function context() {
      return _.result(options, 'context')
    }

    function updateHref() {
      var ctx = context()
      if (skipFirst || !ctx) return skipFirst = false
      _.extend(routeInfo, getRouteInfo(ctx, params(), element))
      attrs.$set('href', routeInfo.url)
    }

    function updateLocation(e) {
      var ctx = context()
      if (element.is('a') && element.attr('target') || !ctx) return
      e.preventDefault()
      scope.renderContext[method](ctx, params(), replace)
    }
  }

})(window)
