angular.module('pl-shared')
  .factory('getEnum', function(i18ng, _) {

    // Returns an array in hash order with nested hash like properties
    // for convenient lookups. Required to maintain correct order in ngOptions.

    // Example:

    // {key_1: 'Key One', key_2: Key Two}

    // becomes...

    // [
    //   0: {label: 'Key One', value: 'key_1'},
    //   1: {label: 'Key Two', value: 'key_2'},
    //   key_1: 'Key One,
    //   key_2: 'Key Two'
    // ]

    return function(key, opts) {
      opts = _.extend({ returnObjectTrees: true }, opts)
      var obj = i18ng.t(key, opts)
      var enumArr = []

      if (typeof obj === 'object') {
        _.each(obj, function(v, k) {
          enumArr.push({ label: v, value: k })
          enumArr[k] = v
        })
      }

      return enumArr
    }
  })
