angular.module('pl-shared')
  .component('chatMessage', {
    bindings: {
      users: '=',
      message: '=',
      likes: '=',
      isLikedByUser: '=',
      messageLoaded: '<',
      allMessages: '=',
      displayedMessages: '=',
      currentUser: '=',
      index: '=',
      team: '<'
    },
    template: require('/static/shared/components/chat/chat-message.html'),
    controller: function($filter, linkify, dialog) {
      var $ctrl = this
      $ctrl.hover = false

      function getMessage(index) {
        return $ctrl.displayedMessages[index]
      }

      $ctrl.sentFromUser = function() {
        return $ctrl.message.userId === $ctrl.currentUser.uuid
      }

      $ctrl.isPhotoOrGif = function() {
        return $ctrl.message.contentType === 'photo' || $ctrl.message.contentType === 'gif'
      }

      $ctrl.moreToLoad = function() {
        return $ctrl.index === 0 && ($ctrl.allMessages.length > $ctrl.displayedMessages.length)
      }

      $ctrl.openFullscreen = function() {
        dialog.confirm({
          directive: 'fullscreen-image',
          attrs: {
            allMessages: $ctrl.allMessages,
            message: $ctrl.message,
            currentUser: $ctrl.currentUser,
            users: $ctrl.users
          }
        })
      }

      $ctrl.checkDates = function(index) {
        var currentMessage = getMessage(index)
        var previousMessage = getMessage(index - 1)
        if (!previousMessage) {
          return false
        }
        var currentTimestamp = $filter('date')(currentMessage.timestamp, 'mediumDate')
        var previousTimestamp = $filter('date')(previousMessage.timestamp, 'mediumDate')

        return (currentTimestamp == previousTimestamp)
      }

      $ctrl.checkPrevUserId = function(index) {
        var currentMessage = getMessage(index)
        var previousMessage = getMessage(index - 1)
        if (!previousMessage) {
          return false
        }
        return (currentMessage.userId == previousMessage.userId)
      }

      $ctrl.checkNextUserId = function(index) {
        var currentMessage = getMessage(index)
        var nextMessage = getMessage(index + 1)
        if (!nextMessage || !$ctrl.messageLoaded(nextMessage)) {
          return false
        }
        return (currentMessage.userId == nextMessage.userId)
      }

      $ctrl.hasBeenTime = function(index) {
        var currentMessage = getMessage(index)
        var previousMessage = getMessage(index - 1)
        if (!previousMessage) {
          return true
        }
        return ((currentMessage.timestamp - previousMessage.timestamp) / 1000 / 60 > 15)
      }

      $ctrl.showHoverMenu = function(event) {
        angular.element(event.currentTarget).addClass('pl-chat-hover-menu--show')
        $ctrl.hover = true
      }

      $ctrl.hidehoverMenu = function() {
        $ctrl.hover = false
      }

      $ctrl.popoverIsOpen = function() {
        return angular.element('#message-' + $ctrl.message.$id).is('.sn-popover-open')
      }

    }
  })
