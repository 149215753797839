angular.module('pl-shared')

  .directive('shortTextQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/short-text-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id

        ctrl.getPatternErrorMessage = function() {
          if (ctrl.properties.has_validate_password) return 'password'
          else if (ctrl.properties.has_validate_phone) return 'phone'
          else if (ctrl.properties.has_validate_email) return 'email'
          else if (ctrl.properties.has_validate_postal_code) return 'postal_code'
          else if (ctrl.properties.has_validate_acronym) return 'acronym'
        }

        if (ctrl.properties.has_validate_password) ctrl.pattern = new RegExp('^' + _.escapeRegExp(ctrl.properties.password_value) + '$')
        else if (ctrl.properties.has_validate_phone) ctrl.pattern = /^([-+/() 0-9]{5,})$/
        else if (ctrl.properties.has_validate_email) ctrl.pattern = /^([-\w.%+]+)@([-\w]+\.)+([\w]{2,})$/
        else if (ctrl.properties.has_validate_acronym) ctrl.pattern = /^[^.]*$/
        else if (ctrl.properties.has_validate_postal_code) ctrl.pattern = /^[A-Za-z\d\s]*$/

        // additional element attributes that may be needed in future
        // ctrl.question.is_enabled - question does not show in view mode but shows as disabled in edit mode. filtered out by API?
        // ctrl.question.is_hidden - question shows for admins only. filtered out by API
        // ctrl.question.auto_fill - will already be auto-filled by the time it gets here
        // ctrl.question.mask - hide answer on email receipt (still shows while editing). filter out by API for orgs?
        // ctrl.question.properties.hidden_default - question is hidden by default when creating

      }
    }

  })

