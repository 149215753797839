angular.module('pl-shared')
  .factory('colorHelper', function() {

    var RED_LIGHTNESS = 0.299
    var GREEN_LIGHTNESS = 0.587
    var BLUE_LIGHTNESS = 0.114

    return {
      hexToRGB: hexToRGB,
      hexToLuma: hexToLuma,
      hexToChroma: hexToChroma
    }

    function hexToLuma(hex) {
      var rgb = hexToRGB(hex)
      return (rgb.r * RED_LIGHTNESS + rgb.g * GREEN_LIGHTNESS + rgb.b * BLUE_LIGHTNESS) / 255
    }

    function hexToChroma(hex) {
      var rgb = hexToRGB(hex)
      var max = Math.max(rgb.r, rgb.g, rgb.b) / 255
      var min = Math.min(rgb.r, rgb.g, rgb.b) / 255
      return max - min
    }

    function hexToRGB(hex) {
      hex = String(hex || '').replace(/[^0-9A-F]/gi, '') // strip off any leading # and non-hex chars
      if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      var r = parseInt(hex.substr(0, 2), 16)
      var g = parseInt(hex.substr(2, 2), 16)
      var b = parseInt(hex.substr(4, 2), 16)
      return { r: r, g: g, b: b }
    }

  })
