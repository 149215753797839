(function() {
  'use strict'

  angular.module('pl-shared')
    .service('snAsync', function($q, _) {

      function auto(requests, callback, error, notify) {
        var req = _.clone(requests)
        var promises = {}
        var isMade = _.has.bind(_, promises)

        function promiseRequest(deps, key) {
          if (!_.isArray(deps)) deps = [deps]
          var actionFnc = deps.pop()
          if (_.all(deps, isMade)) {
            promises[key] = $q.all(_.pick(promises, deps)).then(actionFnc, error, notify)
            delete req[key]
          }
        }
        while (!_.isEmpty(req)) _.each(req, promiseRequest)

        $q.all(promises).then(callback, error, notify)
      }

      function mapLimit(arr, limit, iterator, makeAllRequests) {
        var len = arr.length
        var requests = _.map(arr, request)
        var result = []
        var dfd = $q.defer()
        var limitLen = len - Math.min(len, limit)
        var error
        var errored
        var canceled
        var openRequests = 0

        function reqError(err) {
          openRequests--
          errored = true
          error = err
          if (!makeAllRequests) done(true)
          else nextRequest()
        }

        function reqSuccess(index, value) {
          openRequests--
          result[index] = value
          nextRequest()
        }

        function request(item, index) {
          return function() {
            if (canceled) return
            openRequests++
            return iterator(item)
              .then(reqSuccess.bind(null, index), reqError)
          }
        }

        function nextRequest() {
          var request = requests.shift()
          if (request) request()
          else done()
        }

        function done(immediate) {
          if (!immediate && openRequests > 0) return
          canceled = true
          if (errored) dfd.reject(error, result)
          else dfd.resolve(result)
        }

        if (!requests.length) dfd.resolve(result)
        while (requests.length > limitLen) nextRequest()
        return dfd.promise
      }

      function eachLimit() {
        return mapLimit.apply(null, arguments).then(_.constant())
      }

      return {
        auto: auto,
        each: _.partial(eachLimit, _, Infinity),
        eachLimit: eachLimit,
        eachSeries: _.partial(eachLimit, _, 1),
        map: _.partial(mapLimit, _, Infinity),
        mapLimit: mapLimit,
        mapSeries: _.partial(mapLimit, _, 1)
      }

    })

})()
