require('team-admin')
angular.module('team-admin')
  .factory('Position', function(DS) {
    var Position = DS.defineResource({
      name: 'positions',
      // endpoint: '/v3/teams/opponents', // use options to inject team ID here
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          teams: {
            localKey: 'team_id',
            localField: 'team',
            parent: true
          }
        }
      }
    })

    return Position
  })
