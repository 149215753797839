angular.module('pl-shared')

  .constant('defaultDeserialize', function(resourceConfig, resp) {
    return resp && resp.data ? resp.data.result || resp.data : resp
  })

  .config(function(DSProvider, defaultDeserialize, DSHttpAdapterProvider) {
    angular.extend(DSProvider.defaults, {
      log: console.debug, // eslint-disable-line no-console
      basePath: '/api', // @deprecated
      deserialize: defaultDeserialize
    })
    angular.extend(DSHttpAdapterProvider.defaults, {
      log: console.debug // eslint-disable-line no-console
    })
  })

  .run(function(DS, DSHttpAdapter, $http, ENV) {

    function ensureHttps(url) {
      return (url || '').replace(/^http:\/\//i, 'https://')
    }

    DSHttpAdapter.defaults.httpConfig = {
      headers: {
        Accept: 'application/vnd.ngin-api.v2, application/json',
        'X-SPORTS-VERSION': 'v1'
      },
      withCredentials: true
    }

    var v1Defaults = {
      http: $http,
      httpConfig: {
        headers: {
          Accept: 'application/vnd.ngin-api.v1, application/json',
          'X-SPORTS-VERSION': 'v1'
        }
      }
    }

    var v2Defaults = {
      basePath: '/api', // @deprecated
      http: $http,
      httpConfig: DSHttpAdapter.defaults.httpConfig,
      adapterName: 'http_api_v2'
    }

    var seApiDefaults = {
      basePath: ensureHttps(ENV.urls.apiProxy),
      http: $http,
      httpConfig: {
        headers: {
          Accept: 'application/json',
          'X-SPORTS-VERSION': undefined
        },
        withCredentials: true,
        adapterName: 'se_api'
      }
    }

    var statNginApiDefaults = {
      basePath: ensureHttps(ENV.urls.apiProxy) + '/stat_ngin',
      http: $http,
      httpConfig: {
        headers: {
          Accept: 'application/vnd.stat-ngin.v2, application/json',
          'X-SPORTS-VERSION': undefined
        },
        withCredentials: true,
        adapterName: 'stat_ngin_api'
      }
    }

    var nginV1Defaults = {
      basePath: ensureHttps(ENV.urls.apiProxy),
      http: $http,
      httpConfig: {
        headers: {
          Accept: 'application/vnd.ngin-api.v1, application/json',
          'X-SPORTS-VERSION': 'v1'
        },
        withCredentials: true,
        adapterName: 'ngin_v1'
      }
    }

    var nginV2Defaults = {
      basePath: ensureHttps(ENV.urls.apiProxy),
      http: $http,
      httpConfig: {
        headers: {
          Accept: 'application/vnd.ngin-api.v2, application/json',
          'X-SPORTS-VERSION': 'v1'
        },
        withCredentials: true,
        adapterName: 'ngin_v2'
      }
    }

    registerAltAdapter('http_api_v1', v1Defaults) // @deprecated
    registerAltAdapter('http_api_v2', v2Defaults) // @deprecated
    registerAltAdapter('se_api', seApiDefaults)
    registerAltAdapter('stat_ngin_api', statNginApiDefaults)
    registerAltAdapter('ngin_v1', nginV1Defaults)
    registerAltAdapter('ngin_v2', nginV2Defaults)

    // The Defaults object in js-data-angular has httpConfig as an object on the prototype,
    // and this prototype object is what the httpConfig properties are mixed into from each config.
    // This leads to subsequently defined adapters overwriting httpConfig for previous ones.
    // By assigning the property directly to the adapter after creating it, we avoid the prototype.
    function registerAltAdapter(name, defaults) {
      var adapter = new DSHttpAdapter.constructor(_.omit(defaults, 'httpConfig'))
      adapter.defaults.httpConfig = angular.merge({}, adapter.defaults.httpConfig, defaults.httpConfig)
      DS.registerAdapter(name, adapter, { default: false })
    }

    // Use this to add relations to a resource after it has been defined.
    // Useful when you want to add a relation for an app resource to a shared resource.
    // Example: DS.addRelations(Player, { belongsTo: { rosters: { localField: 'roster', localKey: 'roster_id' } } })
    DS.addRelations = function(def, relations) {
      // start copy from js-data.js
      def.relationList = def.relationList ? [...def.relationList] : [] // this array is frozen, so overwrite
      def.relationFields = def.relationFields || []
      DS.utils.forOwn(relations, function(relatedModels, type) {
        DS.utils.forOwn(relatedModels, function(defs, relationName) {
          if (!DS.utils._a(defs)) {
            relatedModels[relationName] = [defs]
          }
          DS.utils.forEach(relatedModels[relationName], function(d) {
            d.type = type
            d.relation = relationName
            d.name = def.name
            def.relationList.push(d)
            if (d.localField) {
              def.relationFields.push(d.localField)
            }
          })
        })
      })
      if (relations.belongsTo) {
        DS.utils.forOwn(relations.belongsTo, function(relatedModel, modelName) {
          DS.utils.forEach(relatedModel, function(relation) {
            if (relation.parent) {
              def.parent = modelName
              def.parentKey = relation.localKey
              def.parentField = relation.localField
            }
          })
        })
      }
      // end copy from js-data
      var relationProps = {} // temp object so the utils function doesn't try to redefine the existing properties
      DS.utils.applyRelationGettersToTarget(DS, def, relationProps)
      var proto = def[def.class].prototype
      var newProps = _.omit(Object.getOwnPropertyDescriptors(relationProps), _.keys(Object.getOwnPropertyDescriptors(proto)))
      Object.defineProperties(proto, newProps)
    }

  })
