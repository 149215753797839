require('team-admin')
angular.module('team-admin')
  .directive('snField', function($timeout, $document) {

    var $ = angular.element

    return {
      restrict: 'A',
      scope: true,
      link: function($scope, $el, attrs) {

        var document = $document.get(0)
        var el = $el.get(0)
        var formName = $el.closest('form').attr('name')
        var inputTypes = 'input, textarea, select'

        function enableErrors() {
          if ($scope.focused) return
          $el.addClass('touched')
          $el.off('.snEnableErrors')
          $el.on('change keyup', inputTypes, watchForErrors)
          watchForErrors()
        }

        function watchForErrors() {
          var errors = !!$el.find('.ng-invalid').length
          $el.toggleClass('error', errors)
          if (attrs.snField) {
            $scope.error = $scope.$eval(formName + '.' + attrs.snField + '.$error')
          }
        }

        function updateFocus() {
          $scope.$apply(function() {
            $scope.focused = $.contains(el, document.activeElement)
          })
        }

        function bindEvents() {
          $el.on('focus blur', inputTypes, updateFocus)
          $el.on('blur.snEnableErrors', inputTypes, function() {
            $timeout(enableErrors)
          })
        }

        $el.on('change.snFieldTouch', inputTypes, bindEvents)
      }
    }
  })
