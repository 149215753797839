angular.module('pl-shared')

  .directive('imagePreviewModal', function() {

    return {
      scope: {},
      template: require('/static/shared/components/image-preview-modal/image-preview-modal.html'),
      controllerAs: 'ctrl',
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        initial: '=',
        imgUrl: '=',
        imgName: '=',
        originalImgClass: '=imgClass',
        backgroundImage: '='
      },
      controller: function() {
        var ctrl = this

        ctrl.imgClass = ctrl.originalImgClass || 'pl-image-preview-modal__image'

        ctrl.done = function() {
          ctrl.cancel()
        }
      }
    }

  })
