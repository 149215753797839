require('team-admin')
angular.module('team-admin')
  .directive('gameInformation', function() {
    return {
      restrict: 'A',
      scope: {
        originalGame: '=resource',
        invitations: '=',
        team: '=',
        confirm: '=',
        cancel: '=',
        dialogOptions: '='
      },
      template: require('./game-information.html'),
      controller: function(pageViewHandler, $scope, Game, Alerts, $q, renderContext, dialog, moment, Venue) {
        pageViewHandler.fireEvent('EditGameModal.Open')

        $scope.scope = $scope
        $scope.loading = true
        // $scope.managedTeamInstances = $scope.team.activeSeason().managed_team_instances

        // Game load alone is required for editing
        $scope.$watch('originalGame', function(game) {
          if (!game) return
          $scope.teamsLocked = !!$scope.originalGame.game_details.team_2.id
          $scope.loading = false
          cloneGame()
          var teamsPlaying = [game.game_details.team_1, game.game_details.team_2]
          $scope.isHomeGame = isHomeGame()
          setOpponent(teamsPlaying)
        })

        $scope.isPastEvent = function() {
          var gameStartDateTime = moment($scope.game.start_date_time)
          var oneDayAgo = moment().subtract(1, 'days')
          return !gameStartDateTime.isBefore(oneDayAgo)
        }

        $scope.save = function() {
          $scope.failed = false

          return confirmChangingTeams()
            .then(saveGame)
            .then(saveSuccess)
            .catch(done)
        }

        function isHomeGame() {
          if ($scope.team.id === $scope.game.game_details.team_1.id) {
            if ($scope.game.game_details.team_1.is_home_team) return true
            else return false
          }
          else if ($scope.game.game_details.team_2.is_home_team) return true
          else return false
        }

        function setOpponent(teamsPlaying) {
          var potentialOpponents = _.reject(teamsPlaying, { id: $scope.team.id })
          if (potentialOpponents.length === 1) $scope.opponent = potentialOpponents[0]
          else if (potentialOpponents.length > 1) $scope.opponent = findSharedGameOpponent(potentialOpponents)
        }

        function findSharedGameOpponent(potentialOpponents) {
          return _.find(potentialOpponents, function(opponent) {
            return !_.find($scope.managedTeamInstances, { id: opponent.season_team_id })
          })
        }

        function confirmChangingTeams() {
          if (!$scope.teamsLocked) {
            var opponentNotChanged = true
            if ($scope.gameForm.opponent) opponentNotChanged = $scope.gameForm.opponent.$pristine
            if (opponentNotChanged) return $q.when(false)
            return dialog.confirm({
              directive: 'change-opponent'
            })
          }
          return $q.when()
        }

        function cloneGame(newVal, oldVal) {
          if (!newVal || newVal !== oldVal) $scope.game = angular.copy($scope.originalGame)
        }

        function saveGame() {
          var gameData = angular.copy($scope.game)
          return Venue.check(gameData, $scope.team)
            .then(gameData => Game.update($scope.game.id, gameData, { endpoint: `/v3/calendar/team/${ $scope.team.id }/event` }))
            .then($scope.setAs('savedGame'))
        }

        function saveSuccess() {
          _.extend($scope.originalGame, $scope.savedGame)
          cloneGame()
          $scope.gameForm.$setPristine()
          $scope.$emit('game:save', $scope.originalGame)
          Alerts.success('SCHEDULE.EDIT_GAME.save_success')
          renderContext.requireConfirmation(false)
          $scope.cancel()
        }

        function done(opts) {
          $scope.failed = opts != 'canceled'
        }

      }
    }
  })
