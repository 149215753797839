/* eslint-disable no-console */
(function() {
  'use strict'

  // TODO: add these only in debug/dev mode
  if (typeof console !== 'undefined') {
    console.injectorElementSelector = console.injectorElementSelector || '[ng-app]'
    _.extend(console, {

      findPathOfKey: function findPathOfKey(obj, searchKey, limit, path, iters, results) {
        limit = limit || 5
        iters = iters || 0
        path = path || ''
        results = results || []
        for (var key in obj) {
          var newPath = path + (path ? '.' : '') + key
          if (searchKey instanceof RegExp ? searchKey.test(key) : key === searchKey) {
            results.push(newPath)
            continue
          }
          if (obj instanceof Object && iters < limit) findPathOfKey(obj[key], searchKey, limit, newPath, iters + 1, results)
        }
        return results
      },

      findPath: function findPath(obj, searchObj, limit, path, iters, results) {
        limit = limit || 5
        iters = iters || 0
        path = path || []
        results = results || []
        if (obj === searchObj) results.push(path.join('.'))
        if (obj instanceof Object && iters < limit) {
          for (var key in obj) {
            findPath(obj[key], searchObj, limit, path.concat(key), iters + 1, results)
          }
        }
        return results
      },

      logThrough: function(obj, pretty) {
        console.log(pretty ? JSON.stringify(obj, null, 2) : obj)
        return obj
      },

      getInjected: function(name) {
        return angular.element(console.injectorElementSelector).injector().get(name)
      },

      setInjected: function(/* name, name... */) {
        _.each(arguments, function(name) {
          window[name] = console.getInjected(name)
        })
      },

      getScope: function(selector) {
        var el = angular.element(selector)
        if (!el.length) throw selector + ' not found'
        return el.isolateScope() || el.scope()
      },

      getCtrl: function(selector) {
        var scope = console.getScope(selector)
        return scope.$ctrl || scope.ctrl
      },

      spyOn: function(obj, methodName, prefix) {
        var method = obj[methodName]
        obj[methodName] = function() {
          var ret = method.apply(this, arguments)
          console.info((prefix ? prefix + ': ' : '') + methodName + '(' + Array.prototype.slice.apply(arguments).join(',') + ') = ' + ret)
          return ret
        }
      }

    })
  }

})()
