angular.module('pl-shared')

  .directive('colorQuestionView', function(_) {

    return {
      scope: {},
      template: require('/static/shared/components/regform/questions/color-question-view.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: function() {
        var ctrl = this
        var answer = ctrl.answer || {}
        ctrl.displayValue = answer.display_value || '--'
        if (/^[0-9A-F]{6}$/i.test(ctrl.displayValue)) ctrl.displayValue = '#' + ctrl.displayValue
      }
    }

  })
