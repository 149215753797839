'use strict'

;(function() {

  // Save $ between factory creations, needed for testing with karma
  var $

  require('team-admin')
  angular.module('team-admin')
    .factory('$', function($window) {
      return $ = $ || $window.$
    })

})()
