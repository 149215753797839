angular.module('pl-shared')

  .service('rfFormFacade', function(_) {

    function Form(form, elems) {
      this.id = form.id || 'rf_form_' + Math.floor(Math.random() * 999999)
      this.name = form.name || ''
      this.name2 = form.name2 || null
      this.name3 = form.name3 || null
      this.nameClick = form.nameClick || ''
      this.name2Click = form.name2Click || null
      this.name3Click = form.name3Click || null
      this.nameGrid = form.nameGrid || ''
      this.name2Grid = form.name2Grid || null
      this.name3Grid = form.name3Grid || null
      this.hide_required_reference = form.hide_required_reference || false
      this.model = form.model || {}
      this.question_elements = []
      this.questionGroups = []
      this.hasColumns = false
      this.addElems(elems)
    }

    Form.prototype.addElem = function(elem) {
      var qe = new Question(elem)
      qe.form = this
      qe.form_id = this.id
      if (elem.answer !== undefined) qe.answer = qe.buildAnswer(elem.answer, this.model)
      else if (qe.namespace) {
        var ns = this.model[qe.namespace] || {}
        qe.answer = ns[qe.id] !== undefined ? qe.buildAnswer(ns[qe.id]) : null
      }
      else qe.answer = this.model[qe.id] !== undefined ? qe.buildAnswer(this.model[qe.id]) : null
      this.question_elements.push(qe)

      if (elem.column !== undefined) this.hasColumns = true
      var col = parseInt(elem.column) || 0
      if (!this.questionGroups[col]) this.questionGroups[col] = []
      this.questionGroups[col].push(qe)
    }

    Form.prototype.addElems = function(elems) {
      var self = this
      _.forEach(elems, function(elem) {
        self.addElem(elem)
      })
    }

    Form.prototype.getValues = function() {
      var data = {}
      _.forEach(this.question_elements, function(qe) {
        data[qe.id] = qe.answer.value
      })
      return data
    }

    Form.prototype.getElemById = function(id) {
      return _.find(this.question_elements, { id: id })
    }

    //--------------------------------

    function Question(elem) {
      this.id = elem.id || null
      this.form = null
      this.form_id = null
      this.properties = elem.properties || {}
      this.is_required = !!elem.required
      this.is_enabled = elem.enabled === false ? false : true
      this.hasDependency = false
      this.question_type = elem.type || 'short_text'
      this.questionLabel = elem.label || ''
      this.isInteger = elem.isInteger ? true : false
      this.namespace = elem.namespace

      this.choice_elements = []
      this.choicePlaceholder = this.properties.placeholder || ''
      this.directiveName = _.camelCase(this.question_type) + 'Question'

      this.isEditable = elem.isEditable === false ? false : true
      this.editableInViewMode = ['document_upload', 'profile_photo', 'logo', 'image'].indexOf(this.question_type) !== -1

      this.setChoices(elem.options)
    }

    Question.prototype.buildAnswer = function(answer, model) {
      var a = new Answer(answer, model, this)
      a.question = this
      a.question_id = this.id
      a.valueType = 'FormBuilder::' + _.camelCase(this.question_type) + 'Answer'
      a.directiveName = a.valueType.split('::').pop()
      return a
    }

    Question.prototype.setChoices = function(options) {
      this.choice_elements = []
      if (!options || options.length === 0) return
      var self = this
      _.forEach(options, function(opt) {
        self.choice_elements.push({
          id: opt.value, // id is the value for reg choice elems
          is_enabled: true,
          is_selected: self.answer && (opt.value === self.answer.value), // todo remove this, unused?
          value: opt.value,
          choice: {
            id: opt.value,
            question_id: self.id,
            name: opt.label
          }
        })
      })
    }

    Question.prototype.availableChoiceElements = function() {
      return this.choice_elements
    }

    Question.prototype.choiceDisplay = function(choiceElement) {
      return choiceElement.choice.name
    }

    //--------------------------------

    function Answer(answer, model, question) {
      if (_.isFunction(answer)) {
        answer = answer(model, question.id)
      }

      if (_.isArray(answer)) {
        this.value = _.map(answer, function(v) {
          return v.value
        })
        var displayValues = _.map(answer, function(v) {
          return _.isFunction(v.label) ? v.label(model, question.id) : v.label
        })
        this.display_value = displayValues.join(', ')
      }
      else if (_.isPlainObject(answer)) {
        this.value = _.isFunction(answer.value) ? answer.value(model, question.id) : answer.value
        this.display_value = _.isFunction(answer.label) ? answer.label(model, question.id) : answer.label
      }
      else {
        this.value = answer
        if (_.any(question.choice_elements)) this.display_value = _.get(_.find(question.choice_elements, { value: answer }), 'choice.name') || answer
        else this.display_value = answer
      }
    }

    //--------------------------------

    return {
      Form: Form,
      Question: Question
    }

  })
