angular.module('pl-shared')
  .config(function(i18ngProvider) {
    i18ngProvider.init({
      getAsync: false,
      lng: 'en',
      fallbackLng: 'en',
      ns: { namespaces: ['shared'], defaultNs: 'shared' },
      fallbackNS: 'shared',
      useCookie: false,
      useLocalStorage: false,
      resGetPath: addKarmaBase('/static/__ns__/locales/__lng__/translation.json')
    })

    function addKarmaBase(url) {
      if (typeof window.__karma__ !== 'undefined') return '/base' + url
      return url
    }
  })

// Works around a bug in i18next v1.x.x with fallbackNS and returnObjectTrees.
// FIXME: We should not be extending Object.prototype!
Object.defineProperty(Object.prototype, 'indexOf', {
  value: function(value) {
    for (var key in this) if (this[key] === value) return key
  },
  writable: true
})
