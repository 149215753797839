angular.module('pl-shared')

  .directive('timezoneQuestionView', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/default-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, ApiTimezone) {
        var ctrl = this
        var answer = ctrl.answer || {}

        if (answer.value) {
          ApiTimezone.findAll().then(function(data) {
            var zone = _.find(data, function(z) { return z.identifier === answer.value })
            if (zone) {
              ctrl.answer.display_value = '(UTC ' + zone.offset + ') ' + zone.name
            }
          })

        }
      }
    }

  })
