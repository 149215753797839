angular.module('pl-shared')
  .factory('OAuthState', function($httpParamSerializer, $location, $window, ENV) {
    var key = 'OAuthState'
    var state = ''
    var count = 0
    var MAX_ATTEMPT = 3

    return {
      getState: getState,
      initiateLogin: initiateLogin,
      load: load,
      save: save,
    }

    function load() {
      var item = $window.localStorage.getItem(key)
      var data = { }

      if (item) data = JSON.parse(item)
      $window.localStorage.removeItem(key)

      return data
    }

    function save(data) {
      state = data.state || generateRandomStateValue()
      count = parseInt((data.count || 0), 10) + 1
      var dataStr = JSON.stringify({ state: state, returnTo: data.returnTo || '', count: count })
      $window.localStorage.setItem(key, dataStr)
      return count
    }

    function initiateLogin(base) {
      var returnTo = $location.url()
      if (save({ returnTo: returnTo }) > MAX_ATTEMPT) {
        // prevent infinite redirect loop
        // TODO present user with relevant info
        $window.localStorage.removeItem(key)
        return
      }
      var queryParams = {
        client_id: ENV.oauth[base].clientId,
        redirect_uri: ENV.oauth[base].redirectUri,
        response_type: 'none',
        state: getState(),
      }
      var queryString = $httpParamSerializer(queryParams)
      var redirectUrl = ENV.urls.userService + '/oauth/authorize?' + queryString
      $window.location.href = redirectUrl
    }

    function generateRandomStateValue() {
      return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)
    }

    function getState() {
      return state
    }
  })
