// <label popover="field-message" for="inputField" class="pl-tooltip--right">Something went wrong.</label>

(function() {
  'use strict'

  var $ = angular.element
  var POPOVER_OPEN_CLASS = 'sn-popover-open pl-is-active'

  angular.module('pl-shared')
    .directive('snPopover', function(_, $window, $timeout) {
      return {
        restrict: 'A',
        link: function(scope, el, attrs) {

          /*
            Popover need to:

            - attach to an element
            - position absolutely, in place (in the dom)
            - define boundries for x and y (by separate elements)
            - reposition when out of bounds (if possible)
            - optionally hide when out of bounds
            - optionally show only in specific states (hover/focus)
          */

          el.css('zIndex', 200).hide()
          var id = attrs.for

          // TIP: If you see this, it could be that your `for` attribute is
          // based on a scope variable from a parent directive. Adding an `ng-if`
          // condition can help make sure the popover element and directive only
          // get evaluated after the parent scope value is available.
          if (!id) throw new Error('snPopover reqiures a `for` attribute.')

          var Tether = $window.Tether
          var target = el.parents().last().find('#' + id) // base this search off el so that tests will work within document fragments

          var defaultOpts = { element: el, target: target }
          var defaultConstraints = [{ to: 'scrollParent', attachment: 'together', pin: ['left', 'right'] }]
          var instanceOpts = scope.$eval(attrs.snPopoverOpts) || {}
          var instanceConstraints = instanceOpts.constraints || []
          var constraintOpts = { constraints: [_.extend.apply(_, defaultConstraints.concat(instanceConstraints))] }
          var opts = _.defaults(constraintOpts, instanceOpts, defaultOpts)
          var style = attrs.snPopover

          setWidth(el, target, attrs.snPopoverWidth)
          setAttachment(style, opts)

          var tether = new Tether(opts)
          function showPopover() {
            el.show()
            if (opts.target) opts.target.addClass(POPOVER_OPEN_CLASS)
            tether.position()
          }

          scope.$on('$destroy', function() {
            if (el) el.remove() // manually remove it in case Angular can't
            if (opts.target) opts.target.removeClass(POPOVER_OPEN_CLASS)
            if (tether) tether.destroy()
          })
          $timeout(showPopover)
        }
      }

      function setWidth(popover, target, width) {
        if (!width) return
        if (width) popover.css('minWidth', 0) // override default min width when specifically set
        if (width === 'target') width = target.outerWidth()
        popover.outerWidth(width)
      }

      function setAttachment(style, opts) {
        if (style === 'right') {
          _.extend(opts, {
            attachment: 'middle left',
            targetAttachment: 'middle right'
          })
        }
        else if (style === 'left') {
          _.extend(opts, {
            attachment: 'middle right',
            targetAttachment: 'middle left'
          })
        }
        else if (style === 'below') {
          _.extend(opts, {
            attachment: 'top center',
            targetAttachment: 'bottom center'
          })
        }
        else if (style === 'dropdown' || style === 'dropdown-right') {
          _.extend(opts, {
            attachment: 'top left',
            targetAttachment: 'bottom left'
          })
        }
        else if (style === 'dropdown-left') {
          _.extend(opts, {
            attachment: 'top right',
            targetAttachment: 'bottom right'
          })
        }
        else if (style === 'above') {
          _.extend(opts, {
            attachment: 'bottom center',
            targetAttachment: 'top center'
          })
        }
        else if (!opts.attachment || !opts.targetAttachment) {
          if (style !== undefined) throw new Error('Invalid popover style: ' + style)
          else throw new Error('Attachment options are required when not using a predefined popover style.')
        }
      }

    })

  /**
   * snPopover
   *
   * Provides functions for your code to initiate show/hide popover behavior.
   * Can be injected, and the service object is also copied to $rootScope for easy use in your templates.
   * All `id` arguments to these functions should be registered on the popover element using `sn-popover-id`.
   *
   * snPopover.toggle: function(id, display, delay)
   *   Shows or hides a popover.
   *   Arguments:
   *     id:           The id of the popover
   *     delay:        How long to delay the action for.
   *     explicitHide: Pass `true` if you plan on explicitly calling `snPopover.hide(id)` or `snPopover.toggle(id)`
   *                   from another event handler. If not passed, hidePopover will be added to the document's click handler.
   *     display:      Pass `true` to show the popover. Defaults to true if the id is not in `openPopovers`
   *
   * snPopover.show: function(id, delay, explicitHide)
   *   Shows a popover.
   *   Arguments:
   *     id:           The id of the popover.
   *     delay:        How long to delay the show action for (default 0).
   *     explicitHide: Pass `true` if you plan on explicitly calling `snPopover.hide(id)` from another event handler.
   *                   If not passed, hidePopover will be added to the document's click handler.
   *
   * snPopover.hide: function(id, delay)
   *   Hides a popover.
   *   Arguments:
   *     id:           The id of the popover.
   *     delay:        How long to delay the hide action for (default 0)
   *
   * snPopover.cancelShow: function(id)
   *   Cancels a delayed snPopover.show action.
   *   Arguments:
   *     id:           The id of the popover.
   *
   * snPopover.cancelHide: function(id)
   *   Cancels a delayed snPopover.hide action.
   *   Arguments:
   *     id:           The id of the popover.
   */

    .service('snPopover', function($q, $document, $timeout) {
      var openPopovers = {}
      var hidePopoverHandlers = {}
      var showTimeouts = {}
      var hideTimeouts = {}

      function togglePopover(id, delay, explictHide, display) {
        display = arguments.length > 3 ? display : !openPopovers[id]

        if (display) {
          return showPopover(id, delay, explictHide)
        }

        return hidePopover(id, delay)
      }

      // TODO: pass -1 for delay to show/hide immediately? $document.on('click', ...) needs to be done in a $timeout anyway
      function showPopover(id, delay, explicitHide) {
        delay = +delay || 0
        function doShow() {
          openPopovers[id] = true
          if (!explicitHide) $document.on('click', hidePopoverHandlers[id] = hidePopover.bind(null, id, 0))
        }
        cancelHidePopover(id)
        if (!(id in openPopovers)) {
          var timeout = $timeout(doShow, delay)
          timeout.finally(function() { delete showTimeouts[id] })
          return showTimeouts[id] = timeout
        }
        return $q.reject()
      }
      function hidePopover(id, delay) {
        delay = +delay || 0
        function doHide() {
          if (hidePopoverHandlers[id]) {
            $document.off('click', hidePopoverHandlers[id])
            delete hidePopoverHandlers[id]
          }
          delete openPopovers[id]
        }
        cancelShowPopover(id)
        if (id in openPopovers) {
          var timeout = $timeout(doHide, delay)
          timeout.finally(function() { delete hideTimeouts[id] })
          return hideTimeouts[id] = timeout
        }
        return $q.reject()
      }
      function cancelShowPopover(id) {
        return $timeout.cancel(showTimeouts[id])
      }
      function cancelHidePopover(id) {
        return $timeout.cancel(hideTimeouts[id])
      }

      return {
        openPopovers: openPopovers,
        toggle: togglePopover,
        show: showPopover,
        hide: hidePopover,
        cancelShow: cancelShowPopover,
        cancelHide: cancelHidePopover
      }

    })
    .run(function($rootScope, snPopover) {
      $rootScope.constructor.prototype.snPopover = snPopover
    })

  /**
   * sn-popover-id
   *
   * Registers an element to be shown/hidden by calling `snPopover.show`/`snPopover.hide` from another element.
   * Hides the element until that is called.
   * Consolidates the standard dropdown behavior of showing when clicked and hiding when clicking the document.
   * If the value of sn-popover-id changes while the popover is shown, the popover is hidden until `snPopover.show` is called again with the new id.
   *
   * Usage:
   *   <button id="indicatorElement{{id}}" ng-click="showPopover('popover' + id)"
   *   <div sn-popover="dropdown" for="indicatorElement{{id}}" sn-popover-id="popover{{id}}">
   */

    .directive('snPopoverId', function($animate, $interpolate, snPopover) {
      return {
        transclude: 'element', // no template, just hide/show
        priority: 601, // ngIf priority + 1
        link: function(scope, element, attrs, ctrl, $transclude) {
          var popoverId

          function interpolateAttr(attr) {
            return function() { return $interpolate(attrs[attr] || '')(scope) }
          }

          scope.$watch(interpolateAttr('snPopoverId'), function(id, oldId) {
            popoverId = id
            if (id !== oldId) snPopover.hide(oldId) // cleanup
          })

          scope.$watch(interpolateAttr('snPopoverOpen'), function(open) {
            if (open) snPopover.show(popoverId) // TODO: what happens if this watch is somehow triggered before snPopoverId?
          })

          scope.$on('$destroy', function() { snPopover.hide(popoverId) })


          // add/remove linked popover from DOM - adapted from ngIf code
          var temp = {}
          scope.$watch(function() { return snPopover.openPopovers[popoverId] }, function(open) {
            if (!open) {
              if (temp.childScope) temp.childScope.$destroy()
              if (temp.clone) $animate.leave(temp.clone)
              temp = {}
            }
            else if (!temp.childScope) {
              $transclude(function(clone, newScope) {
                $animate.enter(clone, element.parent(), element)
                temp = { clone: clone, childScope: newScope }
              })
            }
          })
        }
      }
    })

})()
