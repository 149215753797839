angular.module('pl-shared')

  .factory('UserPreferences', function(_, DS, $http, apiV2, currentUser) {

    var V2_HEADERS = { headers: apiV2.headers, withCredentials: true }
    var URL = DS.adapters.se_api.defaults.basePath + '/user_preferences'

    var cachedPreferences = {}
    var preferencePromise

    preload()

    return {
      get: get,
      set: set
    }

    function clearCache() {
      preferencePromise = null
      for (var prop in cachedPreferences) {
        if (cachedPreferences.hasOwnProperty(prop)) delete cachedPreferences[prop]
      }
    }

    function cacheAll(resp) {
      clearCache()
      _.each(resp.data.result, cacheOne)
      return cachedPreferences
    }

    function cacheOne(pref) {
      pref = pref.data || pref
      var p = cachedPreferences[pref.key] = pref
      if (p.datatype === 'boolean') p.value = (p.value === 'true' || p.value === true) // converts string values to boolean
      return p
    }

    function preload() {
      if (currentUser.preferences) cacheAll({ data: { result: currentUser.preferences } })
    }

    function get() {
      if (preferencePromise) return preferencePromise
      return preferencePromise = $http.get(URL, V2_HEADERS).then(cacheAll)
    }

    function set(key, value, feedback) {
      var body = { key: key, value: value }
      if (feedback) body.feedback = feedback
      return $http.put(URL, body, V2_HEADERS).then(cacheOne)
    }
  })
