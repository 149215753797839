require('team-admin')
angular.module('team-admin')
  .component('printContactList', {
    bindings: {
      print: '<',
      cancel: '<',
      team: '<'
    },
    template: require('./print-contact-list.html'),
    controller: function(_, $q, Player, Roster, SNAP) {
      var ctrl = this

      // We have to wait until all the images are loaded until we print, otherwise they won't show up.
      // Set up a hash to track the loads here and only call print (resolve the promise) when they're all done.
      var imgLoads = { logo: false, team: false }

      var dfd = $q.defer()
      ctrl.imgLoaded = function() {
        imgLoads[[].join.call(arguments, '_')] = true
        if (_.all(imgLoads)) dfd.resolve()
      }

      ctrl.$onInit = function() {
        loadMembers()
          .then(ctrl.print)
      }

      function loadMembers() {
        return Player.findAll({
          team_id: ctrl.team.id
        }, _.extend({
          load: 'all',
          bypassCache: true
        }, Player.v3Options))
          .then(Roster.loadRosteringPersonas)
          .then(function(roster) {

            function sortLower(prop) { // helper for case-insensitive sorting
              return function(player) {
                return (player[prop] || '').toLowerCase()
              }
            }

            var unique = _.uniq(roster, false, 'persona.persona_id')
            var sorted =  _.sortByAll(unique, sortLower('last_name'), sortLower('first_name'))
            var grouped = _.groupBy(sorted, 'roster_role')
            _.defaults(grouped, { player: [], staff: [] }) // in case no players/staff on the team

            ctrl.byType = grouped
            ctrl.logoUrl = SNAP.assetPath + '/images/sportsengine-logo-color-black.svg'
            ctrl.teamLogoUrl = SNAP.assetPath + '/images/organization-star-padded.svg'
            ctrl.now = new Date()
            ctrl.programText = ctrl.team.activeSeason().name

            return dfd.promise

          })
      }
    }
  })
