require('team-admin')
angular.module('team-admin').directive('schedule', function() {
  return {
    restrict: 'A',
    scope: {
      team: '='
    },
    template: require('./schedule.html'),
    controller: function($scope, timezone) {
      $scope.timezone = timezone
    }
  }
})
