angular.module('pl-shared')
  .factory('OAuthMe', function($http, apiV2, ENV) {
    return {
      find: find,
      currentUser: null,
    }

    function find() {
      return $http.get(ENV.urls.apiProxy + '/oauth/me', { withCredentials: true })
        .then(apiV2.deserialize)
    }
  })
