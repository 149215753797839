require('team-admin')
angular.module('team-admin')
  .directive('chatMembersModal', function() {
    return {
      scope: {},
      bindToController: {
        team: '=',
        teamInstanceRoster: '=',
        cancel: '=',
        confirm: '='
      },
      template: require('./chat-members-modal.html'),
      controllerAs: 'ctrl',
      controller: function(FirebaseChatFactory) {
        var ctrl = this
        ctrl.users = _.sortByOrder(_.filter(FirebaseChatFactory.getUsers(), function(obj) { return _.any(_.values(obj.rosterPersonaIds)) }), ['firstName'], ['asc'])
        ctrl.activeUsers = _.filter(ctrl.users, 'lastSignInTimestamp')
        ctrl.inactiveUsers = _.filter(ctrl.users, function(user) {
          return !user.lastSignInTimestamp
        })
        ctrl.pendingMembers = _.reject(ctrl.teamInstanceRoster, { invitation: { status: 'accepted' } })

        ctrl.getRosterPlayers = function(user) {
          var personaIds = _.keys(_.pick(user.rosterPersonaIds, _.identity))
          var names = []
          _.forEach(personaIds, function(idString) {
            var rosterPlayer = _.findWhere(ctrl.teamInstanceRoster, { persona: { persona_id: parseInt(idString) } })
            if (rosterPlayer) names.push(rosterPlayer.full_name)
          })
          return names.join(', ')
        }

        ctrl.joinNames = function(firstName, lastName) {
          return firstName + ' ' + lastName
        }
      }
    }
  })
