angular.module('pl-shared')
  .service('wrapDigest', function($rootScope) {

    /* wrapDigest ***

      Returns a function that calls the given function, then applies a digest to $rootScope if necessary.
      This is most useful for tying a non-digested action (e.g. DOM event handler) into the Angular digest cycle.
    */

    return function digested(fnc) {
      return function() {
        var ret = fnc.apply(this, arguments)
        if (!$rootScope.$$phase) $rootScope.$digest()
        return ret
      }
    }

  })
