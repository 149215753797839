angular.module('pl-shared')
  .service('setAs', function(_) {

    /* setAs ***

      Returns a function that sets its argument to a specific property on the specified object.
      This is most useful for binding the result of a resolved promise to a scope/controller property.

      USAGE:
      setAs(target, property[, staticValue[, returnStaticValue]])
        target:            [object]  The object to set the property on.
        property:          [string]  The name of the property to where the returned function should set its argument.
        staticValue:       [any]     (optional) A static value for the returned function to set on the property.
        returnStaticValue: [boolean] If true, the returned function should pass the static value instead of the argument value.

      Example:
        Foo.find(id)
          .then(setAs($scope, 'foo.bar'))
          .catch(setAs($scope, 'errored', true))
          .finally(setAs($scope, 'loading', false))

    */

    return function setAs(target, property, staticValue, returnStaticValue) {
      var isStatic = arguments.length > 2
      return function(value) {
        _.set(target, property, isStatic ? staticValue : value)
        return returnStaticValue ? staticValue : value // static values are likely not useful in the promise chain
      }
    }

  })
