require('team-admin')
angular.module('team-admin')

  .directive('teamChat', function() {
    return {
      scope: {},
      bindToController: {
        team: '=',
        teamInstanceRoster: '='
      },
      template: require('./team-chat.html'),
      controllerAs: 'ctrl',
      controller: function($scope, dialog) {
        var ctrl = this

        ctrl.triggerChatMembersModal = function() {
          dialog.confirm({
            directive: 'chat-members-modal',
            attrs: {
              team: ctrl.team,
              teamInstanceRoster: ctrl.teamInstanceRoster
            }
          })
        }
      }
    }
  })
