require('./bundle-dependencies')

require('./app.js')
require('./app-config.js')
require('./app-i18ng.js')
require('./app-routes.js')

require('./bundle-app')

angular.element(function() {
  angular.bootstrap(document, ['team-admin'])
})
