angular.module('pl-shared')
  .directive('slider', function() {

    // value        (ngModel) source to store the value (single-point slider)
    // start        (ngModel) source to store the start value (multi-point slider)
    // end          (ngModel) source to store the end value (multi-point slider)
    // min          (Number) minimum value allowed (defaults to 0)
    // max          (Number) maximum value allowed (defaults to 100)
    // step         (Number) increment value (defaults to 1)
    // updateOnDrag (Boolean) update model values while draggng (defaults to false)

    // EXAMPLE:   <slider multi-point start="data.start" end="data.end" min="0" max="20" step="2">/slider>

    return {
      restrict: 'E',
      template: require('/static/shared/components/slider/slider.html'),
      scope: {},
      controllerAs: 'ctrl',
      bindToController: {
        value: '=',
        startValue: '=',
        endValue: '=',
        _min: '=min',
        _max: '=max',
        _step: '=step',
        disabled: '=disabled',
        updateOnDrag: '='
      },
      controller: sliderControler
    }


    function sliderControler(_, $scope, $element, $attrs, $document) {

      var VALUE_KEY = 'value'
      var START_KEY = 'startValue'
      var END_KEY = 'endValue'
      var ATTR_KEYS = $attrs.$attr

      checkRequiredAttrs()

      var ctrl = this
      var $ = angular.element
      var multi = ctrl.multi = START_KEY in ATTR_KEYS
      var startKey = multi ? START_KEY : VALUE_KEY
      var endKey = multi ? END_KEY : VALUE_KEY
      var sliderEl = $element.find('.js-slider')
      var rangeEl = $element.find('.js-slider-range')

      watchAndSetDefault('min', 0)
      watchAndSetDefault('max', 100)
      watchAndSetDefault('step', 1)
      watchHandle($scope, ctrl, startKey)
      if (startKey !== endKey) watchHandle($scope, ctrl, endKey)

      $element.on('dragstart', function(event) {
        event.preventDefault()
        return false
      })
      $element.on('mousedown', '.js-slider-handle', startDrag)
      $scope.$on('$destroy', stopDrag)
      $scope.$watch('ctrl.drag.value', function() {
        updateHandle(_.pick(ctrl.drag, VALUE_KEY, 'min', 'max', 'key'))
      })

      _.extend(ctrl, {

        className: ctrl.multi ? 'sn-slider--multi-point' : 'sn-slider',

        setStart: function(val) {
          ctrl[startKey] = Math.max(val, ctrl.min)
        },

        setEnd: function(val) {
          ctrl[endKey] = Math.min(val, ctrl.max)
        }

      })

      // PRIVATE METHODS

      function startDrag(e) {
        if (ctrl.disabled) return
        var offset = sliderEl.offset()
        var drag = ctrl.drag = {
          minX: offset.left,
          maxX: offset.left + sliderEl.outerWidth(),
          min: ctrl.min,
          max: ctrl.max,
          step: ctrl.step,
          key: $(e.currentTarget).attr('range-value')
        }

        drag.rangeWidth = drag.maxX - drag.minX
        drag.value = ctrl[drag.key]

        $document.on('mousemove.slider', doDrag)
        $document.one('mouseup.slider', stopDrag)
        $scope.$apply()
      }

      function doDrag(e) {
        var drag = ctrl.drag
        var offsetX = Math.max(Math.min(e.clientX, drag.maxX), drag.minX) - drag.minX

        $scope.$apply(function() {
          drag.value = Math.round((drag.min + offsetX / drag.rangeWidth * (drag.max - drag.min)) / drag.step) * drag.step
          if (drag.key === END_KEY && drag.value < ctrl[START_KEY]) drag.value = ctrl[START_KEY]
          else if (drag.key === START_KEY && drag.value > ctrl[END_KEY]) drag.value = ctrl[END_KEY]
          if (ctrl.updateOnDrag) ctrl[drag.key] = drag.value
        })
      }

      function stopDrag() {
        var drag = ctrl.drag
        if (!drag) return
        ctrl[drag.key] = drag.value
        ctrl.drag = null
        $document.off('.slider')
        $scope.$apply()
      }

      function updateHandle(src) {
        if (!src || src.value === undefined) return
        var endHandle = src.key === END_KEY
        var marginProp = endHandle ? 'right' : 'left'
        var percent = (src.value - src.min) / (src.max - src.min) * 100
        if (endHandle) percent = 100 - percent
        rangeEl.css(marginProp, percent + '%')
      }

      function watchHandle($scope, src, key) {
        $scope.$watch('ctrl.' + key, function(val) {
          if (val === undefined) return
          updateHandle(_.extend(_.pick(src, 'min', 'max'), {
            value: src[key],
            key: key
          }))
        })
      }

      function watchAndSetDefault(key, def) {
        $scope.$watch('ctrl._' + key, function(val) {
          ctrl[key] = val !== undefined ? val : def
        })
      }

      function checkRequiredAttrs() {
        var hasValue = VALUE_KEY in ATTR_KEYS
        var hasStartValue = START_KEY in ATTR_KEYS
        var hasEndValue = END_KEY in ATTR_KEYS
        if (hasStartValue !== hasEndValue) throw new Error('a multi-point slider requires `start-value` and `end-value` attributes.')
        else if (!hasStartValue && !hasValue) throw new Error('A slider requires a `value` attribute.')
      }

    }

  })
