angular.module('pl-shared')
  .service('preloadScript', function() {
    return function preloadScript(src) {
      const existing = document.querySelector(`script[src="${ src }"]`)
      if (existing) return
      const script = document.createElement('script')
      script.src = src
      document.body.appendChild(script)
    }
  })
