angular.module('pl-shared')

  .directive('checkboxQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/checkbox-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        model: '=',
        question: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this
        ctrl.properties = ctrl.question.properties
        ctrl.model = ctrl.answer ? _.clone(ctrl.answer.value) : [] // don't use the direct reference
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.availableChoices = ctrl.question.availableChoiceElements({ include: ctrl.model })
        ctrl.selectOptions = {
          closeOnSelect: ctrl.properties.max === 1
        }

        $scope.$watch('ctrl.model', function() {
          ctrl.isCheckRequired = ctrl.question.is_required && ctrl.model.length === 0
        }, true)
      }
    }

  })
