angular.module('pl-shared')
  .directive('bindSelect2Dropdown', function() {

    /**
     *  Allows an element to act as the container (indicator/trigger) for the dropdown of another select2.
     *
     *  Usage:
     *    <span bind-select2-dropdown="opts">
     *    opts:
     *      {string} select - The selector of the <select select2> to bind the dropdown of.
     *      {string} [parent] - The selector of the parent element to use as the container for binding.
     *                          Defaults to the element that the directive is added to.
     */

    return {
      restrict: 'A',
      link: function($scope, $element, $attrs) {
        var opts = $scope.$eval($attrs.bindSelect2Dropdown)
        var $select = angular.element(opts.select).eq(0)
        var $target = opts.target ? $element.closest(opts.target) : $element

        if (!$select.length) return console.error('bindSelect2Dropdown: select not found: ' + opts.select)
        if (!$target.length) return console.error('bindSelect2Dropdown: target not found: ' + opts.target)

        $target.addClass('select2 select2-container--default')

        $element.on('click', function(event) {
          var sl2 = $select.data('select2')
          if (!sl2) return console.warn('select2 data not found for ' + opts.select)
          if (sl2.$container[0] !== $target[0]) {
            sl2.$container = $target // make the label the new base element
            $target.data('element', sl2.$element) // for _attachCloseHandler to work correctly
            sl2.dropdown.position(sl2.$dropdown, $target) // hooks dropdown to label
          }
          sl2.toggleDropdown()
        })

      }
    }

  })
