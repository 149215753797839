angular.module('pl-shared')

  .directive('linkQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/link-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id
      }
    }

  })
