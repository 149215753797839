require('team-admin')
angular.module('team-admin')
  .directive('addPlayers', function() {

    var EMPTY_SLOT_COUNT = 10

    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        team: '=',
        dialogOptions: '=',
        context: '=',
        gaCategory: '=',
        gaLabelType: '='
      },
      template: require('./add-players.html'),
      controller: function(pageViewHandler, _, snAsync, snGlobals, $scope, $element, $timeout, Player, TeamInvite) {
        pageViewHandler.fireEvent('RosterAddModal.Open')

        var team = $scope.team
        var orgId = team.org_details.id
        var newPlayers = $scope.newPlayers = []
        var creatingPlayers = false
        var createdPlayers = []
        var nonInvitedPlayers = []
        var tempId = 1

        $scope.TYPE = $scope.context.toUpperCase()
        $scope.failed = false

        while (newPlayers.length < EMPTY_SLOT_COUNT) {
          newPlayers.push({ id: tempId++ })
        }

        $scope.validate = function(rowForm, player) {
          player.required = !!player.first_name || !!player.last_name || !!player.email
        }

        $scope.addAnother = function() {
          newPlayers.push({ id: tempId++ })
          $timeout(function() {
            var content = $element.find('.pl-modal__content').get()[0]
            var focused = $element.find('[ng-model="player.first_name"]').last()
            content.scrollTop = content.scrollHeight - content.clientHeight
            focused.focus()
          })
        }

        $scope.removePlayer = function(player) {
          var index = newPlayers.indexOf(player)
          if (index >= 0) newPlayers.splice(index, 1)
          if (!newPlayers.length) $scope.addAnother()
        }

        $scope.createPlayers = function() {
          pageViewHandler.fireEvent('AddPlayerModal.Submit')
          var validPlayers = []
          var failedPlayers = []

          $scope.failed = false

          _.each($scope.newPlayers, function(player, i) {
            if (!player.first_name || !player.last_name) return

            player.data = {
              org_id: orgId,
              roster_id: _.get(team, ['rosters', 0, 'id']),
              name: `${ player.first_name } ${ player.last_name }`,
              roster_role: Player.contextTypeV3[$scope.context]
            }

            validPlayers.push(player)
          })

          function createPlayerSuccess(createdPlayer) {
            createdPlayers.push(createdPlayer)
            return createdPlayer
          }

          function createPlayerError(player) {
            $scope.failed = true
            failedPlayers.push(player)
          }

          function createPlayer(player) {
            return Player.create(player.data, Player.v3Options)
              .then(
                sendInvitation.bind(null, player),
                createPlayerError.bind(null, player)
              )
              .then(createPlayerSuccess)
          }

          function sendInvitation(player, createdPlayer) {
            if (!player.email) return createdPlayer
            return TeamInvite.create({
              email_address: player.email
            }, {
              endpoint: `v3/roster_personas/${ createdPlayer.id }/invitations`
            })

              .then(
                sendInvitationSuccess.bind(null, createdPlayer),
                sendInvitationError.bind(null, createdPlayer)
              )
          }

          function sendInvitationSuccess(createdPlayer, invite) {
            createdPlayer.invitation = invite
            return createdPlayer
          }

          function sendInvitationError(createdPlayer) {
            nonInvitedPlayers.push(createdPlayer)
          }

          function checkForFailures(players) {
            creatingPlayers = false
            if (!$scope.failed) $scope.confirm(createdPlayers)
            $scope.newPlayers = failedPlayers
          }

          creatingPlayers = true
          return snAsync.eachLimit(validPlayers, 5, createPlayer).then(checkForFailures)
        }

        $scope.dialogOptions.preCloseCallback = function() {
          if (creatingPlayers) return false
          if (createdPlayers.length) {
            $scope.confirm(createdPlayers, nonInvitedPlayers)
            return false
          }
        }

      }
    }
  })
