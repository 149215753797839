require('team-admin')
angular.module('team-admin')
  .directive('upgrade', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./upgrade.html'),
      controller: function($scope, $sce, Team, ENV) {

        $scope.$watch('team', function() {
          if (!$scope.team) return
          var bossHost = ENV.urls.bossService
          $scope.upgradeUrl = $sce.trustAsResourceUrl(bossHost +
            '/provisioning/orders/new?' +
            'product_key=team_management&context_type=Team&context_id=' + $scope.team.id)
        })


      }
    }
  })
