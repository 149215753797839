angular.module('pl-shared')
  .filter('maskTaxId', function() {
    return function(value) {
    // Mask all but the last four digits
      var taxId = value + ''

      if (taxId.length === 4) return '●●●●●' + taxId

      var last4 = taxId.slice(-4)
      var masked = taxId.slice(0, -4).replace(/\d/g, '●')
      return masked + last4
    }
  })
