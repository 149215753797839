angular.module('pl-shared')

  .directive('fileUploadModal', function() {

    // AVAILABLE OPTIONS

    // accept:            ['jpg', 'png', 'gif'] (defaults to all)
    // titleText:         String or {{ translated.key | t }}
    // uploadLinkText:    String or {{ translated.key | t }}
    // clearLinkText:     String or {{ translated.key | t }}
    // imgClass:          String
    // showFilename:      Boolean (default to true)
    // showSizeLimit:     Boolean (default to true)
    // showSupports:      Boolean (default to false) - shows supported file types
    // checkboxModel:     model for footer checkbox option
    // checkboxLabel:     String

    return {
      scope: {},
      template: require('/static/shared/components/file-upload/file-upload-modal.html'),
      controllerAs: 'ctrl',
      bindToController: {
        options: '=fileUploadOptions',
        originalModel: '=model',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      controller: function(_, $q, $scope, fileHelper, i18ng) {
        var ctrl = this
        var opts = ctrl.options || {}

        ctrl.accept = _.compact([].concat(opts.accept))
        ctrl.acceptTypes = _.uniq(_.values(_.pick(fileHelper.mimeTypes, ctrl.accept))).join(',')
        ctrl.acceptLabel = ctrl.accept.length ? ctrl.accept.join(', ').toUpperCase() : i18ng.t('FILE_UPLOAD.supports_all')
        ctrl.backgroundImage = opts.backgroundImage === true
        ctrl.titleText = opts.titleText || i18ng.t('FILE_UPLOAD.title')
        ctrl.uploadLinkText = opts.uploadLinkText || i18ng.t('FILE_UPLOAD.upload_link')
        ctrl.clearLinkText = opts.clearLinkText || i18ng.t('FILE_UPLOAD.clear_link')
        ctrl.imgClass = opts.imgClass || 'pl-document-upload__image'
        ctrl.showFilename = opts.showFilename === false ? false : true
        ctrl.showSizeLimit = opts.showSizeLimit === false ? false : true
        ctrl.showSupports = opts.showSupports === true
        ctrl.model = ctrl.originalModel ? _.clone(ctrl.originalModel) : {}
        ctrl.document = null
        ctrl.fileData = null
        ctrl.checkboxLabel = null
        ctrl.checkboxModel = opts.checkboxModel === undefined ? null : opts.checkboxModel
        if (ctrl.checkboxModel !== null) ctrl.checkboxLabel = opts.checkboxLabel || ''
        ctrl.allowCrop = opts.allowCrop && !ctrl.model.url && !ctrl.model.data

        ctrl.done = function() {
          if (ctrl.model.url) return ctrl.cancel()
          var result = ctrl.model.data ? ctrl.model : null
          if (_.isFunction(opts.done)) {
            return $q.when(opts.done(result)).then(function() {
              return ctrl.confirm(result)
            })
          }
          return ctrl.confirm(result)
        }

        ctrl.save = function() {
          if (ctrl.model.url) return ctrl.cancel()
          return $q
            .when(opts.save(ctrl.model.data ? ctrl.model : null))
            .then(ctrl.confirm, retry)
        }

        ctrl.clearValue = function() {
          ctrl.model = {}
          ctrl.invalidFileType = false
          ctrl.allowCrop = opts.allowCrop
        }

        ctrl.isImage = function() {
          return fileHelper.isImage(ctrl.model)
        }

        ctrl.base64Data = function() {
          return fileHelper.base64Data(ctrl.model)
        }

        ctrl.handleFileSelect = function(event) {
          var file = event.currentTarget.files[0]
          var reader = new FileReader()

          reader.onload = function(event) {
            ctrl.updateModelFromFile(file, event.target.result)
          }

          reader.readAsDataURL(file)
        }

        var acceptRgx = new RegExp('\\.(' + ctrl.accept.map(_.escapeRegExp).join('|') + ')$', 'i')
        // This is exposed on the controller for testing purposes
        ctrl.updateModelFromFile = function(file, dataUrl) {
          $scope.$apply(function() {
            ctrl.model.file = file
            ctrl.model.data = fileHelper.stripBase64Data(dataUrl)
            ctrl.model.filetype = file.type
            ctrl.model.filename = file.name
            ctrl.allowCrop = opts.allowCrop && ctrl.isImage()
            ctrl.invalidFileType = _.any(ctrl.accept) && !acceptRgx.test(file.name)
          })
        }

        function retry() {
          ctrl.failed = true
        }

      }
    }

  })
