var str = window.navigator.userAgent
var ua = {}

if (str.match('MSIE')) ua.ie = true
if (str.match('MSIE 10')) ua.ie10 = true
if (str.match(/Trident.*rv:11\./)) ua.ie = ua.ie11 = true

require('team-admin')
angular.module('team-admin')

  .value('userAgent', ua)

  // As good as IE 1-/11 are, they STILL need some help
  .directive('browserSpecificClasses', function(userAgent) {
    return {
      restrict: 'A',
      link: function($scope, $el) {
        // Hack to apply classes so we can scope css to IE only.
        // Necessary for getting flexbox to behave in IE
        if (userAgent.ie10) $el.addClass('ie10')
        else if (userAgent.ie11) $el.addClass('ie11')
      }
    }
  })
