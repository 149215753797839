angular.module('pl-shared')
  .service('AttachmentService', function($http, $q, $rootScope, ENV, pageViewHandler) {

    const AttachmentService = {
      ATTACHMENT: 'attachment',
      upload,
      uploadCallback
    }

    _.defaults(ENV.urls, {
      assetService: `https://attachments.${ ENV.current === 'production' ? 'se-assets' : 'se-stage-assets' }.com/`
    })

    function upload(file, assetType = AttachmentService.ATTACHMENT) {
      if (!(file instanceof File)) return { guid: '', status: 'generic_error' }

      const attachment = {
        guid: '',
        file,
        filename: sanitizeFileName(file.name),
        size: file.size,
        content_type: file.type,
        status: 'uploading'
      }

      presignPost()
        .then(presignResult => {
          if (presignResult.url) {
            uploadPresignPost(presignResult)
              .then(uploadResult => {
                if (uploadResult.status === 204) {
                  // key is the url to asset in s3. Ex. /assets/{guid}/{filename}.ext
                  const key = _.get(presignResult, 'fields.key', '')
                  attachment.guid = _.get(key.split('/'), 1, '')

                  const objectUrlPath = getObjectUrlPath(attachment.guid, attachment.filename)
                  attachment.url = getUrl(ENV.urls.assetService, objectUrlPath)
                  attachment.status = 'uploaded'
                }
                else {
                  attachment.status = uploadResult
                }
              })
          }
          else {
            attachment.status = presignResult
          }
        })

      return attachment


      // PRIVATE FUNCTIONS

      function getObjectUrlPath(guid, fileName) {
        var newFileName = encodeURIComponent(fileName)
        newFileName = newFileName.replace(/%20/g, '+')
        return `attachment/${guid}/${newFileName}`
      }

      function sanitizeFileName(fileName) {
        const newFileName = fileName.replace(/[^a-zA-Z0-9_() .-]+/g, '')
        var fileExtension = newFileName.split('.')[1]
        fileExtension = `.${fileExtension}`
        if (newFileName === fileExtension) {
          return `file${fileExtension}`
        }
        else {
          return newFileName
        }
      }

      function getUrl(base, path) {
        // if (ENV.current === 'development') path = 'development/' + path.replace(/^\//, '')
        return String(_.extend(new URL(path, base), { protocol: 'https' }))
      }

      function presignPost() {
        const url = getUrl(ENV.urls.apiProxy, 'v3/assets/presign-post')
        const opts = {
          withCredentials: true,
          headers: { Accept: 'application/json' }
        }
        const data = {
          file_name: file.name,
          file_size: file.size,
          asset_type: assetType
        }
        return $http.post(url, data, opts)
          .then(success => success.data.result, handleUploadErrors)
      }

      function uploadPresignPost(presignResult) {
        const url = presignResult.url
        const data = presignResult.fields
        const formData = new FormData()
        _.each(data, (val, key) => formData.append(key, val))
        formData.append('file', file)

        return $http.post(url, formData, {
          // TODO: Content-Type for uploaded file is returned as binary/octet-stream from S3
          headers: { 'Content-Type': undefined /* data.ContentType */ } // let the browser set this, it adds boundary
        }).catch(handleUploadErrors)
      }

      function handleUploadErrors(err) {
        if (err.status === 400) {
          if (err.data.result === 'Invalid File') return 'file_type_error'
          else if (findXmlErrorCode(err.data) === 'EntityTooLarge') return 'file_size_error'
        }
        return 'generic_error'
      }

      function findXmlErrorCode(xml) {
        var xmlDoc = new DOMParser().parseFromString(xml, 'text/xml')
        return _.get(xmlDoc.evaluate('//Error/Code', xmlDoc, null, XPathResult.STRING_TYPE), 'stringValue', '')
      }

    }

    // Returns a promise that resolves with the attachment object once the upload is complete.
    function uploadCallback(file, assetType = AttachmentService.ATTACHMENT) {
      return $q((resolve, reject) => {
        var attachment = AttachmentService.upload(file, assetType) // don't call directly, so we can stub in tests
        var unwatch = $rootScope.$watch(() => attachment.status, status => {
          if (status === 'uploading') return
          unwatch()
          if (status === 'uploaded') resolve(attachment)
          else reject(attachment)
        })
      })
    }

    return AttachmentService

  })
