angular.module('pl-shared')
  .service('nestedResource', function(_) {

    /**
     * Provides an easy way to wrap request data in a top-level property.
     * This is necessary for some Rails endpoints.
     *
     * Usage:
     * Add this to your resource definition object:
     *   serialize: nestedResource('wrapper_property_name', options)
     *
     * options can be an object, or a function that is run in the context of the instance that returns an object.
     * The object's properties will be extended onto the top level of the serialized result.
     */

    return function(propName, options) {
      return function(config, data) {
        var opts = typeof options === 'function' ? options.call(this) : options
        var result = _.extend({}, opts)
        result[propName] = data
        return result
      }
    }

  })
