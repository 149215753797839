require('team-admin')
angular.module('team-admin')
  .component('gameDuration', {
    bindings: {
      game: '=',
      gameForm: '<',
      validationDisabled: '<'
    },
    template: require('./game-duration.html'),
    controller: function(_, $timeout, moment, $scope) {
      var $ctrl = this

      $scope.$watch('$ctrl.validationDisabled', function() {
        if ($ctrl.gameForm) {
          $ctrl.validateField($ctrl.gameForm)
        }
      })

      $ctrl.$onInit = function() {

        var hours = _.map(_.range(0, 25), function(h) {
          return { id: h, text: _.padLeft(h, 2, 0) }
        })

        var minutes = _.map(_.range(0, 60, 5), function(m) {
          return { id: m, text: _.padLeft(m, 2, 0) }
        })

        $ctrl.select2Hours = {
          data: hours,
          placeholder: 'H',
          tags: true,
          createTag: function(params) {
            return tagFunction(params, 24)
          }
        }

        $ctrl.select2Mins = {
          data: minutes,
          placeholder: 'M',
          tags: true,
          createTag: function(params) {
            return tagFunction(params, 59)
          }
        }

        function tagFunction(tagParams, maxNum) {
          var numEntry = parseInt(tagParams.term, 10)
          var displayedNum = ('0' + numEntry).slice(-2)
          if (/^\d{1,2}$/.test(tagParams.term) && numEntry > 0 && numEntry <= maxNum) {
            return { id: numEntry, text: displayedNum }
          }
        }

        if ($ctrl.game.end_date_time) {
          var startTime = moment($ctrl.game.start_date_time)
          var endTime = moment($ctrl.game.end_date_time)
          var duration = moment.duration(endTime.diff(startTime))
          var durationHours = duration.hours()
          var durationMinutes = duration.minutes()
          $ctrl.game.duration_hours = durationHours
          $ctrl.game.duration_minutes = durationMinutes
          var hoursSelectOption = {
            id: durationHours,
            text: _.padLeft(durationHours, 2, 0)
          }
          var minutesSelectOption = {
            id: durationMinutes,
            text: _.padLeft(durationMinutes, 2, 0)
          }
          if (!_.findWhere(hours, hoursSelectOption)) {
            hours.push(hoursSelectOption)
          }
          if (!_.findWhere(minutes, minutesSelectOption)) {
            minutes.push(minutesSelectOption)
          }
        }

        $ctrl.validateField = function(form) {
          if ($ctrl.game.duration_hours == '0' && $ctrl.game.duration_minutes == '0' && !$ctrl.validationDisabled) {
            form.duration_hours.$setValidity('durationIsZero', false)
            form.duration_minutes.$setValidity('durationIsZero', false)
          }
          else {
            form.duration_hours.$setValidity('durationIsZero', true)
            form.duration_minutes.$setValidity('durationIsZero', true)
          }

          if ($ctrl.game.duration_hours == '24' && $ctrl.game.duration_minutes > '0' && !$ctrl.validationDisabled) {
            form.duration_hours.$setValidity('durationTooLong', false)
            form.duration_minutes.$setValidity('durationTooLong', false)
          }
          else {
            form.duration_hours.$setValidity('durationTooLong', true)
            form.duration_minutes.$setValidity('durationTooLong', true)
          }
        }

        $timeout(function() {
          var selectHours = angular.element('#duration_hours')
          var selectMinutes = angular.element('#duration_minutes')
          selectHours.triggerHandler('change') // removes Angular's unknownOption injected by <select>
          selectMinutes.triggerHandler('change') // removes Angular's unknownOption injected by <select>
          if ($ctrl.gameForm) {
            $ctrl.gameForm.$setPristine() // reverts the dirty state set by triggering the change event
          }
        }, 100)

      }
    }
  })
