require('team-admin')
angular.module('team-admin')

  .directive('teamSettings', function() {

    return {
      scope: {},
      bindToController: {
        team: '='
      },
      template: require('./team-settings.html'),
      controllerAs: 'ctrl',
      controller: function(_, $q, $scope, Alerts, AttachmentService, i18ng, rfFormFacade, setAs, fileHelper, setWhile) {

        var ctrl = this
        ctrl.submitTeam = submitTeam
        createForms()

        function createForms() {
          ctrl.forms = [new rfFormFacade.Form({
            id: 'team_info_form',
            name: i18ng.t('TEAM_SETTINGS.title'),
            model: ctrl.team
          },
          [
            {
              id: 'name',
              label: i18ng.t('TEAM_SETTINGS.LABELS.name'),
              required: true,
              properties: {
                max: 100
              },
              type: 'short_text',
              column: 0
            },
            {
              id: 'primary_color',
              label: i18ng.t('TEAM_SETTINGS.LABELS.primary_color'),
              type: 'color',
              column: 0
            },
            {
              id: 'team_logo',
              label: i18ng.t('TEAM_SETTINGS.LABELS.team_logo'),
              type: 'document_upload',
              properties: {
                url: _.get(ctrl.team, 'logo.image_urls.large_uncropped'),
                modal: {
                  title: i18ng.t('TEAM_SETTINGS.TEAM_LOGO.title')
                },
                clearWhenMatch: /organization_placeholder.svg$/,
                fileUploadOptions: {
                  accept: fileHelper.imageTypes,
                  allowCrop: false,
                  showSupports: true,
                  showFilename: true,
                  titleText: i18ng.t('TEAM_SETTINGS.TEAM_LOGO.title'),
                  clearLinkText: i18ng.t('TEAM_SETTINGS.TEAM_LOGO.clear_link'),
                  uploadLinkText: i18ng.t('TEAM_SETTINGS.TEAM_LOGO.upload_link'),
                  done: updateModel
                }
              },
              answer: function() {
                var logo = _.get(ctrl.team, 'logo.image_urls.large_uncropped') || ''
                return {
                  value: logo && {
                    url: logo,
                    crop_url: logo
                  },
                  label: _.last(logo.split('/'))
                }
              },
              column: 1
            }
          ])]
          ctrl.editMode = false
          ctrl.syncForms = true
        }

        function updateModel(imageData) {
          return $q.when(imageData?.file ? AttachmentService.uploadCallback(imageData.file) : { url: null })
            .then(attachment => ctrl.team.DSUpdate({ logo_image_key: null, remote_logo_url: attachment.url }))
            .then(() => Alerts.success(i18ng.t('TEAM_SETTINGS.TEAM_LOGO.logo_was_updated')))
        }

        function submitTeam(data, formCtrl) {
          if (_.isString(data.primary_color)) data.primary_color = data.primary_color.replace(/^#/, '')
          if (_.isString(data.secondary_color)) data.secondary_color = data.secondary_color.replace(/^#/, '')
          return $q.all([
            ctrl.team.DSUpdate(data)
              .then(setAs(ctrl, 'team'))
          ])
            .then(function() {
              createForms()
              Alerts.success('FORM_RESULT.success')
            })
            .catch(function(data, err) {
              var message = [].concat(_.get(err, 'data.error.messages')).join(', ')
              Alerts.error('FORM_RESULT.error' + (message ? '_message' : ''), { message: _.unescape(message) })
            })
        }
      }
    }
  })
