angular.module('pl-shared')

  // Strips protocol and leading www. if present:
  //   http://www.se.com    se.com
  //   https://foo.se.com   foo.se.com
  .filter('website', function() {
    return function(url) {
      return typeof url === 'string' ? url.replace(/^\w*:\/\/(www\.)?/, '') : ''
    }
  })

  // Adds http:// protocol if no other protocol is present:
  //   www.se.com           http://se.com
  //   https://foo.se.com   https://foo.se.com
  .filter('website_with_protocol', function() {
    return function(url) {
      if (typeof url !== 'string') return ''
      if (url.indexOf('://') === -1) url = 'http://' + url
      return url
    }
  })
