angular.module('pl-shared').filter('age', function() {
  return function(dob, targetDate) {
    if (!dob) return ''
    targetDate = targetDate ? new Date(targetDate) : new Date()
    var date = new Date(dob)
    var targetYear = targetDate.getFullYear()
    var birthYear = date.getFullYear()
    var age = targetYear - birthYear

    date.setYear(targetYear)
    if (date > targetDate) age -= 1
    return age
  }
})
