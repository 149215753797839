(function() {
  'use strict'

  require('team-admin')
  angular.module('team-admin')
    .directive('timePicker', function(moment) {

      return {
        restrict: 'E',
        replace: true,
        require: 'ngModel',
        template: require('./time-picker.html'),
        scope: {},
        link:  function($scope, $el, $attrs, ngModel) {

          var input = $el.find('input:first')
          var dropdown = input.data('timepicker-list')

          $el.removeAttr('id')

          $scope.attrs = $attrs
          $scope.temp = {}
          $scope.fallback = {
            date: moment().startOf('day')
          }

          function modelValueChange() {
            return ngModel.$modelValue
          }

          function tempDateChange() {
            return +$scope.temp.date
          }

          function setModelValue() {
            var date = $scope.temp.date
            var newVal = date && date.isValid() ? date.format('HH:mm') : null
            ngModel.$setViewValue(newVal)
          }

          function setTempDate(modelVal) {
            if (!modelVal) return $scope.temp.date = null
            var hoursAndMinutes = modelVal.split(':')
            var hours = parseInt(hoursAndMinutes[0], 10) || 0
            var minutes = parseInt(hoursAndMinutes[1], 10) || 0
            $scope.temp.date = $scope.temp.date ? $scope.temp.date.clone() : $scope.fallback.date.clone()
            $scope.temp.date
              .set('hours', hours)
              .set('minutes', minutes)
          }

          function setRequired(required) {
            $scope.requiredBool = !!required
          }

          $scope.$watch(modelValueChange, setTempDate)
          $scope.$watch(tempDateChange, setModelValue)
          $scope.$watch('attrs.required', setRequired)

          $scope.setTouched = function() { ngModel.$setTouched() }

        }
      }
    })

})()
