angular.module('pl-shared')
  .filter('dash_if_blank', function() {
    return function(input) {
      input = input || ''
      if (input === '') {
        input = '--'
      }
      return input
    }
  })
