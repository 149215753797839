'use strict'

angular.module('pl-shared')
  .directive('formatDate', function() {
    return {
      restrict: 'A',
      scope: {
        date: '=',
        format: '@',
        noDate: '=',
        tz: '=',
        timeFormat: '=',
        tbdTime: '=',
        unspecifiedTime: '=',
        unspecifiedTimeLabel: '='
      },
      template: '{{ formatted }}',
      controller: function($scope, $filter, moment, i18ng, timezone) {
        var zone
        var formats = {
          default: 'lll',
          12: 'h:mm a',
          24: 'HH:mm',
          calendar: function(date) {
            return date.calendar()
          },
          time: function(date) {
            var fmt = formats[$scope.timeFormat || '12']
            return date.format(fmt)
          },
          zone: function(date) {
            return zone.abbr(date)
          },
          compactTime: function(date) {
            return formats.time(date).replace(/(:00| |m$)/gi, '')
          },
          abbrevDateTime: function(date) {
            var formatted = date.format('ddd MMM D')
            if (!$scope.tbdTime) {
              formatted += ' @ ' + formats.compactTime(date)
            }
            return formatted
          }
        }

        function updateFormatted() {
          zone = $scope.tz ? moment.tz.zone($scope.tz) : timezone.current

          if ($scope.date) {
            if ($scope.unspecifiedTime) return $scope.formatted = i18ng.t($scope.unspecifiedTimeLabel)
            if ($scope.tbdTime) return $scope.formatted = i18ng.t('SCHEDULE.tbd_time')
            var date = moment($scope.date).tz(zone.name)
            var format = formats[$scope.format] || $scope.format || formats.default
            if (angular.isFunction(format)) $scope.formatted = format(date)
            else $scope.formatted = date.format(format)
          }

          else {
            if ($scope.noDate) $scope.formatted = i18ng.t($scope.noDate)
            else $scope.formatted = ''
          }
        }

        $scope.$watch('date', updateFormatted)
        $scope.$watch('format', updateFormatted)
        $scope.$watch('tz', updateFormatted)
        $scope.$watch('unspecifiedTime', updateFormatted)
        $scope.$watch('tbdTime', updateFormatted)
      }
    }
  })
