require('team-admin')
angular.module('team-admin')
  .factory('Contact', function(DS, Player) {
    var Contact = DS.defineResource({
      name: 'contacts',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      relations: {
        belongsTo: {
          players: {
            parent: true,
            localField: 'player',
            localKey: 'playerId'
          }
        }
      }
    })

    return Contact
  })
