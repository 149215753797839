angular.module('pl-shared')
  .filter('displayCurrency', function($filter) {
    return function(amount, options) {
      if (amount === '--') return amount
      options = options || {}

      // use displayZeroAs option to render different text in case of a zero amount
      if (options.displayZeroAs !== undefined && amount == 0) return options.displayZeroAs

      // Amounts can be displayed as negative even if they aren't represented
      // that way in the data by passing the 'isNegative:true' in options
      var isNegative = options.negative || (amount < 0)
      if (amount == 0) isNegative = false

      // Because negative amounts are shown in parenthesis, we don't want a leading
      // '-' on negative amounts, so it is converted to a positive number
      amount = Math.abs(amount)

      if (options.inputCents) {
        amount /= 100
      }

      var currencySymbol = options.currencySymbol || '$'

      var formattedAmount = $filter('currency')(amount, currencySymbol)

      var displays = {
        endUser: '–' + formattedAmount,
        accountant: '(' + formattedAmount + ')'
      }

      var defaultDisplay = displays.accountant

      if (isNegative) {
        return options.display ? displays[options.display] : defaultDisplay
      }
      else {
        return formattedAmount
      }
    }
  })
