angular.module('pl-shared')

  .factory('Organization', function(DS, ENV, Geocoder, moment, apiV2, $http, _, payload) {

    var DEFAULT_TIMEZONE = 'America/New_York'
    var apiUrl = DS.adapters.se_api.defaults.basePath

    var Organization = DS.defineResource({
      name: 'organizations',
      basePath: apiUrl,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      computed: {
        siteUrl: [
          function() { return ENV.urls.siteRedirect + '/' + this.id }
        ],
        sportsengineUrl: [
          function() { return ENV.urls.sportsengine + '/org/' + this.slug  }
        ]
      },
      actions: {
        send_claim_invite: {
          method: 'GET'
        },
        mine: {
          method: 'GET',
          response: apiV2.deserialize
        }
      },
      methods: {
        // formats a utc dateTime in orgs timezone
        formatDateTime: function(dateTime, _fmt) {
          var fmt = _fmt || 'MMM D, YYYY'
          var tz = this.timezone || DEFAULT_TIMEZONE
          return moment(dateTime).tz(tz).format(fmt)
        },
        // Get the root org for an organization (if they have the necessary FT/product) for use on admin mgmt screens
        rootOrgForAdminManagement: function() {
          if (hasAffiliationsEnabled()) {
            return this.root_organizations.length ? this.root_organizations[0] : this
          }
          else {
            return undefined
          }
        }
      }
    })

    function hasAffiliationsEnabled() {
      return !!_.result(payload.featureToggles, 'affiliate_rostering') || hasProduct(payload.organizationProducts, 'affiliations')
    }

    function hasProduct(organization_products, product_key) {
      return !!_.find(organization_products, function(organization_product) {
        return organization_product.product_key === product_key && organization_product.status === 'active'
      })
    }

    Organization.index = function(orgIds) {
      var joinedOrgIds = ''
      if (orgIds) joinedOrgIds = orgIds.join(',')
      return $http({
        method: 'GET',
        url: apiUrl + '/organizations_public',
        params: {
          'filter_by_ids': joinedOrgIds
        },
        headers: apiV2.headers,
        withCredentials: true,
      })
        .then(apiV2.deserialize)
    }

    Organization.findPublic = function(orgId) {
      var opt = arguments[1] || {}
      var defaultOpt = {
        method: 'GET',
        url: apiUrl + '/organization_public/' + orgId,
        headers: apiV2.headers,
        withCredentials: true,
      }
      opt = angular.merge({}, defaultOpt, opt)

      return $http(opt)
        .then(apiV2.deserialize)
    }

    Organization.indexPublic = function(orgIds) {
      return Organization.index(orgIds)
    }

    Organization.getFeatureToggles = function(orgId) {
      return $http({
        method: 'GET',
        url: apiUrl + '/organizations/' + orgId + '/feature_instances?context_type=Organization',
        headers: apiV2.headers,
        withCredentials: true,
      })
        .then(apiV2.deserialize)
    }

    Organization.hasFeatureToggleEnabled = function(orgId, featureToggleKey) {
      return Organization.getFeatureToggles(orgId)
        .then(function(toggles) {
          var toggle = _.find(toggles, { key: featureToggleKey })
          return toggle && toggle.enabled
        })
        .catch(function(err) {
          return false
        })
    }

    Geocoder.bindToResource(Organization)
    return Organization
  })

  .factory('currentOrg', function(_, $rootScope, $route, $routeParams, $log, Organization, routeTitle) {
    var currentOrgId
    var currentOrg = $rootScope.constructor.prototype.currentOrg = Organization.createInstance({ $loading: true })

    function setCurrentOrg(newOrg) {
      if (currentOrg.id) Organization.eject(currentOrg)
      Organization.eject(newOrg)
      Organization.inject(_.extend(currentOrg, newOrg))
      Organization.currentOrg = currentOrg
      routeTitle.setParams({
        org_name: currentOrg.name
      })
      return currentOrg
    }

    function orgError(err) {
      $log.error(err)
      currentOrg.$error = err
    }

    function done(org) {
      delete currentOrg.$loading
      $rootScope.$emit('currentOrg:change', currentOrg)
    }

    function update() {
      var newOrgId = $routeParams.currentOrgId

      // newOrgId may be undefined during redirects
      if (!newOrgId || newOrgId === currentOrgId) return

      // some modules (provisioning) need this right away
      currentOrgId = newOrgId
      setCurrentOrg({ id: parseInt(newOrgId, 10) })

      Organization.find(currentOrgId, { bypassCache: true, params: { include: 'root_organizations' } })
        .then(setCurrentOrg)
        .catch(orgError)
        .finally(done)
    }

    $rootScope.$on('$routeChangeSuccess', update)
    if ($route.current) update()

    return currentOrg
  })

  .run(function(currentOrg) {})
