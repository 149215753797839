angular.module('pl-shared')
  .provider('seBar', function SeBarProvider($windowProvider) {
    var seBarProvider = this
    var win = $windowProvider.$get()
    var config = win.nginBarConfig = {
      ccpa: true,
      on: { init: function(instance) { seBarProvider.instance = instance } }
    }

    registerDefaultSetters([
      'app',
      'orgId',
      'teamId',
      'theme',
      'pageMode'
    ])

    this.setMobileNav = function(mobileNav) {
      config.mobileNavToggle = mobileNav.toggle
      config.mobileNavClose = mobileNav.close
    }

    function registerDefaultSetter(varKey) {
      var setterKey = 'set' + varKey.charAt(0).toUpperCase() + varKey.slice(1)
      seBarProvider[setterKey] = function(val) {
        (seBarProvider.instance || config)[varKey] = val
      }
    }

    function registerDefaultSetters(setterKeys) {
      var i, key
      for (i = 0; i < setterKeys.length; i++) {
        key = setterKeys[i]
        registerDefaultSetter(key)
      }
    }

    this.$get = [function() {
      return seBarProvider
    }]

  })
