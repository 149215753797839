require('team-admin')
angular.module('team-admin')

  .component('teamLeftNav', {
    bindings: {
      team: '<',
      teamPageUrl: '<',
      userUnreadObject: '<'
    },
    controllerAs: '$ctrl',
    template: require('./team-left-nav.html'),

    controller: function(i18ng, $scope, $route, ENV, featureToggles, launchDarklyFlags, renderContext, SNAP, $compile, $element, $timeout) {

      var $ctrl = this

      $ctrl.seLogo = SNAP.assetPath + '/images/sportsengine-logo-color-black.svg'
      $ctrl.subHeader = $ctrl.team.activeSeason().name
      $ctrl.seLeftNavTree = []

      $ctrl.$onInit = function() {
        $ctrl.seLeftNavTree = [
          { label: i18ng.t('SCHEDULE.title'), gaLabel: 'Schedule', attributes: { gotoContext: 'team.schedule' } },
        ]

        if (!!launchDarklyFlags.newTeamCenterEnabled) { $ctrl.seLeftNavTree.push({ label: i18ng.t('ROSTER.title'), gaLabel: 'Roster', attributes: {
          href: `${ENV.urls.newTeamCenter}/team/${$ctrl.team.id}/roster`} })
        }
        else {
          $ctrl.seLeftNavTree.push({ label: i18ng.t('ROSTER.title'), gaLabel: 'Roster', attributes: { gotoContext: 'team.roster.players' } })
        }

        if (featureToggles.next_generation_messaging) {
          var indicator = $ctrl.userUnreadObject?.hasUnread && !renderContext.team.chat
          $ctrl.seLeftNavTree.push({ label: i18ng.t('CHAT.title'), gaLabel: 'Chat', attributes: { gotoContext: 'team.chat' }, indicatorDot: indicator })
        }

        if (featureToggles.squadlocker_integration && $ctrl.team.canManage() && $ctrl.team.nginTeamId) {
          $ctrl.seLeftNavTree.push({ label: i18ng.t('SQUAD_LOCKER.title'), gaLabel: 'ApparelStore', attributes: { gotoContext: 'team.squadLocker' } })
        }
  
        if ($ctrl.team.canManageTeamSettings()) {
          $ctrl.seLeftNavTree.push({ label: i18ng.t('TEAM_SETTINGS.title'), gaLabel: 'Settings', attributes: { gotoContext: 'team.settings' } })
        }
        
        $timeout(function appendDisplayStatus() {
          if ($element.find('#display_status').length) return
          if (!$element.find('.se-left-nav-context__subheader').length) return $timeout(appendDisplayStatus, 200)
          $compile(`
            <div id="display_status" class="{{ $ctrl.team.status }} {{ $ctrl.team.display_status }}">
              {{ $ctrl.team.status === 'inactive' ? $ctrl.team.status : $ctrl.team.display_status }}
            </div>
          `)($scope).appendTo('.se-left-nav-context__subheader')
        })
      }

      $ctrl.$onChanges = function() {
        $ctrl.seLeftNavFooters = [
          ...($ctrl.teamPageUrl ? [{ label: i18ng.t('TEAM.go_to_team_page'), gaLabel: 'TeamPage', attributes: { href: $ctrl.teamPageUrl } }] : [])
        ]
      }

      setSelectedItem()

      $scope.listenToRoot('$locationChangeSuccess', setSelectedItem)

      function setSelectedItem() {
        _.forEach($ctrl.seLeftNavTree, function(navItem) {
          var currentContext = navItem.attributes.gotoContext
          navItem.isSelected = $route.current.context.includes(currentContext)
        })

        // Notify the component of the change
        document.dispatchEvent(
          new CustomEvent(
            'se-left-nav.triggerTreeChanges',
            { detail: { detectChanges: false } }
          )
        )

      }

    }

  })
