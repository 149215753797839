(function() {

  var $ = angular.element
  var html = $('html')
  var hasTouch = 'ontouchstart' in document.documentElement
  var mediaSizes = ['phone', 'tablet', 'desktop', 'hd']

  if (hasTouch) html.addClass('pl-has-touch')

  angular.module('pl-shared')

    .factory('ux', function(_, $window, $rootScope) {

      var breakpoints = parseSassMap('pl-grid-values')
      var breakpointKeys = _.keys(breakpoints)
      var breakpointValues = _.values(breakpoints)
      var $main = $('#App')
      var scrollTop = 0
      var scrollLeft = 0

      var ux = $rootScope.constructor.prototype.ux = {
        atMedia: {},
        breakpoints: breakpoints,
        hasTouch: hasTouch,
        lockScrollToTop: lockScrollToTop,
        unlockScroll: unlockScroll
      }

      setMedia()
      $($window).on('resize', setMedia)

      return ux

      // `parseSassMap` -- Reads a hash-like font-family value from a specific tag
      // as a hacky way to pass a hash variable from a css file. This prevents the
      // JS and SASS variables from getting out of sync. Browsers mangle the $map
      // variables a bit, so CLEAN_RX removes soem of the extra garbage before we
      // parse it into a JS hash.

      // Examples:
      // sass: `foo-values { font-family: $map; }`
      // css:  `foo-values { font-family: "($key: 0, $key2: 50px)"; }`

      function parseSassMap(_tag) {
        var CLEAN_RX = /["';(){}]/g
        var obj = {}
        var tag = $('<' + _tag + '/>').appendTo('body')
        var text = tag.css('font-family').replace(CLEAN_RX, '').replace(/\s$/, '')
        tag.remove()

        _.each(text.split(', '), function(str) {
          var values = str.split(': ')
          obj[values[0]] = parseInt(values[1], 10)
        })

        return obj
      }

      // Use this to prevent strange issues on iOS where modals jump around.
      function lockScrollToTop() {
        scrollTop = $main.scrollTop
        scrollLeft = $main.scrollLeft
        $main.scrollTop(0).scrollLeft(0)
      }

      function unlockScroll() {
        if (!scrollLeft && !scrollTop) return
        $main.scrollTop(scrollTop).scrollLeft(scrollLeft)
      }

      // Sets properties like `ux.atMedia.tablet`, `ux.atMedia.tabletAndAbove`,
      // `ux.atMedia.tabletAndBelow`, 'ux.belowMedia.tablet', 'ux.aboveMedia.tablet'
      function setMedia() {
        var oldMedia = ux.media
        var at = ux.atMedia = {}
        var above = ux.aboveMedia = {}
        var below = ux.belowMedia = {}
        var alreadyMatched

        for (var i = 0; i < breakpointValues.length; i++) {
          var w = window.innerWidth
          var media = breakpointKeys[i]
          var width = breakpointValues[i]
          var nextWidth = breakpointValues[i + 1] || Infinity
          var atMedia = w >= width && w < nextWidth
          var belowMedia = w < width
          var aboveMedia = !atMedia && !belowMedia

          if (atMedia) ux.media = media

          at[media] = atMedia
          at[media + 'AndBelow'] = !aboveMedia
          above[media] = aboveMedia
          at[media + 'AndAbove'] = !belowMedia
          below[media] = belowMedia
        }

        if (!$rootScope.$$phase && ux.media !== oldMedia) $rootScope.$digest()
      }

    })

    .run(function(ux) { }) // preload to ensure our resize handler fires first

})()
