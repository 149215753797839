angular.module('pl-shared')

  .directive('phoneQuestionView', function($filter) {

    return {
      scope: {},
      template: require('/static/shared/components/regform/questions/phone-question-view.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: function() {
        var ctrl = this
        var answer = ctrl.answer || {}
        ctrl.displayValue = '--'
        if (answer.display_value) {
          ctrl.displayValue = $filter('tel')(answer.display_value)
        }
      }
    }

  })

