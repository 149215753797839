require('team-admin')

function addKarmaBase(url) {
  if (typeof window.__karma__ !== 'undefined') return '/base' + url
  return url
}

angular.module('team-admin')
  .config(function(i18ngProvider) {
    i18ngProvider.init({
      getAsync: false,
      lng: 'en',
      fallbackLng: 'en',
      ns: { namespaces: ['teams', 'shared'], defaultNs: 'teams' },
      fallbackNS: 'shared',
      useCookie: false,
      useLocalStorage: false,
      resGetPath: addKarmaBase('/static/__ns__/locales/__lng__/translation.json')
    })
  })
