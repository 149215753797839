angular.module('pl-shared')
  .factory('globalSessionInterceptor', function($cookies) {
    return {
      request: function(config) {
        if (!_.startsWith(config.url, '/api')) return config

        var sessionCookie = $cookies.get('sportngin_session')
        if (sessionCookie) _.set(config, 'headers.X-SPORTNGIN-SESSION', sessionCookie)

        return config
      }
    }
  })

