angular.module('pl-shared')

  .service('RosteringPersonas', function(DS, paginate, Player) {
    var RosteringPersonas = DS.defineResource({
      name: 'rosteringPersonas',
      endpoint: 'rostering_personas',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      computed: {
        full_name: [
          'name', 'first_name', 'last_name',
          function(name, first_name, last_name) {
            return name || (first_name + ' ' + last_name)
          }
        ],
        is_orphan: [
          'persona_type_id',
          function(persona_type_id) { return persona_type_id === 7 }
        ]
      },
      relations: {
        hasMany: {
          players: {
            localField: 'roster_players',
            foreignKey: 'persona_id'
          }
        }
      }
    })

    return paginate(RosteringPersonas)
  })
