angular.module('pl-shared')
  .service('debounceCallback', function($q) {

    /**
     * debounceCallback takes in a function that returns a promise, and returns a function that will also return a
     * promise, but if the function is called twice before the first call's promise resolves, then the promise returned
     * by debounceCallback will not resolve when the first call's promise resolves.
     *
     * For example, say you have a request that happens every time you type a letter into a search field, and its
     * .then() callback updates a list. You type in one letter, and the request takes a long time to finish.
     * During that time, you type in another letter, and that new request starts and finishes before the first request
     * finishes. In this case, your list would update, but it would show the results of the first request.
     *
     * @param {*} actionFnc - The async function to debounce.
     * @returns {*} - The debounced function.
     */
    return function debounceCallback(actionFnc) {

      if (typeof actionFnc !== 'function') throw new Error('`debounceCallback` requires a function as the first argument.')

      var cancelPrevious // this lives from call to call of the actionFnc

      return function _debouncedCallback() {

        var canceled = false // this is constrained to only this call

        if (cancelPrevious) cancelPrevious() // this triggers the local canceled state on the previous call
        cancelPrevious = function() { canceled = true } // this creates a new trigger for this call, and links the local var to the closure-scoped var

        // wrap the actionFnc promise
        var dfd = $q.defer()

        // we don't want to resolve or reject if the request was canceled before returning
        $q.when(actionFnc.apply(this, arguments))
          .then(function(ret) { if (!canceled) dfd.resolve(ret) })
          .catch(function(ret) { if (!canceled) dfd.reject(ret) })

        // return the wrapped promise
        return dfd.promise

      }

    }

  })
