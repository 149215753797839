angular.module('pl-shared')

  .directive('documentUploadQuestionView', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/document-upload-question-view.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: function($scope, dialog, fileHelper) {

        var ctrl = this

        var model = ctrl.answer ? ctrl.answer.value : {}
        ctrl.model = fileHelper.parseUrl(model.url, model.crop_url)

        ctrl.fileHelper = fileHelper

        ctrl.viewImage = function(event) {
          event.stopPropagation()
          dialog.confirm({
            directive: 'image-preview-modal',
            scope: $scope,
            attrs: {
              imgUrl: ctrl.model.url,
              imgName: ctrl.model.filename
            }
          })
        }

      }
    }

  })
