angular.module('pl-shared')
  .factory('Geocoder', function(_, $http, $q) {

    var GOOGLE_APIS_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
    var ADDRESS_FIELDS = ['address', 'address_1', 'address_2', 'city', 'state', 'postal_code', 'country']

    return {
      addressData: addressData,
      ADDRESS_FIELDS: ADDRESS_FIELDS,
      addressByData: addressByData,
      bindToResource: bindToResource,
      geocodeByAddress: geocodeByAddress,
      geocodeByZipcode: geocodeByZipcode,
      geocodeByData: geocodeByData
    }

    function addressData(newData, oldData) {
      return _.defaults(_.pick(newData, ADDRESS_FIELDS), _.pick(oldData, ADDRESS_FIELDS))
    }

    function addressByData(data) {
      return _.compact(_.map(ADDRESS_FIELDS, function(key) { return data[key] })).join(' ')
    }

    function geocodeByAddress(address) {
      return $http
        .get(GOOGLE_APIS_URL + '?address=' + encodeURIComponent(address))
        .then(resultsIfStatusOK)
    }

    function geocodeByData(data) {
      return geocodeByAddress(addressByData(data))
    }

    // Country is not mandatory, but it narrows the search and prevent incorrect locations
    function geocodeByZipcode(zipcode, country) {
      var address = zipcode
      if (country) address += ' ' + country
      return geocodeByAddress(address)
    }

    function resultsIfStatusOK(response) {
      var ok = response && response.data.status === 'OK'
      return ok ? response.data.results : $q.reject()
    }

    // HELPER FOR SAVING RESOURCES WITH ADDRESS DATA
    function bindToResource(Resource, options) {
      options = options || {}
      Resource.saveAndSyncLocation = saveAndSyncLocation
      return Resource

      function saveAndSyncLocation(newData, originalData) {
        newData = angular.copy(newData)
        originalData = originalData || {}

        if (options.beforeSave) options.beforeSave(newData, originalData)
        if (!addressByData(newData)) return save()

        var mergedData = addressData(newData, originalData)
        var newAddress = addressByData(mergedData)
        var oldAddress = addressByData(originalData)

        return newAddress === oldAddress ? save() : updateLocationAndSave()

        function updateLocationAndSave() {
          return geocodeByData(mergedData)
            .then(setLocation, clearLocation)
            .then(save, save)
        }

        function setLocation(res) {
          var location = _.get(res, '[0].geometry.location') || {}
          newData.service_latitude = location.lat || null
          newData.service_longitude = location.lng || null
          // TODO: potentially use googles result for other address components (to normalize data)
          // e.g. google timezone api: https://developers.google.com/maps/documentation/timezone/intro
        }

        function clearLocation() {
          newData.service_latitude = null
          newData.service_longitude = null
        }

        function save() {
          return originalData.id ? Resource.update(originalData.id, newData) : Resource.create(newData)
        }
      }
    }

  })
