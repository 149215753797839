require('team-admin')
angular.module('team-admin')
  .directive('changeOpponent', function() {
    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '='
      },
      template: require('./change-opponent.html')
    }
  })
