require('team-admin')
angular.module('team-admin')
  .directive('messagesEnabledStatus', function(i18ng) {

    var strings = i18ng.t('PLAYERS.MESSAGES_ENABLED', { returnObjectTrees: true })
    var classes = {
      true: 'ta-messages-opted-in',
      false: 'ta-messages-opted-out',
      sent: 'ta-messages-pending'
    }

    return {
      restrict: 'A',
      scope: {
        player: '=',
        messagesEnabled: '=',
        loading: '='
      },
      template: '<svg class="pl-spinner--inline" ng-if="loading">\
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-spinner"></use>\
                </svg>\
                <div ng-if="player.invitation.status !== \'uninvited\' && !loading" class="{{ classes[status] }}">\
                  {{ strings[status] }}\
                </div>',
      controller: function($scope) {
        $scope.strings = strings
        $scope.classes = classes
        $scope.$watchGroup(['player', 'messagesEnabled'], function() {
          if ($scope.loading || !$scope.player) return
          // Use Pending for sent and NOT opted-out, mapped strings for everything else
          $scope.status = $scope.messagesEnabled
          if ($scope.messagesEnabled !== false && $scope.player.invitation.status === 'sent') $scope.status = 'sent'
        })
      }
    }
  })
