require('team-admin')
angular.module('team-admin')
  .component('eventLocation', {
    bindings: {
      event: '<',
      team: '<',
      eventForm: '<'
    },
    template: require('./event-location.html'),
    controller: function($scope, $q, i18ng, setWhile, Subvenue, Venue, wrapDigest) {
      var ctrl = this

      ctrl.$onInit = setWhile(ctrl, 'locationsLoading', function() {
        $scope.event = ctrl.event // set event for google-places directive
        ctrl.syncVenue = _.partial(Venue.syncFields, ctrl.event)
        ctrl.syncSubvenue = _.partial(Subvenue.syncFields, ctrl.event)

        var params = [{ org_id: ctrl.team.org_details.id }, { load: 'all' }]
        return $q.all([Venue.findAll(...params), Subvenue.findAll(...params)])
          .then(function([venues, subvenues]) {
            ctrl.venues = _.sortBy(venues, 'name')
            ctrl.venues.unshift({
              id: -1,
              name: i18ng.t('New Location')
            }, {
              id: null,
              name: i18ng.t('TBD')
            })
            ctrl.subvenuesByVenueId = _.groupBy(_.sortBy(subvenues, 'name'), 'venue_id')
          })
      })

      ctrl.getOptgroup = function(venue) {
        return venue.id > 0 ? i18ng.t('Existing Locations') : venue.name
      }

      ctrl.checkEmptyAddress = wrapDigest(({ target: { value } }) => {
        if (!value) _.extend(ctrl.event, { location_address: '', location_name: '', location_place_id: null, place: null })
      })

      $scope.$watch('$ctrl.event.place', function(newPlace, oldPlace) {
        if (newPlace === oldPlace) return
        ctrl.event.location_address = newPlace?.formatted_address || ''
        ctrl.event.location_name = newPlace?.name || ''
      })

    }
  })
