angular.module('pl-shared')
  .factory('gtm', function(_, $window, $rootScope) {

    var dataLayer = $window.dataLayer = $window.dataLayer || []
    var variables = {}
    var dataLayerReady = false

    return {
      addVariables: addVariables,
      dataLayer: dataLayer,
      gaEvent: gaEvent,
      virtualPageView: virtualPageView,
      initDataLayerOnUpdate: initDataLayerOnUpdate,
      // The methods below belong somewhere else. This service is for interacting with GTM, not business logic. :rage:
      getPersonaFiltersString: getPersonaFiltersString,
      getCategoryString: getCategoryString
    }

    function virtualPageView(title) {
      dataLayer.push({
        event: 'pageview',
        page: 'virtual-pageview',
        title: title
      })
      resetDataLayerProperties('page', 'title')
    }

    // Send a custom event to google analytics (cofigured through GTM)
    function gaEvent(category, action, label, value, nonInteractionHit) {
      if (!(action || category || label)) return // at least one of these is required to be useful

      var hasValue = arguments.length > 3
      var hasNonInteractionHit = arguments.length > 4
      var reset = undefined
      var opts = {
        event: 'gaEvent',
        gaAction: action || reset,
        gaCategory: category || reset,
        gaLabel: label || reset,
        gaValue: hasValue ? value : reset,
        gaNonInteractionHit: hasNonInteractionHit ? nonInteractionHit : reset
      }

      dataLayer.push(opts)
      resetDataLayerProperties('gaAction', 'gaCategory', 'gaLabel', 'gaValue', 'gaNonInteractionHit')
    }

    function addVariables(vars) {
      _.extend(variables, vars)
    }

    function initDataLayerOnUpdate() {
      dataLayerReady = true
    }

    function resetDataLayerProperties() {
      if (!arguments.length) return
      var args = Array.prototype.slice.call(arguments)
      var reset = {}
      while (args.length) {
        reset[args.pop()] = undefined
      }
      dataLayer.push(reset)
    }

    // Functions below likely belong somewhere else... I'm moving them here
    // and leaving this comment as a passive aggressive warning to others not
    // to add business logic to this service.

    function getPersonaFiltersString(rosteringStateObject) {
      var personaFiltersApplied = rosteringStateObject
      if (personaFiltersApplied.advanced === null) {
        personaFiltersApplied = _.omit(personaFiltersApplied, 'advanced')
      }
      personaFiltersApplied = Object.keys(_.omit(personaFiltersApplied, 'survey')).join(', ')
      return personaFiltersApplied
    }

    function getCategoryString(rosteringstate) {
      var gtmCategory = ''

      if (rosteringstate.type == 'coach') {
        gtmCategory = 'Backoffice.Rostering.Staff'
      }
      else if (rosteringstate.type == 'player') {
        gtmCategory = 'Backoffice.Rostering.Player'
      }
      else if (rosteringstate.transferable == true) {
        gtmCategory = 'Backoffice.Rostering.Transfer'
      }
      return gtmCategory
    }

  })
