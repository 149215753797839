require('team-admin')
angular.module('team-admin')
  .directive('inviteModal', function(Alerts, i18ng) {
    return {
      restrict: 'A',
      scope: {
        player: '=',
        team: '=',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      template: require('./invite-modal.html'),
      controller: function($scope, TeamInvite) {

        $scope.createOrphanInvite = function(player, team) {
          $scope.inviting = true
          var orgId = team.org_details.id

          return TeamInvite.create({
            email_address: player.email
          }, {
            endpoint: `v3/roster_personas/${ player.id }/invitations`
          })
            .then(inviteSuccess, inviteError)
        }

        $scope.confirmMessage = 'ROSTER.INVITE.confirm'

        function inviteSuccess(data) {
          Alerts.success('ROSTER.INVITE.MESSAGE.send_success', { player: $scope.player.fullName() })
          $scope.inviting = false
          $scope.player.invitation = {
            status: 'sent',
            ..._.pick(data, 'email_address', 'last_sent_at')
          }
          $scope.confirm()
        }

        function inviteError() {
          $scope.inviting = false
          $scope.failed = true
          $scope.confirmMessage = 'ROSTER.INVITE.retry'
          $scope.playerInviteForm.$setValidity()
        }

        $scope.dialogOptions.preCloseCallback = function() {
          if ($scope.inviting) return false
        }

      }
    }
  })
