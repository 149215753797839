require('team-admin')
angular.module('team-admin')
  .factory('SeasonTeam', function(DS, paginate) {
    return paginate(DS.defineResource({
      name: 'season_teams',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
    }))
  })
