angular.module('pl-shared')
  .factory('Venue', function($q, apiV2, DS, localRelations, paginate, Subvenue) {
    var Venue = DS.defineResource({
      name: 'venues',
      endpoint: 'venues',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      relations: {
        hasMany: {
          subvenues: {
            foreignKey: 'venue_id',
            localField: 'subvenues'
          }
        }
      },
      computed: {
        addressString: ['address', a => a ? _.compact([a.address_1, a.address_2, a.city, a.state]).join(', ') : '']
      }
    })

    Venue.check = function(event, team) {
      if (!(event.venue_id < 0)) return $q.when(event)
      var addrPart = part => _.find(event.place?.address_components, c => _.contains(c.types, part))?.short_name
      return Venue.create({
        org_id: team.org_details.id,
        name: event.location_name,
        website: event.place?.website || '',
        address: {
          address_1: `${ addrPart('street_number') } ${ addrPart('route') }`,
          city: addrPart('locality'),
          state: addrPart('administrative_area_level_1'),
          country: addrPart('country'),
          postal_code: _.compact([addrPart('postal_code'), addrPart('postal_code_suffix')]).join('-')
        }
      })
        .then(venue => Subvenue.create({
          venue_id: venue.id,
          name: event.subvenue_name || venue.name
        }))
        .then(subvenue => Venue.syncFields(_.extend(event, { venue_id: subvenue.venue_id, subvenue_id: subvenue.id })))
    }

    Venue.syncFields = function(event) {
      var venue = event.venue
      _.extend(event, {
        location_name: venue?.name || '',
        location_address: venue?.addressString || '',
        location_url: venue?.website || '',
        subvenue_id: event.subvenue_id || null
      })
      Subvenue.syncFields(event)
      return event
    }

    return paginate(localRelations(Venue))
  })
