angular.module('pl-shared')

  .service('selectHelper', function(_, keyboardHelper) {
    /**
     * Creates a helper object to manage the selected items in a collection
     *
     * @param {Object} scope - Angular scope of the component where the helper is used
     * @param {string} itemsKey - Angular expression which is used to detect mutation in the collection
     * @param {Object} [opts] - additional options. Accepts the following parameters (all are optional):
     * @param {Object} [opts.initialSelection] - items which are selected by default
     * @param {boolean} [opts.preserveSort=false] - enable the parameter to sort data in SelectHelper object each time when these are modified
     * @param {Object} [opts.strategy=selectHelper.STRATEGY.Stateless] - the parameter is used to enable/disable mode to store the selected items when the collection doesn't contain all items and they are loaded partially (for example for the current page in a data grid). Use selectHelper.STRATEGY.Stateful value if want to store items.
     *
     *  Sample:
     *
     *    selectHelper.bind($scope, 'ctrl.contacts', { strategy: selectHelper.STRATEGY.Stateful })
     *
     * @constructor
     */
    function SelectHelper(scope, itemsKey, opts) {
      var CurrentStrategy = opts.strategy || StrategyStateless

      this.strategy = new CurrentStrategy()
      this.data = []
      this.scope = scope
      this.items = []
      this.watch(itemsKey)
      this.opts = opts
      if (opts.initialSelection) this.setData(opts.initialSelection)
      // public methods are bound onto the data array (which gets returned)
      this.data.toggleFn = this.toggleFn.bind(this)
      this.data.toggle = this.toggle.bind(this)
      this.data.contains = this.contains.bind(this)
      this.data.selectAll = this.selectAll.bind(this)
      this.data.clearAll = this.clearAll.bind(this)
      this.data.allSelected = this.allSelected.bind(this)
      this.data.someSelected = this.someSelected.bind(this)
      this.data.deselectedItems = this.deselectedItems.bind(this)
    }

    SelectHelper.prototype.watch = function(itemsKey) {
      // TODO: add additional watcher (observable) strategies here in case this.scope isn't a scope (i.e. Angular 2)
      this.items = this.scope.$eval(itemsKey) || []
      this.scope.$watchCollection(itemsKey, function(items) {
        this.items = items
        this.setData(this.strategy.getDataOnChange(this.data, items))
      }.bind(this))
    }

    SelectHelper.prototype.sortData = function() {
      if (this.opts.preserveSort) {
        this.data.sort(function(a, b) {
          var ia = this.items.indexOf(a)
          var ib = this.items.indexOf(b)
          return (ia > ib) - (ib > ia)
        }.bind(this))
      }
    }

    SelectHelper.prototype.setData = function(data) {
      data = this.strategy.prepareData(data, this.getTotal())
      this.data.length = 0

      // There's a javascript argument size limit that will throw a 'maximum call stack size exceeded' error.
      // This will avoid that issue with large arrays
      while (data.length) {
        this.data.push(...data.splice(0, 50000))
      }

      this.sortData()
    }

    SelectHelper.prototype.toggle = function(item, checked) {
      var set = arguments.length > 1
      var selectedIndex = this.data.indexOf(item)
      var selected = selectedIndex >= 0

      if (!set) return selected

      if (typeof checked !== 'boolean') checked = checked.target.checked // if $event passed in

      // don't select items not in the source array
      if (checked && this.items.indexOf(item) < 0) return

      // use target checked value for valid multi-clicks
      var targetChecked = this.data.indexOf(this.target) >= 0
      var multiClick = keyboardHelper.SHIFT && this.lastClicked && this.target
      if (multiClick) checked = targetChecked
      else this.target = this.lastClicked = null

      if (this.target) this.toggleMany(item, checked)
      else this.toggleOne(item, checked, selectedIndex, selected)

      // prep toggle many vars for next click
      this.target = keyboardHelper.SHIFT && this.target || item
      this.lastClicked = item

      return checked
    }

    SelectHelper.prototype.toggleOne = function(item, checked, selectedIndex, selected) {
      if (checked && !selected) {
        this.data.push(item)
        this.strategy.itemsAdded([item])
      }
      else if (!checked && selected) {
        var removedItem = this.data.splice(selectedIndex, 1)
        this.strategy.itemsRemoved(removedItem)
      }
      this.sortData()
    }

    SelectHelper.prototype.toggleMany = function(item, checked) {
      var items = this.items
      var targetIndex = items.indexOf(this.target)
      var itemIndex = items.indexOf(item)
      var lastIndex = items.indexOf(this.lastClicked)
      var maxIndex = Math.max(targetIndex, itemIndex)
      var minIndex = Math.min(targetIndex, itemIndex)
      var primaryItems = items.slice(minIndex, maxIndex + 1)
      var secondaryItems = []

      if (lastIndex > maxIndex) secondaryItems = items.slice(maxIndex + 1, lastIndex + 1)
      else if (lastIndex < minIndex) secondaryItems = items.slice(lastIndex, minIndex)

      var selectItems = checked ? primaryItems : []
      var deselectItems = checked ? secondaryItems : primaryItems

      this.strategy.itemsAdded(selectItems)
      this.strategy.itemsRemoved(deselectItems)

      this.setData(_.uniq(_.difference(this.data, deselectItems).concat(selectItems)))
    }

    SelectHelper.prototype.toggleFn = function(item) {
      return this.toggle.bind(this, item)
    }

    SelectHelper.prototype.allSelected = function(filter) {
      return this.strategy.allSelected(this.data, this.items, filter)
    }

    SelectHelper.prototype.someSelected = function(filter) {
      return this.strategy.someSelected(this.data, this.items, filter, this.getTotal())
    }

    SelectHelper.prototype.selectAll = function(filter) {
      this.strategy.selectAllEnabled()
      this.setData(_.uniq(this.data.concat(_.filter(this.items, filter))))
      this.lastClicked = this.target = null
      return true
    }

    SelectHelper.prototype.clearAll = function(filter) {
      this.strategy.clearAll()
      this.setData(_.reject(_.filter(this.data), filter))
      this.lastClicked = this.target = null
      return false
    }

    SelectHelper.prototype.contains = function(item) {
      return this.data.indexOf(item) >= 0
    }

    SelectHelper.prototype.getTotal = function() {
      return this.items && this.items.pagination ? this.items.pagination.total : -1
    }

    SelectHelper.prototype.deselectedItems = function() {
      return this.strategy.getDeselectedItems()
    }

    // helper functions
    function inherit(base, child, properties) {
      properties = properties || {}
      if (!child) child = function() { this.super.constructor.apply(this, arguments) }
      properties.constructor = child.prototype.constructor
      properties.super = base.prototype

      child.prototype = _.create(base.prototype, properties)

      return child
    }

    // Check that all items are selected. It uses by Stateless and Stateful (when state is None) strategies
    function allSelected(data, items, filter) {
      if (!data.length) return false

      var selectedItems = data
      var filteredItems = _.filter(items, filter)
      return !!filteredItems.length && _.all(filteredItems, function(item) { return _.contains(selectedItems, item) })
    }

    // Check if at least one item is selected. It uses by Stateless and Stateful (when state is None) strategies
    function someSelected(data, items, filter) {
      if (!data.length) return false
      var selectedItems = data
      var filteredItems = _.filter(items, filter)
      return _.any(filteredItems, function(item) { return _.contains(selectedItems, item) })
    }

    // Strategy base class. Use different strategies to handle data.
    function Strategy() {}

    _.extend(Strategy.prototype, {
      getDataOnChange: function(data, items) { throw new Error('"getDataOnChange" is not implemented.') },
      prepareData: function(data, total) { return data },
      itemsAdded: function(items) {},
      itemsRemoved: function(items) {},
      selectAllEnabled: function() {},
      allSelected: function(data, items, filter) { return allSelected(data, items, filter) },
      someSelected: function(data, items, filter, total) { return someSelected(data, items, filter) },
      clearAll: function() {},
      getDeselectedItems: function() { return [] }
    })

    // use this simple strategy if it doesn't require to remember the selected items when navigate between pages
    var StrategyStateless = inherit(Strategy, null, {
        getDataOnChange: function(data, items) { return _.intersection(data, items) }
      }),
      // use it when need to store selected items when navigate between pages. It has two state (sub-strategies): "All Selected" mode is enabled and disabled
      StrategyStateful = inherit(Strategy, function() {
        this.state = new StateNone()

        this.super.constructor.apply(this, arguments)
      }, {
        getDataOnChange: function(data, items) { return this.state.getDataOnChange(data, items) },
        prepareData: function(data, total) { return this.state.prepareData(data, total) },
        itemsAdded: function(items) { this.state.itemsAdded(items) },
        itemsRemoved: function(items) { this.state.itemsRemoved(items) },
        selectAllEnabled: function() { this.state = new StateAllSelected() },
        allSelected: function(data, items, filter) { return this.state.allSelected(data, items, filter) },
        someSelected: function(data, items, filter, total) { return this.state.someSelected(data, items, filter, total) },
        clearAll: function() { this.state = new StateNone() },
        getDeselectedItems: function() { return this.state.getDeselectedItems() }
      })

    function State() {}

    _.extend(State.prototype, {
      getDataOnChange: function(data, items) {},
      prepareData: function(data, total) { return data },
      itemsAdded: function(items) {},
      itemsRemoved: function(items) {},
      allSelected: function(data, items, filter) { return allSelected(data, items, filter) },
      getDeselectedItems: function() { return [] },
      someSelected: function(data, items, filter, total) { return someSelected(data, items, filter) }
    })

    /**
     * StateNone is used when "Select All" mode is disabled
     */
    var StateNone = inherit(State, null, {
        getDataOnChange: function(data, items) { return _.union(_.intersection(data, items), _.difference(data, items)) }
      }),
      /**
       * This state is used when "Select All" mode is enabled
       */
      StateAllSelected = inherit(State, function() {
        this.deselectedItems = []

        this.super.constructor.apply(this, arguments)
      }, {
        getDataOnChange: function(data, items) { return _.union(_.filter(data), items) },
        prepareData: function(data, total) {
          data = _.difference(data, this.deselectedItems)
          if (total > -1) data.length = total - this.deselectedItemsCount()

          return data
        },
        itemsAdded: function(items) { this.deselectedItems = _.difference(this.deselectedItems, items) },
        itemsRemoved: function(items) { this.deselectedItems = _.union(this.deselectedItems, items) },
        allSelected: function(data, items, filter) { return this.deselectedItemsCount() == 0 },
        deselectedItemsCount: function() { return this.deselectedItems.length },
        getDeselectedItems: function() { return this.deselectedItems },
        someSelected: function(data, items, filter, total) { return total > -1 ? total - this.deselectedItemsCount() > 0 : data.length > 0 }
      })

    return {
      bind: function(scope, itemsKey, opts) {
        var helper = new SelectHelper(scope, itemsKey, opts || {})
        return helper.data
      },

      STRATEGY: {
        Stateless: StrategyStateless,
        Stateful: StrategyStateful
      }
    }
  })

  .directive('toggleAll', function() {
    return {
      restrict: 'A',
      scope: {
        data: '=toggleAll',
        filter: '=toggleAllFilter',
        onToggleSelect: '&?'
      },
      link: function($scope, $element) {
        var rawEl = $element.get(0)

        $scope.$watchCollection('data', function(data) {
          if (!data) return
          rawEl.checked = data.allSelected($scope.filter)
          rawEl.indeterminate = !rawEl.checked && data.someSelected($scope.filter)
        })

        $element.on('change', function() {
          var data = $scope.data
          if (!data) return

          if (!rawEl.checked) {
            $scope.$apply(function() {
              (data.clearAll.bind(data, $scope.filter))()
              if ($scope.onToggleSelect) $scope.onToggleSelect({ allSelected: false })
            })
          }
          else {
            $scope.$apply(function() {
              (data.selectAll.bind(data, $scope.filter))()
              if ($scope.onToggleSelect) $scope.onToggleSelect({ allSelected: true })
            })
          }
        })
      }
    }
  })
