angular.module('pl-shared')
  .directive('select2', function(_, $q, $timeout, $debounce, $compile, i18ng) {

    var $ = angular.element

    return {
      restrict: 'A',
      require: ['select', '?ngModel'],
      link: function($scope, $element, $attrs, controllers) {
        var instance
        var options
        var ngModelController = controllers[1]

        $timeout(setup, 0, false) // Apparently this helps with performance

        function setup() {
          var defaultOptions = {
            placeholder: getPlaceholder(),
            matcher: hideSelectedAndPartialWordMatcher
          }
          var opts = angular.extend({}, defaultOptions, $scope.$eval($attrs.select2))
          instance = $element.select2(opts).data('select2')
          options = instance.options.options

          $scope.$watchCollection(getData, updateValue)
          $scope.$watch(getPlaceholder, updatePlaceholder)
          $scope.$on('$destroy', _.bindKey(instance, 'destroy'))

          cloneClassAttributes()
          fixSelect2Toggle()

          if (ngModelController && opts.data) $element.val(ngModelController.$modelValue)
          if (ngModelController) setTouchedOnChange()
          if (!options.closeOnSelect) refreshOnSelect()
          if (options.setupAutoWidth) setupAutoWidth()
          if ($attrs.inlineHelp) showInlineHelp()
          if (options.collapseOverflow && options.multiple) setupOverflow()
          if (options.groupDelimiter) splitGroups()
          if (options.extraOptionsPlaceholder) showExtraOptionsPlaceholder()
          if (options.closeAfterRequest) closeAfterRequest()

          $element.trigger('select2:init', $element)
        }

        function getPlaceholder() {
          return $element.find('option:not([value]),option[value=""],option[value="?"]').text()
        }

        function getData() {
          return [].concat($scope.$eval($attrs.ngModel), $element[0].options)
        }

        function updatePlaceholder(text) {
          _.set(options, 'placeholder', text)
          _.set(instance, 'results.placeholder.text', text)
          _.set(instance, 'selection.placeholder.text', text)
          updateValue()
        }

        function updateValue() {
          instance.$element.trigger('change.select2') // force display update
        }

        function cloneClassAttributes() {
          if ($attrs.class) instance.$container.addClass($attrs.class) // don't overwrite existing container classes
          if ($attrs.ngClass) $compile(instance.$container.attr('ng-class', $attrs.ngClass))($scope)
        }

        function setTouchedOnChange() {
          $element.on('change', function() {
            $scope.$apply(ngModelController.$setTouched)
          })
        }

        // Select2 4.0 incorrectly toggles the dropdown when clearing and removing options.
        // Fix this by canceling the select2:opening/closing events that occur immediately after a select2:unselect event.
        function fixSelect2Toggle() {
          $element.on('select2:unselect', function() {
            function cancelAndRemove(event) {
              event.preventDefault()
              // removeEvents()
            }
            function removeEvents() {
              $element.off('select2:opening', cancelAndRemove)
              $element.off('select2:closing', cancelAndRemove)
            }
            $element.on('select2:opening', cancelAndRemove)
            $element.on('select2:closing', cancelAndRemove)
            $timeout(removeEvents, 100, false)
          })
        }

        function groupDelimiter() {
          return '<div class="select2-group_delimiter">' + options.groupDelimiter + '</div>'
        }

        function splitGroups() {
          instance.on('results:all', function() {
            var groups = instance.$results.find('[role="group"]')
            if (groups.length < 1) return false

            groups.each(function(index, element) {
              if (index === groups.length - 1 && index !== 0) return false
              $(element).after(groupDelimiter())
            })
          })
        }

        function showExtraOptionsPlaceholder() {
          function hideExtraOptions() {
            instance.$results.find('[data-init-visible="false"]').closest('li').hide()
          }

          function showPlaceholder() {
            if (instance.$results.find('[data-init-visible="false"]').length === 0) return false
            var $placeholder = placeholderElement()
            instance.$results.append(groupDelimiter())
            instance.$results.append($placeholder)
            $placeholder.on('click', showExtraOptions)
          }

          function placeholderElement() {
            var html = '<li class="select2-results__option extra-option-placeholder"><a class="sn-link">' + options.extraOptionsPlaceholder + '</a></li>'
            return angular.element(html)
          }

          function showExtraOptions(e) {
            $(e.currentTarget).prev('div').remove()
            $(e.currentTarget).remove()
            instance.$results.find('[data-init-visible="false"]').closest('li').show()
          }

          instance.on('results:all', hideExtraOptions)
          instance.on('results:all', showPlaceholder)
        }

        function refreshOnSelect() {
          var lastParams = {}
          var selectedIndex = null
          var debouncedRefreshDropdown = $debounce(refreshDropdown)

          instance.on('query', captureParams)
          instance.on('results:all', resetSelection)
          $element.on('select2:unselect', debouncedRefreshDropdown)
          $element.on('select2:select', debouncedRefreshDropdown)

          function captureParams(params) {
            lastParams = params
          }

          function resetSelection() {
            if (selectedIndex === null) return
            var $options = instance.$results.find('[aria-selected]')
            var selected = $options.eq(selectedIndex)
            if (!selected.length) selected = $options.eq(selectedIndex - 1)
            selected.trigger('mouseenter') // restores focus
            selectedIndex = null
          }

          function refreshDropdown() {
            var $highlighted = instance.results.getHighlightedResults()
            var $options = instance.$results.find('[aria-selected]')
            selectedIndex = $options.index($highlighted)
            instance.trigger('query', lastParams)
          }

        }

        /**
         * On selecting checks if selected element contain passed selector and if yes,
         * trigger passed function which should return a promise. While promise
         * is not resolve, adds spinner to selected item and keep dropdown opened.
         * Close select2 dropdown after completed request.
         */
        function closeAfterRequest() {
          var requestFn = options.closeAfterRequest.requestFn
          var selector = options.closeAfterRequest.selector
          if (!requestFn || !selector) return console.error('Please verify that you passed function and selector correctly')

          $element.on('select2:selecting', customizeSelecting)

          function customizeSelecting(event) {
            var selectedElement = $(event.params.args.originalEvent.currentTarget)
            var elemToAppendSpinner = selectedElement.find(selector)
            if (!elemToAppendSpinner.length) return

            event.preventDefault()
            var selectedItem = event.params.args.data
            var spinner = $compile('<svg-img class="\'pl-icon--light\'" use="\'spinner\'" style="\'margin: 0 8px\'"></svg-img>')($scope)
            elemToAppendSpinner.append(spinner)

            $scope.$apply(function() {
              $q.when(requestFn(selectedItem))
                .then(function() {
                  $('.select2-results__option--highlighted .svg-spinner').remove()
                  $element.select2('close')
                })
            })
          }

        }

        function setupAutoWidth() {
          $element.on('select2:selecting', setSelectionWidth)
          $(window).on('resize', setSelectionWidth)

          function setSelectionWidth() {
            var containerWidth = instance.$container.outerWidth()
            instance.$selection.css({ 'max-width': containerWidth })
          }

          $scope.$on('$destroy', function() {
            $(window).off('resize', setSelectionWidth)
          })
        }

        function showInlineHelp() {
          function showHelp(data) {
            if (data.data.results.length === 0) return // no items, will show no results message instead
            var helpOptions = $scope.$eval($attrs.inlineHelpOptions)
            var helpText = i18ng.t($attrs.inlineHelp, helpOptions)
            instance.$results.prepend('<li class="select2-dropdown__help">' + helpText + '</li>')
          }
          instance.on('results:all', showHelp)
        }

        // As of 4.0.3, the default matcher when searching returns the first item
        // with the search term anywhere in the string. This means if I type "ma"
        // into a gender select, I get "Female" since it comes first in the list.
        // We also want to hide selected items when using milt-selects.

        function hideSelectedAndPartialWordMatcher(params, data) {
          if (data.element && data.element.selected && options.multiple) return null
          if (matchWordStart(params, data)) return data
          if (data.children) {
            var matchedChildren = _.filter(data.children, hideSelectedAndPartialWordMatcher.bind(null, params))
            if (matchedChildren.length) return _.defaults({ children: matchedChildren }, data)
          }
          return null
        }

        function matchWordStart(params, data) {
          var term = _.trim(params.term)
          if (!term) return true
          var text = data.text.toLowerCase()
          var wordStart = new RegExp('\\b' + escapeRegExp(term.toLowerCase()))
          return wordStart.test(text)
        }

        function escapeRegExp(str) {
          return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
        }


        // Overflow-collapsing logic.
        // Add `collapseOverflow: true` to the opts to collapse any options beyond the first line
        // to a "and X others" placeholder when the field is not focused.

        var overflowElement = $('<li class="select2-selection__choice--overflow">')

        function setupOverflow() {
          if (instance.$element.hasClass('select2-static-text')) {
            instance.$container.on('click', doOverflow)
          }
          else {
            instance.on('focus', doOverflow)
          }

          instance.on('selection:update', doOverflow)
          instance.on('blur', $debounce(doOverflow, 10)) // blur event is delayed

          function doOverflow($event) {
            var target = $($event.target)

            if ($event.type === 'click') {
              if (!target.hasClass('overflow-link')) return

              instance.$container.addClass('select2-container--focus')
            }

            function overflows() {
              var cntrHeight = container.height() // w/o padding
              var itemHeight = items.outerHeight(true)
              return Math.round(cntrHeight) > Math.round(itemHeight) // round because fractional due to 7's
            }

            function hideNext() {
              if (overflows() && next) {
                items.eq(--next).hide()
                return true
              }
              return false
            }

            var container = instance.$selection.find('.select2-selection__rendered')
            var items = instance.$selection.find('.select2-selection__choice')
            overflowElement.detach()
            items.show()
            if (instance.hasFocus() || !overflows() || !items.length) return

            overflowElement.insertBefore(instance.selection.$search)

            // Save some loops by mass-hiding everything beyond the first row
            var firstTop = items[0].offsetTop
            var next = _.findIndex(items, function(item) { return item.offsetTop !== firstTop })
            items.slice(next).hide()

            // Loop to hide everything else until it all fits
            while (hideNext());

            function formatOverflowElement() {
              var hiddenItems = items.filter(':hidden')
              overflowElement.html($compile(i18ng.t('SELECT2.overflow', { count: hiddenItems.length }))($scope))
              overflowElement.prop('title', i18ng.t('SELECT2.overflow_title', { list: _.map(hiddenItems, 'title').join(', ') }))
            }

            formatOverflowElement()

            // just in case the new text made it wrap
            if (hideNext()) {
              formatOverflowElement()
            }
          }
        }

        //We should clear input field on select
        $element.on('select2:select', clearSelectInput)

        function clearSelectInput() {
          instance.$container.find('input').val('')
        }
      }
    }

  })
