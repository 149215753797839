require('team-admin')
angular.module('team-admin')
  .directive('snTimezoneField', function(_, $document, $timeout, timezone, ApiTimezone, moment) {
    var TEMP_ID = 0

    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        tzDate: '=',
        event: '='
      },
      template: require('./sn-timezone-field.html'),
      link: function($scope, $element, $attrs, $ngModel) {
        $scope.timezone = timezone
        $scope.ngModel = $ngModel
        var zones = []
        $scope.zones = []
        $scope.displayMessage = 'Timezone'
        $scope.tempId = ++TEMP_ID

        $scope.sortOffsets = function(zone) {
          var minus = zone.offset.charAt(0) === '-'
          var hr = parseInt(zone.offset.substring(1, 3))
          var min = parseInt(zone.offset.substring(4, 6)) / 60
          var offsetAsNum = hr + min
          return minus ? 0 - offsetAsNum : offsetAsNum
        }

        $scope.setTimezone = function(zone) {
          $ngModel.$setViewValue(zone)
          $scope.displayMessage = ApiTimezone.get(zone).name
        }

        function buildOptions(data) {

          zones = _.map(data, function(zone) {
            zone.value = zone.identifier
            return zone
          })

          function isUsCanada(z) {
            return _.contains(['United States', 'Canada'], z.country_name)
          }

          $scope.zoneGroups = [
            {
              name: 'United States and Canada',
              items: _.filter(zones, function(z) { return isUsCanada(z) })
            },
            {
              name: 'International',
              items: _.filter(zones, function(z) { return !isUsCanada(z) })
            }
          ]

          $scope.zones = zones

          if ($ngModel.$viewValue) {
            var z = ApiTimezone.get($ngModel.$viewValue)
            if (z) $scope.displayMessage = z.name
          }

          if ($scope.tzDate) {
            resetOffsets()
          }


        }

        function resetOffsets() {
          if (!$scope.tzDate) { return }
          _.each(zones, function(zone) {
            zone.offset = moment.tz($scope.tzDate, zone.identifier).format('Z')
          })
        }

        ApiTimezone.findAll().then(buildOptions)

        $scope.$watch('tzDate', function(val) {
          resetOffsets()
        })

      }
    }

  })
