require('team-admin')
angular.module('team-admin')
  .directive('confirmModal', function(userAgent, $interval) {
    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        message: '=',
        messageHelp: '=',
        type: '=',
        cancelButton: '=',
        confirmButton: '='
      },
      template: require('./confirm-modal.html')
    }
  })
