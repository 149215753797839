require('team-admin')
angular.module('team-admin')
  .config(function($routeProvider) {

    var routes = {
      '/teams/team-instance-redirect/:teamInstanceId': {
        name: 'teamInstanceRedirect'
      },
      '/teams/:teamId': {
        context: 'app.team.schedule.list',
        gaPageTitle: 'Schedule Game Event List',
        reloadOnSearch: false,
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents' }
      },
      '/teams/:teamId/print': {
        context: 'app.team.printRosterLanding',
        gaPageTitle: 'Print Roster Landing',
        pageDepths: { depth1: 'TeamCenter', depth2: 'PrintRosterLanding' }
      },
      '/teams/:teamId/roster': {
        context: 'app.team.roster.players',
        gaPageTitle: 'Roster Players List',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Roster', depth3: 'Players' }
      },
      '/teams/:teamId/players': {
        context: 'app.team.roster.players',
        gaPageTitle: 'Roster Players List',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Roster', depth3: 'Players' }
      },
      '/teams/:teamId/players/:playerId': {
        context: 'app.team.roster.players.detail',
        gaPageTitle: 'Roster Player Detail',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Roster', depth3: 'Players', depth4: 'Detail' }
      },
      '/teams/:teamId/staff': {
        context: 'app.team.roster.staff',
        gaPageTitle: 'Roster Staff List',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Roster', depth3: 'Staff' }
      },
      '/teams/:teamId/staff/:playerId': {
        context: 'app.team.roster.staff.detail',
        gaPageTitle: 'Roster Staff Detail',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Roster', depth3: 'Staff', depth4: 'Detail' }
      },
      '/teams/:teamId/schedule': {
        context: 'app.team.schedule.list',
        gaPageTitle: 'Schedule Game Event List',
        reloadOnSearch: false,
        title: 'ROUTE_TITLE.event_list',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule' }
      },
      '/teams/:teamId/schedule/events': {
        context: 'app.team.schedule.list',
        gaPageTitle: 'Schedule Game Event List',
        reloadOnSearch: false,
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents' }
      },
      '/teams/:teamId/schedule/events/:eventId': {
        context: 'app.team.schedule.list.events.detail.information',
        gaPageTitle: 'Event Details',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents', depth4: 'EventDetails' }
      },
      '/teams/:teamId/schedule/events/:eventId/rsvp-participant': {
        context: 'app.team.schedule.list.events.detail.rsvpParticipant',
        gaPageTitle: 'Event Player RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents', depth4: 'RSVPPlayers' }
      },
      '/teams/:teamId/schedule/events/:eventId/rsvp-staff': {
        context: 'app.team.schedule.list.events.detail.rsvpStaff',
        gaPageTitle: 'Event Staff RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents', depth4: 'RSVPStaff' }
      },
      '/teams/:teamId/schedule/calendar': {
        context: 'app.team.schedule.calendar',
        gaPageTitle: 'Schedule Calendar',
        reloadOnSearch: false,
        title: 'ROUTE_TITLE.calendar',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'Calendar' }
      },
      '/teams/:teamId/schedule/calendar/event/:eventId': {
        context: 'app.team.schedule.calendar.events.detail.information',
        gaPageTitle: 'Event Details',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'Calendar', depth4: 'EventDetail' }
      },
      '/teams/:teamId/schedule/calendar/event/:eventId/rsvp-participant': {
        context: 'app.team.schedule.calendar.events.detail.rsvpParticipant',
        gaPageTitle: 'Event Player RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'Calendar', depth4: 'EventDetail', depth5: 'PlayerRSVPs' }
      },
      '/teams/:teamId/schedule/calendar/event/:eventId/rsvp-staff': {
        context: 'app.team.schedule.calendar.events.detail.rsvpStaff',
        gaPageTitle: 'Event Staff RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'Calendar', depth4: 'EventDetail', depth5: 'StaffRSVPs' }
      },
      '/teams/:teamId/schedule/calendar/game/:gameId': {
        context: 'app.team.schedule.calendar.games.detail.information',
        gaPageTitle: 'Game Details',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'Calendar', depth4: 'GameDetail' }
      },
      '/teams/:teamId/schedule/calendar/game/:gameId/rsvp-participant': {
        context: 'app.team.schedule.calendar.games.detail.rsvpParticipant',
        gaPageTitle: 'Game Player RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'Calendar', depth4: 'GameDetail', depth5: 'PlayerRSVPs' }
      },
      '/teams/:teamId/schedule/calendar/game/:gameId/rsvp-staff': {
        context: 'app.team.schedule.calendar.games.detail.rsvpStaff',
        gaPageTitle: 'Game Staff RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'Calendar', depth4: 'GameDetail', depth5: 'StaffRSVPs' }
      },
      '/teams/:teamId/schedule/games/:gameId': {
        context: 'app.team.schedule.list.games.detail.information',
        gaPageTitle: 'Game Details',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents', depth4: 'GameDetail' }
      },
      '/teams/:teamId/schedule/games/:gameId/rsvp-participant': {
        context: 'app.team.schedule.list.games.detail.rsvpParticipant',
        gaPageTitle: 'Game Player RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents', depth4: 'GameDetail', depth5: 'PlayerRSVPs' }
      },
      '/teams/:teamId/schedule/games/:gameId/rsvp-staff': {
        context: 'app.team.schedule.list.games.detail.rsvpStaff',
        gaPageTitle: 'Game Staff RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'GamesAndEvents', depth4: 'GameDetail', depth5: 'StaffRSVPs' }
      },
      '/teams/:teamId/schedule/rsvps': {
        context: 'app.team.schedule.rsvpGrid',
        gaPageTitle: 'Schedule RSVP Tab',
        reloadOnSearch: false,
        title: 'ROUTE_TITLE.rsvps',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'RSVPs' }
      },
      '/teams/:teamId/schedule/rsvps/event/:eventId': {
        context: 'app.team.schedule.rsvpGrid.events.detail.information',
        gaPageTitle: 'Event Details',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'RSVPS', depth4: 'EventDetails' }
      },
      '/teams/:teamId/schedule/rsvps/event/:eventId/rsvp-participant': {
        context: 'app.team.schedule.rsvpGrid.events.detail.rsvpParticipant',
        gaPageTitle: 'Event Player RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'RSVPS', depth4: 'EventDetail', depth5: 'PlayerRSVPs' }
      },
      '/teams/:teamId/schedule/rsvps/event/:eventId/rsvp-staff': {
        context: 'app.team.schedule.rsvpGrid.events.detail.rsvpStaff',
        gaPageTitle: 'Event Staff RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'RSVPs', depth4: 'EventDetail', depth5: 'StaffRSVPs' }
      },
      '/teams/:teamId/schedule/rsvps/game/:gameId': {
        context: 'app.team.schedule.rsvpGrid.games.detail.information',
        gaPageTitle: 'Game Details',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'RSVPs', depth4: 'GameDetail' }
      },
      '/teams/:teamId/schedule/rsvps/game/:gameId/rsvp-participant': {
        context: 'app.team.schedule.rsvpGrid.games.detail.rsvpParticipant',
        gaPageTitle: 'Game Player RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'RSVPs', depth4: 'GameDetail', depth5: 'PlayerRSVPs' }
      },
      '/teams/:teamId/schedule/rsvps/game/:gameId/rsvp-staff': {
        context: 'app.team.schedule.rsvpGrid.games.detail.rsvpStaff',
        gaPageTitle: 'Game Staff RSVPs',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Schedule', depth3: 'RSVPs', depth4: 'GameDetail', depth5: 'StaffRSVPs' }
      },
      '/teams/:teamId/settings': {
        context: 'app.team.settings',
        gaPageTitle: 'Settings',
        pageDepths: { depth1: 'TeamCenter', depth2: 'TeamSettings' }
      },
      '/teams/:teamId/chat': {
        context: 'app.team.chat',
        gaPageTitle: 'Chat',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Chat' }
      },
      '/teams/:teamId/squad-locker': {
        context: 'app.team.squadLocker.info',
        gaPageTitle: 'Apparel',
        pageDepths: { depth1: 'TeamCenter', depth2: 'Apparel' }
      },
      '/teams/:teamId/squad-locker/store': {
        context: 'app.team.squadLocker.store',
        gaPageTitle: 'Apparel',
        reloadOnSearch: false
      },
      '/teams/:teamId/reports': {
        context: 'app.team.reports'
      },
      '/teams/:teamId/upgrade': {
        context: 'app.team.upgrade'
      },
      '/teams/oauth/callback': {
        appPermission: 'oauthCallback',
        context: 'app.oauthCallback',
        title: '',
      },
    }

    _.each(routes, function(opts, path) {
      $routeProvider.when(path, opts)
    })
    $routeProvider.otherwise({ context: 'error.404' })

  })

  .run(function($rootScope, $route, $routeParams, $location, Team, seBar, showError, pageViewHandler) {

    $rootScope.$on('$routeChangeStart', routeChangeHandler)
    if ($route.current) routeChangeHandler(null, $route.current)

    function routeChangeHandler(event, next) {

      // Replaces the ngin team/instance ID with the Team Service team ID in the route.
      function findTsTeam(teamInstanceId, routePath) {
        return Team.findAll({
          originator_id: teamInstanceId,
          originator_system: 'Ngin',
          originator_type: 'team_instance'
        })
          .then(function([team]) {
            seBar.setTeamId(team.id)
            $location.replace().url('/teams/' + team.id + (routePath ? $location.path().replace(/^\/teams\/[-\w]+/, '') : ''))
          }, showError)
      }

      if (next?.name === 'teamInstanceRedirect') {
        return findTsTeam($routeParams.teamInstanceId)
      }

      if (/^\d+$/.test($routeParams?.teamId)) {
        var seasonId = +$routeParams.season_id // capture this here in case this route gets hit twice, won't be available in callback
        return Team.find($routeParams.teamId, Team.v2Options)
          .then(nginTeam => {
            var activeSeason = _.find(nginTeam.seasons, { id: seasonId }) ||
              _.find(nginTeam.seasons, { current: true }) ||
              _.first(nginTeam.seasons)
            findTsTeam(activeSeason.season_team_id, true)
          }, showError)
      }

      return seBar.setTeamId($routeParams?.teamId)
    }

  })
