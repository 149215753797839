// The `directive` directive allows for dynamic directive names. For example,
// if $scope.directiveName = "myDirective", `<div directive="directiveName:data" default-directive="defaultName:defaultData"></div>`
// will be converted into `<div my-directive="data"></div>`.
angular.module('pl-shared')
  .service('directiveExists', function($injector) {
    return function directiveExists(name) {
      return !!name && $injector.has(_.camelCase(name) + 'Directive')
    }
  })
  .directive('directive', function($compile, directiveExists) {

    return {
      priority: 449,
      restrict: 'A',
      link: function(scope, el, attrs) {

        var missingDirectives = []
        var directiveData
        var directive = findDirective('directive') || findDirective('defaultDirective')
        if (!directive) return directiveError()

        compileDirective()

        function findDirective(attr) {
          var directiveParts = attrs[attr].split(/:/)
          var directiveName = scope.$eval(directiveParts[0])
          if (directiveExists(directiveName)) {
            directiveData = directiveParts[1] || directiveData || ''
            return {
              name: _.kebabCase(directiveName),
              data: directiveData
            }
          }
          else {
            missingDirectives.push(directiveName)
          }
        }

        function compileDirective() {
          var clone = el.clone()
            .removeAttr('directive')
            .removeAttr('default-directive')
            .attr(directive.name, directive.data)
          el.replaceWith(clone)
          $compile(clone)(scope)
        }

        function directiveError() {
          var directivePlural = missingDirectives.length > 1 ? 'directives' : 'directive'
          var directiveList = '`' + missingDirectives.join('`, `') + '`'
          throw new Error('Dynamic Directive: The following ' + directivePlural + ' could not be found: ' + directiveList)
        }
      }
    }
  })
