require('team-admin')
angular.module('team-admin')
  .directive('eventSettings', function() {

    return {
      restrict: 'A',
      scope: {
        team: '=',
        event: '=',
        changeRange: '<?',
        eventForm: '='
      },
      template: require('./event-settings.html'),
      controller: function($scope, Event, Alerts, _, moment, timezone, featureToggles) {
        Event.setupTranslation($scope)

        $scope.scope = $scope
        $scope.failed = false
        $scope.timezone = timezone

        var lastRecurInterval
        var recur_info = $scope.event.recur_info

        $scope.recurringEvent = function(bool) {
          if (bool === undefined) return $scope.event.recurInterval !== 'none'
          if (bool) {
            $scope.event.recurInterval = lastRecurInterval || 'week'
            recur_info.month_repeat_by ||= 'day_of_month'
            $scope.setRecurEndDate($scope.event.recurInterval)
          }
          else {
            lastRecurInterval = $scope.event.recurInterval
            $scope.event.recurInterval = 'none'
          }
        }

        $scope.setRecurEndDate = function(recurInterval) {
          var endDate = moment($scope.event.date)
          if (recurInterval == 'day') endDate.add(5, 'days')
          if (recurInterval == 'week') endDate.add(5, 'weeks')
          if (recurInterval == 'week2') endDate.add(10, 'weeks')
          if (recurInterval == 'month') endDate.add(1, 'years')
          recur_info.end_date_time = endDate.format('YYYY-MM-DD')
        }

        $scope.setDay = function(day) {
          if (!$scope.event.recur_info.day[day]) delete $scope.event.recur_info.day[day] // for equality check later
        }

        $scope.makeOrdinal = function(num) {
          switch (num) {
            case 1: return '1st'
            case 2: return '2nd'
            case 3: return '3rd'
            case 4: return '4th'
            case 5: return 'Last'
          }
        }

        $scope.clearLocationPlaceId = function() {
          $scope.event.location_place_id = ''
        }

        function validateEndDate(form) {
          if (form.recur_end_date && moment(recur_info.end_date_time).isAfter(moment($scope.event.date).add(1, 'years'))) {
            form.recur_end_date.$setValidity('maximumEndDate', false)
          }
          else if (form.recur_end_date) {
            form.recur_end_date.$setValidity('maximumEndDate', true)
          }
        }

        $scope.$watch('event.recur_info.end_date_time', function() {
          validateEndDate($scope.eventForm)
        })

        $scope.$watchGroup(['event.recurInterval', 'event.date'], function([recurInterval, date = '']) {
          var weekly = /^week2?/.test(recurInterval)
          date = date.replace(/-/g, '/')
          var day = $scope.days[$scope.dayIndex = (new Date(date)).getDay()] || {}

          if (weekly) _.set($scope.event.recur_info, ['day', day.value], true)
          $scope.requiredDayTranslated = day.label || ''
          $scope.dayOfMonth = moment(date).format('Do')
          $scope.dayCount = (Math.ceil(moment(date).format('D') / 7))
          validateEndDate($scope.eventForm)
        })
      }
    }
  })
