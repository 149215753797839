angular.module('pl-shared')

  .factory('Persona', function(_, apiV2, DS, ENV, paginate, nestedResource, $http, $injector) {

    var currentUser

    function match_current_user_uuid(listener) {
      var cu = currentUser = currentUser || $injector.get('currentUser') // circumvent circular dependency
      return listener.uuid === cu.uuid
    }

    var Persona = DS.defineResource({
      name: 'personas',
      // @deprecated user_personas endpoint will be removed
      // @see https://github.com/sportngin/central_service/
      endpoint: 'user_personas',
      basePath: ENV.urls.apiProxy,
      httpConfig: {
        headers: apiV2.headers,
        withCredentials: true,
      },
      actions: {
        merge: {
          method: 'POST',
          response: apiV2.deserialize
        }
      },
      computed: {
        full_name: [
          'first_name', 'last_name',
          function(first_name, last_name) { return _.compact([first_name, last_name]).join(' ') }
        ],
        full_name_with_suffix: [
          'first_name', 'last_name', 'suffix',
          function(first_name, last_name, suffix) { return _.compact([first_name, last_name, suffix]).join(' ') }
        ],
        is_self: [
          'persona_type',
          function(persona_type) { return persona_type === 'self' }
        ],
        is_guarded: [
          'guardian_listeners',
          function(listeners) { return _.any(listeners, match_current_user_uuid) }
        ],
        meets_copa_age_requirement: [
          'date_of_birth',
          function(dateOfBirth) {
            var COPAAgeRequirement = 13
            var personaAge = moment().diff(dateOfBirth, 'years')

            return personaAge >= COPAAgeRequirement
          }
        ],
        profile_image_70: [
          'profile_images',
          function(profile_images) {
            return _.findWhere(profile_images, { image_type: 'crop_icon' })
          }
        ],
        profile_image_200: [
          'profile_images',
          function(profile_images) {
            return _.findWhere(profile_images, { image_type: 'crop' })
          }
        ],
        profile_image_original: [
          'profile_images',
          function(profile_images) {
            return _.findWhere(profile_images, { image_type: 'original' })
          }
        ],
        is_orphan: [
          'persona_type',
          function(persona_type) { return persona_type === 'orphan' }
        ],
        is_other: [
          'persona_type',
          function(persona_type) { return persona_type === 'other' }
        ]
      },
      serialize: nestedResource('persona'),
      methods: {
        update: function(attrs) {
          return Persona.save(this.id, { data: attrs })
        },
        receiveEmail: function(orgId) {
          return hasEnabledContact(this.alert_preferences, 'PersonaEmailAddress', orgId)
        },
        receiveText: function(orgId) {
          return hasEnabledContact(this.alert_preferences, 'PhoneNumber', orgId)
        }
      }
    })

    function personaGraduationYears() {
      var minYear = 2000
      var thisYear = (new Date()).getFullYear()
      var yearsOut = 20
      var graduationYears = []
      for (var i = thisYear + yearsOut; i >= minYear; i--) {
        graduationYears.push(i)
      }
      return graduationYears
    }

    function hasEnabledContact(alert_preferences, contactType, orgId) {
      if (!alert_preferences || !alert_preferences.length) return false
      return _.some(alert_preferences, {
        context_id: orgId,
        context_type: 'Organization',
        contact_type: contactType,
        enabled: true
      })
    }

    Persona.disabilities = ['yes', 'no', 'unspecified']
    Persona.genders = ['female', 'male', 'unspecified', 'not_provided']
    Persona.suffixOptions = ['Jr.', 'Sr.', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X']
    Persona.graduation_years = personaGraduationYears()
    Persona.military_statuses = ['active', 'veteran', 'none', 'unspecified']

    // @todo update this to use the GeographicOptions service
    Persona.countries = {
      'US': 'United States',
      'CA': 'Canada'
    }
    Persona.states_provinces = {
      'United States': {
        'AL': { 'name': 'Alabama' },
        'AK': { 'name': 'Alaska' },
        'AS': { 'name': 'American Samoa' },
        'AZ': { 'name': 'Arizona' },
        'AR': { 'name': 'Arkansas' },
        'AA': { 'name': 'Armed Forces Americas' },
        'AE': { 'name': 'Armed Forces Europe' },
        'AP': { 'name': 'Armed Forces Pacific' },
        'CA': { 'name': 'California' },
        'CO': { 'name': 'Colorado' },
        'CT': { 'name': 'Connecticut' },
        'DE': { 'name': 'Delaware' },
        'DC': { 'name': 'District of Columbia' },
        'FL': { 'name': 'Florida' },
        'GA': { 'name': 'Georgia' },
        'GU': { 'name': 'Guam' },
        'HI': { 'name': 'Hawaii' },
        'ID': { 'name': 'Idaho' },
        'IL': { 'name': 'Illinois' },
        'IN': { 'name': 'Indiana' },
        'IA': { 'name': 'Iowa' },
        'KS': { 'name': 'Kansas' },
        'KY': { 'name': 'Kentucky' },
        'LA': { 'name': 'Louisiana' },
        'ME': { 'name': 'Maine' },
        'MH': { 'name': 'Marshall Islands' },
        'MD': { 'name': 'Maryland' },
        'MA': { 'name': 'Massachusetts' },
        'MI': { 'name': 'Michigan' },
        'FM': { 'name': 'Micronesia' },
        'MN': { 'name': 'Minnesota' },
        'MS': { 'name': 'Mississippi' },
        'MO': { 'name': 'Missouri' },
        'MT': { 'name': 'Montana' },
        'NE': { 'name': 'Nebraska' },
        'NV': { 'name': 'Nevada' },
        'NH': { 'name': 'New Hampshire' },
        'NJ': { 'name': 'New Jersey' },
        'NM': { 'name': 'New Mexico' },
        'NY': { 'name': 'New York' },
        'NC': { 'name': 'North Carolina' },
        'ND': { 'name': 'North Dakota' },
        'MP': { 'name': 'Northern Marianas' },
        'OH': { 'name': 'Ohio' },
        'OK': { 'name': 'Oklahoma' },
        'OR': { 'name': 'Oregon' },
        'PW': { 'name': 'Palau' },
        'PA': { 'name': 'Pennsylvania' },
        'PR': { 'name': 'Puerto Rico' },
        'RI': { 'name': 'Rhode Island' },
        'SC': { 'name': 'South Carolina' },
        'SD': { 'name': 'South Dakota' },
        'TN': { 'name': 'Tennessee' },
        'TX': { 'name': 'Texas' },
        'UT': { 'name': 'Utah' },
        'VT': { 'name': 'Vermont' },
        'VI': {
          'name': 'Virgin Islands',
          'alt_abbrs': ['USVI']
        },
        'VA': { 'name': 'Virginia' },
        'WA': { 'name': 'Washington' },
        'WV': { 'name': 'West Virginia' },
        'WI': { 'name': 'Wisconsin' },
        'WY': { 'name': 'Wyoming' }
      },
      'Canada': {
        'AB': { 'name': 'Alberta' },
        'BC': { 'name': 'British Columbia' },
        'MB': { 'name': 'Manitoba' },
        'NB': { 'name': 'New Brunswick' },
        'NL': { 'name': 'Newfoundland and Labrador' },
        'NT': { 'name': 'Northwest Territories' },
        'NS': { 'name': 'Nova Scotia' },
        'NU': { 'name': 'Nunavut' },
        'ON': { 'name': 'Ontario' },
        'PE': { 'name': 'Prince Edward Island' },
        'QC': { 'name': 'Quebec' },
        'SK': { 'name': 'Saskatchewan' },
        'YT': { 'name': 'Yukon' },
      }
    }
    Persona.country_state = {
      'US': 'state',
      'CA': 'province'
    }

    Persona.adminDestroy = function(id) {
      return $http({
        method: 'DELETE',
        // @deprecated user_personas endpoint will be removed
        // @see https://github.com/sportngin/central_service/
        url: ENV.urls.apiProxy + '/user_personas/' + id + '/admin_destroy',
        headers: apiV2.headers,
        withCredentials: true
      })
    }

    return paginate(Persona)
  })
