angular.module('pl-shared')
  .factory('ProgramConfiguration', function(apiV2, DS, paginate) {
    var ProgramConfiguration = DS.defineResource({
      name: 'program_configurations',
      endpoint: '/v3/competition/program_configurations',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          credential_definitions: [
            {
              foreignKey: 'credential_definition_id',
              localField: 'credentialDefinition'
            }
          ],
          programs: [
            {
              foreignKey: 'program_id',
              localField: 'program'
            }
          ],
          program_configurations: [
            {
              foreignKey: 'parent_id',
              localField: 'parent'
            }
          ]
        }
      }
    })

    return paginate(ProgramConfiguration)
  })
