angular.module('pl-shared')
  .directive('fullscreenImage', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        allMessages: '=',
        message: '=',
        currentUser: '=',
        users: '=',
        cancel: '='
      },
      controllerAs: '$ctrl',
      template: require('/static/shared/components/chat/fullscreen-image.html'),
      controller: function($scope, moment, $document) {
        var $ctrl = this
        var leftKeyCode = '37'
        var rightKeyCode = '39'
        $ctrl.allPhotoMessages = _.filter($ctrl.allMessages, function(message) {
          return messageIsPhotoOrGif(message) && !messageIsHidden(message)
        })

        var index = _.findIndex($ctrl.allPhotoMessages, { $id: $ctrl.message.$id })
        setImage()

        $ctrl.nextImage = function() {
          if (index < $ctrl.allPhotoMessages.length - 1) {
            index++
            setImage()
          }
        }

        $ctrl.previousImage = function() {
          if (index > 0) {
            index--
            setImage()
          }
        }

        function setImage() {
          $ctrl.displayedPhotoMessage = $ctrl.allPhotoMessages[index]
          $ctrl.user = _.find($ctrl.users, { $id: $ctrl.displayedPhotoMessage.userId })
          $ctrl.timeFromNow = moment($ctrl.displayedPhotoMessage.timestamp).fromNow()
        }

        function messageIsHidden(message) {
          return message.hide && message.hide[$ctrl.currentUser.uuid]
        }

        function messageIsPhotoOrGif(message) {
          return message.contentType === 'photo' || message.contentType === 'gif'
        }

        $ctrl.disableCarouselBackNavigation = function() {
          return index === 0
        }

        $ctrl.disableCarouselForwardNavigation = function() {
          return index === $ctrl.allPhotoMessages.length - 1
        }

        $ctrl.getFullName = function(user) {
          return user.firstName + ' ' + user.lastName
        }

        $document.bind('keydown', function(event) {
          if (event.keyCode == leftKeyCode) {
            $ctrl.previousImage()
            $scope.$apply()
          }
          if (event.keyCode == rightKeyCode) {
            $ctrl.nextImage()
            $scope.$apply()
          }
        })

        $scope.$on('$destroy', function() {
          $document.unbind('keydown')
        })
      }
    }
  })
