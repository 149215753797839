(function() {

  var HTTP_PROTOCOL = 'http://'
  var HTTPS_PROTOCOL = 'https://'

  function isHTTP(value) {
    value = value || ''
    return value.trim().indexOf(HTTP_PROTOCOL) === 0
  }

  function isHTTPS(value) {
    value = value || ''
    return value.trim().indexOf(HTTPS_PROTOCOL) === 0
  }

  function hasProtocol(value) {
    return isHTTP(value) || isHTTPS(value)
  }

  angular.module('pl-shared')

    .directive('websiteQuestion', function() {

      return {
        scope: {},
        template: require('/static/shared/components/regform/questions/website-question.html'),
        controllerAs: 'ctrl',
        bindToController: {
          question: '=',
          model: '=',
          answer: '='
        },
        controller: function(_, $scope) {
          var ctrl = this

          ctrl.pattern = /^(https?:\/\/)?[^ ."]+(\.[^ ."]+)+$/
          ctrl.properties = ctrl.question.properties
          ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
          ctrl.inputName = 'form_result_' + ctrl.question.id

          // initialize model with protocol because $parser stuff below only works after change
          if (ctrl.model && ctrl.pattern.test(ctrl.model) && !hasProtocol(ctrl.model)) {
            ctrl.model = HTTP_PROTOCOL + ctrl.model
          }

        }
      }

    })

    .directive('websiteDefaultProtocol', function() {

      return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, modelCtrl) {

          modelCtrl.$parsers.push(function(inputValue) {
            var defaultProtocol = attrs.websiteDefaultProtocol || 'http'
            var protocolValue = addProtocolIfNecessary(inputValue, defaultProtocol)
            if (protocolValue && !isHTTP(protocolValue)) {
              modelCtrl.$setViewValue(protocolValue)
              modelCtrl.$render()
            }
            return protocolValue
          })

          modelCtrl.$formatters.push(function(value) {
            if (isHTTP(value)) {
              return value.split('://')[1]
            }
            return value
          })
        }
      }

      function addProtocolIfNecessary(value, protocol) {
        var http_min_length = Math.min(value.length, HTTP_PROTOCOL.length)
        var https_min_length = Math.min(value.length, HTTPS_PROTOCOL.length)
        var protocolValue = value
        switch (protocol) {
          case 'http':
            if (protocolValue.indexOf(HTTP_PROTOCOL.slice(0, http_min_length)) === -1 && protocolValue.indexOf(HTTPS_PROTOCOL.slice(0, https_min_length)) === -1) {
              protocolValue = HTTP_PROTOCOL + protocolValue
            }
            break
          case 'https':
            if (protocolValue.indexOf(HTTPS_PROTOCOL.slice(0, https_min_length)) === -1) {
            // the value entered is not https valid
              if (protocolValue.indexOf(HTTP_PROTOCOL.slice(0, http_min_length)) !== -1) {
              // the value entered is http valid - replace it for https
                protocolValue = protocolValue.replace('http', 'https')
              }
              else {
                protocolValue = HTTPS_PROTOCOL + protocolValue
              }
            }
            break
        }
        return protocolValue
      }

    })
})()
