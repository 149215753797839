angular.module('pl-shared')

  .run(function(CustomizeSelect2) {
    CustomizeSelect2.selectOnTab()
    CustomizeSelect2.searchWhenClosed()
    CustomizeSelect2.refocusAfterClose()
    CustomizeSelect2.deleteFullSelection()
    CustomizeSelect2.replaceCloseListener()
    CustomizeSelect2.createLoadingMore()
    CustomizeSelect2.overrideLoadingBar()
    CustomizeSelect2.overrideFocusEvent()
  })

  .factory('CustomizeSelect2', function() {
    var $ = angular.element

    return {
      refocusAfterClose: refocusAfterClose,
      searchWhenClosed: searchWhenClosed,
      selectOnTab: selectOnTab,
      deleteFullSelection: deleteFullSelection,
      replaceCloseListener: replaceCloseListener,
      createLoadingMore: createLoadingMore,
      overrideLoadingBar: overrideLoadingBar,
      overrideFocusEvent: overrideFocusEvent
    }

    // As of 4.0.3, multiselect delete behavior has no option for deleting the entire
    // item with one keystroke (as it did in 3.5). The default behavior is to delete
    // characters independenly, which can re-trigger a search. We want the old behavior.
    function deleteFullSelection() {
      $.fn.select2.amd.require([
        'select2/selection/search'
      ], function(Search) {
        var oldRemoveChoice = Search.prototype.searchRemoveChoice

        Search.prototype.searchRemoveChoice = function() {
          oldRemoveChoice.apply(this, arguments)
          this.$search.val('')
        }
      })
    }

    //By default, select2 clearing input after we closing select container.
    //This adapter replace container's 'close' listener in order to not clear the input field.
    function replaceCloseListener() {
      $.fn.select2.amd.require([
        'select2/selection/search'
      ], function(Search) {
        var oldRemoveBind = Search.prototype.bind

        Search.prototype.bind = function() {
          oldRemoveBind.apply(this, arguments)
          var self = this
          this.container.listeners.close[1] = function() {
            self.$search.removeAttr('aria-activedescendant')
            self.$search.trigger('focus')
          }
        }
      })
    }


    // As of 4.0.3, single selects do not start searching until you
    // open the dropdown by pressing space or enter. This adapter adjusts
    // the focused element during the keypress to allow the dropdown search
    // box (if present) to receive the first keypress event.
    function searchWhenClosed() {
      $.fn.select2.amd.require([
        'select2/defaults',
        'select2/keys',
        'select2/utils'
      ], function(Defaults, KEYS, Utils) {
        function SearchWhenClosed() {}

        SearchWhenClosed.prototype.bind = function(decorated, container, $container) {
          var self = this
          decorated.call(this, container, $container)

          container.$selection.on('keydown', function(evt) {
            self._handleSearchWhenClosed(evt)
          })
        }

        SearchWhenClosed.prototype._handleSearchWhenClosed = function(originalMethod, evt) {

          // For now, exit unless we know search is going to be there
          if (this.options.get('minimumResultsForSearch')) return

          var self = this
          var key = evt.which
          var irrelevantKeys = [
            8,  // DELETE
            9,  // TAB
            13, // ENTER
            16, // SHIFT
            17, // CTRL
            18, // OPT/ALT
            20, // CAPS
            27, // ESC
            32, // SPACE
            37, // LEFT
            38, // UP
            39, // RIGHT
            40, // DOWN
            91, // L-CMD
            93, // R-CMD
          ]
          var doOpen = (key === KEYS.ENTER || key === KEYS.SPACE || (key === KEYS.DOWN && evt.altKey))
          var doSearch = !doOpen && !evt.altKey && irrelevantKeys.indexOf(key) === -1

          if (doSearch) {
            self.trigger('open')
            self.$search.focus().select()
            // For some reason, the query doesn't always fire the first time
            setTimeout(function() {
              self.trigger('query', {
                term: self.$search.val()
              })
            })
          }
          else if (doOpen) {
            self.trigger('open')
            evt.preventDefault()
          }

        }

        var _apply = Defaults.constructor.prototype.apply
        Defaults.constructor.prototype.apply = function() {
          var options = _apply.apply(this, arguments)
          if (options.minimumResultsForSearch === 0 && !options.multiple) {
            options.dropdownAdapter = Utils.Decorate(
              options.dropdownAdapter,
              SearchWhenClosed
            )
          }
          return options
        }
      })
    }


    // As of 4.0.3, single selects also do not reset focus to themselves when thier
    // dropdown closes after pressing escape, hitting enter, or clicking an option.
    function refocusAfterClose() {
      $.fn.select2.amd.require([
        'select2/defaults',
        'select2/keys',
        'select2/utils'
      ], function(Defaults, KEYS, Utils) {
        function RefocusAfterClose() {}

        RefocusAfterClose.prototype.bind = function(decorated, container, $container) {
          decorated.call(this, container, $container)

          var input = container.dropdown.$search
          var results = container.results.$results

          function refocus(e) {
            var key = e.which
            if (e.type === 'keydown' && key !== KEYS.ESC && key !== KEYS.ENTER) return
            setTimeout(function() { container.focus() })
          }

          // Refocus after enter or escape.
          if (input) input.on('keydown', refocus)

          // Refocus on mouse selection if dropdown is set to close on select.
          if (this.options.get('closeOnSelect')) results.on('mouseup', refocus)
        }

        var _apply = Defaults.constructor.prototype.apply
        Defaults.constructor.prototype.apply = function() {
          var options = _apply.apply(this, arguments)
          if (options.minimumResultsForSearch === 0 && !options.multiple) {
            options.dropdownAdapter = Utils.Decorate(
              options.dropdownAdapter,
              RefocusAfterClose
            )
          }
          return options
        }
      })
    }

    function overrideFocusEvent() {
      $.fn.select2.amd.require([
        'select2/core'
      ], function(Select2) {
        var select2Focus = Select2.prototype.focus

        Select2.prototype.focus = function(data) {
          if (!this.$container.hasClass('select2-static-text')) select2Focus.call(this)
        }
      })
    }

    function overrideLoadingBar() {
      $.fn.select2.amd.require([
        'select2/results'
      ], function(Results) {
        var resultsSelector = '.select2-results'

        Results.prototype.showLoading = function() {
          var that = this

          setTimeout(function() {
            var loadingIsPresent = !!$(resultsSelector).find('.select2-custom-searching-results').length

            if (loadingIsPresent) {
              return
            }

            var loading = {
              disabled: true,
              loading: true,
              text: ''
            }

            var $loading = that.option(loading)
            $loading.className += ' select2-custom-searching-results'

            var $option = $(
              '<li class="select2-results__option select2-results__option--load-more" role="treeitem" aria-disabled="true">' +
              '<svg class="svg-spinner pl-icon--light"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-spinner"></use></svg>' +
              '<span class="loading-message">Searching...</span>' +
              '</li>'
            )

            $loading.append(_.first($option))

            $(resultsSelector).append($loading)
          })
        }

        Results.prototype.hideLoading = function() {
          setTimeout(function() {
            $(resultsSelector).find('.select2-custom-searching-results').remove()
          })
        }
      })
    }

    function createLoadingMore() {
      $.fn.select2.amd.require([
        'select2/dropdown/infiniteScroll'
      ], function(InfiniteScroll) {
        InfiniteScroll.prototype.createLoadingMore = function() {
          var $option =  $(
            '<li class="select2-results__option select2-results__option--load-more select2-custom-loading-results" role="treeitem" aria-disabled="true">' +
            '<svg class="svg-spinner pl-icon--light"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-spinner"></use></svg>' +
            '<span class="loading-message">Loading more...</span>' +
            '</li>'
          )

          return $option
        }
      })
    }


    // This will select the active option when you tab away.
    function selectOnTab() {
      $.fn.select2.amd.require([
        'select2/defaults',
        'select2/keys',
        'select2/utils'
      ], function(Defaults, KEYS, Utils) {
        function SelectOnTab() {}

        SelectOnTab.prototype.bind = function(decorated, container, $container) {
          // It's already doing what we want in this case
          if (this.options.get('selectOnClose')) return
          decorated.call(this, container, $container)
          container.$dropdown.on('keydown', function selectOnTab(e) {
            if (e.which === KEYS.TAB) container.trigger('results:select', {})
          })
        }

        var _apply = Defaults.constructor.prototype.apply
        Defaults.constructor.prototype.apply = function() {
          var options = _apply.apply(this, arguments)
          options.dropdownAdapter = Utils.Decorate(
            options.dropdownAdapter,
            SelectOnTab
          )
          return options
        }
      })
    }


  })
