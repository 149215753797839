(function() {

  // Hepler to avoid confirm / closeThisDialog boilerplate.
  // Inject `dialog` into your conttroller/link function,
  // then just call it like this:

  // dialog('modal-directive', {
  //   directiveProp1: 'parentScopeProp1',
  //   directiveProp2: 'parentScopeProp2'
  // })

  angular.module('pl-shared')
    .service('dialog', function(_, ngDialog, $rootScope) {

      var DEFAULT_OPTS = {
        'confirm': 'confirm',
        'cancel': 'closeThisDialog',
        'dialog-options': 'dialogOptions'
      }

      function directiveTemplate(directive, attrs) {
        var opts = angular.extend({}, DEFAULT_OPTS, attrs)
        opts[directive] = ''
        return angular.element('<div/>', opts)[0].outerHTML
      }

      function componentTemplate(component, attrs) {
        var opts = angular.extend({}, DEFAULT_OPTS, attrs)
        // We need to modify the tag name after the template is created because
        // jquery does not add attrs to custom tag names for some reason
        return angular.element('<div/>', opts)[0].outerHTML
          .replace(/^<div/, '<' + component)
          .replace(/<\/div>$/, '</' + component + '>')
      }

      function setupScope(opts) {
        var prefixes = ['=', '&', '@']
        var parentScope = opts && opts.scope || $rootScope
        var $scope = parentScope.$new()

        // set watchers if necessary
        _.each(opts.scope && opts.attrs, function(val, key) {
          if (typeof val === 'string' && prefixes.indexOf(val[0]) >= 0) {
            opts.attrs[key] = key
            var attr = val.substring(1) || key
            opts.scope.$watch(attr, function(val) {
              $scope[attr] = val
            })
          }
        })

        return _.extend($scope, opts.attrs)
      }

      function confirm(opts) {
        if (!opts || !(opts.directive || opts.component)) throw new Error('Cannot create the dialog. A directive or component name is required.')

        var $scope = setupScope(opts)

        // Allow directive modals to set their own preclose callback by
        // setting `dialogOptions.preCloseCallback` to a function.
        var dialogOptions = $scope.dialogOptions = {}
        var values = _.keys(opts.attrs)
        var keys = _.map(values, function(k) { return k.replace(/[A-Z]/g, function(m) { return '-' + m.toLowerCase() }) })
        var attrsMap = _.object(keys, values)
        var template = opts.component ? componentTemplate(opts.component, attrsMap) : directiveTemplate(opts.directive, attrsMap)

        function preCloseCallback() {
          if (dialogOptions.preCloseCallback) return dialogOptions.preCloseCallback.apply(this, arguments)
        }

        return ngDialog.openConfirm({
          plain: true,
          closeByEscape: true,
          closeByNavigation: true,
          className: 'pl-ng-dialog',
          preCloseCallback: preCloseCallback,
          template: template,
          scope: $scope
        })
      }

      return {
        confirm: confirm
      }

    })

})()
