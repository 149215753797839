require('team-admin')
angular.module('team-admin')
  .directive('eventRsvpCard', function() {
    return {
      restrict: 'A',
      scope: {
        invite: '=',
        persona: '=',
        eventForm: '=',
        player: '='
      },
      template: require('./event-rsvp-card.html'),
      controller: function(
        pageViewHandler, $scope, $sce, renderContext, Alerts, $q, EventRsvp, SNAP
      ) {
        $scope.SNAP = SNAP
        $scope.editing = {}
        $scope.setResponse = function(response) {
          pageViewHandler.fireEvent('RSVP.'+response, 8)
          if (response !== $scope.invite.response) $scope.eventForm.$setDirty()
          $scope.invite.response = response
        }
        $scope.joinNames = function(firstName, lastName) {
          return firstName + ' ' + lastName
        }
      }
    }
  })
