angular.module('pl-shared')

  .factory('iframeFeedbackModal', function(dialog, $window) {
    return { create: create }
    function create(attrs) {
      return { open: open }
      function open() {
        // force the mobile nav to close (to reveal our modal)
        $window.document.dispatchEvent(new CustomEvent('se-left-nav.close', { detail: { detectChanges: false } }))
        return dialog.confirm({
          component: 'iframe-feedback-modal',
          attrs: attrs
        })
      }
    }
  })

  .component('iframeFeedbackModal', {
    bindings: {
      cancel: '<',
      confirm: '<',
      title: '<',
      url: '<',
      app: '<'
    },
    // template URLs don't work for the admin-nav-standalone app
    template: '<div class="pl-overlay">' +
                '<div ng-if="!$ctrl.complete" class="pl-modal--small">' +
                  '<header ng-if="$ctrl.title" class="pl-modal__header">' +
                    '<button  ng-click="$ctrl.cancel()" class="pl-modal__close" title="{{ \'MODAL.cancel\' | t }}"></button>' +
                    '<h1 class="pl-heading--xlarge" ng-bind="$ctrl.title"></h1>' +
                  '</header>' +
                  '<div class="pl-modal__content--iframe">' +
                    '<iframe class="pl-modal__iframe" ng-src="{{ $ctrl.url }}"></iframe>' +
                  '</div>' +
                '</div>' +
                '<div ng-if="$ctrl.complete" class="pl-modal--small">' +
                  '<div class="pl-modal__content">' +
                    '<se-empty-state' +
                      ' se-data-title="Thank you!"' +
                      ' se-data-centered="true"' +
                      ' se-data-icon="check-circle-outline-thin"' +
                      ' se-data-icon-accent="true"' +
                      ' se-data-action-label="Done"' +
                      ' se-data-action="$ctrl.confirm()">' +
                      '<se-cp-empty-state-description>' +
                        '<span>Your feedback will help us improve our products. If you need further help, please contact <a ng-href="{{ $ctrl.supportUrl }}" class="pl-link"></a>.<span>' +
                      '</se-cp-empty-state-description>' +
                    '</se-empty-state>' +
                  '</div>' +
                '</div>' +
              '</div>',
    controller: function($sce, currentUser, $window, $rootScope) {

      var $ = angular.element
      var $ctrl = this

      $ctrl.$onInit = init
      $ctrl.$onDestroy = destroy
      $ctrl.supportUrl = 'https://help.sportsengine.com'

      function init() {
        var params = $.param({
          name: currentUser.name,
          uuid: currentUser.uuid,
          se_app: this.app,
          se_url: $window.location.href
        })
        this.url = $sce.trustAsResourceUrl(this.url + '?' + params)
        $window.addEventListener('message', onPostMessage)
      }

      function destroy() {
        $window.removeEventListener('message', onPostMessage)
      }

      function onPostMessage(e) {
        if (e && e.data && e.data.type === 'feedback:complete') {
          if ($rootScope.$$phase) complete()
          else $rootScope.$apply(complete)
        }
      }

      function complete() {
        $ctrl.complete = true
      }
    }
  })
