angular.module('pl-shared')

  .service('RoleAssignments', function(DS, UserServicePersonas) {
    var RoleAssignments = DS.defineResource({
      name: 'role_assignments',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          user_service_personas: {
            localField: 'persona',
            localKey: 'persona_id',
            parent: true
          }
        }
      }
    })

    return RoleAssignments
  })
