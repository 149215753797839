require('team-admin')
angular.module('team-admin', [
  'ngRoute',
  'ngAnimate',
  'ngSanitize',
  'ngynSelect2',
  'ngImgCrop',
  'ngMessages',
  'ngCookies',
  'ngQuill',
  'i18ng',
  'firebase',
  'js-data',
  'ngDialog',
  'pl-shared',
  'renderContext',
  'ui.timepicker',
  'launch-darkly-flags',
  'minicolors',
  'ngclipboard',
  'feature-toggles',
  'app-permission',
  'se.ui.button',
  'se.ui.card',
  'se.ui.emptyState',
  'se.ui.icon',
  'se.ui.indicatorDot',
  'se.ui.gummyWorm',
  'se.ui.leftNav',
  'se.ui.list',
  'se.ui.menu',
  'se.ui.personaAvatar',
  'se.ui.spinner',
  'se.ui.teamAvatar',
  'se.ui.tooltip'
])
  .run(function(TeamCurrentUserService, payload, showError) {
    if (payload.serverError) return showError(payload.serverError)
  })
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('globalSessionInterceptor')
  })

if (typeof console !== 'undefined') console.injectorElementSelector = '.pl-app' // eslint-disable-line no-console
