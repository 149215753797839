require('team-admin')
angular.module('team-admin')
  .directive('svgIcon', function($http, $log, _) {
    return {
      restrict: 'E',
      scope: {
        iconName: '='
      },

      link: function($scope, $element) {
        // Example svg-icon directive markup:
        // <svg-icon icon="'star'" class="star large"></svg-icon>

        if (!$scope.iconName) return $log.error('svg-icon name attribute cannot be blank')

        var iconUrl = '/icons/' + $scope.iconName + '.svg'
        $http.get(iconUrl)
          .success(function(result) {
            var svg = angular.element(result)
            var classes = $element.attr('class')
            svg.attr('class', classes)
            $element.replaceWith(svg)
          })
          .error($log.error)
      }
    }
  })
