angular.module('pl-shared')
  .run(function(_) {
    _.mixin({
      merge: function() {
        throw 'Do not use _.merge; it has been identified as a security vulnerability prior to Lodash 4.17.11. Use angular.merge instead.'
      },
      defaultsDeep: function() {
        throw 'Do not use _.defaultsDeep; it has been identified as a security vulnerability prior to Lodash 4.17.13.'
      }
    })
  })
