require('team-admin')
angular.module('team-admin')
  .directive('viewDateNav', function() {
    return {
      restrict: 'A',
      template: require('./view-date-nav.html'),

      controller: function($, _, $scope, $routeParams, $location, moment) {

        var now = $scope.nowDate = moment.tz(new Date(), $scope.team.timezone)
        function updateViewDates() {
          var routeDate = ($routeParams.date || now.format('YYYY-MM')) + '-01'
          $scope.viewDate = moment.tz(routeDate, $scope.team.timezone)
          $scope.viewDateLongFormat = $scope.viewDate.format('MMMM YYYY')
          $scope.prevMonth = $scope.viewDate.clone().subtract(1, 'months').format('YYYY-MM')
          $scope.nextMonth = $scope.viewDate.clone().add(1, 'months').format('YYYY-MM')
        }
        updateViewDates()
        $scope.listenToRoot('$routeUpdate', updateViewDates)

        function updateSearchPrefix() {
          $scope.searchPrefix = $.param(_.omit($location.search(), 'date'))
          if ($scope.searchPrefix) $scope.searchPrefix += '&'
        }
        updateSearchPrefix()
        $scope.listenToRoot('$locationChangeSuccess', updateSearchPrefix)

      }
    }
  })
