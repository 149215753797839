angular.module('pl-shared')

  .component('modalNavBar', {

    replace: true,

    bindings: {
      backContext: '@',
      backText: '@',
      contextStrategy: '@'
    },

    template: require('/static/shared/components/modal-nav-bar/modal-nav-bar.html'),

    controller: function() {}

  })
