require('team-admin')
angular.module('team-admin')
  .directive('snFocused', function($timeout) {
    return {
      restrict: 'A',
      scope: {
        focused: '=snFocused'
      },
      link: function(scope, element) {
        scope.$watch('focused', function(value) {
          if (value) element.focus()
        })

        element.on('blur', function() { scope.focused = false })
      }
    }
  })
