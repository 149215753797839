angular.module('pl-shared')

  // Define mobile nav callbacks
  .constant('mobileNav', {
    toggle: function() {
      angular.element('body').toggleClass('nb-mobile-nav-open')
    },
    close: function() {
      angular.element('body').removeClass('nb-mobile-nav-open')
    }
  })

  // Setup nginbar config
  .config(function(seBarProvider, mobileNav) {
    seBarProvider.setMobileNav(mobileNav)
  })

  // directive to close mobile nav on click
  .directive('closeMobileNav', function(mobileNav) {
    return {
      restrict: 'A',
      link: function($scope, $element) {
        $element.on('click', mobileNav.close)
      }
    }
  })
