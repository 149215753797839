(function() {
  angular.module('pl-shared')

    // <form name="someForm" sn-validators="{
    //   group1: groupValidateFn,
    //   field3: fieldValidateFn
    // }">
    //   <input name="field1" sn-validate="'group1'" />
    //   <input name="field2" sn-validate="'group1'" />
    //   <input name="field3" sn-validate="'field3'" />
    // </form>

    .directive('snValidators', function(_) {

      function SnValidatorsCtrl($scope, $attrs) {
        this.modelGroups = {}
        this.validators = $scope.$eval($attrs.snValidators)
      }

      SnValidatorsCtrl.prototype.registerModel = function(name, model, validatorName) {
        var modelGroup = this.modelGroups[validatorName] = this.modelGroups[validatorName] || {}
        modelGroup[name] = model
        return modelGroup
      }

      SnValidatorsCtrl.prototype.unregisterModel = function(name, validatorName) {
        var modelGroup = this.modelGroups[validatorName]
        if (modelGroup) delete modelGroup[name]
      }

      return {
        controller: SnValidatorsCtrl
      }
    })

    .directive('snValidate', function() {
      return {
        require: ['^?snValidators', 'ngModel'],
        link: function($scope, $el, $attrs, $ctrls) {
          var snValidators = $ctrls[0]
          var ngModel = $ctrls[1]
          var propName = $attrs.name
          var validatorNames = [].concat($scope.$eval($attrs.snValidate) || [])
          _.each(validatorNames, function(validatorName) {

            var validatorFn = snValidators && snValidators.validators[validatorName]

            // ignore empty validator params which are useful for dynamic assignment
            if (!validatorName) return

            // allow directly assigned validator functions
            if (typeof validatorName === 'function') {
              validatorFn = validatorName
              validatorName = $attrs.snValidate
            }

            if (!validatorFn) return console.warn('SnValidate: validator ' + validatorName + ' not found.')

            var modelGroup = {}
            if (snValidators) {
              modelGroup = snValidators.registerModel(propName, ngModel, validatorName)
              $scope.$on('$destroy', function() {
                snValidators.unregisterModel(propName, validatorName)
              })
            }

            function modelGroupViewValues() {
              return _.mapValues(_.omit(modelGroup, propName), '$viewValue')
            }

            ngModel.$validators[validatorName] = function(modelVal, viewVal) {
              var scope = $scope
              var pseudoModel = _.mapValues(modelGroup, '$$rawModelValue') // $$rawModelValue allows it to use the value pre-validation
              pseudoModel[propName] = modelVal
              return !!validatorFn(pseudoModel, modelGroup, propName)
            }

            // Revalidate (to update model values) whenever a codependent field's view value changes
            if (snValidators) $scope.$watch(modelGroupViewValues, ngModel.$validate, true)
          })
        }
      }
    })

})()
