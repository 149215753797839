angular.module('pl-shared')
  .component('chatMessageHoverMenu', {
    bindings: {
      message: '=',
      userId: '=',
      fromUser: '=',
      allMessages: '=',
      isLikedByUser: '=',
      team: '<',
      popoverIsOpen: '='
    },
    template: require('/static/shared/components/chat/chat-message-hover-menu.html'),
    controller: function($http, dialog, i18ng) {
      var $ctrl = this

      $ctrl.setPopoverPosition = function() {
        return $ctrl.fromUser ? 'left' : 'right'
      }

      $ctrl.toggleLike = function() {
        if ($ctrl.isLikedByUser) {
          delete $ctrl.message.reactions[$ctrl.userId]
        }
        else {
          if (!$ctrl.message.reactions) $ctrl.message.reactions = {}
          $ctrl.message.reactions[$ctrl.userId] = true
        }
        $ctrl.isLikedByUser = !$ctrl.isLikedByUser
        $ctrl.allMessages.$save($ctrl.message)
      }

      $ctrl.saveImage = function() {
        $http.get($ctrl.message.contentUrl, {
          responseType: 'arraybuffer'
        })
          .success(function(data) {
            var anchor = angular.element('<a/>')
            var blob = new Blob([data])
            anchor.attr({
              href: window.URL.createObjectURL(blob),
              download: 'photo.png'
            })[0].click()
          })
      }

      $ctrl.hideMessage = function() {
        if (!$ctrl.message.hide) $ctrl.message.hide = {}
        $ctrl.message.hide[$ctrl.userId] = true
        $ctrl.allMessages.$save($ctrl.message)
      }

      $ctrl.deleteMessage = function() {
        dialog.confirm({
          component: 'confirm-dialog',
          attrs: {
            heading: i18ng.t('CHAT.MODAL_LABELS.delete_this_message'),
            message: i18ng.t('CHAT.MODAL_LABELS.cannot_be_undone'),
            confirmLabel: i18ng.t('CHAT.MESSAGE_OPTIONS.delete'),
            confirmAction: confirmDelete
          }
        })
      }

      function confirmDelete() {
        $ctrl.allMessages.$remove($ctrl.message)
      }
    }
  })
