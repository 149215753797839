angular.module('pl-shared')
  .factory('Program', function(_, apiV2, defaultDeserialize, DS, i18ng, paginate, ProgramConfiguration, Team) {
    function hasAction(actions, config, resource) {
      if (!config) return true
      if (!config[resource]) return false
      return _.contains(actions, config[resource])
    }

    var Program = DS.defineResource({
      name: 'programs',
      endpoint: '/v3/competition/programs',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      actions: {
        copy: {
          method: 'PUT',
          response: apiV2.deserializeAs('programs')
        }
      },
      relations: {
        hasOne: {
          programs: {
            localField: 'childProgram',
            get: function(Program, relationDef, program, orig) {
              return program.childProgramId() && Program.get(program.childProgramId())
            }
          },
          program_configurations: [
            {
              foreignKey: 'program_id',
              localField: 'programConfiguration'
            }
          ]
        },
        hasMany: {
          program_configurations: [
            {
              foreignKey: 'root_program_id',
              localField: 'descendentProgramConfigurations'
            }
          ]
        }
      },
      serialize: function(resourceConfig, program) {
        function escapeUnicode(str) {
          return str && String(str).replace(/[^\0-~]/g, function(ch) {
            return '\\u' + ('000' + ch.charCodeAt().toString(16)).slice(-4)
          })
        }
        var programParams = angular.copy(JSData.DSUtils.removeCircular(program))
        _.each(programParams.extended_attributes, function(xa) {
          if (angular.isObject(xa.value)) xa.value = escapeUnicode(JSON.stringify(xa.value)) // workaround because we can't figure it out in stat_ngin
        })
        return programParams
      },
      deserialize: function(resource, res) {
        var result = defaultDeserialize(resource, res)
        function parseXA(program) {
          _.each(program.extended_attributes, function(xa) {
            if (/^\{.*\}$/.test(xa.value)) xa.value = JSON.parse(xa.value)
          })
          return program
        }
        return angular.isArray(result) ? _.map(result, parseXA) : parseXA(result)
      },
      computed: {
        $extended: ['extended_attributes', function(xas) {
          return (xas || []).reduce(function(o, xa) {
            o[xa.key] = xa.value
            return o
          }, {})
        }],
        tagStatus: [
          'start_date', 'end_date',
          function(start_date, end_date) {
            var today = moment().format('YYYY-MM-DD')
            var endDateStr = moment(end_date).format('MMM D, YYYY')
            if (today < start_date) {
              return {
                status: 'upcoming',
                color: 'primary',
                label: i18ng.t('GOVERNING_SEASONS.STATUS.upcoming'),
                ended_label: i18ng.t('GOVERNING_SEASONS.STATUS.ends', { date: endDateStr })
              }
            }
            if (start_date <= today && today <= end_date) {
              return {
                status: 'active',
                color: 'success',
                label: i18ng.t('GOVERNING_SEASONS.STATUS.active'),
                ended_label: i18ng.t('GOVERNING_SEASONS.STATUS.ends', { date: endDateStr })
              }
            }
            if (end_date < today) {
              return {
                status: 'past',
                color: 'gray',
                label: i18ng.t('GOVERNING_SEASONS.STATUS.past'),
                ended_label: i18ng.t('GOVERNING_SEASONS.STATUS.ended', { date: endDateStr })
              }
            }
          }
        ]
      },
      methods: {
        childProgramId: function() {
          return _.chain(this.descendentProgramConfigurations)
            .first()
            .get('program_id')
            .value()
        },
        read: function(resource) {
          return hasAction(['read', 'write'], this.credential_configuration, resource)
        },
        write: function(resource) {
          return hasAction(['write'], this.credential_configuration, resource)
        },
        isActionLocked: function(action) {
          var value = _.get(this, '$extended.SESYSTEM_rosters_locked.' + action)
          var now = moment.utc()

          if (!value) return false
          if (value === true) return true

          var lockDate = moment.utc(value)
          if (lockDate.isValid()) {
            return now > lockDate
          }

          return false
        },
        loadRosterStatusRollup: function(useAffiliate, reload) {
          var program = this
          if (program.$rosterStatusRollup && !reload) return
          if (program.tagStatus.status == 'upcoming') return
          var statusApproved = ['approved', 'no_approval_required', 'approved_exception']
          var statusNeedsAttention = ['unsubmitted', 'rejected', 'auto_rejected', 'missing_information', 'pending']
          var statusUnsubmitted = ['unsubmitted']
          _.set(program, '$rosterStatusRollup.loading', true)
          var rollupProgram = useAffiliate ? program.childProgram : program
          if (rollupProgram) {
            Team.org_rollup({
              params: {
                org_id: rollupProgram.org_id,
                originator_system: 'StatNgin',
                program_originator_system: 'StatNgin',
                program_originator_type: 'program',
                program_originator_id: rollupProgram.id
              }
            })
              .then(function(result) {
                var statusRollup = _.get(result, 'team_roster_rollups[0].roster_player_statuses')
                var needsAttention = _.sum(_.pick(statusRollup, statusNeedsAttention))
                var unsubmitted = _.sum(_.pick(statusRollup, statusUnsubmitted))
                var approved = _.sum(_.pick(statusRollup, statusApproved))
                var count = 0, text, cssClass

                // Past season - show how many approved
                if (program.tagStatus.status == 'past') {
                  count = approved
                  text = 'approved'
                  cssClass = 'amount'
                }
                else {
                  // Active season for player approver org or player submitter org
                  // Approver org cannot view unsubmitted players
                  count = rollupProgram.write('teams') ? needsAttention : needsAttention - unsubmitted
                  text = 'needs_attention'
                  cssClass = count > 0 ? 'count' : 'amount'
                }

                angular.merge(program.$rosterStatusRollup, {
                  count: count,
                  text: i18ng.t('GOVERNING_SEASONS.ROSTER_STATUS_ROLLUP.' + text + (count == 1 ? '' : '_plural')),
                  class: cssClass,
                  loading: false
                })
              })
          }
          else {
            // Admin has not yet created this program (i.e. GS setup not yet initiated)
            angular.merge(program.$rosterStatusRollup, {
              count: 0,
              text: i18ng.t('GOVERNING_SEASONS.ROSTER_STATUS_ROLLUP.needs_attention_plural'),
              class: 'amount',
              loading: false
            })
          }
        }
      }
    })

    Program.defaultSortOrder = '-start_date,name'
    Program.defaultSortDir = 'asc'
    Program.types = ['GoverningSeason', 'Tournament', 'League']
    Program.read = function(config, resource) {
      return hasAction(['read', 'write'], config, resource)
    }
    Program.write = function(config, resource) {
      return hasAction(['write'], config, resource)
    }

    return paginate(Program)
  })
