angular.module('pl-shared')

  // Used a factory so these don't get accidentally modified
  .factory('apiV2', function(_, DS, i18ng) {

    var DEFAULT_ERROR_MESSAGE = i18ng.t('APIV2.default_error')

    return {
      deserialize: defaultDeserialize,
      deserializeAs: deserializeAs,
      errorMessages: errorMessages,
      headers: {
        Accept: 'application/vnd.ngin-api.v2, application/json',
        'X-SPORTS-VERSION': 'v1'
      }
    }

    function deserializeAs(resourceName, customDeserialize) {
      var deserialize = customDeserialize || defaultDeserialize
      return function(resp) {
        var data = deserialize(resp)
        var resource = DS.definitions[resourceName]

        if (!resource) return data
        if (_.isArray(data)) return _.map(data, createInstance)
        return createInstance(data)

        function createInstance(attrs) {
          return resource.createInstance(attrs)
        }
      }
    }

    function defaultDeserialize(resp) {
      var data = resp && resp.data
      return data ? data.result || data : resp
    }

    function errorMessages(resp, defaultMessage) {
      var err = resp.data && resp.data.error || resp.data

      if (typeof err === 'string') return [err]

      if (!err || !err.messages || !err.messages.length) {
        return [defaultMessage ? i18ng.t(defaultMessage) : DEFAULT_ERROR_MESSAGE]
      }

      return [].concat(err.messages)
    }

  })
