var RE_AUTH_SENTINEL_KEY = 'se_api_re_auth_sentinel'
var RE_AUTH_SENTINEL_VAL = '1'
var RE_AUTH_SENTINEL_EXPIRES_IN_MS = 1000 * 60 * 2 // 2 minutes
var TRIGGER_STATUSES = [-1, 401] // statuses that trigger the interceptor, include -1 to handle lack of cors headers in the gateway

angular.module('pl-shared')
  .factory('http401StaticInterceptor', ['_', '$cookies', '$q', '$timeout', '$location', 'ENV', 'OAuthState',
    function(_, $cookies, $q, $timeout, $location, ENV, OAuthState) {
      function seApiReAuthInterceptor(rejection) {
        // only trigger the seApi reAuth interceptor in the needed cases, so that we exclude other unrelated unauthorized errors
        var shouldReAuth = (
          (ENV.current !== 'development' || ENV.localPlatform) &&
          _.includes(TRIGGER_STATUSES, rejection.status)
        )
        if (shouldReAuth) {
          // use the reAuthSentinel to prevent redirect loop in case of persistent auth issues
          var reAuthSentinel = !!$cookies.get(RE_AUTH_SENTINEL_KEY)
          if (!reAuthSentinel) {
            var cookieExpires = (new Date(Date.now() + RE_AUTH_SENTINEL_EXPIRES_IN_MS)).toGMTString()
            $cookies.put(
              RE_AUTH_SENTINEL_KEY,
              RE_AUTH_SENTINEL_VAL,
              { expires: cookieExpires }
            )
            var app = $location.path().replace(/^\//, '').split('/').shift()
            $timeout(function() {
              // right now full signout, which brings user back through full login flow,
              // is the only way to get them to set the sportngin_session cookie on the alternate domain for se-api
              // UPDATE: this previously went to /session/signout - consider updating this to user-service signout if weird redirects happen
              var appName = $location.host().split('.')[0]
              OAuthState.initiateLogin(appName)
            })
          }
        }
        return $q.reject(rejection)
      }

      return { responseError: seApiReAuthInterceptor }

    }])
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('http401StaticInterceptor')
  }])
