angular.module('pl-shared')
  .service('localRelations', function(DS, _) {

    // Alters js-data interactions, so that resources defined with relations can be created and used on the front end.
    // These created items use temporary (negative) IDs and are injected into the data store after createInstance.
    // The temporary IDs are stripped off before saving.

    var TEMP_ID = 0

    function setup(Resource) {
      var definition = DS.definitions[Resource.name]
      var relations = definition.relationList
      var idAttr = definition.idAttribute

      var beforeInject = Resource.beforeInject
      var afterCreateInstance = Resource.afterCreateInstance
      var save = Resource.save

      // All instances must be injected for relations to work. However, after saving
      // we DO NOT inject the saved resource, but merge it onto the original instead.
      Resource.afterCreateInstance = function(options, instance) {
        // NOTE: inject args are reversed for some reason
        if (options.cacheResponse !== false) Resource.inject(instance, options)
        return afterCreateInstance(options, instance)
      }

      // Add temporary id before inject
      Resource.beforeInject = function(options, instance) {
        if (instance[idAttr] == null) instance[idAttr] = --TEMP_ID // eslint-disable-line no-eq-null
        return beforeInject(options, instance)
      }

      // TODO: does this get called from the instance's DSSave()?
      Resource.save = function(attrs, options) {
        options = options || {}
        var id = attrs[idAttr]
        if (_.startsWith(id, '_')) { // This is a copy of an existing resource, and should be treated as an update.
          var realId = id.replace(/^_/, '')
          options.cacheResponse = false // prevent the response from going into the DS since we will merge onto an existing resource
          options.afterUpdate = replaceTempId.bind(null, attrs, options.afterUpdate)
          return Resource.update(realId, _.extend({}, attrs, _.object([idAttr], [realId])), options)
        }
        else if (id < 0) {
          options.cacheResponse = false // prevent the response from going into the DS since we will merge onto an existing resource
          options.afterCreate = replaceTempId.bind(null, attrs, options.afterCreate)
          return Resource.create(_.omit(attrs, idAttr), options)
        }
        return save.call(Resource, attrs, options)
      }

      // Update associations that reference the old temp id
      function replaceTempId(instance, oldAfterCreate, resource, newInstance, cb) {
        _.each(relations, updateRelationIds)

        // We need to re-inject the resource to update it's location in the cache after changing the id.
        Resource.eject(instance)
        Resource.eject(newInstance) // in case we're updating an existing one
        _.extend(instance, newInstance) // this puts the new id onto the existing instance
        Resource.inject(instance)

        if (oldAfterCreate) oldAfterCreate(null, instance, cb)
        else cb(null, instance)

        // hasOne/hasMany - this will run while the instance still has the old id.
        function updateRelationIds(relation) {
          if (!relation.foreignKey) return
          var newId = newInstance[relation.localKey || idAttr]
          DS.definitions[relation.relation].ejectAll({ where: _.object([relation.foreignKey], [newId]) }) // for update
          var relatedInstances = [].concat(instance[relation.localField] || [])
          _.each(relatedInstances, function(relatedInstance) {
            relatedInstance[relation.foreignKey] = newId
          })
        }
      }

      return Resource
    }

    return setup

  })
