angular.module('pl-shared')
  .factory('Sports', function(SNAP) {
    return {
      getSportLogo: getSportLogo
    }

    function getSportLogo(sport) {
      var logo = SNAP.assetPath

      switch (sport) {
        case 'Baseball/Softball':
          logo += '/images/sport-ball-icons/baseball.svg'
          break
        case 'Basketball':
          logo += '/images/sport-ball-icons/basketball.svg'
          break
        case 'Football':
          logo += '/images/sport-ball-icons/american-football.svg'
          break
        case 'Hockey':
          logo += '/images/sport-ball-icons/hockey.svg'
          break
        case 'Ice Hockey':
          logo += '/images/sport-ball-icons/hockey.svg'
          break
        case 'Lacrosse':
          logo += '/images/sport-ball-icons/lacrosse.svg'
          break
        case 'Rugby Union':
          logo += '/images/sport-ball-icons/rugby.svg'
          break
        case 'Soccer':
          logo += '/images/sport-ball-icons/soccer.svg'
          break
        case 'Tennis':
          logo += '/images/sport-ball-icons/tennis.svg'
          break
        case 'Volleyball':
          logo += '/images/sport-ball-icons/volleyball.svg'
          break
        default:
          logo += '/images/sport-ball-icons/other.svg'
      }

      return logo
    }
  })
