angular.module('pl-shared')
  .service('pollUntilResolved', function(_, $q, $timeout, i18ng) {

    /* pollUntilResolved ***

      Executes a function on an interval until a condition is satisfied.
      Returns a promise that will be resolved when the success condition is met.
      Has support for a limit to the number of times the poll function should be run.

      USAGE:
      pollUntilResolved(options)
        options:    [object]
          success:     [function]  (required) A _.callback function that returns true if the polling condition is resolved.
          error:       [function]  (optional) A _.callback function that returns true if the poll should be stopped due to an error in the polling result.
          errorMsg:    [string]    (optional) A i18n string that will be used for the reject error message if the error function returns true.
          fetch:       [function]  (optional) A _.callback function (promise-friendly) that should fetch the data for the next success test.
          limit:       [number]    (optional) The maximum number of times that the poll should be run. Defaults to Infinity (no limit).
          limitMsg:    [string]    (optional) A i18n string that will be used for the reject error message if the limit is exceeded.
          interval:    [number]    (optional) The interval (in milliseconds) to poll at. Defaults to 1000.

      Example:
        Foo.create(attrs)
          .then(function(item) {
            return pollUntilResolved({
              success: { status: 'complete' },
              error: { status: 'errored' },
              errorMsg: 'An error occurred when trying to create __name__.',
              fetch: item.DSRefresh
              limit: 20,
              interval: 250
            })
          })
          .then(setAs(ctrl, 'createdItem'))
          .catch($log.error)
    */

    return function pollUntilResolved(options) {
      options = options || {}

      var success = _.callback(options.success) // _.identity if not provided
      var error = 'error' in options ? _.callback(options.error) : _.constant(false) // avoid false positives for errors
      var fetch = _.callback(options.fetch) // returns data undefined if not provided
      var limit = options.limit // undefined is NaN, > will always be false, so same as Infinity
      var interval = options.interval || 1000

      var count = 0
      return $q(function(resolve, reject) {
        (function poll() {
          $q.when(fetch()) // fetch on the leading edge
            .then(function(data) {
              if (success(data)) return resolve(data)
              if (error(data)) return reject({ message: i18ng.t(options.errorMsg, data), data: data })
              if (++count >= limit) return reject({ message: i18ng.t(options.limitMsg || 'POLL_UNTIL_RESOLVED.limit_exceeded', data), data: data })
              $timeout(poll, interval)
            })
            .catch(reject)
        })()
      })
    }

  })
