require('team-admin')
angular.module('team-admin')
  .directive('eventRsvps', function() {
    return {
      restrict: 'A',
      scope: {
        team: '=',
        event: '=',
        originalInvitations: '=invitations',
        rsvpFor: '@'
      },
      template: require('./event-rsvps.html'),
      controller: function($scope, $log, $timeout, selectHelper, EventRsvp, _, dialog, renderContext, currentUser, SNAP) {
        $scope.scope = $scope
        $scope.editing = {}
        $scope.selectedPlayerRsvps = selectHelper.bind($scope, 'players')
        $scope.loading = true
        $scope.SNAP = SNAP
        currentUser.getPersonas()

        loadPlayers()

        $scope.isPastEvent = function() {
          var eventStartDateTime = moment($scope.event.start_date)
          var oneDayAgo = moment().subtract(1, 'days')
          return !eventStartDateTime.isBefore(oneDayAgo)
        }

        $scope.$watchGroup(['originalInvitations', 'originalInvitations.length', 'originalPlayers', 'originalPlayers.loading', 'rsvpFor'], function(data) {
          var [invitations, invitationsLength, players, playersLoading, rsvpFor] = data

          if (!invitations || !players || playersLoading) return

          var emptyMessagePrefix = 'SCHEDULE.EVENT_RSVPS.'

          if (rsvpFor === 'participant') {
            players = _.filter(players, { roster_role: 'player' })
            emptyMessagePrefix = 'SCHEDULE.EVENT_RSVPS.RSVP_PARTICIPANT.'
            $scope.memberType = 'Player'
          }
          else if (rsvpFor === 'staff') {
            players = _.filter(players, { roster_role: 'staff' })
            emptyMessagePrefix = 'SCHEDULE.EVENT_RSVPS.RSVP_STAFF.'
            $scope.memberType = 'Staff'
          }

          var playerByPersona = _.indexBy(players, 'persona.persona_id')

          invitations = _.filter(invitations, function(value) {
            return !!playerByPersona[value.persona_id]
          })

          $scope.loading = false
          $scope.invitations = angular.copy(invitations)
          $scope.players = angular.copy(players)
          $scope.playerByPersona = playerByPersona
          $scope.hasMembers = !!players.length
          $scope.emptyMessagePrefix = emptyMessagePrefix + ($scope.hasMembers ? 'EMPTY' : 'EMPTY_TEAM')

          _.forEach($scope.players, function(player, key) {
            $scope.players[key].invitation = _.find(invitations, { persona_id: player.persona.persona_id })
          })

          $scope.invitedPlayers = _.filter($scope.players, 'invitation')
          updateSavedRsvps()
          updateRsvpResponseCounts()
          updateInviteOrder($scope.invitedPlayers)
        })

        $scope.cancel = function() {
          goBack()
        }

        $scope.saveRsvp = function(player) {
          $scope.editing = {}
          return EventRsvp.create({
            ..._.pick(player.invitation, 'event_id', 'persona_id', 'response', 'note'),
            team_id: $scope.team.id
          })
            .then(function(rsvp) {
              player.invitation = rsvp
            })
        }

        function goBack() {
          renderContext.requireConfirmation(false)
          renderContext.backto(renderContext.schedule.next)
        }

        $scope.sendRemindersModal = function() {
          dialog.confirm({
            directive: 'rsvp-reminders',
            scope: $scope,
            attrs: {
              event: $scope.event,
              invitedPlayers: $scope.invitedPlayers,
              team: $scope.team,
              rosterRole: $scope.players[0].roster_role,
            }
          })
        }

        $scope.newMessageModal = function() {
          dialog.confirm({
            directive: 'new-team-message',
            scope: $scope,
            attrs: {
              team: $scope.team,
              roster: $scope.originalPlayers,
              recipients: _.map($scope.selectedPlayerRsvps, function(player) { return player.persona.persona_id })
            }
          })
        }

        $scope.listenToRoot('event_rsvp:update_response', function($event, player) {
          $scope.saveRsvp(player)
          updateRsvpResponseCounts()
          updateInviteOrder($scope.invitedPlayers)
        })

        function updateRsvpResponseCounts() {
          $scope.yesCount = _.where($scope.invitedPlayers, { 'invitation': { 'response': 'yes' } }).length
          $scope.maybeCount = _.where($scope.invitedPlayers, { 'invitation': { 'response': 'maybe' } }).length
          $scope.noCount = _.where($scope.invitedPlayers, { 'invitation': { 'response': 'no' } }).length
        }

        // by category (Going, Not Going, Maybe, Pending), then by first/last name
        var RESPONSE_ORDER = ['yes', 'no', 'maybe', 'no_response']
        var inviteOrderMap = {}

        $scope.savedInviteOrder = function(player) {
          if (player.invitation) return inviteOrderMap[player.invitation.id]
        }

        function updateInviteOrder(players) {
          _.each(players, function(player) {
            var response = player.invitation?.response
            var responseIndex = RESPONSE_ORDER.indexOf(response)
            if (responseIndex === -1) responseIndex = RESPONSE_ORDER.length // not found (pending) go last
            var orderKey = responseIndex + player.name
            inviteOrderMap[player.invitation.id] = orderKey
          })
        }

        function updateSavedRsvps() {
          $scope.savedRsvps = {}
          _.each($scope.invitedPlayers, function(player) {
            $scope.savedRsvps[player.invitation.id] = angular.copy(player.invitation)
          })
        }

        function loadPlayers() {
          return $scope.team.getRoster()
            .then($scope.setAs('originalPlayers'), $log.error)
        }
      }
    }
  })
