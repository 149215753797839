require('team-admin')
angular.module('team-admin')
  .directive('addEvent', function() {
    return {
      restrict: 'A',
      scope: {
        team: '=',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      template: require('./add-event.html'),
      controller: function(pageViewHandler, $scope, $rootScope, $q, $timeout, _, Event, Alerts, Venue) {
        pageViewHandler.fireEvent('NewEventModal.Open')
        $scope.event = Event.createInstance({
          event_type: 'event',
          status: 'scheduled',
          local_timezone: $scope.team.timezone_identifier,
          with_notification: false, // !!$scope.featureToggles.availability,
          principals: [
            {
              id: $scope.team.id, // CS will fill in the rest, this is just for extended_attributes
              extended_attributes: {}
            }
          ]
        })

        $scope.scope = $scope
        $scope.buttonLabel = 'SCHEDULE.ADD_EVENT.button_label'

        $scope.createEvent = function() {
          pageViewHandler.fireEvent('NewEventModal.AddEvent', 8)
          $scope.failed = false

          var clonedEvent = angular.copy($scope.event)
          var recurs = $scope.event.recurInterval !== 'none'
          var endpoint = `/v3/calendar/team/${ $scope.team.id }/event${ recurs ? '_series' : '' }`

          return Venue.check(clonedEvent, $scope.team)
            .then(clonedEvent => Event.create(clonedEvent, { endpoint }))
            .then(function checkStatus(es) {
              if (recurs && es.series_status !== 'completed') {
                return $timeout(() => es.DSRefresh({ endpoint, params: { show_events: 1 } }).then(checkStatus), 1000)
              }
              if (recurs) $rootScope.$emit('event:save_recurring') // listeners reload on this event
              $scope.createdEvent = recurs ? Event.inject(es.event_list)[0] : es
              $timeout($scope.cancel) // confirms in preCloseCallback
            })
            .catch(err => $scope.failed = true)
        }

        $scope.dialogOptions.preCloseCallback = function() {
          if ($scope.eventForm.$submitting) return false
          if ($scope.createdEvent) {
            Alerts.success('SCHEDULE.ADD_EVENT.success')
            $scope.confirm($scope.createdEvent)
            return false
          }
        }
      }
    }
  })
