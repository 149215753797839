require('team-admin')
angular.module('team-admin')
  .directive('nonRemovableEvents', function() {

    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        events: '=',
        team: '='
      },
      template: require('./non-removable-events.html'),
      controllerAs: 'ctrl',
      controller: function(_, i18ng) {

        var ctrl = this

        ctrl.groupedEvents = _.groupBy(ctrl.events, e => e.shared_event?.management_org_name || e.scheduled_by?.org_name)

        ctrl.translationOpts = function(hostName, events) {
          return {
            host_name: hostName,
            team: ctrl.team,
            count: events.length,
            event: events[0]
          }
        }

      }
    }
  })
