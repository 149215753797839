// Filter to convert US formated dates into a standard YYYY-MM-DD String
angular.module('pl-shared')
  .filter('feetAndInches', function() {
    return function(input) {
      var numInches = parseInt(input, 10)
      if (input != numInches) return input
      var feet = Math.floor(numInches / 12)
      var inches = numInches % 12
      return feet + "' " + inches + '"'
    }
  })
