require('team-admin')
angular.module('team-admin')
  .directive('rosterDirective', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./roster.html'),
      controller: function ($routeParams, $window, ENV, launchDarklyFlags) {
        var ctrl = this
        ctrl.$onInit = $onInit

        function $onInit() {
          if (!!launchDarklyFlags.newTeamCenterEnabled) $window.location.href = `${ENV.urls.newTeamCenter}/team/${$routeParams.teamId}/roster`
        }
      }
    }
  })
