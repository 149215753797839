require('team-admin')
angular.module('team-admin')
  .directive('resendInvites', function() {
    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '='
      },
      template: require('./resend-invite.html')
    }
  })
