require('team-admin')
angular.module('team-admin')
  .directive('accessDenied', function() {
    return {
      restrict: 'A',
      scope: {},
      template: require('./access-denied.html'),
      controller: function($scope, Team, $routeParams, i18ng) {
        Team.find($routeParams.teamId).then($scope.setAs('team'))
      }
    }
  })
