angular.module('pl-shared')
  .factory('userSettings', function($window) {

    function getSettings() {
      var storage = $window.localStorage || ($window.localStorage = {})
      try {
        if (!storage.userSettings) storage.userSettings = '{}'
        return JSON.parse(storage.userSettings)
      }
      catch (e) {
        console.error('Error parsing userSettings data', e, storage.userSettings)
        return {}
      }
    }

    function setSettings(settings) {
      $window.localStorage.userSettings = JSON.stringify(settings)
    }

    function get(path) {
      var obj = getSettings()
      var parts = path.split('.')
      while (obj && parts.length) obj = obj[parts.shift()]
      return obj
    }

    function set(path, val) {
      try { JSON.stringify(val) }
      catch (e) { throw new Error('Cannot stringify value for storage', { path: path, value: val, error: e }) }

      var settings = getSettings()
      var obj = settings
      var parts = path.split('.')
      var prop = parts.pop()
      while (parts.length) {
        var part = parts.shift()
        obj = obj[part] = obj[part] || {}
      }
      obj[prop] = val
      setSettings(settings)
      return val
    }

    function remove(path) {
      var settings = getSettings()
      var obj = settings
      var parts = path.split('.')
      var prop = parts.pop()
      while (obj && parts.length) obj = obj[parts.shift()]
      if (!obj || !(prop in obj)) return false

      delete obj[prop]
      setSettings(settings)
      return true
    }


    return {
      get: get,
      set: set,
      remove: remove
    }

  })
