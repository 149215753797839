angular.module('pl-shared')

  .directive('setFocus', function($timeout) {
    return {
      restrict: 'A',
      link: function($scope, $element, $attrs) {
        // ignore focus if attribute is present and evaluates to falsy
        if ($attrs.setFocus && !$scope.$eval($attrs.setFocus)) return

        // focus after select2 initializes and don't run another digest
        $timeout(function() {
          // make the element focusable if it isn't already
          if (!$element.attr('tabindex')) $element.attr('tabindex', 0)
          if ($element.data('select2')) $element.data('select2').focus()
          else $element.focus()
        }, 10, false)
      }
    }
  })
