(function() {
  'use strict'

  function addHandlerDirective(eventType) {
    var snEventType = eventType.replace(/^[a-z]/, function(c) { return 'sn' + c.toUpperCase() })
    angular.module('pl-shared')
      .directive(snEventType, function() {
        return {
          restrict: 'A',
          priority: 100, // add handlers ahead of ng-src directive
          link: function($scope, $element, $attrs) {
            $element.on(eventType, function(event) {
              var ret = $scope.$eval($attrs[snEventType], { $event: event })
              if (typeof ret === 'function') ret(event)
            })
          }
        }
      })
  }

  // Angular-compatible event handlers for img and other elements/events not covered by Angular
  addHandlerDirective('load')
  addHandlerDirective('error')
  addHandlerDirective('change')
  addHandlerDirective('scroll')

})()
