angular.module('pl-shared')

  .directive('websiteQuestionView', function() {

    return {
      scope: {},
      template: require('/static/shared/components/regform/questions/website-question-view.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this
        var answer = ctrl.answer || {}
        var value = answer.display_value

        ctrl.displayValue = '--'
        ctrl.websiteUrl = ''

        if (!value) return

        var hasProtocol = value.indexOf('://') !== -1
        var isHttps = value.indexOf('https://') !== -1

        ctrl.websiteUrl = hasProtocol ? value : 'http://' + value
        ctrl.displayValue = isHttps ? value : hasProtocol ? value.split('://')[1] : value
      }
    }

  })
