angular.module('pl-shared')
  .factory('Subvenue', function(apiV2, DS, localRelations, paginate) {
    var Subvenue = DS.defineResource({
      name: 'subvenues',
      // endpoint: 'subvenues',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          venues: {
            localKey: 'venue_id',
            localField: 'venue',
            parent: true
          }
        }
      }
    })

    Subvenue.syncFields = function(event) {
      event.location_description = event.subvenue?.name || ''
    }

    return paginate(localRelations(Subvenue))
  })
