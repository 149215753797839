/*eslint-env es6 */
angular.module('pl-shared')
  .service('fileHelper', function() {

    var imageTypes = {
      gif: 'image/gif',
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      png: 'image/png'
    }

    /* This type set is required because GIF images are not supported ngin services, but are supported by other services (e.g. boss_service) */
    var staticImageTypes = {
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      png: 'image/png'
    }

    var teamImageTypes = {
      eps: 'application/postscript',
      jpg: 'image/jpeg',
      png: 'image/png',
      svg: 'image/svg+xml'
    }

    var docTypes = {
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      json: 'application/json',
      odt: 'application/vnd.oasis.opendocument.text',
      pdf: 'application/pdf',
      ppt: 'application/vnd.ms-powerpoint',
      pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }

    var vectorTypes = {
      ai: 'application/postscript',
      cdr: 'application/cdr',
      eps: 'application/postscript',
      svg: 'image/svg+xml'
    }

    var textTypes = {
      csv: 'text/csv',
      json: 'text/json',
      txt: 'text/plain'
    }

    var fileTypes = angular.merge({}, imageTypes, vectorTypes, textTypes, docTypes)

    return {
      base64Data: base64Data,
      blobify: blobify,
      textTypes: _.keys(textTypes),
      imageTypes: _.keys(imageTypes),
      staticImageTypes: _.keys(staticImageTypes),
      vectorTypes: _.keys(vectorTypes),
      docTypes: _.keys(docTypes),
      mimeTypes: fileTypes,
      isImage: isImage,
      parseUrl: parseUrl,
      stripBase64Data: stripBase64Data,
      makeFileObj,
      getEXIFOrientation: getEXIFOrientation
    }

    function isImage(file) {
      if (!file) return false
      return _.startsWith(file.filetype, 'image/')
    }

    function parseUrl(url, thumbnailUrl) {
      if (!url) return {}
      var parts = url.split('.')
      var ext = parts.length > 1 ? parts.pop().toLowerCase() : 'txt' // default if unknown

      return {
        filename: url.slice(url.lastIndexOf('/') + 1),
        filetype: fileTypes[ext] || 'text/plain',
        thumbnailUrl: thumbnailUrl,
        url: url
      }
    }

    function stripBase64Data(fileData) {
      return fileData.replace(/^data:([^;]+);base64,/, '')
    }

    function base64Data(file) {
      if (!file || !file.data || !file.filetype) return null
      return 'data:' + file.filetype + ';base64,' + file.data
    }

    function blobify(data, filetype) {
      var byteArrays = []
      var sliceSize = 512

      for (var offset = 0; offset < data.length; offset += sliceSize) {
        var slice = data.slice(offset, offset + sliceSize)
        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        byteArrays.push(new Uint8Array(byteNumbers))
      }

      return new Blob(byteArrays, { type: filetype })
    }

    // Makes a new File object from base64 data and a file name.
    function makeFileObj(base64Data, filename) {
      var [, type, data] = base64Data.match(/^data:([^;]+);base64,(.*)$/)
      var fileBits = Uint8Array.from(atob(data), c => c.charCodeAt(0))
      return new File([fileBits], filename, { type })
    }

    // getEXIFOrientation returns EXIF orientation value of an uploaded jpeg file
    // logic based on -> https://stackoverflow.com/questions/7584794
    // If the file uploaded is not a jpeg, callback returns -1.
    // If the jpeg file contains NO EXIF Orientation, callback returns 0.
    function getEXIFOrientation(file, callback) {
      var reader = new FileReader()
      reader.onload = function(e) {

        var view = new DataView(e.target.result)
        if (view.getUint16(0, false) !== 0xFFD8) return callback(-1)
        var length = view.byteLength
        var offset = 2
        while (offset < length) {
          var marker = view.getUint16(offset, false)
          offset += 2
          if (marker === 0xFFE1) {
            var little = view.getUint16(offset += 8, false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            var tags = view.getUint16(offset, little)
            offset += 2
            for (var i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                return callback(view.getUint16(offset + (i * 12) + 8, little))
              }
            }
          }
          else if ((marker & 0xFF00) !== 0xFF00) break
          else offset += view.getUint16(offset, false)
        }
        return callback(0)
      }
      reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
    }

  })
