angular.module('pl-shared')
  .component('confirmDialog', {
    bindings: {
      cancel: '<',
      confirm: '<',
      heading: '<',
      message: '<',
      modalSize: '<',
      confirmAction: '<?',
      confirmLabel: '<?',
      cancelLabel: '<?',
      deleteButton: '@?'
    },
    template: require('/static/shared/components/confirm-dialog/confirm-dialog.html'),
    controller: function(_, i18ng, $sce, $q, $element) {
      var $ctrl = this
      var SIZES = ['extra-small', 'small', 'medium', 'medium-large', 'large', 'xxlarge']
      var DEFAULT_SIZE = 'medium-large'

      if (SIZES.indexOf($ctrl.modalSize) === -1) $ctrl.modalSize = DEFAULT_SIZE

      $ctrl.$onInit = function() {
        _.defaults($ctrl, {
          cancelLabel: i18ng.t('MODAL.cancel'),
          confirmLabel: i18ng.t('MODAL.confirm')
        })
        $ctrl.message = $sce.trustAsHtml($ctrl.message)
        if ($ctrl.confirmAction) {
          var confirm = $ctrl.confirm
          $ctrl.confirm = function() {
            return $q.resolve($ctrl.confirmAction.call(null, arguments))
              .then(confirm)
          }
        }
      }
    }
  })
