angular.module('pl-shared')
  .service('setWhile', function(_, $q, setAs) {

    /* setWhile ***

      Returns a function that sets a specific property to true on the specified object, while a promise is running.
      This is most useful for setting a scope/controller property while a load is running.

      USAGE:
      setWhile(target, property, loadFnc)
        target:   [object]   The object to set the property on.
        property: [string]   The name of the property that should be set while running.
        loadFnc:  [function] The function to run. Should return a promise.

      Example:
        setWhile($scope, 'loading', loadMyStuff)()                          // call returned function in order to execute immediately
        $scope.$watch('params', setWhile($scope, 'updating', loadParams))   // returned function plays nicely with $watch
    */

    return function setWhile(target, property, loadFnc) {
      return function() {
        _.set(target, property, true)
        var ret = typeof loadFnc === 'function' ? loadFnc.apply(this, arguments) : loadFnc
        $q.when(ret).finally(setAs(target, property, false))
        return ret
      }
    }

  })
