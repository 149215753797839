require('team-admin')
angular.module('team-admin')

  .config(function($locationProvider, seBarProvider) {
    $locationProvider.html5Mode({ enabled: true, requireBase: false })
    seBarProvider.setApp('team-center')
    seBarProvider.setTheme('light')
  })

  .constant('defaultPageDepths', { depth1: 'TeamCenter', depth2: 'Generic' })

// Timepicker Config (requires moment in the global scope)
angular.module('ui.timepicker')
  .value('uiTimepickerConfig', {
    step: 15,
    appendTo: 'body',
    asMoment: true,
    showOn: 'click',
    matchElementWidth: true,
    timeFormat: function(d) {
      return moment(d).format('LT').toLowerCase()
    },
    scrollDefault: '12:00'
  })
