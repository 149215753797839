angular.module('pl-shared')

  .directive('defaultQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/default-question.html'),
      bindToController: {
        question: '=',
        answer: '='
      },
      controllerAs: 'ctrl',
      controller: function() {
      }
    }

  })
