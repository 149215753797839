'use strict'

var MININUM_DURATION = 2000
var DURATION_MODIFIER = 30
var FADE_RESTART_DURATION = 2000

angular.module('pl-shared')

  /**
   * Provides a service to show toast notifications to the user
   *
   * Each function takes in the i18ng translation key for the message and an object of options
   * Options: {
   *   title {String} - The title to display on the toast notification
   *   ignoreOptions: { - When provided, will allow the user to ignore future messages
   *     id {String} - The unique string for the alert
   *     countThreshold {Number} - How many times should we show the alert before we start auto ignoring it
   *   }
   * }
   *
   * @returns {Object} - A service that provides the ability to show info, success, alert, and warnings
   */
  .factory('Alerts', function($timeout, userSettings, moment, i18ng) {
    var alerts = []
    var api = {
      alerts: alerts,
      info: show('pl-alert-popup--info', 'info-circle-o'),
      success: show('pl-alert-popup--success', 'check-circle'),
      alert: show('pl-alert-popup--alert', 'warning'),
      warn: show('pl-alert-popup--warn', 'warning'),
      remove: remove,
      clearIgnoreStats: clearIgnoreStats
    }

    // Alias old methods
    api.show = api.info
    api.error = api.alert
    api.warning = api.warn

    return api

    function show(cssClass, icon) {
      return function(key, options) {
        if (!key) return // seems pointless to show an empty alert

        if (shouldIgnoreAlert(options)) {
          return
        }

        options = options || {}
        var message = i18ng.t(key, options)
        var title = options && options.title || null
        var duration = calculateDuration(message)
        var alert = angular.extend({
          cssClass: cssClass,
          icon: icon,
          message: message,
          title: title,
          duration: duration,
          ignoreOptions: options.ignoreOptions
        })

        if (!options.noTimeout) alert._timer = $timeout(remove.bind(null, alert), duration)
        alerts.push(alert)

        updateShownCount(alert)

        return alert
      }
    }

    function shouldIgnoreAlert(options) {
      if (!!options && !!options.ignoreOptions && !!options.ignoreOptions.id) {
        var ignoreData = userSettings.get('alerts.' + options.ignoreOptions.id)
        if (!!ignoreData && !!ignoreData.countThreshold) {
          var countThreshold = ignoreData.countThreshold
          var currentCount = ignoreData.currentCount

          if (!countThreshold || currentCount >= countThreshold) {
            return true
          }
        }
      }

      return false
    }

    function updateShownCount(alert) {
      if (!alert.ignoreOptions || !alert.ignoreOptions.id || !alert.ignoreOptions.countThreshold) return

      var currentCount = 0

      var currentIgnoreData = userSettings.get('alerts.' + alert.ignoreOptions.id)
      if (currentIgnoreData) {
        currentCount = currentIgnoreData.currentCount
      }

      var ignoreData = {
        id: alert.ignoreOptions.id,
        countThreshold: alert.ignoreOptions.countThreshold,
        currentCount: currentCount + 1
      }

      userSettings.set('alerts.' + alert.ignoreOptions.id, ignoreData)
    }

    function remove(alert) {
      $timeout.cancel(alert._timer)
      var idx = alerts.indexOf(alert)
      if (idx > -1) alerts.splice(idx, 1)
    }

    function calculateDuration(message) {
      return MININUM_DURATION + (message.length * DURATION_MODIFIER)
    }

    function clearIgnoreStats(id) {
      userSettings.remove('alerts.' + id)
    }

  })

  .directive('alerts', function alertsDirective(Alerts, $timeout) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {},
      template: require('/static/shared/components/alerts/alerts.html'),

      controller: function alertsDirectiveCtrl($scope) {
        $scope.alerts = Alerts.alerts
        $scope.close = function(alert) {
          Alerts.remove(alert)
        }
        $scope.timeout = function(alert, cancel) {
          if (alert._timer) {
            if (cancel) $timeout.cancel(alert._timer)
            else alert._timer = $timeout(Alerts.remove.bind(null, alert), FADE_RESTART_DURATION)
          }
        }
      }
    }
  })
