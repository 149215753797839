require('team-admin')
angular.module('team-admin')
  .directive('unauthorized', function() {
    return {
      restrict: 'A',
      scope: {},
      template: require('./unauthorized.html'),
      controller: function($scope, $window) {
        $scope.clearSession = function() {
          // Forces a page refresh to escape the Angular router.
          $window.location.href = '/session/clear?next=' + encodeURIComponent($window.location.href)
        }
      }
    }
  })
