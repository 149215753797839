angular.module('feature-toggles', [])

  .service('featureToggles', function(DS, _, $q, $rootScope, $route, $routeParams, renderContext, showError, Team) {

    var FeatureToggle = DS.defineResource({
      name: 'feature_toggles',
      endpoint: 'feature_instances',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })
    var featureToggles = {}
    var teamId
    var deferred

    function update() {
      if (renderContext.layout === 'error') return
      if (/^\d*$/.test($routeParams?.teamId || '') || $routeParams.teamId === teamId) return

      deferred = $q.defer()
      featureToggles.promise = deferred.promise

      getTeam()
        .then(getFeatures)
        .then(parse)
        .catch(handleError)
        .finally(done)

    }

    // TEAM (loading team here to get the org_id)

    function getTeam() {
      teamId = $routeParams.teamId
      return teamId ? Team.find(teamId) : $q.reject(new Error('No team id found.'))
    }

    function getFeatures(team) {
      return FeatureToggle.findAll({
        organization_id: team?.org_details?.id
      })
    }

    function parse(features) {
      clearFeatures()
      featureToggles.features = features

      _.each(features, function(feature) {
        if (feature.can_use) featureToggles[feature.key] = feature.release_stage || true
      })

      // This overrides certain features that were originally used throughout the system,
      // but have needed to be used in different scenarios as usage cases expanded.
      // Overriding them here allows them to be opened up, without destroying the checkpoints
      // in case they need to be restricted again at a later time.
      _.extend(featureToggles, {
        team_management: true,
        team_center_for_participants: true,
        next_generation_messaging: true,
        availability: true,
        league_custom_opponents: true,
        allow_event_data_bags: true
      })

      deferred.resolve(featureToggles)
    }

    function handleError(error) {
      clearFeatures()
      deferred.reject()
      showError(error)
    }

    function clearFeatures() {
      for (var key in featureToggles) { delete featureToggles[key] }
    }

    function done() {
      featureToggles.loaded = true
    }

    $rootScope.$on('$routeChangeSuccess', update)
    if ($route.current) update()

    return featureToggles

  })
  .run(function($rootScope, featureToggles) {
    $rootScope.constructor.prototype.featureToggles = featureToggles
  })
