(function() {
  'use strict'

  var _ // Save _ between factory creations, needed for testing with karma

  angular.module('pl-shared')
    .factory('_', function($window) {
      return _ = _ || $window._
    })

})()
