require('team-admin')
angular.module('team-admin')
  .directive('squadLocker', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        team: '='
      },
      controllerAs: 'ctrl',
      template: require('./squad-locker.html'),
      controller: function(pageViewHandler, _, $q, $scope, $timeout, AttachmentService, dialog, featureToggles, fileHelper, i18ng, pollUntilResolved, setAs, setWhile, showError, SNAP, SquadLockerStores, TeamHighResImages) {
        if (!featureToggles.squadlocker_integration) return showError('404')

        var ctrl = this
        var team_id = ctrl.team.isNginOriginator ? (ctrl.team.nginTeamId || ctrl.team.originator_id) : ctrl.team.id
        ctrl.color = ctrl.team.primary_color
        ctrl.editLogo = editLogo
        ctrl.fileHelper = fileHelper
        ctrl.launchStore = launchStore
        ctrl.squadlockerLogoPath = SNAP.assetPath + '/images/squadlocker-logo.svg'
        ctrl.onDelete = function() {
          delete ctrl.squadLockerStore
        }


        setWhile(ctrl, 'loading', function() {
          return findStores()
            .then(function(stores) {
              return _.last(_.filter(stores, 'squad_locker_store_id'))
            })
            .then(setAs(ctrl, 'squadLockerStore'))
            .then(function(store) {
              if (!store) setWhile(ctrl, 'loadingLogo', getLogo)()
            })
        })()


        function editLogo() {
          dialog.confirm({
            directive: 'file-upload-modal',
            scope: $scope,
            attrs: {
              fileUploadOptions: {
                accept: ['svg', 'eps', 'png', 'jpg'],
                allowCrop: false,
                showFilename: true,
                showSupports: true,
                showSizeLimit: false,
                titleText: i18ng.t('SQUAD_LOCKER.LABELS.upload_your_logo'),
                uploadLinkText: i18ng.t('SQUAD_LOCKER.LABELS.upload_a_logo'),
                clearLinkText: i18ng.t('SQUAD_LOCKER.LABELS.clear_logo')
              },
              model: ctrl.logo
            }
          })
            .then(setAs(ctrl, 'logo'))
        }

        function findStores() {
          return SquadLockerStores.findAll({ resource_type: 'Team', resource_id: team_id }, { bypassCache: true })
        }

        var originalLogo
        function getLogo() {
          return $q.when(ctrl.team.isNginOriginator ?
            TeamHighResImages.findAll({ team_id }) :
            ctrl.team.logo && fileHelper.parseUrl(ctrl.team.logo?.image_urls?.large_uncropped))
            .then(function(logo) {
              ctrl.logo = originalLogo = logo
              if (logo && logo.public_filename) _.defaults(ctrl.logo, fileHelper.parseUrl(logo.public_filename))
            })
        }

        function saveLogo() {
          if (!ctrl.logo.data) return // means a new upload hasn't occurred

          if (ctrl.team.isNginOriginator) {
            _.extend(ctrl.logo, { team_id, photo: ctrl.logo.data })
            return $q.when(originalLogo && TeamHighResImages.destroy(originalLogo.id))
              .then(() => TeamHighResImages.create(ctrl.logo))
          }
          else {
            return AttachmentService.uploadCallback(ctrl.logo.file)
              .then(attachment => ctrl.team.DSUpdate({ logo_image_key: null, remote_logo_url: attachment.url }))
          }
        }

        function saveColor() {
          return ctrl.team.DSUpdate({ primary_color: ctrl.color.replace(/^#/, '') }) // team doesn't like leading #
        }

        function launchStore() {
          pageViewHandler.fireEvent('Apparel.CreateStore', 8)
          // ctrl.logo and ctrl.color validated thru disabled button
          return $q.all([
            saveLogo(),
            saveColor()
          ])
            .then(setWhile(ctrl, 'creating', createStore))
        }

        function createStore() {
          return SquadLockerStores.create({ resource_type: 'Team', resource_id: team_id })
            .then(checkForCreatedStore)
            .then(showComplete)
            .then(setAs(ctrl, 'squadLockerStore'))
            .then(setAs(ctrl, 'showNewStoreModal', true))
            .catch(setAs(ctrl, 'createError'))
        }

        function checkForCreatedStore(newStore) {
          return pollUntilResolved({
            success: 'squad_locker_store_id',
            limit: 15,
            limitMsg: 'SQUAD_LOCKER.ERRORS.refresh_limit_exceeded',
            fetch: function() {
              return findStores().then(function(stores) {
                return _.find(stores, { id: newStore.id })
              })
            }
          })
        }

        function showComplete(store) {
          return setWhile(ctrl, 'showComplete', function() {
            return $q(function(resolve, reject) {
              $timeout(resolve, 1000, true, store)
            })
          })()
        }

      }
    }
  })
