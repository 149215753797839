angular.module('pl-shared')
  .component('oauthCallback', {
    template: '<spinner></spinner>',
    scope: {},
    bindings: {},
    controller: function($location, $window, OAuthState, showError) {
      var $ctrl = this
      var state

      $ctrl.$onInit = function() {
        state = OAuthState.load()

        if (!state.returnTo || state.state !== $location.search().state) {
          return showError('404', 'oauthCallback')
        }

        $window.location.href = state.returnTo
      }
    }
  })
