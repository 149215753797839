require('team-admin')
angular.module('team-admin')
  .directive('eventInformation', function() {
    return {
      restrict: 'A',
      scope: {
        originalEvent: '=resource',
        invitations: '=',
        changeRange: '<?',
        team: '=',
        confirm: '=',
        cancel: '=',
        dialogOptions: '='
      },
      template: require('./event-information.html'),
      controller: function(pageViewHandler, $q, $scope, $rootScope, Event, Alerts, $timeout, moment, dialog, Venue) {
        pageViewHandler.fireEvent('EditEventModal.Open')

        $scope.scope = $scope
        $scope.$watch('originalEvent', function(event) {
          if (!event) return
          $scope.loading = false
          $scope.wasRecurring = $scope.originalEvent.recurInterval !== 'none'
          cloneEvent()
        })

        $scope.isPastEvent = function() {
          var eventStartDateTime = moment($scope.event.start_date_time)
          var oneDayAgo = moment().subtract(1, 'days')
          return !eventStartDateTime.isBefore(oneDayAgo)
        }

        $scope.save = function() {
          $scope.failed = false

          return saveEvent().then(saveSuccess, done)
        }

        function cloneEvent(newVal, oldVal) {
          if (newVal && newVal == oldVal) return
          $scope.event = angular.copy($scope.originalEvent)
          if ($scope.wasRecurring && $scope.changeRange === 'one') $scope.event.recurInterval = 'none'
        }

        function saveEvent(resendInvites) {
          var event = angular.copy($scope.event)

          function confirmRsvpRequired() {
            var hasDifferences = prop => !angular.equals($scope.originalEvent[prop], $scope.event[prop])
            return _.any(['recur_info', 'date', 'start_time', 'end_time', 'tbd_time', 'all_day_event'], hasDifferences) ||
              $scope.changeRange !== 'one' && hasDifferences('recurInterval') // handles future && new recur
          }

          if (!resendInvites && confirmRsvpRequired()) {
            return dialog.confirm({
              directive: 'confirm-modal',
              attrs: {
                message: `SCHEDULE.EDIT_EVENT.resend_rsvps_note${ event.recurInterval !== 'none' || $scope.changeRange === 'future' ? '_recur' : '' }`,
                cancelButton: 'MODAL.cancel',
                confirmButton: 'MODAL.confirm'
              }
            })
              .then(() => saveEvent(true), $q.reject('canceled'))
          }

          function updateEvent() {
            var endpoint = `/v3/calendar/team/${ $scope.team.id }/event_series`
            var singleEndpoint = { endpoint: endpoint.replace(/_series$/, '') }
            var params = { show_events: 1 }
            var postOpts = { method: 'POST', upsert: false } // id on event will cause PUT otherwise
            var isRecurring = $scope.event.recurInterval !== 'none'
            var notify = ev => $rootScope.$emit('event:save_recurring', ev)

            function checkStatus(es) {
              if (es.series_status === 'completed') notify(Event.inject(es.event_list)[0]) // listeners reload on this event
              else $timeout(() => es.DSRefresh({ endpoint, params }).then(checkStatus), 1000)
            }

            if ($scope.wasRecurring && $scope.changeRange === 'future') {
              var opts = {
                endpoint,
                params: { event_id: event.event_id, ...params } // Start and end dates are taken from the event_id in the event properties in CS
              }
              return (isRecurring ? // false case is for going from recur to non
                Event.update(event.event_series_id, event, { ...opts, method: 'PUT', upsert: false }) :
                Event.destroy(event.event_series_id, opts))
                .then(isRecurring ? checkStatus : _es => Event.create(event, { ...singleEndpoint, ...postOpts }).then(notify))
            }
            // This is for changing the recur from non-recur to recur. Updating a single event in a series goes to the else condition
            // because recurInterval gets set to 'none' in cloneEvent and the dropdown is hidden in event-settings due to changeRange === 'one'.
            else if (isRecurring) {
              return Event.create(event, { ...postOpts, endpoint })
                .then(checkStatus)
            }
            else {
              return Event.update(event.id, event, singleEndpoint)
            }
          }

          return Venue.check(event, $scope.team)
            .then(updateEvent)
            .then($scope.setAs('savedEvent'))
        }

        function saveSuccess() {
          _.extend($scope.originalEvent, $scope.savedEvent)
          if ($scope.originalEvent.recurInterval === 'none' || $scope.changeRange === 'one') $scope.$emit('event:save', $scope.originalEvent)
          Alerts.success('SCHEDULE.EDIT_EVENT.save_success')
          $scope.cancel()
        }

        function done(opts) {
          $scope.failed = opts != 'canceled'
        }
      }
    }
  })
