angular.module('pl-shared')
  .filter('list', function(_, i18ng) {

    // "LIST_FORMAT" : {
    //   "separator" : ", ",
    //   "separator_only" : " and ",
    //   "separator_final" : ", and ",
    //   "limit" : 3,
    //   "others_start_index" : 2,
    //   "others" : "__othersCount__ __otherTerm__"
    // },

    return function(array, translationKey, options) {
      if (!array) return ' '

      if (typeof array === 'string') return array

      if (typeof translationKey === 'object') {
        options = translationKey
        translationKey = null
      }

      var format = options && options.format
      options = _.omit(options, 'format')

      if (_.isString(format)) format = _.partial(_.result, _, format, undefined)
      if (!_.isFunction(format)) format = _.identity

      var list = _.map(array, format)

      var listFormat = _.clone(i18ng.t('LIST_FORMAT', { returnObjectTrees: true }))
      _.extend(listFormat, _.pick(options, _.keys(listFormat)))
      var res = ''

      // Split of others array (as last item) if over the limit
      if (list.length > listFormat.limit) {
        var others = list.splice(listFormat.others_start_index || listFormat.limit - 1)
        if (listFormat.others) {
          list.push(i18ng.t(listFormat.others, {
            othersCount: others.length,
            otherTerm: i18ng.t('LIST_FORMAT.OTHER_TERMS.' + listFormat.other_term, { count: others.length })
          }))
        }
      }

      _.each(list, function(item, i) {
        if (i === 0) res += item
        else if (list.length === 2) res += listFormat.separator_only + item
        else if (i + 1 === list.length) res += listFormat.separator_final + item
        else res += listFormat.separator + item
      })

      return translationKey ? i18ng.t(translationKey, _.defaults({ list: res }, options)) : res
    }
  })
