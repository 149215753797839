require('team-admin')
angular.module('team-admin')
  .component('printRoster', {
    bindings: {
      print: '<',
      cancel: '<',
      team: '<'
    },
    template: require('./print-roster.html'),
    controller: function(_, $http, $q, getEnum, Player, Roster, SNAP) {
      var ctrl = this

      ctrl.$onInit = function() {
        loadMembers()
          .then(ctrl.print)
      }

      function loadMembers() {
        return Player.findAll({
          team_id: ctrl.team.id
        }, _.extend({
          load: 'all',
          bypassCache: true
        }, Player.v3Options))
          .then(Roster.loadRosteringPersonas)
          .then(function(roster) {

            var guestObj = { isGuest: true }
            var chunkSize = { player: 26, staff: 4 } // player section is 28 rows with 2 guest rows
            function sortLower(prop) { // helper for case-insensitive sorting
              return function(player) {
                return (player[prop] || '').toLowerCase()
              }
            }

            var sorted =  _.sortByAll(roster, sortLower('last_name'), sortLower('first_name'))
            var grouped = _.groupBy(sorted, 'roster_role')
            grouped = _.mapValues(grouped, function(arr) { return _.uniq(arr, false, 'persona.persona_id') })
            _.defaults(grouped, { player: [], staff: [] }) // in case no players/staff on the team
            var chunked = _.mapValues(grouped, function(arr, key) { return _.chunk(arr, chunkSize[key]) }) // break data set into page-size chunks
            var pagePairs = _.spread(_.zip)(_.values(chunked)) // get sets of each page's worth of data for players and staff
            ctrl.pages = _.map(pagePairs, _.partial(_.object, _.keys(chunked))) // turn each set into an object with player/staff properties
            _.each(ctrl.pages, function(page) {
              _.each(page, function(arr, key) {
                page[key] = arr || [] // prevent undefined errors when setting length
                page[key].length = chunkSize[key] // make each array a page's length
              })
              page.player.push(guestObj, guestObj) // add two Guest objects for player
              _.each(page, function(arr, key) {
                page[key + 'Pictures'] = _.chunk(arr, 4) // make this easy for the template to iterate four at a time
              })
            })

            ctrl.types = getEnum('REPORTS.ROSTER.TYPES')
            ctrl.logoUrl = SNAP.assetPath + '/images/sportsengine-logo-color-black.svg'
            ctrl.teamLogoUrl = SNAP.assetPath + '/images/organization-star-padded.svg'
            ctrl.avatarUrl = SNAP.assetPath + '/images/avatar-empty-padded.svg'
            ctrl.now = new Date()
            ctrl.programText = ctrl.team.activeSeason().name

            // We have to wait until all the images are loaded until we print, otherwise they won't show up.
            // Set up a hash to track the loads here and only call print (resolve the promise) when they're all done.
            var imgLoads = {}
            _.each(ctrl.pages, function(page, pageIndex) {
              imgLoads[pageIndex + '_logo'] = imgLoads[pageIndex + '_team'] = false
              _.each(ctrl.types, function(type, typeIndex) {
                _.each(page[type.value + 'Pictures'], function(row, rowIndex) {
                  _.each(row, function(pic, index) {
                    imgLoads[[pageIndex, typeIndex, rowIndex, index].join('_')] = false
                  })
                })
              })
            })

            var dfd = $q.defer()
            ctrl.imgLoaded = function() {
              imgLoads[[].join.call(arguments, '_')] = true
              if (_.all(imgLoads)) dfd.resolve()
            }

            return dfd.promise

          })
      }
    }
  })
