angular.module('pl-shared')
  .component('chatMessageLike', {
    bindings: {
      allMessages: '=',
      message: '=',
      likes: '=',
      isPhoto: '=',
      isLikedByUser: '=',
      userId: '='
    },
    template: require('/static/shared/components/chat/chat-message-like.html'),
    controller: function() {
      var $ctrl = this

      $ctrl.toggleLike = function() {
        if ($ctrl.isLikedByUser) {
          delete $ctrl.message.reactions[$ctrl.userId]
        }
        else {
          if (!$ctrl.message.reactions) $ctrl.message.reactions = {}
          $ctrl.message.reactions[$ctrl.userId] = true
        }
        $ctrl.isLikedByUser = !$ctrl.isLikedByUser
        $ctrl.allMessages.$save($ctrl.message)
      }
    }
  })
