angular.module('pl-shared', [
  'ngRoute',
  'ngMessages',
  'ngDialog',
  'i18ng',
  'firebase',
  'renderContext',
  'checklist-model',
  'dndLists',
  'js-data',
  'ui.timepicker',
  'linkify',
  'shared-module'
])
