require('team-admin')
angular.module('team-admin')
  .directive('eventsList', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./events-list.html'),

      controller: function(_, $scope, $log,
        Calendar, Alerts, selectHelper, dialog, moment, preloadScript,
        timezone, showError, debounceCallback, ENV, launchDarklyFlags, Organization, Subvenue, Event) {

        var loading = $scope.setAs('loading')
        var ctrl = this
        loading(true)

        preloadScript(`${ ENV.urls.sportAdminElements }/event-add-edit-modal-element/element.js`)
        preloadScript(`${ ENV.urls.sportAdminElements }/game-add-edit-modal-element/element.js`)
        preloadScript(`${ ENV.urls.seFeAngularServices }/se-fe-play-chip/se-fe-play-chip.js`)

        $scope.selectedEvents = selectHelper.bind($scope, 'filteredEvents')

        $scope.$watchGroup(['team.id', 'viewDate'], function(group) {
          if (_.all(group)) loadEvents()
        })

        var findAll = debounceCallback(Calendar.findAll)
        function loadEvents() {
          loading(true)
          findAll({
            start_date: $scope.viewDate.clone().startOf('month').format(),
            end_date: $scope.viewDate.clone().endOf('month').endOf('day').format(),
            order_by: 'start_date',
            show_event_attendees: 1,
          }, { endpoint: `/v3/calendar/team/${ $scope.team.id }`, load: 'all' })
            .then((events) => {
              $scope.events = events
              $scope.eventOptions = {}
              events.forEach((event) => {
                const route = 'team_center_schedule'
                $scope.eventOptions[event.id] = Event.setPlayChipOpts(event, route)
              })
            })
            .catch(showError)
            .finally(loading.bind(null, false))
        }

        $scope.listenToRoot('event:save_recurring', loadEvents)

        $scope.$watch('team', function(team) {
          return $scope.team.getRoster()
            .then(function(players) {
              $scope.players = players
              $scope.hasPlayers = !_.isEmpty(players)
            })
            .catch($log.error)
        })


        function eventZoneAbbrev(event) {
          return timezone.current.abbr(moment.tz(event.date, event.local_timezone))
        }

        async function getOrgSubvenues() {
          const subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' }).filter((i) => 'venue_name' in i)
          return subvenues
        }

        function updateTimezoneAbbrev(events) {
          if (!events) return
          var count = events.length
          var abbrevs = []

          if (count) abbrevs.push(eventZoneAbbrev(events[0]))
          if (count > 1) abbrevs.push(eventZoneAbbrev(events[count - 1]))

          $scope.timezoneAbbrev = count ? '(' + _.uniq(abbrevs).join('/') + ')' : ''
        }

        $scope.orderByTime = Calendar.orderByTime
        $scope.$watch('filteredEvents', updateTimezoneAbbrev)

        /** @deprecated */
        // TODO: Remove when Game modal is done and deployed to production
        const addGameUsingOldModal = (type = 'game') => {
          dialog.confirm({
            directive: `add-${type}`,
            scope: $scope,
            attrs: { team: $scope.team }
          })
            .then($scope.addEvent)
        }

        $scope.addGameModal = async function(type = 'game') {
          if (!launchDarklyFlags.gameAddEditModal) {
            return addGameUsingOldModal(type)
          }

          const modal = document.createElement('se-game-add-edit-modal')
          modal.seDataTriggerId = 'se-add-game-tc'
          document.body.append(modal)
          modal.addEventListener('seAfterClose', () => setTimeout(() => modal.remove(), 5000))
          modal.addEventListener('seOnSuccess', () => loadEvents())
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          let subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)

          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i))

          modal.seOpen = true
        }

        $scope.addEventModal = async function() {

          const modal = document.createElement('se-event-add-edit-modal')
          document.body.append(modal)
          modal.seDataTriggerId = 'se-add-event-tc'
          modal.addEventListener('seAfterClose', () => setTimeout(() => modal.remove(), 5000))
          modal.addEventListener('seOnSuccess', () => loadEvents())
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          const subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)

          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i) || [])

          modal.seOpen = true
        }

        $scope.addEvent = function(event) {
          $scope.events.push(event)
          // loadEvents()
        }

        const deleteEventModal = (events, prev) => {
          const eventType = new Set(events.map(({ event_type }) => event_type))
          const listOfTypes = Array.from(eventType)
          const type = listOfTypes.length > 1 ? 'mix' : listOfTypes[0]

          if (type === 'game' && !launchDarklyFlags.gameAddEditModal) {
            return prev()
          }

          const modal = document.createElement('se-event-add-edit-modal')
          modal.seDataTriggerId = `se-delete-${ type }-tc`

          if (events.length === 1) {
            modal.seEventId = events[0].id

            if (events[0].is_affiliated) {
              modal.seIsEventAffiliated = true
            }
          }
          else {
            modal.seEventsIds = JSON.stringify(events.filter((event) => !event.is_affiliated).map(({ id }) => id))
            const hasAffiliatedEvent = events.some((event) => !!event.is_affiliated)

            if (hasAffiliatedEvent) {
              modal.seIsEventAffiliated = true
            }
          }

          modal.toDelete = type
          document.body.append(modal)
          modal.addEventListener('seAfterClose', () => setTimeout(() => modal.remove(), 5000))
          modal.addEventListener('seOnSuccess', () => loadEvents())

          modal.seTeam = JSON.stringify($scope.team)

          modal.seOpen = true
        }

        $scope.removeEventsModal = function() {
          const prevMethod = () => dialog.confirm({
            directive: 'remove-events',
            attrs: {
              team: $scope.team,
              events: $scope.selectedEvents,
              gaCategory: 'Schedule List'
            }
          }).then($scope.removeEvents)

          deleteEventModal($scope.selectedEvents, prevMethod)
        }

        $scope.removeEvents = function(deletedEvents) {
          _.each(deletedEvents, function(event) {
            var eventInList = _.findWhere($scope.events, { id: event.id })
            _.pull($scope.events, eventInList)
          })
          var opts = {
            count: deletedEvents.length,
            event: deletedEvents[0]
          }
          // loadEvents()
          Alerts.success('SCHEDULE.REMOVE.success', opts)
        }

      }
    }
  })
