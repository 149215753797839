angular.module('pl-shared')
  .directive('googlePlaces', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, model) {
        var options = {
          types: []
        }
        scope.gPlace = new google.maps.places.Autocomplete(element[0], options)

        google.maps.event.addListener(scope.gPlace, 'place_changed', function() {
          scope.$apply(function() {
            model.$setViewValue(element.val())
            var place = scope.gPlace.getPlace()

            if (place === undefined) { return }

            scope.event.location_place_id = place.place_id
            scope.event.place = place
          })
        })
      }
    }
  })
