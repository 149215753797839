(function() {
  'use strict'

  // This service provides a getter/setter entry point for all variables for which
  // there should only be one of at a time throughout the app's front-end life cycle.

  // getter: snGlobals('foo')
  // setter: snGlobals('foo', val)

  var values = {}

  angular.module('pl-shared')
    .service('snGlobals', function($rootScope) {
      // var childScope = $rootScope.$new()
      function snGlobals(varName, newVal) {
        var oldVal = values[varName]
        if (arguments.length > 1) {
          values[varName] = newVal
          if (oldVal !== newVal) {
            $rootScope.$emit('snGlobalChange:' + varName, newVal, oldVal)
          }
          return newVal
        }
        return oldVal
      }

      snGlobals.setAs = function(varName) {
        return function(newVal) {
          snGlobals(varName, newVal)
        }
      }

      snGlobals.watcher = function(varName) {
        return function() {
          return values[varName]
        }
      }

      // convenience method for binding and destroying listeners for global vars
      $rootScope.constructor.prototype.onSnGlobalChange = function(varName, callback) {
        this.$on('$destroy', $rootScope.$on('snGlobalChange:' + varName, callback))
      }

      window.rootScope = $rootScope
      window.snGlobals = snGlobals


      return snGlobals
    })
})()
