require('team-admin')
angular.module('team-admin')
  .factory('TeamInvite', function(DS) {
    return DS.defineResource({
      name: 'teamInvite',
      endpoint: '/v3/roster_personas/invitations', // overwrite for calls
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
    })
  })
