angular.module('pl-shared')
  .directive('timeQuestion', function() {

    return {
      replace: true,
      template: require('/static/shared/components/regform/questions/time-question.html'),
      scope: {},
      controllerAs: 'ctrl',
      bindToController: {
        model: '=',
        answer: '=',
        question: '='
      },
      controller: function() {
        var ctrl = this

        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.model.time_of_day = ctrl.answer.value.time_of_day || null
        ctrl.model.time_of_day_additional_info = ctrl.answer ? ctrl.answer.value.time_of_day_additional_info : ''
        ctrl.model.add_additional_info = ctrl.model.time_of_day_additional_info ? true : false

        ctrl.properties = ctrl.question.properties

        ctrl.toggleCheckAdditionalInfo = function() {
          ctrl.model.time_of_day_additional_info = ''
        }
      }
    }
  })
