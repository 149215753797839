function plEllipsisHandler($scope, $element, $attrs) {
  if ('title' in $attrs) return // element has set its own tooltip already

  function setTitle($event) {
    $element.attr('title', ($element[0].clientWidth < $element[0].scrollWidth) ? $element.text().replace(/\s+/g, ' ').trim() : '')
  }
  $element.on('mouseenter', setTitle)
  $scope.$on('$destroy', function() {
    $element.off('mouseenter', setTitle)
  })
}

angular.module('pl-shared')

  .directive('plEllipsis', function() {
    return {
      restrict: 'C',
      link: plEllipsisHandler
    }
  })
