require('team-admin')
angular.module('team-admin')
  .directive('eventTypeSelect', function() {
    return {
      restrict: 'A',
      require: '?^gaCategory',
      template: require('./event-type-select.html'),
      link: function($scope, $element, $attrs, ctrl) {
        if (ctrl) $scope.gaCategoryCtrl = ctrl
      },
      controller: function(_, $scope, $document, $timeout, Calendar, moment) {

        $scope.gaLabels = {
          all: 'All Filter',
          game: 'Games Filter',
          event: 'Events Filter'
        }

        function updateFilteredEvents() {
          if (!($scope.viewDate && $scope.events)) return

          var startOfMonth = $scope.viewDate.clone().startOf('month')
          var endOfMonth = $scope.viewDate.clone().endOf('month')

          $scope.filteredEvents = _.sortBy(_.filter($scope.events, function(event) {
            var type = $scope.eventType
            var date = moment.tz(event.start_date_time, $scope.team.timezone)
            var typeMatches = type === 'all' || Calendar.instanceType(event).toLowerCase() === type
            var dateMatches = date >= startOfMonth && date <= endOfMonth

            return typeMatches && dateMatches
          }), 'start_date_time')
        }

        $scope.eventType = 'all'
        $scope.setEventType = $scope.setAs('eventType')
        $scope.$watchGroup(['viewDate', 'eventType'], updateFilteredEvents)
        $scope.$watchCollection('events', updateFilteredEvents)

      }
    }
  })
