// Helper to print content.

angular.module('pl-shared')
  .run(function($window) {
    if ('onafterprint' in $window || !$window.matchMedia) return

    // Shim the beforeprint/afterprint events for browsers that do not natively support them
    $window.matchMedia('print').addListener(function(mqlEvent) {
      angular.element($window).trigger(mqlEvent.matches ? 'beforeprint' : 'afterprint')
    })
  })
  .service('printService', function(_, $q, i18ng, $compile, $rootScope, $timeout, $window) {

    var DEFAULT_OPTS = {}
    var DEFAULT_MARGIN = .5 // inches
    var PRELOAD_STRING = 'BESbswy'
    var PRELOAD_CSS = { position: 'absolute', left: '-999in', top: '-999in', zIndex: -99 }
    // var PRELOAD_CSS = { position: 'absolute', left: '0', top: '50%', zIndex: '9999', background: 'yellow' }
    var PRELOAD_FONT_SIZE = { fontSize: '1000px' }
    var PRELOAD_FONT_FAMILY = { fontFamily: 'sans-serif' }
    var PRELOAD_TIMEOUT = 100

    var $ = angular.element
    var $win = $($window)
    var $printer = $('<div id="Printable">')
    var $preloader = $('<div/>').css(PRELOAD_CSS)
    var fontCache = {}

    return {
      printContent: printContent,
      preloadFonts: preloadFonts
    }

    function printContent(opts) {
      if (!opts || !(opts.directive || opts.component)) throw new Error('Cannot print the content. A directive or component name is required.')
      var printDfd = $q.defer()
      opts.attrs = _.extend({}, opts.attrs, {
        print: printDfd.resolve, // the component will call this when it's ready for printing, and it will call $window.print() in the returned promise below
        cancel: printDfd.reject
      })

      var $scope = setupScope(opts)
      var values = _.keys(opts.attrs)
      var keys = _.map(values, _.kebabCase)
      var attrsMap = _.object(keys, values)
      var template = opts.component ? componentTemplate(opts.component, attrsMap) : directiveTemplate(opts.directive, attrsMap)

      return preloadFonts()
        .then(renderPrintView)
        .then(print)
        .catch(printError)

      function renderPrintView() {
        $compile(template)($scope, appendPrintView)
        pageSetup(opts)
        if (!$rootScope.$$phase) $scope.$digest()

        // Use afterprint event to clear out the printable div (window.print() in Safari is non-blocking after one canceled print)
        $win.one('afterprint', clearPrintDiv)
        return printDfd.promise
      }
    }

    function pageSetup(opts) {
      var orientation = opts.landscape ? 'landscape' : 'portrait'
      var pageSize = 'letter ' + orientation
      var pageWidth = opts.landscape ? 11 : 8.5 // inches
      var margin = opts.margin || DEFAULT_MARGIN // inches
      var contentWidth = pageWidth - (margin * 2)
      var style = '<style>@page { size: ' + pageSize + '; } @media screen { #Printable { width: ' + contentWidth + 'in; } }</style>'
      $printer.append(style)
    }

    function directiveTemplate(directive, attrs) {
      var opts = angular.extend({}, DEFAULT_OPTS, attrs)
      opts[directive] = ''
      return $('<div/>').attr(opts)[0].outerHTML
    }

    function componentTemplate(component, attrs) {
      var opts = angular.extend({}, DEFAULT_OPTS, attrs)
      return $('<' + component + '>').attr(opts)[0].outerHTML
    }

    function setupScope(opts) {
      var prefixRgx = /^[@&=<]/
      var parentScope = opts && opts.scope || $rootScope
      var $scope = parentScope.$new()

      _.extend($scope, opts.attrs)

      // copy properties from scope
      _.each(opts.scope && opts.attrs, function(val, key) {
        if (prefixRgx.test(val)) {
          opts.attrs[key] = key
          var attr = val.replace(prefixRgx, '') || key
          $scope[attr] = opts.scope.$eval(attr)
        }
      })

      return $scope
    }

    function print() {
      return $timeout($window.print)
    }

    function printError(err) {
      clearPrintDiv()
      return $q.reject(err)
    }

    function appendPrintView(clone) {
      $printer
        .empty()
        .insertBefore('#App')
        .append(clone)
    }

    function clearPrintDiv() {
      $printer.empty().remove()
    }

    function preloadFonts() {
      $preloader.appendTo('body')
      return $q.all(_.map(cssFonts(), preloadFont))
        .then(preloadFontsCleanup)
    }

    function cssFonts() {
      try {
        var $fontFaceList = $('<font-face-list/>').appendTo('body')
        var json = $fontFaceList.css('fontFamily').replace(/\\/g, '').replace(/^"|"$/g, '')
        $fontFaceList.remove()
        var fontData = JSON.parse(json)
        return fontData
      }
      catch (e) {
        return []
      }
    }

    function preloadFont(fontCss) {
      var fontName = _.values(fontCss).join(' ')
      var loaded = fontCache[fontName]
      if (loaded) return loaded
      var dfd = $q.defer()
      var pairs = generatePreloadPairs(fontCss)
      checkPreloadPairs(fontName, pairs, dfd)
      return fontCache[fontName] = dfd.promise
    }

    function generatePreloadPairs(fontCss) {
      var customCss = _.extend({}, fontCss, PRELOAD_FONT_SIZE)
      var defaultCss = _.extend({}, customCss, PRELOAD_FONT_FAMILY)
      var checks = PRELOAD_STRING.split('').concat(PRELOAD_STRING)
      var pairs = []
      while (checks.length) {
        var el = '<span>' + checks.pop() + '</span>'
        pairs.push([
          $(el).appendTo($preloader).css(customCss)[0],
          $(el).appendTo($preloader).css(defaultCss)[0]
        ])
      }
      return pairs
    }

    function checkPreloadPairs(fontName, pairs, dfd) {
      if (_.any(pairs, checkPreloadPair)) return dfd.resolve()
      $timeout(checkPreloadPairs, PRELOAD_TIMEOUT, false, fontName, pairs, dfd)
    }

    function checkPreloadPair(pair) {
      return pair[0].offsetWidth !== pair[1].offsetWidth
    }

    function preloadFontsCleanup() {
      // remove the extra markup later (not returned because there is no need to wait for this)
      $timeout(function() { $preloader.empty().remove() }, PRELOAD_TIMEOUT, false)
    }

  })
