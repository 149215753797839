require('team-admin')
angular.module('team-admin')
  .factory('EventRsvp', function(apiV2, DS, Player) {
    var TEMP_ID = 0
    var EventRsvp = DS.defineResource({
      name: 'event_rsvps',
      endpoint: '/v3/rsvp',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      bypassCache: true,
      upsert: false,
      beforeInject: function beforeInject(resource, instance) {
        if (_.isArray(instance)) return _.map(instance, ins => beforeInject(resource, ins))
        if (!instance.id) {
          if (instance.event_id && instance.principal_id && instance.persona_id) {
            instance.id = `${ instance.event_id }_${ instance.principal_id }_${ instance.persona_id }` // avoid bloat
          }
          else instance.id = TEMP_ID--
        }
        if (/^\d+$/.test(instance.persona_id)) instance.persona_id = +instance.persona_id // comes as a string from CS
        return instance
      },
      relations: {
        belongsTo: {
          events: {
            localKey: 'event_id',
            localField: 'event'
          },
          player: {
            localKey: 'persona_id',
            localField: 'player',
            get: function(CompositeRole, relationDef, rsvp, origGetter) {
              return _.filter(Player.getAll(), { persona: { persona_id: +rsvp.persona_id } })[0]
            }
          }
        }
      },
      actions: {
        remind: {
          method: 'POST',
          response: apiV2.deserialize
        }
      }
    })

    return EventRsvp
  })
