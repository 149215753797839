angular.module('pl-shared')

  .directive('logoQuestion', function() {

    return {
      scope: {},
      template: require('/static/shared/components/registration/questions/profile-photo-question.html'),
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '=',
        editMode: '=',
        organizationName: '='
      },
      controller: function(_, $scope, dialog, fileHelper, i18ng, currentUser) {
        var ctrl = this

        ctrl.fileHelper = fileHelper
        ctrl.properties = ctrl.question.properties
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.saveAsProfile = false
        ctrl.viewable = false
        $scope.$watch('ctrl.editMode', function(editMode) {
          setInitial()
          if (!_.isObject(ctrl.answer) || !_.isObject(ctrl.answer.value)) return ctrl.model = null
          var url = ctrl.answer.value.url
          var icon = ctrl.answer.value.crop_url
          updateModel(fileHelper.parseUrl(url, icon))
        })

        ctrl.edit = function() {
          if (!ctrl.question.isEditable) return
          var dialogModel = ctrl.model
          //  If the current file is the default file, call file-upload with an empty model to display "upload" form directly
          if (ctrl.properties.clearWhenMatch && _.isString(_.get(ctrl, 'model.filename')) && ctrl.model.filename.match(ctrl.properties.clearWhenMatch)) {
            dialogModel = {}
          }
          dialog.confirm({
            directive: 'file-upload-modal',
            scope: $scope,
            attrs: {
              fileUploadOptions: {
                accept: fileHelper.imageTypes,
                allowCrop: true,
                clearLinkText: i18ng.t('ANSWER.LOGO.clear_link'),
                imgClass: 'pl-document-upload__image--profile-photo',
                backgroundImage: true,
                showFilename: false,
                titleText: ctrl.properties.upload_title || i18ng.t('ANSWER.LOGO.title'),
                uploadLinkText: i18ng.t('ANSWER.LOGO.upload_link')
              },
              model: dialogModel
            }
          })
            .then(updateModel)
        }

        ctrl.viewImage = function(event) {
          event.stopPropagation()
          dialog.confirm({
            directive: 'image-preview-modal',
            scope: $scope,
            attrs: {
              imgUrl: fileHelper.base64Data(ctrl.model) || ctrl.model.url,
              backgroundImage: true,
              imgClass: 'pl-image-preview-modal__image--profile-photo'
            }
          })
        }

        function updateModel(model) {
          if (model && model.data && model.filetype) ctrl.viewable = true
          else if (ctrl.answer && ctrl.answer.value) {
            ctrl.viewable = !ctrl.answer.value.is_default
          }
          else ctrl.viewable = false

          ctrl.model = model
        }

        function setInitial() {
          if (!_.isObject(ctrl.answer) || !_.isObject(ctrl.answer.value)) return ctrl.initial = null
          ctrl.initial = ctrl.answer.value.initial
        }

      }
    }

  })
