angular.module('pl-shared')

  .service('paginationSettings', function(userSettings) {
    return {
      perPage: userSettings.get('pagination.perPage') || 25,
      perPageOptions: [25, 50, 100]
    }
  })


  /* ### OPTIONS /////////////////

    load      String (defaults to 'default')

              all' -- get all pages in one promise
              inBackground' -- get all pages, but return after the first page is loaded
              more' -- get the next page and add it to the existing set of results
              default' -- get one page at a time and replace the set of results

    results   Array (defaults to undefined)

              A predefined array where fetched items will be inserted.
              Useful for preserving an object reference.

    showUnfilteredTotal   Boolean

              Maintains the original `total` number of the unfiltered results list as `unfiltered_total` when updating the set

  /* ### PROPERTIES /////////////////

    results.loading -- Set to true when any active request is in progress.

    results.loaded -- Set to true when any result request has been processed and results are available.
                      This is NOT set to false on subsequent page loads, and should be used with
                      results.loading for more complex UI.

  *////////////////////////////////

  .factory('paginate', function(snAsync, _, setWhile, $q, paginationSettings) {
    return function(Resource, paginateOpts) {

      paginateOpts = paginateOpts || {}

      var findAll = Resource.findAll.bind(Resource)
      var done = $q.when()

      Resource.findAll = function(params, opts) {
        params = _.extend({}, params)
        opts = _.extend({}, opts)

        var result = opts.result || []
        var pagination = opts.pagination || result.pagination || {}
        var staticPagination = { goto: gotoPage, reload: reload, valid: validPage, setPerPage: setPerPage }
        var loadAllPagination = { total_pages: 1, first_page: true, last_page: true, current_page: 1, offset: 0 }
        var loadType = opts.load || paginateOpts.load || 'default'
        var showUnfilteredTotal = opts.showUnfilteredTotal

        if (loadType === 'all') _.defaults(_.extend(params, { page: 1 }), { per_page: 100 }) // always load all starting at page 1
        else if (loadType === 'more') params.page = pagination.current_page + 1 || 1 // always load the next page

        result.loadError = false

        function deserialize(resourceConfig, resp) {
          var deserialized = Resource.deserialize(resourceConfig, resp)

          if (resp && resp.data) {
            var data = resp.data
            var meta = data.metadata || {}

            _.extend(pagination, meta.pagination, staticPagination)

            pagination.filtered = showUnfilteredTotal == false ? false : pagination.unfiltered_total > pagination.total
          }

          return deserialized
        }

        opts = _.extend({
          bypassCache: true,
          deserialize: deserialize
        }, opts)

        function replaceResult(newResult) {
          if (loadType !== 'more') result.length = 0
          result.push.apply(result, newResult)
          result.pagination = pagination
        }

        function appendResult(index, newResult) {
          var args = [index, 0].concat(newResult)
          result.splice.apply(result, args)
        }

        function setPerPage(perPage) {
          params.per_page = perPage
          gotoPage(1)
        }

        function gotoPage(page) {
          page = validPage(page)
          if (page === null) return done
          return setWhile(result, 'loading', loadPage)(page).then(replaceResult)
        }

        function validPage(page) {
          if (isNaN(Number(page))) return null
          return Math.max(1, Math.min(page, pagination.total_pages))
        }

        function reload() {
          return gotoPage(pagination.current_page)
        }

        function loadPage(page) {
          var newParams = page ? _.extend({}, params, { page: page }) : params

          if (typeof newParams.per_page === 'string' && newParams.per_page === 'all') {
            newParams.per_page = null
          }
          else {
            newParams.per_page = newParams.per_page || paginationSettings.perPage
          }

          opts.result = result
          return findAll(newParams, opts)
        }

        function loadPages(startPage, totalPages) {
          var pages = _.range(startPage, totalPages + 1)
          var index = 0

          function loadPageAtIndex(page) {
            if (page > 1) index += pagination.limit
            return loadPage(page).then(appendResult.bind(null, index))
          }

          return snAsync.eachLimit(pages, 5, loadPageAtIndex)
            .then(function() { return result })
        }

        function load() {
          return loadPage()
            .then(replaceResult)
            .then(function() {
              if (pagination.current_page && _.contains(['all', 'inBackground'], loadType)) {
                var loadPromise = loadPages(2, pagination.total_pages)
                _.extend(staticPagination, loadAllPagination, { limit: result.pagination.total })
                _.extend(result.pagination, staticPagination)
                if (loadType === 'all') return loadPromise
              }
              return result
            })
        }

        function loadError(err) {
          result.loadError = true
          result.loaded = true
          return $q.reject(err)
        }

        function loadComplete() {
          result.loaded = true
          return result
        }

        return setWhile(result, 'loading', load)().then(loadComplete, loadError)
      }

      return Resource
    }
  })
